
import {Component, Prop, Vue} from "vue-property-decorator";
import {ChampionshipCalendarItem} from "@/components/calendar/Calendar";
import ChampionshipCalendarItemComponent
  from "@/components/calendar/item/CalendarChampionshipLargeItemComponent.vue";

@Component({
  components: {ChampionshipCalendarItemComponent}
})
export default class ChampionshipCalendarItemWithCompetitionsComponent extends Vue {
  @Prop() championships!: Array<ChampionshipCalendarItem>

  private championshipToShow: ChampionshipCalendarItem = {} as ChampionshipCalendarItem
  private index = 0

  created() {
    this.updateChampionshipsToShow()
  }

  private nextIndex() {
    this.index++
    this.updateChampionshipsToShow()
  }

  private prevIndex() {
    this.index--
    this.updateChampionshipsToShow()
  }

  private updateChampionshipsToShow() {
    this.championshipToShow = this.championships[this.index]
  }
}
