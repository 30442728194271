import {Action, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {FinanceTransaction, Money} from "@/shared/domain/Finance";
import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";
import {TransactionData, UpdateTransactionData} from "@/services/FinanceService";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {competitionFinanceService} from "@/modules/competition/service/CompetitionFinanceService";

@Module({namespaced: true, name: 'competition-finance'})
export default class CompetitionFinanceModule extends VuexModule {
    balance: Money = {} as Money
    outstandingBalance: Money = {} as Money

    @MutationAction
    async loadBalance(req: LoadBalanceRequest) {
        const response = await competitionFinanceService.getBalance(req.ref)
        return {
            balance: response.total,
            outstandingBalance: response.outstandingTotal
        }
    }
}

interface LoadBalanceRequest {
    ref: string
}