
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyBootstrapTypeahead from "@/components/typeahead/EqifyBootstrapTypeahead.vue";
import {Avatar} from "vue-avatar";
import SearchInput from "@/components/general/SearchInput.vue";
import {PersonSearchResultItem} from "@/shared/domain/Person";
import PersonListItem from "@/components/general/PersonListItem.vue";

@Component({
  components: {PersonListItem, SearchInput, EqifyBootstrapTypeahead, Avatar}
})
export default class PersonSearchInput extends Vue {
  @Prop() value?: PersonSearchResultItem | undefined;
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop() allowUnexisting?: boolean;
  @Prop() allowSave?: boolean;
  @Prop() disabled?: boolean;

  get inputValue(): PersonSearchResultItem | undefined {
    return this.value;
  }

  set inputValue(value: PersonSearchResultItem | undefined) {
    this.$emit('input', value);
  }
}
