
import {Component, Prop, Vue} from 'vue-property-decorator';
import {emptyGeoLocation, GeoLocation} from "@/shared/domain/Address";
import ManualLocationEditComponent from "@/components/location/ManualLocationEditComponent.vue";

@Component({
  components: {ManualLocationEditComponent}
})
export default class LocationEditComponent extends Vue {
  @Prop() value?: GeoLocation

  get location() {
    if (this.value == undefined) {
      return emptyGeoLocation()
    } else return this.value
  }

  set location(value: GeoLocation) {
    this.$emit("input", value)
  }
}

