import {AvatarUrl, Language} from "@/shared/domain/General";
import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";
import _ from "lodash";
import {MembershipDetails} from "@/shared/domain/Person";
import {Vue} from "vue-property-decorator";
import {HorseResource} from "@/modules/horses/services/HorseService";
import {Membership} from "@/modules/members/services/MemberService";

export interface UserProfileData {
    personRef?: string;
    name: string;
    avatar?: AvatarUrl;
    username: string;
    email: string;
    isSuperAdmin: boolean;
    isRegistered: boolean;
    hasPendingRegistration: boolean;
    defaultFederation?: FederationUserInfo;
    defaultLanguage?: Language;
    federations: Array<FederationUserInfo>;
    organisations: Array<OrganisationUserInfo>;
    federationRoles: Map<FederationRef, FederationUserRole[]>
    organisationRoles: Map<OrganisationRef, OrganisationUserRole[]>,
    favorites: PersonFavorite[]
}

export class UserProfile {
    constructor(private data: UserProfileData) {
    }

    apply(data: UserProfileData) {
        this.data = data
    }

    get isRegistered() {
        return this.data.isRegistered
    }

    get hasPendingRegistration() {
        return this.data.hasPendingRegistration
    }

    set hasPendingRegistration(p: boolean) {
        this.data.hasPendingRegistration = p
    }

    get isSuperAdmin() {
        return this.data.isSuperAdmin
    }

    get ref(): string | undefined {
        return this.data.personRef;
    }

    get username(): string {
        return this.data.username;
    }

    get name(): string {
        return this.data.name;
    }

    get avatar(): AvatarUrl | undefined {
        return this.data.avatar;
    }

    get email(): string {
        return this.data.email;
    }

    get defaultFederation(): FederationUserInfo | undefined {
        return this.data.defaultFederation;
    }

    get defaultLanguage(): Language | undefined {
        return this.data.defaultLanguage;
    }

    get federations(): Array<FederationUserInfo> {
        return this.data.federations;
    }

    get organisations(): Array<OrganisationUserInfo> {
        return this.data.organisations;
    }

    get federationRoles(): Map<FederationRef, FederationUserRole[]> {
        return this.data.federationRoles;
    }

    get organisationRoles(): Map<OrganisationRef, OrganisationUserRole[]> {
        return this.data.organisationRoles;
    }

    get favorites(): PersonFavorite[] {
        return this.data.favorites
    }

    set favorites(pv: PersonFavorite[]) {
        this.data.favorites = pv
    }

    hasFederationRoles(): boolean {
        let hasRoles = false

        Object.entries(this.data.federationRoles).forEach((value, index) => {
            if (value[1] != undefined && value[1].length > 0) {
                hasRoles = true
            }
        });

        return hasRoles
    }

    hasAtLeastOneHigherRoleWithinFederation(federationRef?: string): boolean {
        const arr1 =
            this.organisations
                .filter(it => it.federationRef == federationRef)
                .map(it => {
                    return `ORG:${it.ref}`
                })

        arr1.push(`FED:${federationRef}`)

        const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_ADMINISTRATOR], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR])
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`hasAtLeastOneHigherRoleWithinFederation? ${arr1}, ${arr2}, ${result}`);
        return result
    }

    hasAtLeastOneHigherOrderRole(): boolean {
        const intents = this.getIntents();
        const result = this.data.isSuperAdmin || intents.length > 0;
        Vue.$log.debug(`hasAtLeastOneHigherOrderRole? ${intents} ${result}`);
        return result;
    }

    canCreateMember(): boolean {
        const intents = this.getIntents();
        const result = this.data.isSuperAdmin || intents.length > 0;
        Vue.$log.debug(`canCreateMember? ${intents} ${result}`);
        return result;
    }

    canCreateOrganisationWithinFederation(federationRef: FederationRef | undefined): boolean {
        const arr1 = federationRef ? [`FED:${federationRef}`] : [];
        const arr2 = this.getIntents([]);
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canCreateOrganisationWithinFederation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canUpdateOrganisationWithinFederation(federationRef: FederationRef, organisationRef: OrganisationRef): boolean {
        const arr1 = [`FED:${federationRef}`, `ORG:${organisationRef}`];
        const arr2 = this.getIntents();
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canUpdateOrganisationWithinFederation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canCreateHorse(): boolean {
        Vue.$log.debug(`canCreateHorse?`);
        return this.username !== undefined;
    }

    canImportHorses(): boolean {
        Vue.$log.debug(`canimportHorses?`);
        return this.isSuperAdmin
    }

    canUpdateHorse(horse: HorseResource): boolean {
        let result = this.data.isSuperAdmin || this.isFederationAdministratorOrCollaboratorOfAnyFederation()
        if (!horse.verified && horse.createdBy) {
            result = result || (horse.createdBy.ref == this.ref && horse.practitionerCount <= 0)
        }
        Vue.$log.debug(`canUpdateHorse? ${result}`);
        return result;
    }

    canManagePractitioner(federationRef: FederationRef | undefined, memberShipDetails: MembershipDetails | undefined): boolean {
        const arr1 = [];
        if (federationRef) {
            arr1.push(`FED:${federationRef}`);
        }
        if (memberShipDetails) {
            const associations = memberShipDetails.associations || [];
            associations.map(a => a.ref).filter(ref => ref !== undefined).forEach(o => arr1.push(`ORG:${o}`))
        }
        const arr2 = this.getIntents();
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canManagePractitioner? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canUpdateMember(federationRef: FederationRef | undefined, memberShipDetails: MembershipDetails | undefined): boolean {
        const arr1 = [];
        if (federationRef) {
            arr1.push(`FED:${federationRef}`);
        }
        if (memberShipDetails) {
            const associations = memberShipDetails.associations || [];
            associations.map(a => a.ref).filter(ref => ref !== undefined).forEach(o => arr1.push(`ORG:${o}`))
        }
        const arr2 = this.getIntents();
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canUpdateMember? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canImportPersons(): boolean {
        Vue.$log.debug(`canImportPersons?`);
        return this.isSuperAdmin
    }

    hasAtleastOneHigherRoleInsideAnyMemberOrganisation(membershipDetails: MembershipDetails | undefined): boolean {
        const arr1 = [] as Array<string>;
        if (membershipDetails) {
            const associations = membershipDetails.associations || [];
            associations.map(a => a.ref).filter(ref => ref !== undefined).forEach(o => arr1.push(`ORG:${o}`))
        }
        const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_ADMINISTRATOR]);

        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`hasAtleastOneHigherRoleInsideAnyMemberOrganisation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canUpdateOrganisation(federationRef: FederationRef | undefined, organisationRef: OrganisationRef | undefined): boolean {
        const arr1 = [];
        if (federationRef) {
            arr1.push(`FED:${federationRef}`);
        }
        if (organisationRef) {
            arr1.push(`ORG:${organisationRef}`);
        }
        const arr2 = this.getIntents();

        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canUpdateOrganisation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canCreateCourseWithinFederation(federationRef: FederationRef | undefined): boolean {
        const arr1 = federationRef ? [`FED:${federationRef}`] : [];
        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR]);
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canCreateCourseWithinFederation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canManageAndReadFederationData(federationRef: FederationRef): boolean {
        const arr1 = [`FED:${federationRef}`];
        const arr2 = this.getIntents([]);
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canManageAndReadFederationData? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canManageFederationData(federationRef: FederationRef): boolean {
        const arr1 = [`FED:${federationRef}`];
        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR]);
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canManageFederationData? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canManageAndReadOrganisationData(federationRef: FederationRef, organisationRef: OrganisationRef): boolean {
        if (!this.isFederationAdministratorOrCollaborator(federationRef)) {
            const arr1 = [`ORG:${organisationRef}`];

            const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_ADMINISTRATOR, OrganisationUserRole.ORGANISATION_COLLABORATOR])

            const result = isAllowed(this, arr1, arr2)
            Vue.$log.debug(`canManageAndReadOrganisationData? ${arr1}, ${arr2}, ${result}`);
            return result;
        } else return true
    }

    canManageFinanceTransactionForOrganisation(federationRef: FederationRef | undefined, organisationRef: OrganisationRef): boolean {
        const arr1 = []
        if (federationRef) {
            arr1.push(`FED:${federationRef}`);
        }

        if (organisationRef) {
            arr1.push(`ORG:${organisationRef}`)
        }

        const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_ADMINISTRATOR, OrganisationUserRole.ORGANISATION_COLLABORATOR])
        const result = isAllowed(this, arr1, arr2)

        Vue.$log.debug(`canCreateFinanceTransaction? ${arr1}, ${arr2}, ${result}`);
        return result
    }

    canAddTransactionForMember(federationRef: FederationRef | undefined, memberShipDetails: MembershipDetails | undefined): boolean {
        const arr1 = []
        if (federationRef) {
            arr1.push(`FED:${federationRef}`)
        }

        if (memberShipDetails) {
            const associations = memberShipDetails.associations || [];
            associations.map(a => a.ref).filter(ref => ref !== undefined).forEach(o => arr1.push(`ORG:${o}`))
        }

        const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_ADMINISTRATOR, OrganisationUserRole.ORGANISATION_COLLABORATOR])
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`canUpdateOrganisation? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canManageAchievementsForMember(federationRef: FederationRef | undefined): boolean {
        const arr1 = []
        if (federationRef) {
            arr1.push(`FED:${federationRef}`);
        }

        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR])
        const result = isAllowed(this, arr1, arr2)
        Vue.$log.debug(`canManageAchievementForMember? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    canCreateEventForFederation(federationRef: FederationRef): boolean {
        const result = this.canManageFederationData(federationRef);
        Vue.$log.debug(`canCreateEventForFederation? ${result}`);
        return result;
    }

    canCreateCompetition(federationRef: FederationRef): boolean {
        return this.isFederationAdministratorOrCollaborator(federationRef) || this.isOrganisationCompetitionOrganizerOfAnyOrganisation();
    }

    canAccessCompetition(federationRef: FederationRef): boolean {
        return this.hasAtLeastOneHigherOrderRole() || this.isFederationAdministratorOrCollaborator(federationRef) || this.isOrganisationCompetitionOrganizerOfAnyOrganisation();
    }

    canCreateChampionship(federationRef: FederationRef): boolean {
        return this.isFederationAdministratorOrCollaborator(federationRef);
    }

    isFederationAdministratorOrCollaboratorOfAnyFederation(): boolean {
        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR]);
        const result = arr2.length > 0
        Vue.$log.debug(`isFederationAdministratorOrCollaboratorOfAnyFederation?, ${arr2}, ${result}`);
        return result;
    }

    isFederationAdministratorOrCollaboratorInMemberShip2(membership: Membership | undefined): boolean {
        const arr1 = [] as Array<string>
        if (membership) {
            const associations = [membership.organisation.ref];
            associations.filter(ref => ref !== undefined).forEach(o => arr1.push(`ORG:${o}`))
        }

        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR])
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`isFederationAdministratorOrCollaboratorInMemberShip2? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    isOrganisationCompetitionOrganizerOfAnyOrganisation(): boolean {
        const arr2 = this.getIntents([OrganisationUserRole.ORGANISATION_COMPETITION_ORGANIZER], []);
        const result = arr2.length > 0
        Vue.$log.debug(`isOrganisationCompetitionOrganizerOfAnyOrganisation?, ${arr2}, ${result}`);
        return result;
    }

    isFederationAdministratorOrCollaborator(federationRef: FederationRef): boolean {
        const arr1 = [`FED:${federationRef}`];
        const arr2 = this.getIntents([], [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR]);
        const result = isAllowed(this, arr1, arr2);
        Vue.$log.debug(`isFederationAdministratorOrCollaborator? ${arr1}, ${arr2}, ${result}`);
        return result;
    }

    private getIntents(
        organisationAdminRoles: OrganisationUserRole[] = [OrganisationUserRole.ORGANISATION_ADMINISTRATOR],
        federationAdminRoles: FederationUserRole[] = [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR],
    ): string[] {
        return getIntents(this, organisationAdminRoles, federationAdminRoles)
    }
}

function isAllowed(user: UserProfile, required: string[], intents: string[]): boolean {
    return user.isSuperAdmin || _.intersectionBy(required, intents).length > 0;
}

export function getIntents(user: UserProfile,
                           organisationAdminRoles: OrganisationUserRole[] = [OrganisationUserRole.ORGANISATION_ADMINISTRATOR],
                           federationAdminRoles: FederationUserRole[] = [FederationUserRole.FEDERATION_ADMINISTRATOR, FederationUserRole.FEDERATION_COLLABORATOR],
): string[] {
    const federationRoles: Map<FederationRef, FederationUserRole[]> = user.federationRoles!!;
    const organisationRoles: Map<FederationRef, OrganisationUserRole[]> = user.organisationRoles!!;
    const result: string[] = [];
    [...Object.keys(federationRoles)]
        //@ts-ignore
        .flatMap(k => federationRoles[k].map(fr => {
            return {federation: k, role: fr}
        }))
        .filter(fr => federationAdminRoles.indexOf(fr.role) !== -1)
        .forEach(fr => result.push(`FED:${fr.federation}`));
    [...Object.keys(organisationRoles)]
        //@ts-ignore
        .flatMap(k => organisationRoles[k].map(or => {
            return {organisation: k, role: or}
        }))
        .filter(or => organisationAdminRoles.indexOf(or.role) !== -1)
        .forEach(or => result.push(`ORG:${or.organisation}`));
    /*if (user.ref) {
        result.push(`USR:${user.ref}`)
    }*/
    return result;
}

export class FederationUserInfo {
    ref?: string;
    name?: string;
    avatar?: string;

    static empty() {
        const info = new FederationUserInfo();
        info.name = "";
        return info
    }
}

export interface OrganisationUserInfo {
    ref: string;
    federationRef: string;
    name?: string;
    avatar?: string;
    isHorse?: boolean;
    isPony?: boolean;
}

export enum FederationUserRole {
    FEDERATION_ADMINISTRATOR = "FEDERATION_ADMINISTRATOR",
    FEDERATION_COLLABORATOR = "FEDERATION_COLLABORATOR"
}

export enum OrganisationUserRole {
    ORGANISATION_MEMBER = "ORGANISATION_MEMBER",
    ORGANISATION_ADMINISTRATOR = "ORGANISATION_ADMINISTRATOR",
    ORGANISATION_BOARD_MEMBER = "ORGANISATION_BOARD_MEMBER",
    ORGANISATION_COLLABORATOR = "ORGANISATION_COLLABORATOR",
    ORGANISATION_TIME_ASSIGMENT_RESPONSIBLE = "ORGANISATION_TIME_ASSIGMENT_RESPONSIBLE",
    ORGANISATION_COMPETITION_ORGANIZER = "ORGANISATION_COMPETITION_ORGANIZER"
}

export enum OrganisationUserStatute {
    ORGANISATION_MEMBER = "ORGANISATION_MEMBER",
    ORGANISATION_CHAIRMAN = "ORGANISATION_CHAIRMAN",
    ORGANISATION_HONORARY_CHAIRMAN = "ORGANISATION_HONORARY_CHAIRMAN",
    ORGANISATION_VICE_CHAIRMAN = "ORGANISATION_VICE_CHAIRMAN",
    ORGANISATION_BOARD_MEMBER = "ORGANISATION_BOARD_MEMBER",
    ORGANISATION_CAPTAIN = "ORGANISATION_CAPTAIN",
    ORGANISATION_ASSISTANT_CAPTAIN = "ORGANISATION_ASSISTANT_CAPTAIN",
    ORGANISATION_SECRETARY = "ORGANISATION_SECRETARY",
    ORGANISATION_TREASURER = "ORGANISATION_TREASURER",
}

export enum PersonFavoriteType {
    COMPETITION = "COMPETITION",
    COURSE_EVENT = "COURSE_DAY_EVENT",
    CHAMPIONSHIP = "CHAMPIONSHIP"
}

export interface PersonFavorite {
    ref: string,
    type: PersonFavoriteType,
    subjectRef: string
}

