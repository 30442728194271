
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import 'highlight.js/styles/tomorrow.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

@Component
export default class QuillEditorField extends mixins(Vue, abstractField) {
  value!: string;

  editorOption = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{'header': 1}, {'header': 2}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'indent': '-1'}, {'indent': '+1'}],
      [{'direction': 'rtl'}],
      [{'size': ['small', false, 'large', 'huge']}],
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      [{'font': []}],
      [{'color': []}, {'background': []}],
      [{'align': []}],
      ['clean'],
      ['link', 'image', 'video']
    ]
  }

  get height() {
    //@ts-ignore
    return this.schema.height ? this.schema.height : "250px"
  }
}
