
import {Component, Vue} from 'vue-property-decorator';
import {Avatar} from 'vue-avatar'
import EqifyAvatar from "@/components/general/EqifyAvatar.vue";
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import PageNavbarFederationDropdown from "@/components/PageNavbarFederationDropdown.vue";
import {Task, taskService, TaskStatus} from "@/modules/tasks/services/TaskService";
import EqifyLoader from "@/components/EqifyLoader.vue";
import {ConfigProvider} from "@/ConfigProvider";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import {mixins} from "vue-class-component";
import {download} from '@/utils/DownloadUtils';
import SharedObjectsMenu from "@/components/SharedObjectsMenu.vue";
import {httpService} from "@/services/HttpService";
import FastNavigationSidebarMixin from "@/modules/sidebar/mixin/FastNavigationSidebarMixin";

@Component({
  components: {SharedObjectsMenu, EqifyLoader, PageNavbarFederationDropdown, EqifyAvatarAndText, EqifyAvatar, Avatar}
})
export default class PageNavBar extends mixins(AuthenticatedUserMixin, FastNavigationSidebarMixin, Vue) {
  private tasks: Array<Task> = []

  async doLogin() {
    await this.$keycloak.login!()
  }

  async doLogout() {
    await this.$keycloak.logout!({redirectUri: window.location.origin})
  }

  async mounted() {
    this.refreshTasks()
  }

  private refreshTasks() {
    if (this.authenticated) {
      this.$loader.load(async () => {
        this.tasks = await taskService.getTasksForUser()
      }, "load::tasks")
    }
  }

  getIcon(task: Task) {
    if (task.status === TaskStatus.DONE) {
      return "check"
    } else if (task.status === TaskStatus.ERROR) {
      return "times"
    } else if (task.status === TaskStatus.SCHEDULED) {
      return "clock"
    } else {
      return "spinner"
    }
  }

  downloadItem({url}: { url: string }) {
    httpService.download(url).then(fileContent => {
      download(fileContent)
    })
  }

  getDownloadUrl(task: Task) {
    return ConfigProvider.value('api_url') + (task.downloadUrl || "").replace("/api", "")
  }

}
