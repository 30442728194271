
import {Component, Prop, Vue} from "vue-property-decorator";
import CalendarItemComponent from "@/components/calendar/item/CalendarItemComponent.vue";
import {ChampionshipCalendarItem} from "@/components/calendar/Calendar";
import {Championship, ChampionshipStatus} from "@/modules/championship/services/PublicChampionshipService";
import ChampionshipUtils from "@/utils/ChampionshipUtils";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import CompetitionStatusBadge from "@/components/general/CompetitionStatusBadge.vue";
import SportTypeVariantBadge from "@/components/general/SportTypeVariantBadge.vue";
import EqifyListAvatar from "@/components/general/EqifyListAvatar.vue";
import SaveChampionshipAsFavoriteButton from "@/modules/championship/components/SaveChampionshipAsFavoriteButton.vue";

@Component({
  components: {
    SaveChampionshipAsFavoriteButton,
    EqifyListAvatar, SportTypeVariantBadge, CompetitionStatusBadge, CalendarItemComponent}
})
export default class CalendarChampionshipItemComponent extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop({type: Object, required: true}) item!: ChampionshipCalendarItem
  @Prop({default: true}) withSportTypes?: boolean

  get championship(): Championship {
    return this.item.championship
  }

  get avatar(): string | undefined {
    return this.item.avatar
  }

  get showState() {
    return this.championship && this.championship.status && (
        this.championship.status === ChampionshipStatus.DRAFT ||
        this.championship.status === ChampionshipStatus.ARCHIVED ||
        this.championship.status === ChampionshipStatus.CANCELLED ||
        this.championship.status === ChampionshipStatus.HIDDEN)
  }

  get textClass() {
    if (this.championship.status === ChampionshipStatus.CANCELLED) return 'text-danger'
    else return ""
  }

  private handleClick() {
    const url = this.championshipUrl(this.item.championship)
    if (url) {
      this.$router.push(url)
    }
  }

  private championshipUrl(championship: Championship) {
    return ChampionshipUtils.championshipUrl(this.user, championship)
  }

  private getChampionshipColor(color: string) {
    return `background-color: ${color}`
  }
}
