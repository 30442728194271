import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {Championship, ChampionshipRef} from "@/modules/championship/services/PublicChampionshipService";
import {manageChampionshipService} from "@/modules/championship/services/ManageChampionshipService";

@Module({namespaced: true, name: 'championship-manage'})
export default class ManageChampionshipModule extends VuexModule {
    championship: Championship = {} as Championship

    @Mutation
    clearStore() {
        this.championship = {} as Championship
    }

    @MutationAction
    async loadChampionship(ref: ChampionshipRef) {
        const championship = await manageChampionshipService.getChampionship(ref)
        return {championship: championship}
    }
}
