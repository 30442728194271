import {Action, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    initEmptyOrganisationMemberData,
    OrganisationMemberData,
    OrganisationMemberEntry,
    OrganisationRef,
    organisationService
} from "@/modules/organisations/services/OrganisationService";
import {MemberRef} from "@/modules/members/services/MemberService";

@Module({namespaced: true, name: 'organisation-members'})
export default class OrganisationMembersModule extends VuexModule {
    members: Array<OrganisationMemberEntry> = []
    selectedMember: OrganisationMemberData = initEmptyOrganisationMemberData()

    @MutationAction
    async loadMembers(req: LoadOrganisationMembersRequest) {
        const overview = await organisationService.getOrganisationMembersOverview(req.ref, req.showInActive)
        return {members: overview.members}
    }

    @MutationAction
    async loadMember(req: LoadMemberRequest) {
        const member = await organisationService.getOrganisationMemberData(req.organisationRef, req.memberRef)
        return {selectedMember: member}
    }

    @Action
    async createMember(req: CreateMemberRequest) {
        await organisationService.createOrganisationMember(req.organisationRef, req.data)
    }

    @Action
    async saveMember(req: SaveMemberRequest) {
        await organisationService.saveOrganisationMemberData(req.organisationRef, req.data.personRef, req.data)
    }

    @Action
    async endMembership(req: EndMembershipRequest) {
        await organisationService.endOrganisationMemberMembership(req.organisationRef, req.data, req.end)
    }
}

export interface LoadOrganisationMembersRequest {
    ref: OrganisationRef,
    showInActive: boolean
}

export interface LoadMemberRequest {
    organisationRef: OrganisationRef,
    memberRef: MemberRef
}

export interface SaveMemberRequest {
    organisationRef: OrganisationRef,
    data: OrganisationMemberData
}

export interface CreateMemberRequest {
    organisationRef: OrganisationRef,
    data: OrganisationMemberData
}

export interface EndMembershipRequest {
    organisationRef: OrganisationRef,
    data: MemberRef,
    end: Date
}