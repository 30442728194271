import { render, staticRenderFns } from "./AddSplitRevenueModal.vue?vue&type=template&id=08b2a3e2&scoped=true"
import script from "./AddSplitRevenueModal.vue?vue&type=script&lang=ts"
export * from "./AddSplitRevenueModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b2a3e2",
  null
  
)

export default component.exports