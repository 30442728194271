import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";

class UserGroupService {
    list(federationRef: FederationRef): Promise<Array<UserGroup>> {
        return httpService.get(`/myfederation/${federationRef}/usergroup`)
    }

    add(federationRef: FederationRef, name: string, members: Array<UserGroupMember>): Promise<UserGroup> {
        return httpService.post(`/myfederation/${federationRef}/usergroup`, {
            name: name,
            members: members
        })
    }

    update(federationRef: FederationRef, ref: UserGroupRef, name: string, members: Array<UserGroupMember>): Promise<UserGroup> {
        return httpService.put(`/myfederation/${federationRef}/usergroup/${ref}`, {
            name: name,
            members: members
        })
    }

    delete(federationRef: FederationRef, ref: UserGroupRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/usergroup/${ref}`)
    }

    searchPersonAndGroupsByName(federationRef: FederationRef, search: string): Promise<Array<UserGroupMember>> {
        return httpService.get(`/myfederation/${federationRef}/usergroup/search?q=${escape(search)}`)
    }
}

export type UserGroupRef = string;

export interface UserGroup {
    ref: UserGroupRef;
    name?: string;
    members?: Array<UserGroupMember>
}

export interface UserGroupMember {
    ref: string;
    name?: string,
    email?: string,
    type?: UserGroupMemberType
}

export enum UserGroupMemberType {
    PERSON = "PERSON",
    GROUP = "GROUP"
}

export const userGroupService = new UserGroupService();
