
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import LicenseSearchInput from "@/components/general/LicenseSearchInput.vue";

@Component({
        components: {LicenseSearchInput}
    })
    export default class LicenseSearchField extends mixins(Vue, abstractField) {
    }
