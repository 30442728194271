import {Action, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    Career,
    CompetitionTest,
    CompetitionTestConfigurationDescriptor,
    CompetitionTestRef,
    competitionTestsService,
    CreateForCareerRequest,
    CreateForSportCategoryRequest,
    CreateForSportTypeVariantRequest, PractitionerGroup,
    SaveTestRequest,
    SportCategory
} from "@/modules/competition/service/CompetitionTestsService";
import {CompetitionDetail, CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {ArrayListMultimap, RefWithName} from "@/utils/Utils";
import {SportTypeVariant, SportTypeVariantRef} from "@/modules/federation/services/SportTypeVariantService";

@Module({namespaced: true, name: 'competition-manage-tests'})
export default class CompetitionManageTestsModule extends VuexModule {
    sportTypeVariants: Array<SportTypeVariant> = []
    categories: Array<SportCategory> = []
    practitionerGroups: Array<PractitionerGroup> = []
    configurations: Array<CompetitionTestConfigurationDescriptor> = []
    careers: Array<Career> = []
    championships: Array<RefWithName> = []
    tests: Array<CompetitionTest> = []
    groupedByConfigurationType: ArrayListMultimap<string, CompetitionTest> = new ArrayListMultimap<string, CompetitionTest>()
    selectedSportTypeVariant: SportTypeVariant = {} as SportTypeVariant;
    selectedCompetitionTests: Array<CompetitionTest> = [];

    @Mutation
    clearStore() {
        this.tests = []
        this.careers = []
        this.categories = []
        this.practitionerGroups = []
    }

    @MutationAction
    async loadData(competition: CompetitionDetail) {
        const data = await competitionTestsService.getData(competition.ref)

        const m = new ArrayListMultimap<string, CompetitionTest>()
        data.tests.forEach(c => m.put(c.sportTypeVariant.ref, c))

        let stv: SportTypeVariant | undefined = undefined
        let tests: Array<CompetitionTest> = []
        if (this.selectedSportTypeVariant && this.selectedSportTypeVariant.ref) {
            stv = data.tests.find(it => it.sportTypeVariant.ref == this.selectedSportTypeVariant.ref)?.sportTypeVariant
            if (stv) {
                tests = m.get(stv.ref)
            }
        }
        if (!stv) {
            const stvKey = m.keys()[0]
            if (stvKey) {
                stv = data.tests.find(it => it.sportTypeVariant.ref == stvKey)!.sportTypeVariant
                tests = m.get(stvKey)
            }
        }

        return {
            categories: Utils.sortByName(data.categories),
            sportTypeVariants: Utils.sortByName(data.sportTypeVariants),
            careers: Utils.sortByName(data.careers),
            championships: Utils.sortByName(data.championships),
            tests: Utils.sortByName(data.tests),
            configurations: Utils.sortByName(data.configurations),
            practitionerGroups: Utils.sortByName(data.practitionerGroups),
            groupedByConfigurationType: m,
            selectedSportTypeVariant: stv,
            selectedCompetitionTests: tests
        }
    }

    @Action
    async createForCareer(request: CreateForCareerRequest) {
        await competitionTestsService.createForCareer(request.competitionRef, request.careerRef)
    }

    @Action
    async createForSportCategory(request: CreateForSportCategoryRequest) {
        await competitionTestsService.createForSportCategory(request.competitionRef, request.sportCategoryRef)
    }

    @Action
    async createForSportTypeVariant(request: CreateForSportTypeVariantRequest) {
        await competitionTestsService.createForSportTypeVariant(request.competitionRef, request.sportTypeVariantRef)
    }

    @Action
    async saveTest(request: SaveTestRequest) {
        this.tests[this.tests.findIndex(t => t.ref == request.ref)] = await competitionTestsService.saveTest(request.competitionRef, request)
    }

    @Action
    async deleteCompetitionTest(request: DeleteTestRequest) {
        await competitionTestsService.deleteCompetitionTest(request.competitionRef, request.testRef)
    }

    @Mutation
    async select(sportTypeVariantRef: SportTypeVariantRef) {
        this.selectedSportTypeVariant = this.tests.find(it => it.sportTypeVariant.ref == sportTypeVariantRef)!.sportTypeVariant
        this.selectedCompetitionTests = this.groupedByConfigurationType.get(sportTypeVariantRef)
    }

}

export class Utils {

    public static sortByName(list: Array<any>) {
        return list ? list.sort((a, b) => a.name.localeCompare(b.name)) : []
    }
}

export interface DeleteTestRequest {
    competitionRef: CompetitionRef,
    testRef: CompetitionTestRef
}


