
import {Component, Prop, Vue} from 'vue-property-decorator';
import {RefWithName} from "@/utils/Utils";
import SearchInput from "@/components/general/SearchInput.vue";

@Component({
        components: {SearchInput}
    })
    export default class StudBookSearchInput extends Vue {
        @Prop() value?: RefWithName | undefined;
        @Prop() inputClass?: string;
        @Prop() size?: string;
        @Prop() placeholder?: string;

        get inputValue(): any {
            return this.value;
        }

        set inputValue(value: any) {
            this.$emit('input', value);
        }
    }
