
import {Component, Prop, Vue} from "vue-property-decorator";
import {WitherHeight, witherHeightService} from "@/services/WitherHeightService";
import {Option, RefWithName} from "@/utils/Utils";

@Component({})
export default class WitherHeightSelectComponent extends Vue {
  @Prop() value?: RefWithName

  private availableWitherHeights: Array<Option> = []

  mounted() {
    this.getWitherHeights()
  }

  get inputValue(): string | undefined {
    return this.value?.ref
  }

  set inputValue(value: string | undefined) {
    const v = this.availableWitherHeights.find(it => it.value == value)
    const r = v ? RefWithName.create(v.value!, v.text!) : undefined
    this.$emit('input', r)
  }

  async getWitherHeights() {
    this.availableWitherHeights = (await witherHeightService.getWitherHeights()).map(it => Option.create(it.ref, `${it.code} (${it.name})`))
  }
}

