
import {Component, Prop, Vue} from 'vue-property-decorator';
import {GeoLocation} from "@/shared/domain/Address";

@Component
export default class AddressComponent extends Vue {
  @Prop() value!: GeoLocation
  @Prop({ default: true }) withIcon?: boolean

  get withIconState(): boolean {
    if (this.withIcon === undefined) {
      return true
    } else {
      return this.withIcon
    }
  }

  checkIfEmptyOrNull(val: string) {
    return val != undefined || val != null;
  }
}
