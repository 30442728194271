
import {Component, Prop, Vue} from "vue-property-decorator";
import CalendarItemComponent from "@/components/calendar/item/CalendarItemComponent.vue";
import {CompetitionCalendarItem} from "@/components/calendar/Calendar";
import SportTypeVariantBadge from "@/components/general/SportTypeVariantBadge.vue";
import LiveBadge from "@/modules/competition/components/LiveBadge.vue";
import CompetitionStatusBadge from "@/components/general/CompetitionStatusBadge.vue";
import {CompetitionDetail} from "@/modules/competition/service/CompetitionService";
import {CompetitionStatus} from "@/modules/competition/service/ManageCompetitionService";
import CompetitionUtils from "@/utils/CompetitionUtils";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import EqifyAvatar from "@/components/general/EqifyAvatar.vue";
import EqifyListAvatar from "@/components/general/EqifyListAvatar.vue";
import SaveCompetitionAsFavoriteButton from "@/modules/competition/components/SaveCompetitionAsFavoriteButton.vue";

@Component({
  components: {
    SaveCompetitionAsFavoriteButton,
    EqifyListAvatar,
    EqifyAvatar, SportTypeVariantBadge, CalendarItemComponent, LiveBadge, CompetitionStatusBadge}
})
export default class CalendarCompetitionItemComponent extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop({type: Object, required: true}) item!: CompetitionCalendarItem
  @Prop({default: true}) withSportTypes?: boolean
  @Prop({default: true}) withChampionships?: boolean
  @Prop({default: false}) withYear?: boolean

  get competition(): CompetitionDetail {
    return this.item.competition
  }

  get avatar(): string | undefined {
    return this.item.avatar
  }

  get showState() {
    return this.competition && this.competition.status && (
        this.competition.status === CompetitionStatus.DRAFT ||
        this.competition.status === CompetitionStatus.ARCHIVED ||
        this.competition.status === CompetitionStatus.CANCELLED ||
        this.competition.status === CompetitionStatus.HIDDEN)
  }

  get textClass() {
    if (this.competition.status === CompetitionStatus.CANCELLED) return "text-danger"
    return ""
  }

  private handleClick() {
    const url = this.competitionUrl((this.item as CompetitionCalendarItem).competition)
    if (url) {
      this.$router.push(url)
    }
  }

  private competitionUrl(competition: CompetitionDetail) {
    return CompetitionUtils.competitionUrl(this.user, competition)
  }

  getBackgroundColor(color: string) {
    return `background-color: ${color}`
  }
}
