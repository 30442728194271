
import {Component, Prop, Vue} from "vue-property-decorator";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import WitherHeightSelectComponent from "@/components/rules/components/WitherHeightSelectComponent.vue";
import {initWitherHeight, WitherHeight} from "@/services/WitherHeightService";

@Component({
  components: {WitherHeightSelectComponent, EqifyRuleComponent}
})
export default class EqifyRulePonyHasWitherHeight extends Vue {
  @Prop() value?: PonyHasWitherHeight

  get witherHeight(): WitherHeight {
    return this.value?.witherHeight || initWitherHeight()
  }

  set witherHeight(val: WitherHeight) {
    this.$emit("input", {
      witherHeight: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      witherHeight: this.witherHeight,
      required: val,
      inverted: this.inverted
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      witherHeight: this.witherHeight,
      required: this.required,
      inverted: val
    })
  }
}

export interface PonyHasWitherHeight {
  witherHeight: WitherHeight,
  required: boolean,
  inverted: boolean
}

