
import {Component, Prop, Vue} from "vue-property-decorator";
import {CalendarItem, CalendarItemType} from "@/components/calendar/Calendar";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";

@Component({})
export default class CalendarItemComponent extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() item!: CalendarItem
  @Prop({default: undefined}) end?: string
  @Prop({default: false}) withYear?: boolean

  get classes(): string {
    const defaultClasses = "d-flex align-items-center my-1 my-lg-2 bg-light py-1 rounded"
    if (this.item.type == CalendarItemType.COMPETITION) {
      return defaultClasses + ' competition-class'
    }
    if (this.item.type == CalendarItemType.CHAMPIONSHIP) {
      return defaultClasses + ' championship-class'
    }
    if (this.item.type == CalendarItemType.COURSE) {
      return defaultClasses + ' course-class'
    }
    return ''
  }

  get minDateWidthClass() {
    if (!this.end) return 'min-width: 80px'
    else return 'min-width: 130px'
  }
}
