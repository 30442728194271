import {checkVAT, countries} from 'jsvat-next';
import moment from "moment";
import {isValidBIC, isValidIBAN, validateBIC} from "ibantools";
import {
    getBICValidationError,
    getIBANValidationError,
    validateBICResponse,
    validateIBAN,
    validateIBANResponse
} from "@/utils/FinanceUtils";
import {validateHorseUeln} from "@/utils/HorseUtils";

export const hereLocationValidator = (value: any, field: any) => {
    if (!field.required && (!value)) {
        return []
    }
    if (!value) {
        return ['Location cannot be empty']
    }
    if (typeof value != "object") {
        return ["Invalid location"]
    }
    if (!value.street) {
        return ["No valid location specified"]
    }
    return []
}

export const timeValidator = (value: any, field: any) => {
    if (!field.required && (!value)) {
        return []
    }
    if (!value) {
        return ['Cannot be empty']
    }
    if (typeof value != "string") {
        return ["Time should be a text"]
    }
    const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
    if (value.charAt(0).match(hours[0]) && value.charAt(1).match(hours[1]) && value.charAt(3).match(minutes[0]) && value.charAt(4).match(minutes[1])) {
        return []
    } else {
        return ["Invalid Time Format"]
    }
}
export const timeMinAndSecValidator = (value: any, field: any) => {
    if (!field.required && (!value)) {
        return []
    }
    if (!value) {
        return ['Cannot be empty']
    }
    if (typeof value != "string") {
        return ["Time should be a text"]
    }
    return []
}

export const dateValidator = (value: any, field: any) => {
    if (!field.required) {
        return []
    }

    if (!value) {
        return ['Cannot be empty']
    }
    if (typeof value != "string") {
        return ["Date should be a text"]
    }

    if (!moment(value).isValid()) {
        return ["Invalid Date Format"]
    } else {
        return []
    }
}

export const refByNameValidator = (value: any, field: any, entity: string = "RefByName") => {
    if (!field.required && (!value || !value.ref)) {
        return []
    }
    if (!value) {
        return [entity + ' cannot be empty']
    }
    if (typeof value != "object") {
        return [entity + " invalid structure"]
    }
    if (!value.ref) {
        return [entity + " not specified"]
    }
    return []
}

export const federationValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Federation")
}

export const personValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Person")
}

export const organisationValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Organisation")
}

export const accountValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Account")
}

export const competitionValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, 'Competition')
}

export const licenseValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "License")
}

export const articlesValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Article")
}

export const achievementValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Achievement")
}

export const courseValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Course")
}

export const memberValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Member")
}

export const studbookValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Studbook")
}

export const horseValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Horse")
}

export const arenaValidator = (value: any, field: any) => {
    return refByNameValidator(value, field, "Arena")
}

export const currencyValidator = (value: any, field: any) => {
    if (!field.required && !value) {
        return []
    }
    if (!value) {
        return ["Currency cannot be empty"]
    }
    return []
}

export const horseGenderValidator = (value: any, field: any) => {
    if (!field.required && !value) {
        return []
    }
    if (!value) {
        return ["HorseGender cannot be empty"]
    }
    if (typeof value != "string") {
        return ["Invalid HorseGender"]
    }
    return []
}

export const horseUelnValidator = async (value: any | undefined, field: any) => {
    if (!field.required && !value) return []
    if (!value) return ["ueln cannot be empty"]
    if (typeof value != "string") return ["Invalid ueln"]
    if (!field.shouldValidate) return []
    return new Promise((resolve: any) => {
        validateHorseUeln(value, field.ref)
            .then((valid) => {
                if (valid) resolve()
                else resolve(["Ueln is already in use"])
            })
    })
}

export const vatNumberValidator = async (value: any, field: any) => {
    if (!field.required && !value) {
        return []
    }
    if (!value) {
        return ["VatNumber cannot be empty"]
    } else {
        const result = checkVAT(value, countries)
        if (!result.isValidFormat) {
            return ["Invalid VatNumber format"]
        }

        if (!result.isValid) {
            return ["Invalid VatNumber"]
        }
    }
}

export const ibanValidator = (value: any, field: any) => {
    if (!field.required && !value) {
        return []
    }
    if (value) {
        const response = validateIBANResponse(value)
        if (!response?.valid) {
            return response?.errorCodes.map(
                it => {
                    return getIBANValidationError(it)
                }
            )
        }
    }
    return []
}

export const bicValidator = (value: any, field: any) => {
    if (!field.required && !value) {
        return []
    }
    if (value) {
        const response = validateBICResponse(value)
        if (!response.valid) {
            return response.errorCodes.map(
                it => {
                    return getBICValidationError(it)
                }
            )
        }
    }
}

export const moneyValidator = (value: any, field: any) => {
    if (!field.required) {
        return []
    }

    if (field.required && !value) {
        return ['Cannot be empty']
    } else {
        if (value.amountWithTax == undefined || value.amountWithTax.toString() == "") return ['Amount cannot be empty']
        else if (value.currency == undefined || value.currency == "") return ['Currency cannot be empty']
        else if (value.taxLevelCode == undefined || value.taxLevelCode == "") return ['Tax level cannot be empty']
        else return []
    }
}

export const splitValidator = (value: any, field: any) => {
    if (value.value < 0) {
        return ['Cannot be negative']
    }
}

export const validatorTrigger = (value: any, field: any) => {
    return []
}


/**
 * Generate a time mask based on input value (23:59)
 * @param {string} value
 */
export function timeMinuteAndSecondsMask(value: string) {
    const minutes = [/[0-5]/, /[0-9]/];
    const seconds = [/[0-5]/, /[0-9]/];
    return value.length > 2
        ? [...minutes, ':', ...seconds]
        : minutes;
}
