
import {Component, Prop, Vue} from 'vue-property-decorator';
import DeleteButton from "@/components/general/buttons/DeleteButton.vue";
import EditButton from "@/components/general/buttons/EditButton.vue";
import PublishButton from "@/components/general/buttons/PublishButton.vue";
import {emptyEntityStateResource, EntityState, EntityStateResource} from "@/shared/domain/EntityState";

@Component({
  components: {PublishButton, EditButton, DeleteButton}
})
export default class StateButtonBar extends Vue {
  @Prop({default: () => emptyEntityStateResource(), type: Object}) public value!: EntityStateResource;
  private actionPerState: Map<EntityState, Array<String>> = this.initActionsPerState();

  initActionsPerState() {
    let o: Map<EntityState, Array<String>> = new Map();
    o.set(EntityState.DRAFT, ['edit', 'delete', 'publish']);
    o.set(EntityState.PUBLISHED, ['edit', 'unpublish', 'archive']);
    o.set(EntityState.UNPUBLISHED, ['edit', 'publish', 'archive']);
    o.set(EntityState.ARCHIVED, ['publish', 'unpublish']);
    o.set(EntityState.DELETED, []);
    return o;
  }

  shouldShowButton(action: String) {
    let possibleActions = this.actionPerState.get(this.value.state!!);
    return possibleActions && possibleActions.includes(action)
  }

  get draft() {
    return this.value.state == EntityState.DRAFT
  }

  get published() {
    return this.value.state == EntityState.PUBLISHED
  }

  get unpublished() {
    return this.value.state == EntityState.UNPUBLISHED
  }

  get archived() {
    return this.value.state == EntityState.ARCHIVED
  }

  doDelete() {
    this.$emit("delete", this.value)
  }

  doEdit() {
    this.$emit("edit", this.value)
  }

  doPublish() {
    this.$emit("publish", this.value)
  }

  doUnpublish() {
    this.$emit("unpublish", this.value)
  }

  doArchive() {
    this.$emit("archive", this.value)
  }
}
