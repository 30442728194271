
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import OrganisationSearchField from "@/components/form/OrganisationSearchField.vue";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";
import {RefWithName} from "@/utils/Utils";
import FederationSelectField from "@/components/form/FederationSelectField.vue";
import FederationSelectComponent from "@/components/rules/components/FederationSelectComponent.vue";

@Component({
  components: {
    FederationSelectComponent,
    FederationSelectField, OrganisationSearchInput, OrganisationSearchField, EqifyRuleComponent}
})
export default class EqifyRulePersonIsInFederation extends Vue {
  @Prop() value?: PersonIsInFederation

  get federation(): RefWithName {
    return this.value?.federation || RefWithName.empty()
  }

  set federation(val: RefWithName) {
    this.$emit("input", {
      federation: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      federation: this.federation,
      required: val,
      inverted: this.inverted
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      federation: this.federation,
      required: this.required,
      inverted: val
    })
  }
}

export interface PersonIsInFederation {
  federation: RefWithName,
  required: boolean,
  inverted: boolean
}

