
import Component, {mixins} from "vue-class-component";
import {Prop, Ref, Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import {Option, toOption} from "../../utils/Utils";
import {BModal} from "bootstrap-vue";

@Component({})
export default class SelectWithInfoModalField extends mixins(Vue, abstractField) {
  @Ref("modal")
  private modal?: BModal

  private defaultSelectOptions = {
    withInfoModal: true,
    multiple: false,
    label: "text",
    reducer: (tupple: Option) => tupple.value,
    min: 1
  }

  get _selectOptions() {
    // @ts-ignore
    let options = this.schema.selectOptions;
    return {...this.defaultSelectOptions, ...options};
  }

  descriptionValue(value: any) {
    // @ts-ignore
    const item = this.options().find(s => s.ref == value)
    if (item != undefined && item.description != null) {
      return item.description
    } else {
      return "License has no info"
    }
  }

  options() {
    // @ts-ignore
    let values = this.schema.values;
    if (!values) {
      return [];
    }
    if (typeof values == "function") {
      // @ts-ignore
      return values.apply(this, [this.model, this.schema]);
    } else {
      return values;
    }
  }

  optionsFormatted() {
    // @ts-ignore
    return this.options().map((l: any) => toOption(l))
  }

  defaultReducer(value: any) {
    return value
  }
}
