
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import AccountSelector from "@/components/account/AccountSelector.vue";

@Component({
    components: {AccountSelector}
})
export default class AccountSelectorField extends mixins(Vue, abstractField) {
    get resolver() {
        // @ts-ignore
        return () => this.schema.resolver(this.model)
    }

    get contactTypes() {
        // @ts-ignore
        return this.schema.contactTypes
    }
}
