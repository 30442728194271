export interface EqifyRenewal {
    type: string,
    period?: string,
    startType?: string,
    startMonth?: number,
    startDay?: number,
    closePeriod?: EqifyRenewalClosePeriod
}

export interface EqifyRenewalClosePeriod {
    startDay?: number
    startMonth?: number,
    endDay?: number,
    endMonth: number
}

export enum EqifyRenewalType {
    ONE_TIME = "ONE_TIME",
    RECURRING = "RECURRING"
}

export enum EqifyRenewalPeriod {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    ANNUAL = "ANNUAL"
}

export enum EqifyRenewalStartType {
    NOW = "NOW",
    DAY_OF_WEEK = "DAY_OF_WEEK",
    DAY_OF_MONTH = "DAY_OF_MONTH",
    DAY_OF_YEAR = "DAY_OF_YEAR"
}