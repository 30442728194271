
import {Component, Vue} from "vue-property-decorator";
import SplitValueInput from "@/components/general/split/SplitValueInput.vue";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
@Component({
  components: {SplitValueInput}
})
export default class SplitValueInputField extends mixins(Vue, abstractField) {

  get maxPercentage() {
    //@ts-ignore
    return this.schema.maxPercentage
  }

  get maxAbsolute() {
    //@ts-ignore
    return this.schema.maxAbsolute
  }

}
