export enum EntityState {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    UNPUBLISHED = "UNPUBLISHED",
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED"
}

export interface EntityWithStateData {
    ref: string;
    state: EntityStateResource
}

export interface EntityStateResource {
    state: EntityState;
    when: string;
    by: string;
    revision?: number;
    note?: string;
}

export function emptyEntityStateResource(): EntityStateResource {
    return {
        by: "",
        when: "",
        state: EntityState.DRAFT
    }
}
