import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    CompetitionRanking,
    manageRankingService,
    RankingMenu
} from "@/modules/competition/service/ManageRankingService";
import {CompetitionTestRef, CompetitionTestSetRef} from "@/modules/competition/service/CompetitionTestsService";

@Module({namespaced: true, name: 'competition-manage-ranking'})
export default class CompetitionManageRankingModule extends VuexModule {
    menu: RankingMenu = {} as RankingMenu
    ranking: CompetitionRanking = {} as CompetitionRanking

    @Mutation
    clearStore() {
        this.menu = {} as RankingMenu
        this.ranking = {} as CompetitionRanking
    }

    @MutationAction
    async loadData(ref: CompetitionRef) {
        const data = await manageRankingService.getData(ref)
        return {menu: data.menu}
    }

    @Mutation
    async refresh(competitionRef: CompetitionRef) {
        this.ranking = await manageRankingService.getRanking(competitionRef, this.ranking.test.ref!, this.ranking.testSet.ref!, this.ranking.rankingRef)
    }

    @MutationAction
    async select(request: SelectRequest) {
        const r = await manageRankingService.getRanking(request.competitionRef, request.testRef, request.testSetRef, request.rankingRef)
        return {ranking: r}
    }
}

export interface SelectRequest {
    competitionRef: CompetitionRef
    testRef: CompetitionTestRef
    testSetRef: CompetitionTestSetRef,
    rankingRef: string
}
