
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import PersonSearchInput from "@/components/general/PersonSearchInput.vue";
import PersonRegistrationCandidateSearchInput from "@/components/general/PersonRegistrationCandidateSearchInput.vue";

@Component({
  components: {PersonRegistrationCandidateSearchInput, PersonSearchInput}
})
export default class PersonRegistrationCandidateSearchField extends mixins(Vue, abstractField) {
  get allowUnexisting() {
    // @ts-ignore
    return this.schema.allowUnexisting || false
  }

  get allowSave() {
    // @ts-ignore
    return this.schema.allowSave || false
  }

  get courseEventRef() {
    // @ts-ignore
    return this.schema.courseEventRef || ""
  }
}
