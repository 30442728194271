
import {Component, Prop, Vue} from "vue-property-decorator";
import SaveAsFavoriteButton from "@/components/general/buttons/SaveAsFavoriteButton.vue";
import {PersonFavorite} from "@/shared/domain/User";
import {ChampionshipRef, publicChampionshipService} from "@/modules/championship/services/PublicChampionshipService";

@Component({
  components: {SaveAsFavoriteButton}
})
export default class SaveCompetitionAsFavoriteButton extends Vue {
  @Prop() championshipRef!: ChampionshipRef

  async save(): Promise<PersonFavorite> {
    return await publicChampionshipService.saveAsFavorite(this.championshipRef)
  }

  async remove(ref: string): Promise<void> {
    return await publicChampionshipService.removeAsFavorite(ref)
  }
}
