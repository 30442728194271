import {CourseCalendarItem} from "@/components/calendar/Calendar";
import {httpService} from "@/services/HttpService";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";

class CourseCalendarService {
    searchCourseCalendarItem(fromDate: string, toDate: string, searchString?: string, page?: number): Promise<PagedResponse<CourseCalendarItem>> {
        return httpService.get(`/course/calendar/search?searchString=${searchString == undefined ? "" : searchString}&from=${fromDate}&to=${toDate}&page=${page}`)
    }
}

export const courseCalendarService = new CourseCalendarService()