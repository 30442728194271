
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {formatIban} from "@/utils/FinanceUtils";

@Component({})
export default class IbanInput extends Vue {
  @Prop() value?: string
  @Prop() placeholder?: string

  get iban(): string | null {
    if (this.value) return formatIban(this.value)
    else return ""
  }

  set iban(iban: string | null) {
    this.$emit("input", iban?.replace(/\s/g, ""))
  }
}
