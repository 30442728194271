import {httpService} from "@/services/HttpService";

class TaskService {

    getTasksForUser(): Promise<Array<Task>> {
        return httpService.get(`/tasks`)
    }

    getLastNTasks(count: number): Promise<TaskList> {
        return httpService.get(`/tasks/all?count=${count}`)
    }
}

export interface TaskList {
    tasks: Array<Task>
}

export const initEmptyTaskList = () => {
    const form: TaskList = {
        tasks: []
    }
    return form
}

export interface Task {
    ref: TaskRef;
    name: string;
    start: string;
    end: string;
    status: TaskStatus;
    downloadUrl?: string;
}

export type TaskRef = string;

export enum TaskStatus {
    SCHEDULED = "SCHEDULED",
    ONGOING = "ONGOING",
    DONE = "DONE",
    ERROR = "ERROR"
}

export const taskService = new TaskService();
