
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Account, AccountType, createLink} from "@/modules/federation/services/InvoiceService";
import AddressComponent from "@/components/location/AddressComponent.vue";
import {AccountRefWithName} from "@/shared/domain/Account";

@Component({
    components: {AddressComponent}
})
export default class AccountRefWithNameComponent extends Vue {
    @Prop() account?: AccountRefWithName
}
