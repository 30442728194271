
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

export enum PercentageInputType {
  PERCENTAGE = "%",
  ABSOLUTE_VALUE = "0.01"
}

@Component
export default class PercentageInput extends Vue {
  @Prop({default: 0}) value?: number

  private type: PercentageInputType = PercentageInputType.PERCENTAGE

  get percentage(): number {
    return this.value || 0
  }

  set percentage(v: number) {
    if (this.type == PercentageInputType.PERCENTAGE) {
      this.$emit("input", v)
    }

   /* if (this.type == PercentageInputType.ABSOLUTE_VALUE) {
      const absolute = (v * 100)
      console.log("Absolute value!!", absolute)
      this.$emit("input", absolute)
    }*/
  }

  get types() {
    return [PercentageInputType.PERCENTAGE, PercentageInputType.ABSOLUTE_VALUE]
  }
}
