
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import EqifyAvatar from "@/components/general/EqifyAvatar.vue";
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import CalendarItemView from "@/components/calendar/item/CalendarItemView.vue";
import FastNavigationSidebarMixin from "@/modules/sidebar/mixin/FastNavigationSidebarMixin";
import EqifyMenuItem from "@/components/general/selectable/EqifyMenuItem.vue";
import OrganisationSidebarNavigation from "@/modules/sidebar/component/OrganisationSidebarNavigation.vue";
import FederationSidebarNavigation from "@/modules/sidebar/component/FederationSidebarNavigation.vue";
import CalendarSideBarNavigation from "@/modules/sidebar/component/CalendarSideBarNavigation.vue";
import {Task, taskService, TaskStatus} from "@/modules/tasks/services/TaskService";
import {httpService} from "@/services/HttpService";
import {download} from "@/utils/DownloadUtils";
import {ConfigProvider} from "@/ConfigProvider";
import {fastNavigationEventBus} from "@/modules/sidebar/services/FastNavigationEventBus";

@Component({
  components: {
    CalendarSideBarNavigation,
    FederationSidebarNavigation,
    OrganisationSidebarNavigation, EqifyMenuItem, CalendarItemView, EqifyAvatarAndText, EqifyAvatar}
})
export default class FastNavigationSidebar extends mixins(AuthenticatedUserMixin, FastNavigationSidebarMixin, Vue) {
  private tasks: Array<Task> = []

  async doLogout() {
    await this.$keycloak.logout!({redirectUri: window.location.origin})
  }

  private refreshTasks() {
    if (this.authenticated) {
      this.$loader.load(async () => {
        this.tasks = await taskService.getTasksForUser()
      }, "load::tasks")
    }
  }

  async mounted() {
    this.refreshTasks()
  }

  getIcon(task: Task) {
    if (task.status === TaskStatus.DONE) {
      return "check"
    } else if (task.status === TaskStatus.ERROR) {
      return "times"
    } else if (task.status === TaskStatus.SCHEDULED) {
      return "clock"
    } else {
      return "spinner"
    }
  }

  downloadItem({url}: { url: string }) {
    httpService.download(url).then(fileContent => {
      download(fileContent)
    })
  }

  getDownloadUrl(task: Task) {
    return ConfigProvider.value('api_url') + (task.downloadUrl || "").replace("/api", "")
  }
}
