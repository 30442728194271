import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {PublicCompetition, publicCompetitionService} from "@/modules/competition/service/PublicCompetitionService";

@Module({namespaced: true, name: 'competition-profile'})
export default class CompetitionProfileModule extends VuexModule {
    competition: PublicCompetition = {} as PublicCompetition

    @MutationAction
    async loadCompetition(ref: CompetitionRef) {
        const competition = await publicCompetitionService.getCompetition(ref)
        return {competition: competition}
    }

    @Mutation
    clearStore() {
        return {competition: {} as PublicCompetition}
    }
}
