import {httpService} from "@/services/HttpService";
import {DateUtils} from "@/utils/DateUtils";
import {RefWithName} from "@/utils/Utils";
import {PersonRef} from "@/shared/domain/Person";
import Vue from "vue";
import {MemberRef} from "@/modules/members/services/MemberService";
import {HorseRidersResponse} from "@/shared/domain/Combination";
import {CompetitionDetail, CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {MasterlistItem} from "@/modules/competition/service/PublicCompetitionMasterlistService";
import {PersonName} from "@/shared/domain/CompetitionPractitioner";
import {PractitionerResultsFeedData} from "@/modules/members/services/MemberFeedService";
import {CustomAttribute} from "@/shared/domain/CustomAttributes";

class HorseService {
    createUploadAvatarUrl(ref: HorseRef): string {
        return `/horse/${ref}/avatar`
    }

    uploadHorseDocument(horseRef: HorseRef, type: HorseDocumentType, file: File): Promise<UploadHorseDocumentResponse> {
        return httpService.upload(`/horse/${horseRef}/document?type=${type.toString()}`, file)
    }

    deleteHorseDocument(horseRef: HorseRef, horseDocumentRef: string): Promise<void> {
        return httpService.delete(`/horse/${horseRef}/document/${horseDocumentRef}`)
    }

    createHorse(formData: HorseResource): Promise<HorseRef> {
        return HorseService.saveHorse(`/horse`, formData);
    }

    updateHorse(horseRef: HorseRef, formData: HorseResource) {
        return HorseService.saveHorse(`/horse/${horseRef}`, formData);
    }

    getHorse(horseRef: HorseRef): Promise<HorseResource> {
        return httpService.get(`/horse/${horseRef}`).then((data: any) => {
            return Promise.resolve({
                ...data,
                birthDate: DateUtils.fromDate(data.birthDate),
                deceasedDate: data.deceasedDate ? DateUtils.fromDate(data.deceasedDate) : undefined,
                withersHeightHistory: (data.withersHeightHistory || []).map((whh: any) => {
                    whh.date = DateUtils.fromDate(whh.date)
                    return whh
                })
            })
        })
    }

    getHorseCombinationsOverview(ref: MemberRef): Promise<HorseRidersResponse> {
        return httpService.get(`/horse/${ref}/combinations`)
    }

    getInitialSaveHorseFormData(horseRef: HorseRef | undefined = undefined): Promise<SaveHorseFormMetaData> {
        return this.getCreateMetadata().then(metadata => {
            if (horseRef === undefined || horseRef === 'undefined') {
                return Promise.resolve(
                    {
                        form: initEmptyHorseResource(),
                        withersHeights: metadata.withersHeights || [],
                        colors: metadata.colors || []
                    }
                )
            } else {
                return this.getHorse(horseRef).then(horse => {
                    return Promise.resolve(
                        {
                            form: horse,
                            withersHeights: metadata.withersHeights || [],
                            colors: metadata.colors || []
                        }
                    );
                })
            }
        })
    }

    private static saveHorse(url: string, formData: HorseResource): Promise<HorseRef> {
        const submitForm = {
            ...formData,
            withersHeight: formData.withersHeight,
            studbook: formData.studbook && formData.studbook.ref !== '' ? formData.studbook.ref : undefined,
            birthDate: DateUtils.toDateString(formData.birthDate),
            deceasedDate: formData.deceasedDate ? DateUtils.toDateString(formData.deceasedDate) : undefined,
            withersHeightHistory: (formData.withersHeightHistory || []).map(whh => {
                return {
                    ref: whh.ref,
                    date: DateUtils.toDateString(whh.date),
                    examinator: whh.examinator,
                    valueInCm: whh.valueInCm,
                    withersHeight: whh.withersHeight,
                    comments: whh.comments
                }
            })
        };
        return httpService.post(url, submitForm);
    }

    private getCreateMetadata(): Promise<CreateMetadata> {
        return httpService.get(`/horse/create/meta-data`).then((data: any) => {
            return Promise.resolve(
                {
                    withersHeights: data.withersHeights,
                    colors: data.colors
                }
            );
        });
    }

    getHorseCompetitions(horseRef: HorseRef, from: Date, to: Date): Promise<HorseCompetitionEvent[]> {
        return httpService.get(`/horse/${horseRef}/subscriptions`).then((data: any) => {
            const subscriptions = data.map((s: any) => {
                const subscription: HorseCompetitionEvent = {
                    start: s.date,
                    end: '',
                    event: RefWithName.create(s.event.ref, s.event.name),
                    organizer: RefWithName.create(s.organizer.ref, s.organizer.name),
                    rider: RefWithName.create(s.rider.ref, s.rider.name),
                    category: s.category.name
                }
                return subscription;
            })
            return Promise.resolve(subscriptions);
        });
    }

    getHorsePerformances(horseRef: HorseRef): Promise<HorseCompetitionEvent[]> {
        return httpService.get(`/horse/${horseRef}/results`).then((data: any) => {
            const results = data.map((r: any) => {
                const result: HorseCompetitionEvent = {
                    start: r.date,
                    end: '',
                    event: RefWithName.create(r.event.ref, r.event.name),
                    organizer: RefWithName.create(r.organizer.ref, r.organizer.name),
                    rider: RefWithName.create(r.rider.ref, r.rider.name),
                    category: r.category.name,
                    result: r.result,
                    rank: r.rank
                }
                return result;
            })
            return Promise.resolve(results);
        });
    }

    getHorsePedigree(ref: HorseRef): Promise<HorsePedigreeResponse> {
        return httpService.get(`/horse/${ref}/pedigree`);
    }

    getHorseCompetitionsForMasterlist(horseRef: HorseRef): Promise<Array<CompetitionDetail>> {
        return httpService.get(`/horse/${horseRef}/masterlist-competitions`)
    }

    getHorseCompetitionMasterlist(horseRef: HorseRef, competitionRef: CompetitionRef): Promise<Array<MasterlistItem>> {
        return httpService.get(`/horse/${horseRef}/masterlist/${competitionRef}`)
    }

    getSimilarHorses(horseRef: HorseRef): Promise<Array<SimilarHorse>> {
        return httpService.get(`/horse/${horseRef}/similar-horses`)
    }

    mergeSimilarHorses(horseRef: HorseRef, selected: Array<HorseRef>): Promise<Array<any>> {
        return httpService.post(`/horse/${horseRef}/similar-horses/merge`, {selected: selected})
    }

    public getRecentResults(ref: string): Promise<PractitionerResultsFeedData> {
        return httpService.get(`/horse/${ref}/feed/results`)
    }
}

export interface UploadHorseDocumentResponse {
    ref: string,
    url: string
}

export interface HorsePedigreeResponse {
    pedigree: HorsePedigree
}

export interface HorsePedigree {
    father?: HorsePedigreeNode;
    fatherOfFather?: HorsePedigreeNode;
    motherOfFather?: HorsePedigreeNode;
    mother?: HorsePedigreeNode;
    fatherOfMother?: HorsePedigreeNode;
    motherOfMother?: HorsePedigreeNode;
}

export interface HorsePedigreeNode {
    name: string;
    id?: string;
    isPony?: boolean;
    chip?: string;
    ueln?: string;
    fei?: string;
    birthDate?: string;
    gender?: string;
    withersheight?: string;
    color?: string;
    studbook?: string;
    breeder?: string;
    owner?: string;
}

export interface HorsePedigreeParents {
    fatherRef: HorseRef;
    father: HorseResource;
    motherRef: HorseRef;
    mother: HorseResource;
    greatParents: Array<HorsePedigreeGreatParents>
}

export interface HorsePedigreeGreatParents {
    fatherRef: HorseRef;
    father: HorseResource;
    motherRef: HorseRef;
    mother: HorseResource;
}

export interface HorseCompetitionEvent {
    event: RefWithName,
    start: string;
    end: string;
    organizer: RefWithName;
    category: string;
    rider: RefWithName;
    result?: string;
    rank?: string;
}

export type HorseRef = string;
export type WithersHeightRef = string;
export type StudbookRef = string;
export type HorseColorCode = string;

export enum HorseGender {
    MALE = "MALE",
    MARE = "MARE",
    STALLION = "STALLION",
    GELDING = "GELDING",
    RIG = "RIG",
    FILLY_FOAL = "FILLY_FOAL",
    COLT_FOAL = "COLT_FOAL",
    FILLY = "FILLY",
    COLT = "COLT",
    FILLY_YEARLING = "FILLY_YEARLING",
    COLT_YEARLING = "COLT_YEARLING"
}

export interface HorseWithersHeightHistoryItem {
    ref: string
    date: Date;
    examinator: RefWithName<PersonRef>;
    valueInCm: number;
    comments: string;
    withersHeight: WithersHeightRef;
}

export interface HorseResource {
    ref?: string,
    name: string,
    sportName?: string,
    isPony?: boolean,
    avatar?: string,
    withersHeight?: WithersHeightRef,
    withersHeightName?: string,
    gender?: HorseGender,
    birthDate?: Date,
    deceasedDate?: Date,
    chip?: string,
    ueln?: string,
    feiPassport?: string,
    studbook?: RefWithName<StudbookRef>,
    studbookNumber?: string,
    breeder?: RefWithName<PersonRef>,
    owner?: RefWithName<PersonRef>,
    color?: HorseColorCode,
    signature?: string,
    remarks?: string,
    mother?: RefWithName<HorseRef>,
    father?: RefWithName<HorseRef>,
    fatherOfMother?: RefWithName<HorseRef>,
    verified?: boolean;
    withersHeightHistory?: HorseWithersHeightHistoryItem[],
    createdBy?: RefWithName,
    createdDatetime?: String,
    verifiedBy?: RefWithName,
    verifiedDatetime?: String,
    documents: Array<HorseDocument>,
    practitionerCount: number,
    customAttributes?: Array<CustomAttribute>,
}

export interface SaveHorseFormMetaData {
    form: HorseResource,
    withersHeights: Array<RefWithName<WithersHeightRef>>
    colors: Array<HorseColorCode>
}

export const initEmptyHorseResource = () => {
    const form: HorseResource = {
        ref: undefined,
        name: "",
        sportName: "",
        avatar: undefined,
        isPony: false,
        withersHeight: undefined,
        gender: undefined,
        birthDate: undefined,
        deceasedDate: undefined,
        chip: "",
        ueln: "",
        feiPassport: "",
        studbook: undefined,
        studbookNumber: "",
        breeder: undefined,
        owner: undefined,
        color: undefined,
        signature: "",
        mother: undefined,
        father: undefined,
        remarks: "",
        verified: false,
        withersHeightHistory: [],
        documents: [],
        practitionerCount: 0,
        customAttributes: []
    };
    return form
};

class CreateMetadata {
    withersHeights?: Array<RefWithName<WithersHeightRef>>
    colors?: Array<HorseColorCode>
}

export interface HorseDocument {
    ref: string,
    key?: number,
    name: string,
    document?: File,
    type: HorseDocumentType,
    url: string
}

export enum HorseDocumentType {
    HORSE_PASSPORT = "PASSPORT",
    HORSE_XRAY = "XRAY",
    HORSE_VET_REPORT = "VET_REPORT",
    HORSE_OTHER = "OTHER"
}

export interface SimilarHorse {
    ref: HorseRef,
    name: string,
    ueln?: string,
    chip?: string,
    father?: string,
    fatherOfMother?: string,
    birthDate?: string,
    combinations: Array<PersonName>,
    recentCompetitions: Array<string>,
    matchBy: SimilarHorseMatchBy
}

export enum SimilarHorseMatchBy {
    NAME = "NAME",
    UELN = "UELN",
    CHIP = "CHIP"
}

export const getHorseRefFromRoute = (vue: Vue): HorseRef => {
    return vue.$route.params.horse;
};

export const horseService = new HorseService();
