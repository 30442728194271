

import {Component, Ref, Vue} from "vue-property-decorator";
import {BModal} from "bootstrap-vue";
import {RulesTemplate} from "@/shared/domain/General";
import EqifyTable from "@/components/table/EqifyTable.vue";
import EqifyRuleGroupViewer from "@/components/rules/viewer/EqifyRuleGroupViewer.vue";
import {rulesTemplateService} from "@/modules/federation/services/RulesTemplateService";
import {Loader} from "@/utils/Loader";

@Component({components: {EqifyTable, EqifyRuleGroupViewer}})
export default class EqifyQueryBuilderFederationTemplateModal extends Vue {

  private federationRef: string = ""
  private rulesTemplates: Array<RulesTemplate> = []
  private tableFields: Array<any> = []
  private loader = Loader.create()

  @Ref("modal")
  modal!: BModal

  show(federationRef: string) {
    this.federationRef = federationRef
    this.init()
    this.loadTemplates()
    this.modal.show()
  }

  loadTemplates() {
    if (this.federationRef != undefined) {
      this.loader.load(async () => {
        const response = await rulesTemplateService.getFederationRulesTemplates(this.federationRef)
        this.rulesTemplates = response.rulesTemplates
      })
    }
  }

  init() {
    this.tableFields = [
      {
        key: 'rules',
        label: 'rules'
      },
      {
        key: 'select',
        label: ''
      }
    ]
  }

  useTemplate(template: RulesTemplate) {
    this.$emit("selected", template.rules)
    this.modal.hide()
  }
}

