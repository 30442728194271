import {RouteConfig} from "vue-router";
import {
    createSecureAdminOnlyReadOnlyRouteMetadata,
    createSecureCrudRouteMetadata,
    createSecureReadOnlyRouteMetadata
} from "@/router";

const ROOT = '/organisations';
const routes: Array<RouteConfig> = [
    {
        path: ROOT + '/',
        component: () => import(/* webpackChunkName: "module-organisation" */ "@/modules/organisations/views/OrganisationSearchView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/create',
        component: () => import(/* webpackChunkName: "module-organisation-save" */ "@/modules/organisations/views/OrganisationSave.vue"),
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: ROOT + '/:organisation',
        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileLayout.vue"),
        alias: '/myorganisation/:organisation',
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileOverview.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'members',
                component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileMembersView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'competitions',
                component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileCompetitionsView.vue"),
                meta: createSecureReadOnlyRouteMetadata(),
                children: [
                    {
                        path: 'registrations',
                        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/competition/OrganisationOpenRegistrationsView.vue"),
                        meta: createSecureReadOnlyRouteMetadata(),
                    },
                    {
                        path: 'assigntime',
                        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/competition/OrganisationAssignTimeView.vue"),
                        meta: createSecureReadOnlyRouteMetadata(),
                    },
                    {
                        path: 'preregistrations',
                        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/competition/preregistration/OrganisationChampionshipPreRegistrationsView.vue"),
                        meta: createSecureReadOnlyRouteMetadata(),
                    },
                    {
                        path: 'jury-preregistrations',
                        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/competition/jury/JuryCompetitionsView.vue"),
                        meta: createSecureReadOnlyRouteMetadata(),
                    },
                    {
                        path: 'teams',
                        component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/competition/OrganisationTeamsView.vue"),
                        meta: createSecureReadOnlyRouteMetadata(),
                    }
                ]
            },
            {
                path: 'finance',
                component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileFinanceView.vue"),
                meta: createSecureAdminOnlyReadOnlyRouteMetadata(),
                children: [
                    {
                        path: '',
                        redirect: 'balance',
                        meta: createSecureAdminOnlyReadOnlyRouteMetadata()
                    },
                    {
                        path: 'balance',
                        alias: '',
                        component: () => import("@/modules/organisations/components/finance/OrganisationBillsView.vue"),
                        meta: createSecureAdminOnlyReadOnlyRouteMetadata()
                    },
                    /* todo {
                        path: 'balance-members',
                        alias: '',
                        component: () => import("@/modules/organisations/components/finance/OrganisationFinanceMembersBalanceView.vue"),
                        meta: createSecureAdminOnlyReadOnlyRouteMetadata()
                    },*/
                    {
                        path: 'invoices',
                        alias: '',
                        component: () => import("@/modules/organisations/components/finance/OrganisationFinanceInvoicesView.vue"),
                        meta: createSecureAdminOnlyReadOnlyRouteMetadata()
                    }
                ]
            },
            {
                path: 'information',
                component: () => import(/* webpackChunkName: "module-organisation-profile" */ "@/modules/organisations/views/profile/OrganisationProfileInformationView.vue"),
                meta: createSecureAdminOnlyReadOnlyRouteMetadata()
            }
        ]
    },
    {
        path: ROOT + '/:organisation/edit',
        component: () => import(/* webpackChunkName: "module-organisation-save" */ "@/modules/organisations/views/OrganisationSave.vue"),
        meta: createSecureCrudRouteMetadata()
    }
];

export default {
    routes: routes
};
