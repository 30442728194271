import {FileContent, httpService} from "@/services/HttpService";
import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";
import {InvoiceLimited, Money} from "@/shared/domain/Finance";
import {Bill, BillItem} from "@/services/FinanceService";
import {InvoiceDetail} from "@/modules/federation/services/InvoiceService";
import {BillItemData} from "@/components/finance/FinanceTransactionCallback";
import {RefWithName} from "@/utils/Utils";
import {MemberRef} from "@/modules/members/services/MemberService";
import {Order, OrderDetail, OrderRef} from "@/modules/finance/services/OrderService";

class OrganisationFinanceService {

    deleteBill(ref: OrganisationRef, billRef: string, moveToRef?: string): Promise<void> {
        return httpService.delete(`/organisation/${ref}/finance/bill/${billRef}?moveToRef=${moveToRef}`)
    }

    getBills(ref: OrganisationRef): Promise<Array<Bill>> {
        return httpService.get(`/organisation/${ref}/finance/bill`)
    }

    getBillItems(ref: OrganisationRef, billRef: string): Promise<OrganisationBillItems> {
        return httpService.get(`/organisation/${ref}/finance/bill/${billRef}/item`)
    }

    updateBillItem(ref: OrganisationRef, billRef: string, billItemRef: string, body: BillItemData): Promise<void> {
        return httpService.put(`/organisation/${ref}/finance/bill/${billRef}/item/${billItemRef}`, body)
    }

    addBillItem(ref: MemberRef, billRef: string): Promise<void> {
        return httpService.post(`/organisation/${ref}/finance/bill/${billRef}/item`, {})
    }

    deleteBillItem(ref: MemberRef, billRef: string, billItemRef: string): Promise<void> {
        return httpService.delete(`/organisation/${ref}/finance/bill/${billRef}/item/${billItemRef}`)
    }

    moveBillItem(ref: MemberRef, billRef: string, targetBillRef: string, billItemRef: string): Promise<void> {
        return httpService.put(`/organisation/${ref}/finance/bill/${billRef}/item/${billItemRef}/target/${targetBillRef}`, {})
    }

    getBalance(ref: OrganisationRef): Promise<OrganisationBalance> {
        return httpService.get(`/organisation/${ref}/finance/bill/balance`)
    }

    getOrders(ref: OrganisationRef): Promise<OrganisationOrders> {
        return httpService.get(`/organisation/${ref}/finance/orders`)
    }

    getOrderDetail(ref: OrganisationRef, orderRef: OrderRef): Promise<OrderDetail> {
        return httpService.get(`/organisation/${ref}/finance/orders/${orderRef}`)
    }

    exportOrder(ref: OrganisationRef, orderRef: OrderRef): Promise<FileContent> {
        return httpService.download(`/organisation/${ref}/finance/orders/${orderRef}/export`)
    }

    getCreateBillMetadata(ref: OrganisationRef): Promise<CreateBillMetadata> {
        return httpService.get(`/organisation/${ref}/finance/create-bill-metadata`)
    }

    createBill(ref: OrganisationRef, data: CreateOrganisationBillData): Promise<void> {
        return httpService.post(`/organisation/${ref}/finance/bill`, data)
    }
}

export interface OrganisationBalance {
    amount: Money
}

export interface CreateOrganisationBillData {
    billType: string
    memberRef?: string
}

export interface CreateBillMetadata {
    members: Array<RefWithName>
}

export enum CreateOrganisationBillType {
    ORGANISATION = "ORGANISATION",
    MEMBER = "MEMBER"
}

export interface OrganisationBillItems {
    bill: Bill,
    items: Array<BillItem>,
    articles: Array<RefWithName>
}

export interface OrganisationOrders {
    orders: Array<Order>
}

export const organisationFinanceService = new OrganisationFinanceService();