
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import 'codemirror/theme/neo.css'
import 'codemirror/mode/javascript/javascript.js'

@Component
export default class CodeMirrorField extends mixins(Vue, abstractField) {
  cmOptions = {
    tabSize: 4,
    mode: 'text/x-kotlin',
    theme: 'idea',
    lineNumbers: true,
    line: true,
    inputStyle:'contenteditable',
    styleActiveLine: true,
  }
}
