import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";

axios.defaults.headers["eqify-domain"] = window.location.hostname

axios.interceptors.request.use(function (config) {
    if (Vue.prototype.$keycloak.authenticated) {
        config.headers['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

Vue.use(VueAxios, axios);
