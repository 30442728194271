
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import {CurrencyInput} from 'vue-currency-input'


@Component({
        components: {CurrencyInput}
    })
    export default class CurrencyField extends mixins(Vue, abstractField) {
    }
