import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {FileContent, httpService} from "@/services/HttpService";
import {DateUtils} from "@/utils/DateUtils";
import {ArticleRef, ArticleType} from "@/modules/federation/services/ArticleService";
import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";

class InvoiceService {
    getInvoices(federationRef: FederationRef, invoiceActionRef: InvoiceActionRef): Promise<Array<Invoice>> {
        return httpService.get(`/myfederation/${federationRef}/invoices/actions/${invoiceActionRef}`)
    }

    getInvoiceActions(federationRef: FederationRef): Promise<Array<InvoiceAction>> {
        return httpService.get(`/myfederation/${federationRef}/invoices/actions`)
    }

    getInvoiceDetail(federationRef: FederationRef, invoiceRef: InvoiceRef): Promise<InvoiceDetail> {
        return httpService.get(`/myfederation/${federationRef}/invoices/${invoiceRef}`)
    }

    deleteInvoice(federationRef: FederationRef, invoiceRef: InvoiceRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/invoices/${invoiceRef}`)
    }

    deleteInvoiceAction(federationRef: FederationRef, invoiceActionRef: InvoiceActionRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/invoices/actions/${invoiceActionRef}`)
    }

    createInvoices(federationRef: FederationRef, request: CreateInvoiceRequest): Promise<CreateInvoiceResponse> {
        return httpService.post(`/myfederation/${federationRef}/invoices`, {
            name: request.name,
            fromDate: request.fromDate ? DateUtils.toDateString(request.fromDate) : undefined,
            toDate: request.toDate ? DateUtils.toDateString(request.toDate) : undefined,
            organisationRef: request.organisationRef,
            articleRefs: request.articleRefs
        })
    }

    downloadUbl(url: string): Promise<FileContent> {
        return httpService.download(url);
    }
}

export type InvoiceRef = string;

export interface Invoice {
    ref: InvoiceRef;
    date: string,
    invoiceId?: string;
    recipient: Account,
    amount: number
    status: InvoiceStatus
}

export type InvoiceActionRef = string;

export interface InvoiceAction {
    ref: InvoiceActionRef,
    name: string,
    date: string,
    ublGenerationStatus?: string,
    ublZipLocation?: string,
    exactSyncStatus?: string,
    exactSyncDate?: string
}

export interface InvoiceDetail {
    ref: InvoiceRef;
    date: string,
    invoiceId?: string;
    recipient: Account,
    amount: number
    status: InvoiceStatus,
    fileLocation: string,
    items: Array<InvoiceItem>
}

export interface InvoiceItem {
    description: string,
    transactionType: FinanceTransactionType,
    articleName: string,
    articleType: ArticleType,
    valueWithoutTax: string,
    valueWithTax: string,
    vatLevel: number,
    currency: string
}


export interface CostCenter {
    ref: string,
    type: CostCenter,
    name: string,
}

export enum CostCenterType {
    FEDERATION = "FEDERATION",
    ORGANISATION = "ORGANISATION",
    PERSON = "PERSON",
    COMPETITION = "COMPETITION"
}

export interface Account {
    ref?: string,
    contactRef: string,
    contactType: ContactType,
    type: AccountType,
    name: string,
    to?: string,
    address?: Geolocation,
    email?: string,
    phone?: string,
    iban?: string,
    bic?: string,
    defaultForCommunication: boolean,
    defaultForInvoicing: boolean,
    vatNumber?: string,
    companyName?: string
}

export function createLink(contact: Account, suffixUrl: string = "") {
    switch (contact.contactType) {
        case ContactType.FEDERATION:
            return `/myfederation/${contact.contactRef}/general${suffixUrl}`
        case ContactType.ORGANISATION:
            return `/organisations/${contact.contactRef}${suffixUrl}`
        case ContactType.PERSON:
            return `/members/${contact.contactRef}${suffixUrl}`
        case ContactType.COMPETITION:
            return `/competitions/${contact.contactRef}${suffixUrl}`
        default:
            return undefined
    }
}

export enum AccountType {
    PERSONAL = "PERSONAL",
    COMPANY = "COMPANY"
}

export enum ContactType {
    FEDERATION = "FEDERATION",
    ORGANISATION = "ORGANISATION",
    PERSON = "PERSON",
    COMPETITION = "COMPETITION"
}

export enum InvoiceStatus {
    DRAFT = "DRAFT",
    INVOICED = "INVOICED",
    PAID = "PAID",
    OVERDUE = "OVERDUE"
}

export enum FinanceTransactionType {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT"
}

export interface CreateInvoiceRequest {
    name?: string,
    fromDate?: Date,
    toDate?: Date,
    organisationRef?: OrganisationRef,
    articleRefs: Array<ArticleRef>
}

export interface CreateInvoiceResponse {
    created: number;
}

export const invoiceService = new InvoiceService();
