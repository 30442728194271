
import {Component, Vue} from 'vue-property-decorator';
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import {httpService} from "@/services/HttpService";
import {SharedObject, SharedObjectType} from "@/shared/domain/SharedObject";

@Component({
  components: {EqifyAvatarAndText}
})
export default class SharedObjectsMenu extends Vue {
  shares: Array<SharedObject> = []

  mounted() {
    httpService.get<Array<SharedObject>>("/auth/user/shares").then(s => this.shares = s)
  }

  unmounted() {
    this.shares = []
  }

  icon(share: SharedObject) {
    switch (share.type) {
      case SharedObjectType.COMPETITION:
        return "campground"
      case SharedObjectType.COURSE:
        return "book"
      default:
        return ""
    }
  }

  url(share: SharedObject) {
    switch (share.type) {
      case SharedObjectType.COMPETITION:
        return `/competitions/${share.ref}/manage`
      case SharedObjectType.COURSE:
        return `/courses/${share.ref}/manage`
      default:
        return undefined
    }
  }
}
