
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";

@Component({
  components: {OrganisationSearchInput}
})
export default class OrganisationSearchField extends mixins(Vue, abstractField) {
  get getDisabled() {
    // @ts-ignore
    return this.schema.disabled || false
  }

  get facilityOnly() {
    // @ts-ignore
    return this.schema.facilityOnly || false
  }
}
