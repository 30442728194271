
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import PageTitle from "@/components/PageTitle.vue";
import StateButtonBar from "@/components/general/StateButtonBar.vue";
import {BModal, BvModalEvent} from "bootstrap-vue";
import DeleteButton from "@/components/general/buttons/DeleteButton.vue";
import EditButton from "@/components/general/buttons/EditButton.vue";
import StateBadge from "@/components/general/StateBadge.vue";
import EqifyPage from "@/components/EqifyPage.vue";
import {EntityState} from "@/shared/domain/EntityState";
import EqifyLoader from "@/components/EqifyLoader.vue";
import EqifyTable from "@/components/table/EqifyTable.vue";

@Component({
    components: {EqifyTable, EqifyLoader, EqifyPage, StateBadge, EditButton, DeleteButton, StateButtonBar, PageTitle}
})
export default class EqifyCrudComponent<F, E> extends Vue {
    @Prop() title!: string;
    @Prop() subtitle!: string;
    @Prop() modalTitle!: string;
    @Prop({default: 'md'}) modalSize?: string;
    @Prop() defaultFormModel!: F;
    @Prop() data?: Array<E>;
    @Prop() fields?: Array<any>;
    @Prop() transformer!: (item: E) => F;
    @Prop() formSchema?: any;
    @Prop({type: Boolean}) withState?: boolean;
    @Prop({type: Boolean}) withDetails?: boolean;
    @Prop({type: Boolean}) fullPage?: boolean;
    @Prop({default: false, type: Boolean}) hideTitle?: boolean;
    @Prop({default: false, type: Boolean}) loading?: boolean;
    @Prop({default: 0}) perPage?: number;
    @Prop({type: Function}) checkAddButtonPermission?: CrudPermission;
    @Prop({type: Function}) checkDeletePermission?: CrudPermission;
    @Prop({type: Function}) checkEditPermission?: CrudPermission;

    @Ref("crud-modal") private modal!: BModal;
    private form: F = this.newForm();
    private formValid: boolean = false;
    private formEditing: boolean = false;
    private editingRow: number = -1;
    private formOptions = {
        validateAfterLoad: true,
        validateAfterChanged: true
    };

    newForm(): F {
        return Object.assign({}, this.defaultFormModel);
    }

    async onSubmit(event:BvModalEvent) {
        // @ts-ignore
        this.$refs?.vfg?.validate()
        if (this.formValid) {
            if (this.formEditing) {
                this.$emit("update", {form: this.form, index: this.editingRow});
            } else {
                this.$emit("create", {form: this.form});
            }
        }else {
            event.preventDefault()
        }
    }

    get tbodyTrClass() {
        return this.withDetails ? 'cursor-pointer' : ''
    }

    isAllowedToDelete(row: any) {
        if (this.checkDeletePermission) {
            return this.checkDeletePermission(row)
        } else {
            return true
        }
    }

    isAllowedToEdit(row: any) {
        if (this.checkEditPermission) {
            return this.checkEditPermission(row)
        } else {
            return true
        }
    }

    isAllowedToAdd() {
        if (this.checkAddButtonPermission) {
            return this.checkAddButtonPermission()
        } else {
            return true
        }
    }

    doDelete(row: any) {
        let item = row.item;
        this.$emit("delete", {item: item, index: this.data!.indexOf(item)});
    }

    doEdit(row: any) {
        let item = row.item;
        this.formEditing = true;
        this.editingRow = this.data!.indexOf(item);
        this.form = this.transformer(item);
        this.modal.show()
    }

    doNew() {
        this.formEditing = false;
        this.form = this.newForm();
        this.modal.show()
    }

    doPublish(row: any) {
        this.$emit("publish", {item: row.item, index: this.data!.indexOf(row.item)});
    }

    doUnpublish(row: any) {
        this.$emit("unpublish", {item: row.item, index: this.data!.indexOf(row.item)});
    }

    doArchive(row: any) {
        this.$emit("archive", {item: row.item, index: this.data!.indexOf(row.item)});
    }

    doDetails(row: any) {
        if (this.withDetails) {
            this.$emit("detail", {item: row[0]});
        }
    }

    formValidated(isValid: boolean, errors: Array<string>) {
        this.formValid = isValid;
    }

    get containerClass() {
        return this.fullPage ? 'container-p-y' : 'm-0 p-0';
    }

    get bodyClass() {
        return this.fullPage ? 'bg-white' : 'm-0 p-0';
    }
}

export class EqifyCrudUtils {
    static tableFields() {
        return []
    }

    static actions(vue: Vue) {
        return {key: 'actions', label: vue.$t("general.entity.actions.label")}
    }

    static state(vue: Vue) {
        return {
            key: 'state', label: vue.$t("general.entity.state.label"), sortable: true,
            filterable: true,
            filterOptions: {
                type: 'select',
                values: EntityState,
                function: (v: any) => v.state
            }
        }
    }
}

export type CrudPermission = (...args: any[]) => boolean
