
import {Component, Vue} from 'vue-property-decorator';
//@ts-ignore
import CookieLaw from 'vue-cookie-law'
import {httpService} from "@/services/HttpService";

@Component({components: {CookieLaw}})
    export default class PageFooter extends Vue {
        now = this.$moment()
        private appVersion = ""
        private apiVersion = ""

        created() {
            httpService.get("/version.txt", {isRaw: true, hideToast: false})
                .then((version: any) => this.appVersion = `${version}`)
            httpService.get("/_/system/version", {isRaw: true, hideToast: false})
                .then((version: any) => this.apiVersion = `${version}`)
        }
    }
