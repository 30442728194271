import Vue from 'vue'
import VueI18n, {LocaleMessages} from 'vue-i18n'
import {ConfigProvider} from "@/ConfigProvider";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    // @ts-ignore
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    // @ts-ignore
    locales.keys().forEach(key => {
        const matched = key.match(/\.\/([A-Za-z]*)\/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = Object.assign(messages[locale]||{}, locales(key))
        }
    });
    return messages
}

export default new VueI18n({
    locale: ConfigProvider.i18nLocale || 'en',
    fallbackLocale: ConfigProvider.i18nFallbackLocale || 'en',
    messages: loadLocaleMessages()
})
