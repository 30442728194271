import {FileContent} from "@/services/HttpService";

export function download(filename: FileContent) {
    const fileURL = window.URL.createObjectURL(filename.data);
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename.name);
    document.body.appendChild(fileLink);
    fileLink.click();
}