import {CompetitionDetail, CompetitionListItem} from "@/modules/competition/service/CompetitionService";
import {CompetitionStatus} from "@/modules/competition/service/ManageCompetitionService";
import {UserProfile} from "@/shared/domain/User";

export default class CompetitionUtils {

    static competitionUrl(user: UserProfile | undefined, competition: CompetitionListItem | CompetitionDetail) {
        const url = `/competitions/${competition.ref}`

        if (CompetitionUtils.redirectToManage(user, competition)) {
            return url + "/manage"
        } else if (CompetitionUtils.isCancelled(competition)) {
            return undefined
        } else {
            return url
        }
    }

    static isPublished(competition: CompetitionListItem | CompetitionDetail) {
        return competition.status == CompetitionStatus.PUBLISHED
    }

    static redirectToManage(user: UserProfile | undefined, competition: CompetitionListItem | CompetitionDetail) {
        return user && user.isFederationAdministratorOrCollaborator(competition.federationRef) && [CompetitionStatus.DRAFT, CompetitionStatus.HIDDEN, CompetitionStatus.CANCELLED].includes(competition.status!)
    }

    static isCancelled(competition: CompetitionListItem | CompetitionDetail) {
        return competition.status == CompetitionStatus.CANCELLED
    }
}
