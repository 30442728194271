
import {Component, Vue} from "vue-property-decorator";
import {CalendarGroup, CalendarGroupType, ChampionshipCalendarItem} from "@/components/calendar/Calendar";
import EqifyPage from "@/components/EqifyPage.vue";
import {FederationUserInfo, UserProfile} from "@/shared/domain/User";
import WelcomeMessage from "@/components/general/WelcomeMessage.vue";
import {calendarService} from "@/modules/dashboard/services/CalendarService";
import ChampionshipCalendarItemWithCompetitionsComponent
  from "@/components/calendar/championship/ChampionshipCalendarItemWithCompetitionsComponent.vue";
import {Loader} from "@/utils/Loader";
import CalendarGroupComponent from "@/components/calendar/CalendarGroupComponent.vue";

@Component({
  components: {
    CalendarGroupComponent,
    ChampionshipCalendarItemWithCompetitionsComponent, EqifyPage, WelcomeMessage
  }
})
export default class CalendarView extends Vue {
  private totalItems = 0
  private componentMounted = false
  private championshipsWithCompetitions: Array<ChampionshipCalendarItem> = []

  private loader = Loader.create()

  private updateCount(count: number) {
    if (count > this.totalItems) this.totalItems = count
  }

  async groupCallback(type: CalendarGroupType, page: number | undefined): Promise<CalendarGroup> {
    if (type == CalendarGroupType.THIS_WEEK) {
      return await calendarService.getThisWeekCalendar()
    } else if (type == CalendarGroupType.NEXT_WEEK) {
      return await calendarService.getNextWeekCalendar()
    } else if (type == CalendarGroupType.LAST_WEEK) {
      return await calendarService.getLastWeekCalendar()
    } else if (type == CalendarGroupType.LATER) {
      return await calendarService.getLaterCalendar(page || 0)
    } else {
      return Promise.reject()
    }
  }

  mounted() {
    this.loader.load(async () => {
      this.componentMounted = true
      this.championshipsWithCompetitions = await calendarService.getChampionshipWithCompetitions()
    })
  }

  get actions() {
    const actions = []
    actions.push({
      name: this.$t('courses.create.label'),
      icon: 'book',
      to: '/courses/create',
      variant: 'primary',
      multiFederation: true,
      checkPermission: this.canCreateEvent //todo
    })
    actions.push({
      name: this.$t('calendar.event.create.label'),
      icon: 'calendar',
      to: '/events/create',
      variant: 'primary',
      multiFederation: true,
      checkPermission: this.canCreateEvent
    })
    actions.push({
      name: this.$t('competition.create.label'),
      icon: 'campground',
      to: '/competitions/create',
      variant: 'primary',
      multiFederation: true,
      checkPermission: this.canCreateCompetition
    })
    actions.push({
      name: this.$t('championships.create.label'),
      icon: 'trophy',
      to: '/championships/create',
      variant: 'primary',
      multiFederation: true,
      checkPermission: this.canCreateChampionship
    })
    return actions;
  }

  canCreateEvent(user: UserProfile, federationUserInfo: FederationUserInfo) {
    return user.canCreateEventForFederation(federationUserInfo.ref!)
  }

  canCreateCompetition(user: UserProfile, federationUserInfo: FederationUserInfo) {
    return user.canCreateCompetition(federationUserInfo.ref!)
  }

  canCreateChampionship(user: UserProfile, federationUserInfo: FederationUserInfo) {
    return user.canCreateChampionship(federationUserInfo.ref!)
  }
}
