
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component
export default class MemberSearchField extends mixins(Vue, abstractField) {
  query: string = "";

  created() {
    //@ts-ignore
    this.query = this.value ? this.value.name || "" : "";
  }
}
