
import {Component, Prop, Vue} from "vue-property-decorator";
import {SplitValue, SplitValueType} from "@/shared/domain/General";

@Component
export default class SplitValueInput extends Vue {
  @Prop() value?: SplitValue
  @Prop() maxPercentage!: number
  @Prop() maxAbsolute!: number

  get splitType(): SplitValueType {
    return this.value?.type || SplitValueType.ABSOLUTE
  }

  set splitType(v: SplitValueType) {
    this.$emit('input', {
      ...this.value,
      type: v
    })
  }

  get splitValue(): number {
    return this.value?.value || 0.0
  }

  set splitValue(v: number) {
    this.$emit('input', {
      ...this.value,
      value: v
    })
  }

  get maxValue(): number {
    if (this.splitType == SplitValueType.PERCENTAGE) {
      return this.maxPercentage
    } else if (this.splitType == SplitValueType.ABSOLUTE) {
      return this.maxAbsolute
    } else return 0
  }
}
