
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class LoadingButton extends Vue {
  @Prop({default: ""}) public size?: string;
  @Prop({default: "primary"}) public variant?: string;
  @Prop({default: "button"}) public type?: string;
  @Prop({default: false, type: Boolean}) public disabled?: boolean;
  @Prop({default: false, type: Boolean}) public loading?: boolean;
  @Prop({default: false, type: Boolean}) public block?: boolean;
  @Prop({default: ""}) public loaderName?: string;

  clicked() {
    this.$emit("click", {})
  }

  get isLoading() {
    if (this.loaderName) {
      return this.$wait.is(this.loaderName)
    } else {
      return this.loading
    }
  }

  get isDisabled() {
    return this.disabled || this.isLoading
  }
}
