import {RouteConfig} from "vue-router";
import {createAnyLoggedInUserRoute, createSecureReadOnlyRouteMetadata} from "@/router";


const routes: Array<RouteConfig> = [
    {
        path: '/horses',
        component: () => import(/* webpackChunkName: "module-horses" */ "@/modules/horses/views/HorseSearchView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: '/horses/create',
        component: () => import(/* webpackChunkName: "module-horses-save" */ "@/modules/horses/views/HorseSave.vue"),
        meta: createAnyLoggedInUserRoute()
    },
    {
        path: '/horses/:horse',
        component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/HorseProfileLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/HorseProfileOverview.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'combinations',
                component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/HorseProfileCombinationsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'competitions',
                component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/HorseProfileCompetitionsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'performances',
                component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/HorseProfilePerformancesView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'similar',
                component: () => import(/* webpackChunkName: "module-horses-profile" */ "@/modules/horses/views/profile/SimilarHorsesView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            }
        ]
    },
    {
        path: '/horses/:horse/edit',
        component: () => import(/* webpackChunkName: "module-horses-save" */ "@/modules/horses/views/HorseSave.vue"),
        meta: createAnyLoggedInUserRoute()
    }
];

export default {
    routes: routes
};
