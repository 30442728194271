import {RouteConfig} from "vue-router";
import {createSecureReadOnlyRouteMetadata} from "@/router";

const ROOT = '/finance';
const routes: Array<RouteConfig> = [
    {
        path: ROOT + '/:federation',
        component: () => import(/* webpackChunkName: "module-finance" */ "@/modules/finance/views/FinanceView.vue"),
        meta: createSecureReadOnlyRouteMetadata(),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "module-finance" */ "@/modules/finance/views/FinanceOverviewView.vue"),
            },
            {
                path: 'orders',
                component: () => import(/* webpackChunkName: "module-finance" */ "@/modules/finance/views/FinanceOrdersView.vue"),
                children:[
                    {
                        path: ':orderRef',
                        component: () => import(/* webpackChunkName: "module-finance" */ "@/modules/finance/views/FinanceOrderDetailView.vue"),
                    }
                ]
            },
            {
                path: 'invoices',
                component: () => import(/* webpackChunkName: "module-finance" */ "@/modules/finance/views/FinanceInvoicesView.vue"),
            }
        ]
    }
];

export default {
    routes: routes
};
