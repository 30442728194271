import {CompetitionDetail} from "@/modules/competition/service/CompetitionService";
import {CourseDayEventListItem} from "@/modules/course/service/CourseEvent";
import {Championship} from "@/modules/championship/services/PublicChampionshipService";
import {ChampionshipDetail} from "@/modules/organisations/services/OrganisationPreRegistrationService";

export interface Calendar {
    items: Array<CalendarGroup>
}

export interface CalendarGroup {
    order: number
    type: CalendarGroupType
    items: Array<CalendarItem>
}

export enum CalendarGroupType {
    THIS_WEEK = "THIS_WEEK",
    NEXT_WEEK = "NEXT_WEEK",
    LAST_WEEK = "LAST_WEEK",
    LATER = "LATER"
}

export enum CalendarItemType {
    COMPETITION = "COMPETITION",
    CHAMPIONSHIP = "CHAMPIONSHIP",
    COURSE = "COURSE",
    OTHER = "OTHER"
}

export interface CalendarItem {
    type: CalendarItemType
    date: string,
    avatar?: string
}

export interface CompetitionCalendarItem extends CalendarItem {
    competition: CompetitionDetail,
    championships: Array<CalendarChampionshipDetail>
}

export interface CalendarChampionshipDetail {
    ref: string,
    name: string,
    color?: string
}

export interface ChampionshipCalendarItem extends CalendarItem {
    championship: Championship,
    competitions: Array<CompetitionCalendarItem>
}

export interface CourseCalendarItem extends CalendarItem {
    courseEvent: CourseDayEventListItem
}

