import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import Vue from "vue";
import {httpService} from "@/services/HttpService";
import {SportCategory} from "@/modules/competition/service/CompetitionTestsService";
import {RefWithName} from "@/utils/Utils";
import {Arena} from "@/shared/domain/Combination";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";

class ManageTimescheduleService {
    getData(ref: CompetitionRef): Promise<CompetitionTimeScheduleData> {
        return httpService.get(`/competition/manage/${ref}/timeschedule/metadata`)
    }

    getTimescheduleProposal(ref: CompetitionRef): Promise<TimescheduleProposal> {
        return httpService.get(`/competition/manage/${ref}/timeschedule/proposal`)
    }

    createUnassignedActivities(ref: CompetitionRef, startlistRefs: Array<string>): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/create`, {startlistRefs: startlistRefs})
    }

    getArenas(ref: CompetitionRef): Promise<Array<ArenaSpec>> {
        return httpService.get(`/competition/manage/${ref}/arena`)
    }

    saveArenas(ref: CompetitionRef, arenas: Array<ArenaSpec>): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/arena`, {arenas: arenas})
    }

    recalculateTime(ref: CompetitionRef): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/recalculate-time`, {})
    }

    saveSportActivity(ref: CompetitionRef, activityRef: string | undefined, arena: string, date: string, start: string, end: string, hideTime: boolean, hideEntryTime: boolean, startlistRef: string, name: string): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/sport`, {
            ref: activityRef,
            arenaRef: arena,
            startlistRef: startlistRef,
            date: date,
            start: start,
            end: end,
            hideTime: hideTime,
            hideEntryTime: hideEntryTime,
            name: name,
        })
    }

    saveTextActivity(ref: CompetitionRef, activityRef: string | undefined, general: boolean, arena: string | undefined, date: string, start: string, end: string, hideTime: boolean, name: string, description: string | undefined, link: string | undefined): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/text`, {
            ref: activityRef,
            arenaRef: arena,
            date: date,
            start: start,
            end: end,
            hideTime: hideTime,
            name: name,
            description: description,
            link: link,
            general: general
        })
    }

    saveBreakActivity(ref: CompetitionRef, activityRef: string | undefined, arena: string, date: string, start: string, end: string, hideTime: boolean): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/break`, {
            ref: activityRef,
            arenaRef: arena,
            date: date,
            start: start,
            end: end,
            hideTime: hideTime,
        })
    }

    getChampionshipRankingActivityMetadata(ref: CompetitionRef, championshipRef: string | undefined, championshipPhaseRef: string | undefined, championshipGroupRef: string | undefined, championshipSportCategoryRef: string | undefined): Promise<ChampionshipRankingTimescheduleMetadata> {
        return httpService.get(`/competition/manage/${ref}/timeschedule/championship-ranking/metadata`, {
            params: {
                championshipRef: championshipRef,
                championshipPhaseRef: championshipPhaseRef,
                championshipGroupRef: championshipGroupRef,
                championshipSportCategoryRef: championshipSportCategoryRef
            }
        })
    }

    getCompetitionRankingActivityMetadata(ref: CompetitionRef): Promise<CompetitionRankingTimescheduleMetadata> {
        return httpService.get(`/competition/manage/${ref}/timeschedule/competition-ranking/metadata`)
    }

    saveChampionshipRankingActivity(ref: CompetitionRef, activityRef: string | undefined, general: boolean, arena: string, date: string, championshipRef: string, championshipPhaseRef: string, championshipGroupRef: string, championshipSportCategoryRef: string | undefined, allChampionshipSportCategories: boolean, name: string): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/championship-ranking`, {
            ref: activityRef,
            general: general,
            arenaRef: arena,
            date: date,
            championshipRef: championshipRef,
            championshipPhaseRef: championshipPhaseRef,
            championshipGroupRef: championshipGroupRef,
            championshipSportCategoryRef: championshipSportCategoryRef,
            allChampionshipSportCategories: allChampionshipSportCategories,
            name: name
        })
    }

    saveCompetitionRankingActivity(
        ref: CompetitionRef,
        activityRef: string | undefined,
        general: boolean,
        arena: string,
        date: string,
        start: string | undefined,
        end: string | undefined,
        hideTime: boolean,
        name: string,
        competitionRankingRefs: Array<string>
    ): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/timeschedule/competition-ranking`, {
            ref: activityRef,
            general: general,
            arenaRef: arena,
            date: date,
            start: start,
            end: end,
            name: name,
            hideTime: hideTime,
            competitionRankingRefs: competitionRankingRefs
        })
    }

    deleteSportActivity(ref: CompetitionRef, activityRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/timeschedule/sport/${activityRef}`)
    }

    deleteBreakActivity(ref: CompetitionRef, activityRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/timeschedule/break/${activityRef}`)
    }

    deleteTextActivity(ref: CompetitionRef, activityRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/timeschedule/text/${activityRef}`)
    }

    deleteChampionRankingActivity(ref: CompetitionRef, activityRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/timeschedule/championship-ranking/${activityRef}`)
    }

    deleteCompetitionRankingActivity(ref: CompetitionRef, activityRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/timeschedule/competition-ranking/${activityRef}`)
    }

    addArena(ref: CompetitionRef, code: string, name: string): Promise<Arena> {
        return httpService.post(`/competition/manage/${ref}/arena`, {code: code, name: name})
    }

    updateArena(ref: CompetitionRef, arenaRef: string, code: string, name: string): Promise<Arena> {
        return httpService.post(`/competition/manage/${ref}/arena/${arenaRef}`, {ref: arenaRef, code: code, name: name})
    }

    deleteArena(ref: CompetitionRef, arenaRef: string): Promise<RefWithName> {
        return httpService.delete(`/competition/manage/${ref}/arena/${arenaRef}`)
    }
}

export interface ArenaSpec {
    type: ArenaMainSportType
    quantity: number
}

export interface CompetitionTimeScheduleData {
    menu: TimescheduleMenu;
    sections: Array<ScheduleSection>;
    arenas: Array<RefWithName>;
}

export interface TimescheduleMenu {
    entries: Array<SportCategoryMenuEntry>
}

export interface SportCategoryMenuEntry {
    name: string,
    entries: Array<UnassignedActivityEntry>
}

export interface UnassignedActivityEntry {
    startlistRef: string,
    name: string,
    set: RefWithName,
    hasMultipleSets: boolean,
    includedInCareer: boolean,
    includedInChampionship: boolean,
    sportCategories: Array<SportCategory>,
    duration: number
}

export interface ChampionshipRankingTimescheduleMetadata {
    championships: Array<RefWithName>,
    championshipGroups: Array<RefWithName>,
    championshipPhases: Array<RefWithName>,
    championshipSportCategories: Array<RefWithName>,
}

export interface CompetitionRankingTimescheduleMetadata {
    rankings: Array<RefWithName>
}

export enum ScheduleSectionType {
    EMPTY = "EMPTY",
    GENERAL = "GENERAL",
    GENERAL_RANKING = "GENERAL_RANKING",
    DAY = "DAY",
    ARENA = "ARENA",
    SPORT = "SPORT",
    BREAK = "BREAK",
    TEXT = "TEXT",
    RANKING = "RANKING",
    CHAMPIONSHIP_RANKING = "CHAMPIONSHIP_RANKING"
}

// applies to empty, general and day
export interface ScheduleSection {
    type: ScheduleSectionType;
    name: string;
    order: number;
}

export interface ArenaSection extends ScheduleSection {
    code: string;
    ref: string;
    hasActivities: boolean;
}

// applies to break
export interface ActivitySection extends ScheduleSection {
    ref: string;
    arena: RefWithName;
    date: string;
    start: string;
    end: string;
    hideTime: boolean;
}

export interface TextSection extends ActivitySection {
    description?: string;
    link?: string;
    general: boolean;
}

export interface SportSection extends ActivitySection {
    numberOfStartlistEntries: number,
    activityAvailableDuration: number,
    activityActualDuration: number,
    startlistRef: string,
    startlistName: string,
    hideEntryTime: boolean,
    sportCategories: Array<SportCategory>,
    protocol?: RefWithName
}

export interface ChampionshipRankingSection extends ActivitySection {
    general: boolean,
    championship: RefWithName,
    championshipPhase: RefWithName,
    championshipGroup: RefWithName,
    championshipSportCategory: SportCategory
}

export interface CompetitionRankingSection extends ActivitySection {
    general: boolean,
    ranking: RefWithName,
    sportTypeVariant: SportTypeVariant
    sportCategory?: SportCategory
}

export interface TimescheduleProposal {
    competitionRef: CompetitionRef,
    activities: Array<TimescheduleProposalActivity>
}

export interface TimescheduleProposalActivity {
    test: RefWithName,
    protocol?: RefWithName,
    offset: number,
    capacity: number,
    bucketName?: string,
    round?: number,
    numberOfParticipants: number,
    activityName: string,
    startTime: string,
    endTime: string,
    requiredTime: number,
    arena: string
}

export interface CreateTimescheduleActivity {
    testRef: string,
    round?: number,
    offset: number,
    capacity: number,
    startTime: string,
    endTime: string,
    arenaName: string,
    bucketName?: string,
}

export const getActivityRefFromRoute = (vue: Vue): string => {
    return vue.$route.params.activity;
};

export enum ArenaMainSportType {
    SHOW_JUMPING_ARENA = "SHOW_JUMPING_ARENA",
    DRESSAGE_RING = "DRESSAGE_RING",
    GROUP_DRESSAGE_RING = "GROUP_DRESSAGE_RING",
    CROSS_COUNTRY = "CROSS_COUNTRY",
    PRESENTATION_RING = "PRESENTATION_RING"
}

export const manageTimescheduleService = new ManageTimescheduleService();
