import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {SportCategoryRef} from "@/modules/federation/services/SportCategoryService";
import Vue from "vue";
import {httpService} from "@/services/HttpService";
import {EntityStateResource, EntityWithStateData} from "@/shared/domain/EntityState";
import {SportTypeVariant, SportTypeVariantRef} from "@/modules/federation/services/SportTypeVariantService";
import {RefWithName} from "@/utils/Utils";
import {PointDistribution, PointDistributionSaveRequest} from "@/shared/domain/PointDistribution";
import {Practitioner} from "@/shared/domain/Practitioner";

class CareerService {
    getCareers(federationRef: FederationRef): Promise<Array<Career>> {
        return httpService.get(`/myfederation/${federationRef}/career`)
    }

    getCareerCategories(federationRef: FederationRef, careerRef: CareerRef): Promise<Array<ManageCareerPointDistributionCategory>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/category`)
    }

    getCareerCategoriesMetadata(federationRef: FederationRef, careerRef: CareerRef): Promise<ManageCareerPointDistributionCategoriesMetadata> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/category/metadata`)
    }

    deleteCareerCategory(federationRef: FederationRef, careerRef: CareerRef, category: CareerPointDistributionCategoryRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/career/${careerRef}/category/${category}`)
    }

    saveCareerCategory(federationRef: FederationRef, careerRef: CareerRef, save: ManageCareerPointDistributionCategorySaveRequest): Promise<string> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/category`, save)
    }

    addCareer(federationRef: FederationRef, career: Career): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/career`, {
            name: career.name,
            practitionerGroup: career.practitionerGroup ? career.practitionerGroup.ref : undefined,
            sportTypeVariantRefs: career.sportTypeVariants!.map(it => it.ref)
        })
    }

    updateCareer(federationRef: FederationRef, career: Career): Promise<EntityWithStateData> {
        return httpService.put(`/myfederation/${federationRef}/career/${career.ref}`, {
            name: career.name,
            practitionerGroup: career.practitionerGroup ? career.practitionerGroup.ref : undefined,
            sportTypeVariantRefs: career.sportTypeVariants!.map(it => it.ref)
        })
    }

    deleteCareer(federationRef: FederationRef, ref: CareerRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/career/${ref}`)
    }

    publishCareer(federationRef: FederationRef, ref: CareerRef, note: String = "A Note"): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/career/${ref}/publish`, note)
    }

    unpublishCareer(federationRef: FederationRef, ref: CareerRef): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/career/${ref}/unpublish`, {})
    }

    archiveCareer(federationRef: FederationRef, ref: CareerRef): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/career/${ref}/archive`, {})
    }

    getCareer(federationRef: FederationRef, careerRef: CareerRef): Promise<CareerDetail> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}`)
    }

    getPointDistributions(federationRef: FederationRef, careerRef: CareerRef, categoryRef: CareerPointDistributionCategoryRef): Promise<Array<CareerPointDistribution>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/category/${categoryRef}/pointdistribution`)
    }

    savePointDistribution(federationRef: FederationRef, careerRef: CareerRef, categoryRef: CareerPointDistributionCategoryRef, sportTypeVariantRef: SportTypeVariantRef, request: PointDistributionSaveRequest): Promise<string> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/category/${categoryRef}/pointdistribution`,
            {
                sportTypeVariantRef: sportTypeVariantRef,
                request: request
            })
    }

    addTransitionRule(federationRef: FederationRef, careerRef: CareerRef, rule: CareerTransitionRule): Promise<string> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/add-transition-rule`, rule)
    }

    updateTransitionRule(federationRef: FederationRef, careerRef: CareerRef, rule: CareerTransitionRule): Promise<string> {
        return httpService.put(`/myfederation/${federationRef}/career/${careerRef}/update-transition-rule`, rule)
    }

    deleteTransitionRule(federationRef: FederationRef, careerRef: CareerRef, ruleRef: CareerTransitionRule): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/career/${careerRef}/delete-transition-rule/${ruleRef}`)
    }

    getCareerTransitionCandidatesForMustIncrease(federationRef: FederationRef, careerRef: CareerRef): Promise<Array<CareerTransitionCandidate>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/must-increase`)
    }

    getCareerTransitionCandidatesForCanIncrease(federationRef: FederationRef, careerRef: CareerRef): Promise<Array<CareerTransitionCandidate>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/can-increase`)
    }

    getCareerTransitionCandidatesForCanDecrease(federationRef: FederationRef, careerRef: CareerRef): Promise<Array<CareerTransitionCandidate>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/can-decrease`)
    }

    getCareerTransitionCandidatesForToReset(federationRef: FederationRef, careerRef: CareerRef): Promise<Array<CareerTransitionCandidate>> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/to-reset`)
    }

    getMustIncreaseCount(federationRef: FederationRef, careerRef: CareerRef): Promise<number> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/must-increase/count`)
    }

    getCanIncreaseCount(federationRef: FederationRef, careerRef: CareerRef): Promise<number> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/can-increase/count`)
    }

    getCanDecreaseCount(federationRef: FederationRef, careerRef: CareerRef): Promise<number> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/can-decrease/count`)
    }

    getToResetCount(federationRef: FederationRef, careerRef: CareerRef): Promise<number> {
        return httpService.get(`/myfederation/${federationRef}/career/${careerRef}/cockpit/transition-candidates/to-reset/count`)
    }

    applyCareerTransitionForMustIncrease(federationRef: FederationRef, careerRef: CareerRef, all: boolean, practitionerRefs: Array<string>): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/cockpit/apply-transitions/must-increase?all=${all}`, {practitionerRefs: practitionerRefs})
    }

    applyCareerTransitionForCanIncrease(federationRef: FederationRef, careerRef: CareerRef, all: boolean, practitionerRefs: Array<string>): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/cockpit/apply-transitions/can-increase?all=${all}`, {practitionerRefs: practitionerRefs})
    }

    applyCareerTransitionForCanDecrease(federationRef: FederationRef, careerRef: CareerRef, all: boolean, practitionerRefs: Array<string>): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/cockpit/apply-transitions/can-decrease?all=${all}`, {practitionerRefs: practitionerRefs})
    }

    applyCareerTransitionForToReset(federationRef: FederationRef, careerRef: CareerRef, all: boolean, practitionerRefs: Array<string>): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/cockpit/apply-transitions/to-reset?all=${all}`, {practitionerRefs: practitionerRefs})
    }

    applyCareerTransitions(federationRef: FederationRef, careerRef: CareerRef, practitionerRefs: Array<string>): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/career/${careerRef}/cockpit/apply-transitions`, {practitionerRefs: practitionerRefs})
    }
}

export type CareerRef = string;

export interface Career {
    ref: CareerRef;
    name?: string;
    sportTypeVariants?: Array<SportTypeVariant>;
    practitionerGroup?: RefWithName;
    state?: EntityStateResource;
}

export interface CareerDetail extends Career {
    categories: Array<CareerPointDistributionCategory>,
    transitionRules?: Array<CareerTransitionRule>;
}

export interface CareerPointDistribution {
    sportTypeVariant: SportTypeVariant;
    pointDistribution: PointDistribution;
}

export type CareerPointDistributionCategoryRef = string

export interface CareerPointDistributionCategory {
    ref: CareerPointDistributionCategoryRef,
    name: string
}

export interface ManageCareerPointDistributionCategory {
    ref: CareerPointDistributionCategoryRef,
    name: string,
    criteriaDescription?: string,
    criteria?: ManageCareerPointDistributionCategoryCriteria
}

export interface ManageCareerPointDistributionCategoryCriteria {
    type: ManageCareerPointDistributionCategoryCriteriaType
}

export interface ByChampionshipCategoryCareerPointDistributionCategoryCriteria extends ManageCareerPointDistributionCategoryCriteria {
    championshipCategory: RefWithName
}

export enum ManageCareerPointDistributionCategoryCriteriaType {
    NONE = "NONE",
    BY_CHAMPIONSHIP_CATEGORY = "BY_CHAMPIONSHIP_CATEGORY"
}

export interface ManageCareerPointDistributionCategorySaveRequest {
    ref?: CareerPointDistributionCategoryRef,
    name: string,
    criteria?: ManageCareerPointDistributionCategoryCriteria
}

export interface ManageCareerPointDistributionCategoriesMetadata {
    championshipCategories: Array<RefWithName>
}

export type CareerTransitionRuleRef = string;

export class CareerTransitionRule {
    ref?: CareerTransitionRuleRef;
    sportCategoryRef?: SportCategoryRef;
    lowerBound?: number;
    upperBound?: number;
    canPass?: number;
    mustPass?: number;
    toSportCategoryRef?: SportCategoryRef;
}

export interface CareerTransitionCandidate {
    practitioner: Practitioner,
    currentSportCategory: CurrentSportCategory,
    points: number,
    targetSportCategory: RefWithName,
    targetPoints: number,
    recommendation: TransitionRecommendation
}

export enum TransitionRecommendation {
    CAN_INCREASE = "CAN_INCREASE",
    MUST_INCREASE = "MUST_INCREASE",
    CAN_DECREASE = "CAN_DECREASE",
    RESET = "RESET",
    NA = "NA"
}

export interface CurrentSportCategory {
    sportCategory: RefWithName,
    activeSportCategory: RefWithName,
    suspended: boolean,
    suspendedReason: string,
}

export const getCareerRefFromRoute = (vue: Vue): CareerRef => {
    return vue.$route.params.career;
};

export const careerService = new CareerService();
