
import {Component, Prop, Vue} from "vue-property-decorator";
import {RefWithName} from "@/utils/Utils";

@Component({})
export default class FederationSelectComponent extends Vue {
  @Prop() value?: RefWithName

  get inputValue(): RefWithName | undefined {
    return this.value;
  }

  set inputValue(value: RefWithName | undefined) {
    this.$emit('input', value)
  }

  get federations(): Array<any> {
    return this.$keycloak.userProfile ? this.$keycloak.userProfile.federations.map((federation) => {
      return {
        ref: federation.ref,
        name: federation.name
      }
    }) : [];
  }
}
