
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchInput from "@/components/general/SearchInput.vue";
import {HorseName} from "@/shared/domain/CompetitionPractitioner";
import AvatarUtils from "@/utils/AvatarUtils";

@Component({
  components: {SearchInput}
})
export default class HorseSearchInput extends Vue {
  @Prop() value?: HorseName | undefined;
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop() allowUnexisting?: boolean;
  @Prop() allowSave?: boolean;
  @Prop() disabled?: boolean;
  @Prop() baseUri?: string;

  get baseUriValue(): string {
    return this.baseUri || "/horse/search"
  }

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }

  avatarName(name: string) {
    return AvatarUtils.avatarName(name)
  }
}
