import {Module, Mutation, VuexModule} from "vuex-module-decorators";

@Module({namespaced: true, name: 'fast-navigation-sidebar-module'})
export default class FastNavigationSidebarModule extends VuexModule {
    open: boolean = false

    @Mutation
    initialize() {
        //TODO
    }

    @Mutation
    openSidebar() {
        this.open = true
    }

    @Mutation
    closeSidebar() {
        this.open = false
    }
}