
import Component from "vue-class-component";
import {RefWithName} from "@/utils/Utils";
import {ArticleRef} from "@/modules/federation/services/ArticleService";
import {Prop, Provide, Vue} from "vue-property-decorator";
import SearchInput from "./SearchInput.vue";

@Component({
  components: {SearchInput}
})
export default class ArticlesSearchInput extends Vue {
  @Prop() value?: RefWithName<ArticleRef>
  @Provide() query: string = "";
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }
}
