import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {NameInput, RefWithName} from "@/utils/Utils";
import {GetTeamRegistrationOfferRequest} from "@/modules/competition/service/ManageRegistrationService";
import {
    RegistrationRequest,
    TargetStartlistPosition,
    TeamRegistrationPractitionerRequest, UpdateRegistrationRequest
} from "@/modules/competition/service/ManageSubscriptionService";
import {PractitionerType} from "@/shared/domain/Practitioner";

@Module({namespaced: true, name: 'competition-team-registration-module'})
export default class CompetitionTeamRegistrationModule extends VuexModule {
    registrationRef: string | null = null
    practitionerRef: string | null = null
    organisation: NameInput | null = null
    team: RefWithName | null = null
    priceProfile: string = ""
    quantity: number = 1
    notes: string = ""

    outOfCompetition: boolean = false
    suggestedPriceProfileRef: string = ""

    @Mutation
    reset() {
        this.registrationRef = null
        this.practitionerRef = null
        this.organisation = null
        this.team = null
        this.priceProfile = ""
        this.quantity = 1
        this.notes = ""
        this.outOfCompetition = false
        this.suggestedPriceProfileRef = ""
    }

    @Mutation
    organisationChangeReset() {
        this.practitionerRef = null
        this.team = null
    }

    @Mutation
    initialize(data: {
        ref: string | null,
        practitionerRef: string | null,
        organisation: NameInput | null,
        team: RefWithName | null,
        notes: string | undefined,
        outOfCompetition: boolean
    }) {
        this.registrationRef = data.ref
        this.practitionerRef = data.practitionerRef
        this.organisation = data.organisation
        this.team = data.team
        this.notes = data.notes || ""
        this.outOfCompetition = data.outOfCompetition
    }

    @Mutation
    updateOrganisation(organisation: NameInput | null) {
        this.organisation = organisation
    }

    @Mutation
    updateTeam(team: RefWithName | null) {
        this.team = team
    }

    @Mutation
    updatePriceProfile(priceProfile: string) {
        this.priceProfile = priceProfile
    }

    @Mutation
    updateSuggestedPriceProfileRef(ref: string) {
        this.suggestedPriceProfileRef = ref
    }

    @Mutation
    updateQuantity(quantity: number) {
        this.quantity = quantity
    }

    @Mutation
    updateNotes(notes: string) {
        this.notes = notes
    }

    @Mutation
    updateOutOfCompetition(value: boolean) {
        this.outOfCompetition = value
    }

    @Action
    asRequest(
        data: {
            testRef: string,
            testSetRef: string,
            confirmSaveWithWarnings: boolean,
            startPositions: Array<TargetStartlistPosition>
        }
    ): RegistrationRequest {
        return {
            testRef: data.testRef,
            testSetRef: data.testSetRef,
            quantity: this.quantity,
            data: {
                type: PractitionerType.TEAM,
                isUnknown: false,
                teamRef: this.team!!.ref!!,
            } as TeamRegistrationPractitionerRequest,
            notes: this.notes,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: data.confirmSaveWithWarnings,
            startPositions: data.startPositions,
            outOfCompetition: false
        } as RegistrationRequest
    }

    @Action
    asUpdateRequest(confirmSaveWithWarnings: boolean): UpdateRegistrationRequest {
        return {
            data: {
                practitionerRef: this.practitionerRef,
                isUnknown: false,
                type: PractitionerType.TEAM,
                teamRef: this.team?.ref
            } as TeamRegistrationPractitionerRequest,
            outOfCompetition: this.outOfCompetition,
            notes: this.notes,
            priceProfileRef: this.priceProfile,
            confirmSaveWithWarnings: confirmSaveWithWarnings
        }
    }

    get canGetOffer(): boolean {
        if (!this.organisation?.ref) return false
        if (!this.team?.ref) return false
        if (this.priceProfile?.length == 0) return false
        return true
    }

    @Action
    asOfferRequest(): GetTeamRegistrationOfferRequest {
        return {
            registrationRef: this.registrationRef,
            teamRef: this.team!!.ref!!,
            organisationRef: this.organisation!!.ref!!,
            priceProfileRef: this.priceProfile,
            quantity: this.quantity,
        } as GetTeamRegistrationOfferRequest
    }
}