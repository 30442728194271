
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import AchievementsSearchInput from "@/components/general/AchievementsSearchInput.vue";

@Component({
  components: {AchievementsSearchInput}
})
export default class AchievementSearchField extends mixins(Vue, abstractField) {
  get getFederationRef() {
    // @ts-ignore
    return this.schema.federationRef || ""
  }
}
