import {CalendarItem} from "@/components/calendar/Calendar";
import {httpService} from "@/services/HttpService";

class SidebarService {
    getCalendar(): Promise<CalendarSidebarNavigation> {
        return httpService.get(`/sidebar/calendar`)
    }
}

export interface CalendarSidebarNavigation {
    competitions: Array<CalendarItem>,
    courseDayEvents: Array<CalendarItem>,
    championships: Array<CalendarItem>
}

export const sideBarService = new SidebarService();