
//@ts-ignore
import LoadingButton from "@/components/general/buttons/LoadingButton";
import {Component, Vue} from "vue-property-decorator";
import {registrationService} from "@/modules/security/services/RegistrationService";
import {RouteUtils} from "@/utils/RouteUtils";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";

@Component({
  components: {
    LoadingButton
  }
})
export default class AuthVerifyRegistrationView extends mixins(AuthenticatedUserMixin, Vue) {
  registrationRequest: RegistrationRequestCode = new RegistrationRequestCode();
  private requestVerified: boolean = false
  private accepted: boolean = false
  private requestIsValid: boolean = false

  message: string = "";

  private isLoading = false

  beforeMount() {
    this.isLoading = true
    RouteUtils.accessDenied(this.$router, !this.authenticated)
        .then(() => {
          const registrationRequest = this.parseCode(this.$route.query.c as string);
          if (!registrationRequest) {
            RouteUtils.redirectTo(this.$router, "/auth/login")
          } else {

            registrationService.validateRegistrationRequest(registrationRequest.code)
                .then(() => {
                  this.registrationRequest = registrationRequest;
                  this.requestIsValid = true
                  this.isLoading = false
                })
                .catch((e) => {
                  this.isLoading = false
                  RouteUtils.accessDenied(this.$router, e.functionalCauses.length == 0)
                  this.message = (e.functionalCauses.map((cause: string) => `${this.$t(cause)}`) || []).join("<br/>")
                })
          }
        })
  }

  goToHome() {
    RouteUtils.redirectTo(this.$router, "/")
  }

  verifyRegistrationRequest(accepted: boolean) {
    this.isLoading = true
    registrationService.verifyRegistrationRequest(accepted, this.registrationRequest.code)
        .then(() => {
          this.requestVerified = true
          this.accepted = accepted
        })
        .catch((e) => {
          this.requestIsValid = false
          this.message = (e.functionalCauses.map((cause: string) => `${this.$t(cause)}`) || []).join("<br/>")
        })
        .finally(() => {
          this.isLoading = false
        })
  }

  parseCode(code: string): RegistrationRequestCode | undefined {
    try {
      return JSON.parse(atob(code)) as RegistrationRequestCode;
    } catch (e) {
      return undefined;
    }
  }
}

export class RegistrationRequestCode {
  code!: string;
  name!: string;
  email!: string;
  organisation!: string;
}
