import {RouteConfig} from "vue-router";
import {createSecureReadOnlyRouteMetadata} from "@/router";

const routes: Array<RouteConfig> = [
    {
        path: '/superadmin',
        component: () => import("@/modules/superadmin/views/SuperAdminView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    }
];

export default {
    routes: routes
};
