import {httpService} from "@/services/HttpService";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";
import Vue from "vue";
import {RefWithName} from "@/utils/Utils";
import {
    ChampionshipSportCategoryWithCareer
} from "@/modules/championship/services/ManageChampionshipCompetitionService";
import {ChampionshipCalendarItem} from "@/components/calendar/Calendar";
import {ChampionshipPractitionerDependenceType} from "@/modules/championship/services/ManageChampionshipService";
import {CourseEventRef} from "@/modules/course/service/CourseEvent";
import {PersonFavorite} from "@/shared/domain/User";

class PublicChampionshipService {

    public saveAsFavorite(ref: ChampionshipRef): Promise<PersonFavorite> {
        return httpService.post(`/championship/${ref}/favorite`, {})
    }

    public removeAsFavorite(ref: string): Promise<void> {
        return httpService.delete(`/sidebar/${ref}`)
    }

    public findChampionships(year: number): Promise<Array<ChampionshipCalendarItem>> {
        return httpService.get(`/championship?year=${year}`)
    }

    public getChampionship(ref: ChampionshipRef): Promise<Championship> {
        return httpService.get(`/championship/${ref}`)
    }

    public getChampionshipCompetitions(ref: ChampionshipRef): Promise<ChampionshipLayout> {
        return httpService.get(`/championship/${ref}/competitions`)
    }

    getChampionshipSportCategories(ref: ChampionshipRef): Promise<Array<ChampionshipSportCategoryWithCareer>> {
        return httpService.get(`/championship/${ref}/sportcategories`)
    }
}

export type ChampionshipRef = string
export type ChampionshipPhaseRef = string
export type ChampionshipGroupRef = string

export interface Championship {
    ref: ChampionshipRef;
    federationRef: FederationRef;
    category: RefWithName,
    avatarUrl: string,
    name: string,
    startDate: string,
    endDate: string,
    sportTypeVariants: Array<SportTypeVariant>,
    totalCompetitionCount: number,
    status: ChampionshipStatus,
    layout: ChampionshipLayout,
    registrationFee: number,
    lateRegistrationFee: number,
    noShowFee: number,
    registrationArticleRef: string,
    lateRegistrationArticleRef: string,
    noShowArticleRef: string,
    hasPreRegistration: false,
    preRegistrationStart: Date,
    preRegistrationEnd: Date,
    description: ""
    competitionCreationInProgress: boolean,
    hasJuryPreRegistration: false,
    juryPreRegistrationStart: Date,
    juryPreRegistrationEnd: Date,
    timeAssignmentStartDayCountBeforeCompetition: number,
    sportCategories: Array<ChampionshipSportCategoryWithCareer>,
    color?: string,
    bannerUrl?: string,
    championshipPractitionerDependenceType: ChampionshipPractitionerDependenceType
    practitionerGroupRef?: string,
    maxParticipationsPerRider: number,
    maxParticipationsPerHorse: number,
    maxParticipationsPerCombination: number,
}

export const initEmptyChampionshipLayout = () => {
    const cl: ChampionshipLayout = {
        phases: []
    }
    return cl
}

export interface ChampionshipLayout {
    phases: Array<ChampionshipPhase>
}

export interface ChampionshipPhase {
    ref: ChampionshipPhaseRef,
    name: string,
    groups: Array<ChampionshipGroup>
}

export interface ChampionshipGroup {
    ref: ChampionshipGroupRef,
    name: string,
    hasSelections: boolean,
    competitions: Array<ChampionshipCompetition>
}

export interface ChampionshipCompetition {
    ref: string,
    name: string,
    organizer: RefWithName,
    start: string,
    end: string,
    competition: RefWithName,
    status: string,
    avatar?: string,
    excludedFromTimeAssignment: boolean
}

export interface CompetitionDetail {
    ref: string,
    name: string,
    start: string,
    end: string,
    avatar: string,
}

export const initEmptyChampionshipGroup = () => {
    const group: ChampionshipGroup = {
        ref: "",
        name: "",
        competitions: [],
        hasSelections: false
    }
    return group
}

export enum ChampionshipStatus {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    CANCELLED = "CANCELLED",
    ARCHIVED = "ARCHIVED",
    HIDDEN = "HIDDEN",
    DELETED = "DELETED"
}

export const getChampionshipRefFromRoute = (vue: Vue): ChampionshipRef => {
    return vue.$route.params.championship;
};

export const publicChampionshipService = new PublicChampionshipService();

