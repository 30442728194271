
import {Component, Vue} from 'vue-property-decorator';
//@ts-ignore
import LoadingButton from "@/components/general/buttons/LoadingButton.vue";
import ManualLocationEditComponent from "@/components/location/ManualLocationEditComponent.vue";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";
import PublicOrganisationSearchInput from "@/modules/security/components/PublicOrganisationSearchInput.vue";
import {
  emptyLimitedOrganisationResource,
  emptyRegistrationRequestResource,
  LimitedOrganisationResource,
  RegistrationRequestResource,
  registrationService
} from "@/modules/security/services/RegistrationService";
import {RouteUtils} from "@/utils/RouteUtils";

@Component({
  components: {
    PublicOrganisationSearchInput,
    OrganisationSearchInput,
    ManualLocationEditComponent,
    LoadingButton
  }
})
export default class AuthRegisterView extends Vue {
  form: RegistrationRequestResource = emptyRegistrationRequestResource();
  message: string = "";

  private requestSent = false
  private isLoading = false
  private selectedOrganisation: LimitedOrganisationResource = emptyLimitedOrganisationResource()

  mounted() {
    this.form.firstname = this.$keycloak.firstName || "";
    this.form.lastname = this.$keycloak.lastName || "";
    this.form.email = this.$keycloak.userName || "";
  }

  get isLoggedIn() {
    return this.$keycloak.authenticated
  }

  get organisations() {
    return []
  }

  goToHome() {
    RouteUtils.redirectTo(this.$router, "/")
  }

  register() {
    this.isLoading = true
    if (this.checkIfFormIsNotEmpty()) {
      //only take the ref from the selected organisation
      if (this.selectedOrganisation.ref !== "") {
        this.form.organisation = this.selectedOrganisation.ref
      }

      registrationService.createRegistrationRequest(this.form)
          .then(() => {
            if (this.$keycloak.userProfile) {
              this.$keycloak.userProfile.hasPendingRegistration = true
            }
            this.requestSent = true
          })
          .catch((e) => {
            this.message = (e.functionalCauses.map((cause: string) => `${this.$t(cause)}`) || []).join("<br/>")
          })
          .finally(() => {
            this.isLoading = false
          })
    } else {
      this.message = "All fields are required!"
      this.isLoading = false
    }
  }

  checkIfFormIsNotEmpty(): boolean {
    return this.form.firstname !== ""
        && this.form.lastname !== ""
        && this.form.email !== ""
        && this.form.address.street !== ""
        && this.form.address.number !== ""
        && this.form.address.postalCode !== ""
        && this.form.address.cityName !== ""
        && this.form.address.state !== ""
        && this.form.address.country != null
  }
}

