
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component({})
    export default class CheckBoxGroupField extends mixins(Vue, abstractField) {
      get stacked() {
        // @ts-ignore
        return this.schema.stacked
      }
        options() {
            // @ts-ignore
            let values = this.schema.values;
            if (!values) {
                return [];
            }
            if (typeof values == "function") {
                // @ts-ignore
                return values.apply(this, [this.model, this.schema]);
            } else {
                return values;
            }
        }
    }
