
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRulePersonIsMemberOfOrganisation
  from "@/components/rules/components/EqifyRulePersonIsMemberOfOrganisation.vue";
import EqifyRulePersonHasAge from "@/components/rules/components/EqifyRulePersonHasAge.vue";
import EqifyRulePersonIsInFederation from "@/components/rules/components/EqifyRulePersonIsInFederation.vue";
import EqifyDataLoader from "@/components/EqifyDataLoader.vue";
import VueQueryBuilder from "vue-query-builder";
import EqifyRulePersonHasAchievement from "@/components/rules/components/EqifyRulePersonHasAchievement.vue";
import EqifyRulePersonHasLicense from "@/components/rules/components/EqifyRulePersonHasLicense.vue";
import EqifyQueryBuilderGroup from "@/components/rules/custom/EqifyQueryBuilderGroup.vue";
import EqifyRulePonyHasWitherHeight from "@/components/rules/components/EqifyRulePonyHasWitherHeight.vue";
import EqifyRulePersonHasLicenseType from "@/components/rules/components/EqifyRulePersonHasLicenseType.vue";
import EqifyRuleHorseHasAge from "@/components/rules/components/EqifyRuleHorseHasAge.vue";
import EqifyRuleHorseRegisteredToStudbook from "@/components/rules/components/EqifyRuleHorseRegisteredToStudbook.vue";
import EqifyRulePersonHasIndividualCombinations from "@/components/rules/components/EqifyRulePersonHasIndividualCombinations.vue";

const DEFAULT_RULES = {
  logicalOperator: "all",
  inverted: false,
  children: []
}

@Component({
  components: {EqifyQueryBuilderGroup, EqifyDataLoader, VueQueryBuilder}
})
export default class EqifyRuleBuilder extends Vue {
  @Prop({required: false, type: Object , default: () => DEFAULT_RULES}) value!: Object
  @Prop({}) federationRef?: string

  get query(): Object | undefined {
    if (this.value) {
      return this.value
    } else {
      return {
        logicalOperator: "all",
        inverted: false,
        children: []
      }
    }
  }

  set query(query: Object | undefined) {
    this.$emit("input", query)
  }

  private rules = [
    {
      type: "custom-component",
      id: "PERSON_IS_MEMBER_OF_ORGANISATION",
      label: this.$t('general.rules.rule.PERSON_IS_MEMBER_OF_ORGANISATION'),
      operators: ["="],
      component: EqifyRulePersonIsMemberOfOrganisation
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_ACHIEVEMENT",
      label: this.$t('general.rules.rule.PERSON_HAS_ACHIEVEMENT'),
      operators: ["="],
      component: EqifyRulePersonHasAchievement
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_LICENSE",
      label: this.$t('general.rules.rule.PERSON_HAS_LICENSE'),
      operators: ["="],
      component: EqifyRulePersonHasLicense
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_LICENSE_TYPE",
      label: this.$t('general.rules.rule.PERSON_HAS_LICENSE_TYPE'),
      operators: ["="],
      component: EqifyRulePersonHasLicenseType
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_AGE",
      label: this.$t('general.rules.rule.PERSON_HAS_AGE'),
      operators: ['=', '!=', '<', '<=', '>', '>='],
      component: EqifyRulePersonHasAge
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_AGE_YEAR",
      label: this.$t('general.rules.rule.PERSON_HAS_AGE_YEAR'),
      operators: ['=', '!=', '<', '<=', '>', '>='],
      component: EqifyRulePersonHasAge
    },
    {
      type: "custom-component",
      id: "PERSON_IS_MEMBER_OF_FEDERATION",
      label: this.$t('general.rules.rule.PERSON_IS_MEMBER_OF_FEDERATION'),
      operators: ["="],
      component: EqifyRulePersonIsInFederation
    },
    {
      type: "radio",
      id: "HORSE_IS_TYPE",
      label: this.$t('general.rules.rule.HORSE_IS_TYPE'),
      choices: [
        {label: this.$t('general.rules.horse'), value: "horse"},
        {label: this.$t('general.rules.pony'), value: "pony"}
      ]
    },
    {
      type: "custom-component",
      id: "PONY_HAS_WITHERHEIGHT",
      label: this.$t('general.rules.rule.PONY_HAS_WITHERHEIGHT'),
      operators: ['='],
      component: EqifyRulePonyHasWitherHeight
    },
    {
      type: "custom-component",
      id: "HORSE_HAS_AGE",
      label: this.$t('general.rules.rule.HORSE_HAS_AGE'),
      operators: ['=', '!=', '<', '<=', '>', '>='],
      component: EqifyRuleHorseHasAge
    },
    {
      type: "custom-component",
      id: "HORSE_REGISTERED_TO_STUDBOOK",
      label: this.$t('general.rules.rule.HORSE_REGISTERED_TO_STUDBOOK'),
      operators: ["="],
      component: EqifyRuleHorseRegisteredToStudbook
    },
    {
      type: "custom-component",
      id: "PERSON_HAS_INDIVIDUAL_COMBINATIONS",
      label: this.$t("general.rules.rule.PERSON_HAS_INDIVIDUAL_COMBINATIONS"),
      operators: ['=', '!=', '<', '<=', '>', '>='],
      component: EqifyRulePersonHasIndividualCombinations
    }
  ];
}
