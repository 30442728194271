import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {
    CourseAttendance,
    CourseDayAttendance,
    CourseEvent,
    CourseEventRef,
    CourseRegistration,
    CourseResult,
    ResultResource
} from "@/modules/course/service/CourseEvent";
import {FileContent, httpService} from "@/services/HttpService";
import {Course, CourseRef} from "@/modules/federation/services/CourseService";
import Vue from "vue";

class ManageCourseEventService {
    createUploadAvatarUrl(courseEventRef: string): string {
        return `/course/event/${courseEventRef}/avatar`
    }

    createCourseEvent(federationRef: FederationRef, body: CourseEvent): Promise<void> {
        const form = {
            ...body,
            courseEventRef: body.ref,
            courseRef: body.courseRef!!.ref
        }
        return httpService.post(`/course/event`, form)
    }

    getCourseEvent(federationRef: FederationRef, courseEventRef: CourseEventRef): Promise<CourseEvent> {
        return httpService.get(`/course/event/${courseEventRef}/meta-data`)
    }

    getCourseAttendances(courseEventRef: CourseEventRef): Promise<Array<CourseAttendance>> {
        return httpService.get(`/course/event/${courseEventRef}/profile/attendances`)
    }

    getCourseRegistrations(courseEventRef: CourseEventRef): Promise<Array<CourseRegistration>> {
        return httpService.get(`/course/event/${courseEventRef}/profile/registrations`)
    }

    getCourseResults(courseEventRef: CourseEventRef): Promise<Array<CourseResult>> {
        return httpService.get(`/course/event/${courseEventRef}/profile/results`)
    }

    getCourse(courseRef: CourseRef): Promise<Course> {
        return httpService.get(`/course/${courseRef}`)
    }

    updateCourseAttendance(courseEventRef: string, registrationRef: string, body: Array<CourseDayAttendance>): Promise<void> {
        const attendanceDays = body.map(
            it => {
                return {
                    ref: it.ref,
                    present: it.present
                }
            }
        )
        return httpService.put(`/course/event/${courseEventRef}/profile/attendances`, {
            registrationRef: registrationRef,
            days: attendanceDays
        })
    }

    updateCourseResult(
        courseEventRef: string,
        registrationRef: string,
        body: ResultResource
    ): Promise<void> {
        return httpService.put(`/course/event/${courseEventRef}/profile/results`, {
            registrationRef: registrationRef,
            score: body.score,
            achieved: body.achieved,
            notes: body.notes,
            date: body.date
        })
    }

    deleteCourseRegistration(courseEventRef: string, registrationRef: string): Promise<void> {
        return httpService.delete(`/course/event/${courseEventRef}/profile/registrations/${registrationRef}`)
    }

    updateCourseRegistration(courseEventRef: string, registrationRef: string, body: any): Promise<void> {
        return httpService.put(`/course/event/${courseEventRef}/profile/registrations/${registrationRef}`, body)
    }


    deleteCourseEvent(courseEventRef: string): Promise<void> {
        return httpService.delete(`/course/event/${courseEventRef}`)
    }

    exportCourseRegistrations(courseEventRef: string): Promise<FileContent> {
        return httpService.download(`/course/event/${courseEventRef}/profile/registrations/export`)
    }
}

export const getCourseEventRefFromRoute = (vue: Vue): CourseEventRef => {
    return vue.$route.params.courseEvent;
};

export const manageCourseEventService = new ManageCourseEventService()