
import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarUtils from "@/utils/AvatarUtils";

@Component
    export default class EqifyAvatar extends Vue {
        @Prop() public url?: string;
        @Prop() public name?: string;
        @Prop() public icon?: string;
        @Prop() public size?: string;

        get avatarName() {
            return AvatarUtils.avatarName(this.name)
        }

        get realAvatarSize() {
            return parseInt(this.size!) - 10;
        }

    }
