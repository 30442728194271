
import {Component, Prop, Vue} from "vue-property-decorator";
import {articleService} from "@/modules/federation/services/ArticleService";
import {RefWithName} from "@/utils/Utils";
import {Loader} from "@/utils/Loader";

@Component
export default class ArticleSelectInput extends Vue {
  @Prop() value?: RefWithName
  @Prop() federationRef!: string
  @Prop() disabled!: boolean
  private articles: Array<RefWithName> = []
  private loader = Loader.create()

  get inputValue(): RefWithName | undefined {
    return this.value;
  }

  set inputValue(value: RefWithName | undefined) {
    this.$emit('input', value)
  }

  mounted() {
    if (!this.disabled) {
      this.loader.load(async () => {
        await this.load()
      })
    }
  }

  private async load() {
    this.articles = await articleService.getArticleNames(this.federationRef)
  }
}
