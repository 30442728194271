import {UserProfile, UserProfileData} from "@/shared/domain/User";
import axios from "axios";
import Vue from "vue";

export async function getUserProfile(): Promise<UserProfile | undefined> {
    const r = await axios.get("/api/auth/user")
    if (r.status == 200) {
        return Promise.resolve(new UserProfile(r.data as UserProfileData))
    } else {
        return Promise.resolve(undefined)
    }
}

export async function logout(): Promise<void> {
    const r = await axios.delete("/api/auth/user")
    if (r.status == 200) {
        return Promise.resolve()
    } else {
        return Promise.resolve()
    }
}

export async function isTokenReady(): Promise<void> {
    return new Promise(resolve => checkToken(resolve))
}

const checkToken = (resolve: () => void) => {
    const keycloak = Vue.prototype.$keycloak
    if (keycloak && keycloak.token) {
        resolve()
    } else {
        setTimeout(() => checkToken(resolve), 500)
    }
}
