import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {memberFinanceService} from "@/modules/members/services/MemberFinanceService";
import {Money} from "@/shared/domain/Finance";

@Module({namespaced: true, name: 'member-finance'})
export default class MemberFinanceModule extends VuexModule {
    balance: Money = {} as Money

    @MutationAction
    async loadBalance(req: LoadBalanceRequest) {
        const response = await memberFinanceService.getBalance(req.ref)
        return {
            balance: response.amount,
        }
    }
}

export interface LoadBalanceRequest {
    ref: string
}