
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class DayCountInput extends Vue {
  @Prop() value?: number
  @Prop() placeholder?: string;

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }
}
