
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import StudBookSearchInput from "@/components/general/StudBookSearchInput.vue";

@Component({
        components: {StudBookSearchInput}
    })
    export default class StudBookSearchField extends mixins(Vue, abstractField) {
    }
