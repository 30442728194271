import {Action, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {initEmptyMemberProfile, MemberRef, memberService} from "@/modules/members/services/MemberService";
import {initMemberProfileSecurity, profileSecurityService} from "@/modules/members/services/ProfileSecurityService";

@Module({namespaced: true, name: 'member-profile'})
export default class MemberProfileModule extends VuexModule {
    member = initEmptyMemberProfile()
    security = initMemberProfileSecurity()

    get selectedMember() {
        return this.member
    }

    get profileSecurity() {
        return this.security
    }

    @MutationAction
    async loadMember(ref: MemberRef) {
        const member = await memberService.getMemberProfile(ref);
        const security = await profileSecurityService.getProfileSecurity(ref)

        return {
            member: member,
            security: security
        }
    }
}
