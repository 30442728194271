import {Action, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    CompetitionTestBinding,
    manageStartlistService,
    RegistrationRequest,
    StartlistsMenu
} from "@/modules/competition/service/ManageStartlistService";
import {StartlistEntryRef, StartlistGroupRef, StartlistRef} from "@/modules/competition/service/ManageResultsService";

@Module({namespaced: true, name: 'competition-manage-startlist'})
export default class CompetitionManageStartlistModule extends VuexModule {
    menu: StartlistsMenu = {} as StartlistsMenu
    testsWithoutStartlistsCount: number = 0
    hasAutomaticStartlists: boolean = false
    binding: CompetitionTestBinding = {} as CompetitionTestBinding;

    @Mutation
    clearStore() {
        this.menu = {} as StartlistsMenu
        this.binding = {} as CompetitionTestBinding
        this.hasAutomaticStartlists = false
    }

    @MutationAction
    async loadData(ref: CompetitionRef) {
        const data = await manageStartlistService.getData(ref)
        return {
            menu: data.menu,
            testsWithoutStartlistsCount: data.testsWithoutStartlistsCount,
            hasAutomaticStartlists: data.hasAutomaticStartlists
        }
    }

    @MutationAction
    async select(request: SelectRequest) {
        const data = await manageStartlistService.getTestBinding(request.competitionRef, request.bindingRef)
        return {binding: data}
    }

    @Mutation
    clearSelection() {
        this.binding = {} as CompetitionTestBinding
    }

    @Mutation
    async refresh(competitionRef: CompetitionRef) {
        this.binding = await manageStartlistService.getTestBinding(competitionRef, this.binding.ref)
    }

    @MutationAction
    async load(request: LoadRequest) {
        if (request.bindingRef) {
            const data = await manageStartlistService.getTestBinding(request.competitionRef, request.bindingRef)
            return {binding: data}
        }
        return {}
    }

    @Mutation
    async move(request: MoveRequest) {
        if (request.fromIndex == request.toIndex) {
            return
        }
        await manageStartlistService.moveStartPosition(request.competition, request.startlistRef, request.fromIndex, request.toIndex)
    }

    @Mutation
    async swap(request: MoveRequest) {
        if (request.fromIndex == request.toIndex) {
            return
        }

    }

    @Action
    async addAllRegistrations(request: AddAllRegistrationRequest) {
        await manageStartlistService.addAllRegistrations(request.competition, request.startlistRef)
    }

    @Action
    async addRegistrations(request: AddRegistrationRequest) {
        await manageStartlistService.addRegistrations(request.competition, request.startlistRef, request.registrations)
    }

    @Action
    async addBreak(request: AddBreakRequest) {
        await manageStartlistService.addBreak(request.competition, request.startlistRef, request.breakType, request.order, request.duration)
    }

    @Action
    async addOpenPosition(request: AddOpenPositionRequest) {
        await manageStartlistService.addOpenPosition(request.competition, request.startlistRef, request.position, request.quantity)
    }

    @Action
    async removeEntry(request: RemoveEntryRequest) {
        await manageStartlistService.removeEntry(request.competition, request.startlistRef, request.startlistEntryRef)
    }
}

export interface SelectRequest {
    competitionRef: CompetitionRef,
    bindingRef: StartlistGroupRef,
}

export interface LoadRequest {
    competitionRef: CompetitionRef,
    bindingRef?: StartlistGroupRef
}

export interface AssignStartorderRequest {
    competition: CompetitionRef,
    startlistRef?: StartlistRef
}

export interface MoveRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
    fromIndex: number,
    toIndex: number
}

export interface AddAllRegistrationRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
}

export interface AddRegistrationRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
    registrations: Array<RegistrationRequest>
}

export interface AddBreakRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
    breakType: string,
    order: number,
    duration: number
}

export interface AddOpenPositionRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
    position: number,
    quantity: number
}

export interface RemoveEntryRequest {
    competition: CompetitionRef,
    startlistRef: StartlistRef,
    startlistEntryRef: StartlistEntryRef
}
