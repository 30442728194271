import {
    friendlyFormatIBAN,
    isValidBIC,
    isValidIBAN, validateBIC,
    ValidateBICResult,
    ValidateIBANResult, ValidationErrorsBIC,
    ValidationErrorsIBAN,
    validateIBAN as validateIban2
} from "ibantools";
import {MoneyWithTax} from "@/shared/domain/Finance";

export function formatIban(iban?: string): string | null {
    return friendlyFormatIBAN(iban)
}

export function validateIBAN(iban?: string): boolean {
    if (iban == undefined) return false
    return isValidIBAN(iban)
}

export function validateIBANResponse(iban?: string): ValidateIBANResult | null {
    return validateIban2(iban)
}

export function getIBANValidationError(error: number): string {
    return ValidationErrorsIBAN[error]
}

export function isValidBic(bic?: string): boolean {
    if (bic == undefined) return false
    return isValidBIC(bic)
}

export function validateBICResponse(bic?: string): ValidateBICResult {
    return validateBIC(bic)
}

export function getBICValidationError(error: number): string {
    return ValidationErrorsBIC[error]
}

export function defaultMoneyWithTax(): MoneyWithTax {
    return {
        amountWithTax: 0.0,
        amountWithoutTax: 0.0,
        taxLevelCode: "VAT_0",
        currency: "EUR"
    } as MoneyWithTax
}