
import {Vue} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";
import MoneyInput from "@/components/general/MoneyInput.vue";
import {abstractField} from "vue-form-generator";
@Component({
  components: {MoneyInput}
})
export default class MoneyInputField extends mixins(Vue, abstractField) {
  get moneyOnly() {
    //@ts-ignore
    return this.schema && this.schema.moneyOnly
  }
  //
  // get disabled() {
  //   //@ts-ignore
  //   return this.schema.disabled || false
  // }
}

