/*
*
* Eqify Components
*
* */

//Vue
import Vue from "vue";

//Font-Awesome
// @ts-ignore
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
// @ts-ignore
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from "@fortawesome/vue-fontawesome";
//Vue-Select
import VueSelect from "vue-select";
//Vue-Moment
import VueMoment from "vue-moment";
import moment from 'moment-timezone'
// import 'moment/locale/fr'
import 'moment/locale/nl-be'
// import 'moment/locale/en-gb'

//Vue-Lodash
import VueLodash from "vue-lodash";
import lodash from "lodash";
//Vue-CurrencyInput
import VueCurrencyInput from "vue-currency-input";
//Vue-Mask
//@ts-ignore
import VueMask from 'v-mask'
//Vue-Pikaday
//@ts-ignore
import VuePikaday from '@netcz/vue-pikaday';
//Vue-Toastr
//@ts-ignore
import VueToastr from "vue-toastr";

//Vue-CodeMirror
// @ts-ignore
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/idea.css'
import 'codemirror/mode/clike/clike.js'

//Vue-Quill-Editor
// @ts-ignore
import VueQuillEditor from 'vue-quill-editor'
// VueClipboard
import VueClipboard from 'vue-clipboard2'
// @ts-ignore
import VueVirtualScroller from 'vue-virtual-scroller'
import Cleave from "vue-cleave-component";

//@ts-ignore
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)


Vue.component('v-select', VueSelect);


Vue.use(VueMoment, {
    moment,
})


Vue.use(VueLodash, {lodash: lodash});


Vue.use(VueCurrencyInput, {globalOption: {currency: "EUR"}})


Vue.use(VueMask)


Vue.use(VuePikaday);


Vue.use(VueToastr, {
    defaultProgressBar: true,
    defaultTimeout: 5000
});

// @ts-ignore
import VueHtmlToPaper from 'vue-html-to-paper';
//printing
const options = {
    name: '_blank',
    specs: [
        'fullscreen=no',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        '/css/print.css'
    ],
    timeout: 10, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

declare module 'vue/types/vue' {
    interface Vue {
        $htmlToPaper: any;
    }
}


Vue.use(VueCodemirror)

Vue.use(VueQuillEditor)

Vue.use(VueClipboard)

Vue.use(VueVirtualScroller)

Vue.use(Cleave);

