import {VueRouter} from "vue-router/types/router";

export class RouteUtils {

    static isOnRegisterView(router: VueRouter) {
        let path = router.currentRoute.path;
        return path === '/auth/register';
    }

    static async notFound(router: VueRouter, condition: (() => boolean) | boolean = true) {
        await RouteUtils.replaceRouteWhen(router,"/error/404", condition)
    }

    static async error(router: VueRouter, condition: (() => boolean) | boolean = true) {
        await RouteUtils.replaceRouteWhen(router,"/error/500", condition)
    }

    static async accessDenied(router: VueRouter, condition: (() => boolean) | boolean = true) {
        await RouteUtils.replaceRouteWhen(router,"/error/403", condition)
    }

    private static async replaceRouteWhen(router: VueRouter, to:string, condition: (() => boolean) | boolean = true) {
        let result;
        if (typeof condition == "function") {
            result = condition.apply(this)
        } else {
            result = condition
        }
        if (result) {
            await router.replace(to)
        }
    }

    static async redirectTo(router: VueRouter, route: string) {
        await router.replace(route)
    }

    static async refresh(router: VueRouter) {
        await router.go(0)
    }
}
