import {httpService} from "@/services/HttpService";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {FinanceTransaction, Money, MoneyWithTax} from "@/shared/domain/Finance";
import {Invoice} from "@/modules/federation/services/InvoiceService";
import {Bill, BillItem, TransactionData, UpdateTransactionData} from "@/services/FinanceService";
import {DateUtils} from "@/utils/DateUtils";
import {RefWithName} from "@/utils/Utils";
import {MemberRef} from "@/modules/members/services/MemberService";
import {MemberBillItems} from "@/modules/members/services/MemberFinanceService";
import {BillItemData} from "@/components/finance/FinanceTransactionCallback";

class CompetitionFinanceService {

    getFinanceItems(ref: CompetitionRef): Promise<Array<CompetitionFinanceItem>> {
        return httpService.get(`/competition/manage/${ref}/finance/items`)
    }

    getBills(ref: CompetitionRef): Promise<Array<Bill>> {
        return httpService.get(`/competition/manage/${ref}/finance/bill`)
    }

    getBillItems(ref: CompetitionRef, billRef: string): Promise<CompetitionBillItems> {
        return httpService.get(`/competition/manage/${ref}/finance/bill/${billRef}/item`)
    }

    updateBillItem(ref: CompetitionRef, billRef: string, billItemRef: string, body: BillItemData) {
        return httpService.put(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}`, body)
    }

    addBillItem(ref: MemberRef, billRef: string): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/finance/bill/${billRef}/item`, {})
    }

    deleteBillItem(ref: MemberRef, billRef: string, billItemRef: string): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}`)
    }

    moveBillItem(ref: MemberRef, billRef: string, targetBillRef: string, billItemRef: string): Promise<void> {
        return httpService.put(`/competition/manage/${ref}/finance/bill/${billRef}/item/${billItemRef}/target/${targetBillRef}`, {})
    }

    getBalance(ref: CompetitionRef): Promise<CompetitionBalance> {
        return httpService.get(`/competition/manage/${ref}/finance/bill/balance`)
    }

    splitPayments(
        ref: CompetitionRef
    ): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/finance/split-payments`, {})
    }

    getSplitInfo(
        ref: CompetitionRef
    ): Promise<CompetitionSplitInfo> {
        return httpService.get(`/competition/manage/${ref}/finance/splits`)
    }
}

export interface CompetitionFinanceItem {
    ref: string,
    to: RefWithName,
    toType: string,
    invoiceOrBillTo: RefWithName,
    invoiceOrBillToType: string,
    amount: Money,
    datetime: string,
    description: string,
    status: string,
    paymentStatus: string,
    type: CompetitionFinanceItemType,
    incoming: boolean
}

export enum CompetitionFinanceItemType {
    ORDER = "ORDER",
    BILL = "BILL"
}

export interface CompetitionBalance {
    total: Money,
    outstandingTotal: Money
}

export interface CompetitionBillItems {
    bill: Bill,
    items: Array<BillItem>,
    articles: Array<RefWithName>
}

export interface CompetitionFinanceTransactions {
    balance: number,
    transactions: Array<FinanceTransaction>
}

export interface CompetitionBalance {
    balance: number,
}

export interface CompetitionSplitInfo {
    competitionTotalAmount: MoneyWithTax,
    recipientSplits: Array<RecipientSplit>
}

export interface RecipientSplit {
    recipient: RefWithName,
    totalAmount: MoneyWithTax,
    splits: Array<TestSplit>
}

export interface TestSplit {
    test: RefWithName,
    amount: MoneyWithTax
}

export const competitionFinanceService = new CompetitionFinanceService()