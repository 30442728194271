
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";
import AccountSearchInput from "@/components/general/AccountSearchInput.vue";

@Component({
  components: {AccountSearchInput, OrganisationSearchInput}
})
export default class AccountSearchField extends mixins(Vue, abstractField) {
  get getDisabled() {
    // @ts-ignore
    return this.schema.disabled || false
  }
}
