import {Component, Vue} from "vue-property-decorator";
import {UserProfile} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";

@Component
export default class PageActionMixin extends Vue {
    doClick(def: PageActionDefinition) {
        if (def.action) {
            def.action();
        } else if (def.to) {
            this.$router.push(def.to).catch(_ => {
            })
        }else if (def.link) {
            window.open(def.link, '_blank')
        } else {
            this.$emit(def.event!!, def)
        }
    }

    resolveActions(actions: Array<PageAction | String>) {
        return actions && actions!.map(it => {
            if (typeof it === "string") {
                return this.getAction(it);
            } else {
                return it;
            }
        }).filter(it => it != undefined)
        // .filter(it => this.allowed(it as PageActionDefinition))
    }


    /*private allowed(action: PageActionDefinition) {
        if (action.multiFederation) {
            return true
        }
        if (action.checkPermission) {
            return action.checkPermission(this.user, undefined)
        } else {
            return true
        }
    }*/

    private getAction(name: string): PageAction | undefined {
        switch (name) {
            case "back":
                return {
                    name: this.$t('general.button.back.label').toString(),
                    icon: "chevron-left",
                    action: () => {
                        this.$router.go(-1);
                    },
                    variant: 'link',
                    multiFederation: false
                };
            case "add":
                return {
                    name: this.$t('general.button.add.label').toString(),
                    icon: "plus",
                    event: "add",
                    variant: "primary",
                    multiFederation: false
                };
            case "edit":
                return {
                    name: this.$t('general.button.edit.label').toString(),
                    icon: "pen",
                    event: "edit",
                    variant: "primary",
                    multiFederation: false,
                };
            case "delete":
                return {
                    name: "",
                    icon: "trash",
                    event: "delete",
                    variant: "danger",
                    multiFederation: false,
                }
        }
        return undefined;
    }
}

export class PageActionDefinition {
    name?: string;
    icon?: string;
    event?: string = "click";
    to?: string;
    link?: string;
    action?: Function;
    className?: string = "";
    variant?: string = "primary";
    size?: string = "";
    children?: Array<PageActionDefinition>;
    multiFederation?: boolean = false;
    checkPermission?: CheckPermission = _ => true;
    loading?: boolean = false
}

export type PageAction = PageActionDefinition | string

export type CheckPermission = (user: UserProfile | undefined, ...args: any[]) => boolean
