
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import {RefWithName} from "@/utils/Utils";
import OrganisationSearchField from "@/components/form/OrganisationSearchField.vue";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";

@Component({
  components: {OrganisationSearchInput, OrganisationSearchField, EqifyRuleComponent}
})
export default class EqifyRulePersonIsMemberOfOrganisation extends Vue {
  @Prop() value?: PersonIsMemberOfOrganisation

  get organisation(): RefWithName {
    return this.value?.organisation || RefWithName.empty()
  }

  set organisation(val: RefWithName) {
    this.$emit("input", {
      organisation: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      organisation: this.organisation,
      required: val,
      inverted: this.inverted
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      organisation: this.organisation,
      required: this.required,
      inverted: val
    })
  }
}

export interface PersonIsMemberOfOrganisation {
  organisation: RefWithName,
  required: boolean,
  inverted: boolean
}

