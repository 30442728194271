
import {Component, Prop, Provide, Vue} from 'vue-property-decorator';
import SearchInput from "@/components/general/SearchInput.vue";
import {RefWithName} from "@/utils/Utils";
import {LicenseRef} from "@/modules/federation/services/LicenseDefinitionService";
import {MembershipAssociationType} from "@/modules/members/services/MemberService";

@Component({
        components: {SearchInput}
    })
    export default class LicenseSearchInput extends Vue {
        @Prop() value?: RefWithName<LicenseRef>;
        @Provide() query: string = "";
        @Prop() inputClass?: string;
        @Prop() size?: string;
        @Prop() placeholder?: string;

        get inputValue(): any {
            return this.value;
        }

        set inputValue(value: any) {
            this.$emit('input', value);
        }
    }
