import {httpService} from "@/services/HttpService";
import {emptyGeoLocation, GeoLocation} from "@/shared/domain/Address";

class RegistrationService {
    createRegistrationRequest(formData: RegistrationRequestResource): Promise<Boolean> {
        return httpService.post(`/registration`, formData)
    }

    verifyRegistrationRequest(accepted: boolean, code: string) {
        return httpService.post(`/registration/verify`, {
            code: code,
            accepted: accepted
        })
    }

    validateRegistrationRequest(code: string) {
        return httpService.post(`/registration/validate`, {
            code: code
        })
    }
}

export interface RegistrationRequestResource {
    firstname: string,
    lastname: string,
    email: string,
    organisation: string,
    address: GeoLocation
}

export const emptyRegistrationRequestResource = () => {
    const form: RegistrationRequestResource = {
        firstname: "",
        lastname: "",
        email: "",
        organisation: "",
        address: emptyGeoLocation()
    };
    return form
};

export interface LimitedOrganisationResource {
    ref: string,
    name: string,
    site?: string,
    avatar: string
}

export const emptyLimitedOrganisationResource = () => {
    const form: LimitedOrganisationResource = {
        ref: "",
        name: "",
        site: "",
        avatar: ""
    };
    return form
};

export const registrationService = new RegistrationService();