
import {Component, Prop, Vue} from "vue-property-decorator";
import CalendarCompetitionItemComponent from "@/components/calendar/item/CalendarCompetitionItemComponent.vue";
import CalendarCourseItemComponent from "@/components/calendar/item/CalendarCourseItemComponent.vue";
import {CalendarItem, CalendarItemType} from "@/components/calendar/Calendar";
import CalendarChampionshipItemComponent from "@/components/calendar/item/CalendarChampionshipItemComponent.vue";

@Component({})
export default class CalendarItemView extends Vue {
  @Prop() item!: CalendarItem
  @Prop() withSportTypes?: boolean
  @Prop() withChampionships?: boolean
  @Prop() withAchievement?: boolean
  @Prop() withYear?: boolean

  private getComponent(type: string) {
    if (this.isCompetition(type)) return CalendarCompetitionItemComponent
    if (this.isChampionship(type)) return CalendarChampionshipItemComponent
    if (this.isCourse(type)) return CalendarCourseItemComponent
  }

  private isCompetition(type: string): boolean {
    return type == CalendarItemType.COMPETITION.toString()
  }

  private isCourse(type: string): boolean {
    return type == CalendarItemType.COURSE.toString()
  }

  private isChampionship(type: string): boolean {
    return type == CalendarItemType.CHAMPIONSHIP.toString()
  }

  get componentProps() {
    return {
      withSportTypes: this.withSportTypes,
      withChampionships: this.withChampionships,
      withAchievement: this.withAchievement,
      withYear: this.withYear
    }
  }
}
