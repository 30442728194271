import {MemberRef} from "@/modules/members/services/MemberService";
import {httpService} from "@/services/HttpService";

class ProfileSecurityService {
    getProfileSecurity(memberRef: MemberRef): Promise<MemberProfileSecurity> {
        return httpService.get(`/profile/${memberRef}/security`)
    }
}

export interface MemberProfileSecurity {
    generalSecurity: MemberGeneralSecurity,
    overviewPageSecurity: MemberOverviewPageSecurity,
    achievementsPageSecurity: MemberAchievementsPageSecurity,
    combinationsPageSecurity: MemberCombinationsPageSecurity
    financePageSecurity: MemberFinancePageSecurity
    accountPageSecurity: MemberAccountPageSecurity
}

export interface MemberGeneralSecurity {
    canEdit: boolean,
    canEditAvatar: boolean,
    canViewOverviewPage: boolean,
    canViewAchievementsPage: boolean,
    canViewCombinationsPage: boolean,
    canViewFinancePage: boolean,
    canViewAccountPage: boolean,
    canViewResultsPage: boolean,
    canViewSubscriptionsPage: boolean
}

export interface MemberOverviewPageSecurity {
    canInvitePerson: boolean,
    canViewAddress: boolean,
    canViewFinance: boolean
    canViewCareers: boolean,
    canManageCareers: boolean,
    canViewAchievements: boolean,
    canViewRegistrations: boolean,
    canViewResults: boolean,
    canViewCombinations: boolean
}

export interface MemberAccountPageSecurity {
    canManage: boolean
}

export interface MemberAchievementsPageSecurity {
    canManage: boolean
}

export interface MemberFinancePageSecurity {
    canManage: boolean
}

export interface MemberCombinationsPageSecurity {
    canManage: boolean
}

export const initMemberProfileSecurity = () => {
    const data: MemberProfileSecurity = {
        generalSecurity: {
            canEdit: false,
            canEditAvatar: false,
            canViewCombinationsPage: false,
            canViewAccountPage: false,
            canViewAchievementsPage: false,
            canViewFinancePage: false,
            canViewOverviewPage: true,
            canViewSubscriptionsPage: false,
            canViewResultsPage: false
        },
        overviewPageSecurity: {
            canInvitePerson: false,
            canViewAddress: false,
            canViewCareers: false,
            canManageCareers: false,
            canViewAchievements: false,
            canViewFinance: false,
            canViewRegistrations: false,
            canViewResults: false,
            canViewCombinations: false
        },
        achievementsPageSecurity: {
            canManage: false
        },
        financePageSecurity: {
            canManage: false
        },
        combinationsPageSecurity: {
          canManage: false
        },
        accountPageSecurity: {
            canManage: false
        }
    }
    return data
}

export const profileSecurityService = new ProfileSecurityService()