
import {Component, Prop, Vue} from "vue-property-decorator";
import {LicenseType} from "@/modules/federation/services/LicenseDefinitionService";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import {enumValues, toI18nOptions} from "@/utils/Utils";

@Component({
  components: {EqifyRuleComponent}
})
export default class EqifyRulePersonHasLicenseType extends Vue {
  @Prop() value?: PersonHasLicenseType

  private types = toI18nOptions(enumValues(LicenseType), 'myfederation.licenses.type', this);

  get type(): LicenseType | undefined {
    return this.value?.licenseType
  }

  set type(val: LicenseType | undefined) {
    this.$emit("input", {
      licenseType: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      licenseType: this.type,
      required: val,
      inverted: this.inverted
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      licenseType: this.type,
      required: this.required,
      inverted: val
    })
  }
}

export interface PersonHasLicenseType {
  licenseType: LicenseType,
  required: boolean,
  inverted: boolean
}

