import {RulesTemplate} from "@/shared/domain/General";
import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";

class RulesTemplateService {
    getFederationRulesTemplates(federationRef: FederationRef): Promise<RulesTemplatesResponse> {
        return httpService.get(`/myfederation/${federationRef}/rules-template`)
    }

    createRulesTemplate(federationRef: FederationRef, data: RulesTemplate): Promise<void> {
        return httpService.post(`/myfederation/${federationRef}/rules-template`, data)
    }

    updateRulesTemplate(federationRef: FederationRef, data: RulesTemplate): Promise<void> {
        return httpService.put(`/myfederation/${federationRef}/rules-template/${data.ref}`, data)
    }

    deleteRulesTemplate(federationRef: FederationRef, data: RulesTemplate): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/rules-template/${data.ref}`)
    }
}

export interface RulesTemplatesResponse {
    rulesTemplates: Array<RulesTemplate>
}

export const rulesTemplateService = new RulesTemplateService();