import {
    CompetitionContact,
    CompetitionDetail,
    CompetitionLocation,
    CompetitionOrganizerType,
    CompetitionRef,
    CompetitionSecurityData,
    CompetitionRegistrationSettings
} from "@/modules/competition/service/CompetitionService";
import {httpService} from "@/services/HttpService";
import {PersonRef, PersonSearchResultItem} from "@/shared/domain/Person";
import {RefWithName, RefWithNameAndAvatar} from "@/utils/Utils";
import {DateUtils} from "@/utils/DateUtils";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {PriceProfile} from "@/shared/domain/General";
import {StartlistStatus} from "@/modules/competition/service/ManageStartlistService";
import {CustomAttribute} from "@/shared/domain/CustomAttributes";
import {
    CompetitionRegistrationSettingsForm
} from "@/modules/competition/views/manage/components/CompetitionRegistrationSettingsModal.vue";

class ManageCompetitionService {
    createUploadAvatarUrl(ref: CompetitionRef): string {
        return `/competition/manage/${ref}/avatar`
    }

    getCompetition(ref: CompetitionRef): Promise<CompetitionDetail> {
        return httpService.get(`/competition/manage/${ref}`)
    }

    getSecurity(ref: CompetitionRef): Promise<CompetitionSecurityData> {
        return httpService.get(`/competition/manage/${ref}/security`)
    }

    getCompetitionCode(ref: CompetitionRef): Promise<string> {
        return httpService.get(`/competition/manage/${ref}/code`)
    }

    getCompetitionGeneralData(ref: CompetitionRef): Promise<CompetitionGeneralData> {
        return httpService.get(`/competition/manage/${ref}/general`)
    }

    delete(ref: CompetitionRef): Promise<void> {
        return httpService.delete(`/competition/manage/${ref}`)
    }

    publish(ref: CompetitionRef, request: CompetitionPublishRequest): Promise<CompetitionStatus> {
        return httpService.post(`/competition/manage/${ref}/publish`, request)
    }

    hide(ref: CompetitionRef): Promise<CompetitionStatus> {
        return httpService.post(`/competition/manage/${ref}/hide`, {})
    }

    archive(ref: CompetitionRef): Promise<CompetitionStatus> {
        return httpService.post(`/competition/manage/${ref}/archive`, {})
    }

    cancel(ref: CompetitionRef, reason: string): Promise<CompetitionStatus> {
        return httpService.post(`/competition/manage/${ref}/cancel`, {reason: reason})
    }

    lock(ref: CompetitionRef): Promise<CompetitionStatus> {
        return httpService.post(`/competition/manage/${ref}/lock`, {})
    }

    getShares(ref: CompetitionRef): Promise<Array<CompetitionShare>> {
        return httpService.get(`/competition/manage/${ref}/share`)
    }

    share(ref: CompetitionRef, person: PersonRef, role: CompetitionManageRole): Promise<CompetitionShare> {
        return httpService.post(`/competition/manage/${ref}/share`, {personRef: person, role: role})
    }

    unshare(ref: CompetitionRef, person: PersonRef): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/unshare`, {personRef: person})
    }

    updateShare(ref: CompetitionRef, person: PersonRef, role: CompetitionManageRole): Promise<CompetitionShare> {
        return httpService.put(`/competition/manage/${ref}/share`, {personRef: person, role: role})
    }

    updateCompetitionGeneral(ref: CompetitionRef, request: CompetitionGeneralData): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/update-general`, request)
    }

    updateCompetitionName(ref: CompetitionRef, name: string): Promise<string> {
        return httpService.post(`/competition/manage/${ref}/update-name`, {name: name})
    }

    updateCompetitionLocation(
        ref: CompetitionRef,
        location: CompetitionLocation | undefined
    ): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/update-location`, {location: location})
    }

    updateCompetitionDate(ref: CompetitionRef, start: string, end?: string): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/update-date`, {start: start, end: end})
    }

    updateCompetitionOrganizer(ref: CompetitionRef, type: CompetitionOrganizerType, organizerRef: string, responsibleRef: string): Promise<void> {
        return httpService.post(`/competition/manage/${ref}/update-organizer`, {
            type: type,
            ref: organizerRef,
            responsible: responsibleRef
        })
    }

    updateCompetitionContacts(ref: CompetitionRef, contacts: Array<CompetitionContact>): Promise<Array<CompetitionContact>> {
        return httpService.post(`/competition/manage/${ref}/update-contacts`, {
            contacts: contacts
        })
    }

    updateSubscriptionSettings(competition: CompetitionRef, settings: CompetitionRegistrationSettingsForm): Promise<CompetitionRegistrationSettings> {
        return httpService.post(`/competition/manage/${competition}/update-subscription-settings`, {
            ...settings,
            registrationPeriod: {
                open: DateUtils.toDateString(settings.registrationPeriod!!.open),
                openTime: settings.registrationPeriod!!.openTime,
                close: DateUtils.toDateString(settings.registrationPeriod!!.close),
                closeTime: settings.registrationPeriod!!.closeTime,
                late: DateUtils.toDateString(settings.registrationPeriod!!.late),
                lateTime: settings.registrationPeriod!!.lateTime
            }
        })
    }

    getHistory(ref: CompetitionRef): Promise<Array<CompetitionHistory>> {
        return httpService.get(`/competition/manage/${ref}/history`)
    }

    updateCompetitionBanner(ref: CompetitionRef, banner: File): Promise<string> {
        return httpService.upload(`/competition/manage/${ref}/banner`, banner)
    }
}

export enum CompetitionManageRole {
    ADMINISTRATOR = "ADMINISTRATOR",
    COLLABORATOR = "COLLABORATOR",
    RESULT_PROCESSOR = "RESULT_PROCESSOR"
}

export interface CompetitionShare {
    person: PersonSearchResultItem,
    role: CompetitionManageRole,
    expired: boolean,
    expires: string
}

export enum CompetitionStatus {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    LOCKED = "LOCKED",
    CANCELLED = "CANCELLED",
    ARCHIVED = "ARCHIVED",
    HIDDEN = "HIDDEN",
    DELETED = "DELETED"
}

export interface CompetitionHistory {
    ref: string,
    date: string,
    person: RefWithNameAndAvatar,
    message: string,
}

export enum CompetitionManageMenuStructureType {
    TIMESCHEDULE = "TIMESCHEDULE",
    STARTLIST = "STARTLIST",
    RESULTS = "RESULTS",
    RANKING = "RANKING"
}

export interface CompetitionManageMenuStructure {
    type: CompetitionManageMenuStructureType,
    items: Array<CompetitionManageSportTypeVariantMenuGroup>
}

export interface CompetitionManageSportTypeVariantMenuGroup {
    sportTypeVariant: SportTypeVariant,
    items: Array<CompetitionTestMenuItem>,
    attributes: any//Map<string, any>
}

export interface CompetitionTestMenuItem {
    ref: string,
    name: string,
    tests: Array<CompetitionTestMenuItem>,
    buckets: Array<CompetitionTestBucketMenuItem>,
    attributes: any//Map<string, any>
}

export interface CompetitionTestBucketMenuItem {
    ref: string,
    name: string,
    offset: number,
    capacity: number,
    attributes: any//Map<string, any>
}

export interface CompetitionGeneralData {
    customAttributes?: Array<CustomAttribute>,
    bannerUrl?: string;
    description?: string;
    channel?: string;
    remarksVisitors?: string;
    remarksVip?: string;
    remarksTrailers?: string;
    remarksTrucks?: string;
    priceProfiles?: Array<PriceProfile>;
    rules?: Object,
    liveSettings: CompetitionLiveSettings;
    competitionFederation: RefWithName,
    additionalSettings?: string;
}

export interface CompetitionLiveSettings {
    autoLive: boolean;
    autoPublish: boolean;
    publishRate?: number;
}


export interface CompetitionPublishRequest {
    includeTimeschedule: boolean;
    includeStartlists: boolean;
    includeStartlistStatusUpdate: boolean;
    startlistStatusUpdate: StartlistStatus;
    includeResults: boolean;
}

export const manageCompetitionService = new ManageCompetitionService();
