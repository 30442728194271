
import {Vue} from "vue-property-decorator";
import Component, {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import EqifyRuleBuilder from "@/components/rules/EqifyRuleBuilder.vue";

@Component({
  components: {EqifyRuleBuilder}
})
export default class EqifyRuleBuilderField extends mixins(Vue, abstractField) {

  get federationRef(): string {
    //@ts-ignore
    return this.schema.federationRef || undefined
  }
}

