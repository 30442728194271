
import Component, {mixins} from "vue-class-component";
import {Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import IbanInput from "@/components/general/IbanInput.vue";

@Component({
  components: {IbanInput}
})
export default class IbanInputField extends mixins(Vue, abstractField) {
  get placeholder() {
    // @ts-ignore
    return this.schema.placeholder
  }
}
