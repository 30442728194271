import {RouteConfig} from "vue-router";
import {
    createSecureCompetitionMetadata,
    createPublicRoute,
    createSecureCrudRouteMetadata,
    createSecureReadOnlyRouteMetadata
} from "@/router";

const ROOT = '/competitions';
const routes: Array<RouteConfig> = [
    {
        path: ROOT + '/',
        component: () => import(/* webpackChunkName: "module-competition" */ "@/modules/competition/views/CompetitionCalendarView.vue"),
        meta: createPublicRoute()
    }, {
        path: ROOT + '/create',
        component: () => import(/* webpackChunkName: "module-competition-create" */ "@/modules/competition/views/CreateCompetitionView.vue"),
        meta: createSecureCompetitionMetadata()
    },
    {
        path: ROOT + '/:competition',
        component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionProfileLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionTimescheduleView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'info',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionInfoView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'activity/:activity',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionStartlistView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'championship-ranking/:ranking',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/ChampionshipRankingView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'competition-ranking/:ranking',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionRankingView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'masterlist',
                component: () => import(/* webpackChunkName: "module-competition-profile" */ "@/modules/competition/views/public/CompetitionMasterlistView.vue"),
                meta: createPublicRoute()
            }
        ]
    },
    {
        path: ROOT + '/:competition/manage',
        component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/CompetitionManageLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/CompetitionManageOverview.vue"),
                meta: createPublicRoute(),
                name: 'manage-overview'
            },
            {
                path: 'tests',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/tests/View.vue"),
                meta: createPublicRoute(),
                name: 'manage-tests'
            },
            {
                path: 'subscriptions',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/subscriptions/View.vue"),
                meta: createPublicRoute(),
                children: [
                    {
                        path: 'masterlist',
                        component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/subscriptions/masterlist/RegistrationMasterlistView.vue"),
                        meta: createPublicRoute()
                    },
                    {
                        path: 'list/:ref',
                        component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/subscriptions/ContentPanel.vue"),
                        meta: createPublicRoute()
                    }
                ],
                name: 'manage-registrations'
            },
            {
                path: 'timeschedule',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/timeschedule/View.vue"),
                meta: createPublicRoute(),
                name: 'manage-timeschedule'
            },
            {
                path: 'startlists',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/startlist/View.vue"),
                meta: createPublicRoute(),
                name: 'manage-startlists'
            },
            {
                path: 'results',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/results/View.vue"),
                meta: createPublicRoute(),
                name: 'manage-results'
            },
            {
                path: 'rankings',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/ranking/View.vue"),
                meta: createPublicRoute(),
                name: 'manage-rankings'
            },
            {
                path: 'finance',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/finance/View.vue"),
                meta: createPublicRoute(),
                children: [
                    {
                        path: '',
                        alias: '',
                        component: () => import("@/modules/competition/views/manage/finance/CompetitionFinanceItemsView.vue"),
                        meta: createPublicRoute()
                    },
                    {
                        path: 'transfers',
                        alias: '',
                        component: () => import("@/modules/competition/views/manage/finance/CompetitionFinanceSplitView.vue"),
                        meta: createPublicRoute()
                    },
                ],
                name: 'manage-finance'
            },
            {
                path: 'toolbox',
                component: () => import(/* webpackChunkName: "module-competition-manage" */ "@/modules/competition/views/manage/toolbox/View.vue"),
                meta: createPublicRoute(),
                children: [
                    {
                        path: '',
                        alias: '',
                        component: () => import("@/modules/competition/views/manage/toolbox/Overview.vue"),
                        meta: createPublicRoute()
                    },
                    {
                        path: 'hippomundo',
                        alias: '',
                        component: () => import("@/modules/competition/views/manage/toolbox/hippomundo/HippomundoStartlistEnricher.vue"),
                        meta: createPublicRoute()
                    },
                    {
                        path: 'sync-practitioners',
                        alias: '',
                        component: () => import("@/modules/competition/views/manage/toolbox/sync-practitioners/SyncCompetitionPractitionersView.vue"),
                        meta: createPublicRoute()
                    }
                ],
                name: 'manage-toolbox'
            },
        ]
    }
];

export default {
    routes: routes
};
