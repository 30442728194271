
import {Component, Prop, Vue} from "vue-property-decorator";
import {Loader} from "@/utils/Loader";
import {PersonFavorite} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import {fastNavigationEventBus} from "@/modules/sidebar/services/FastNavigationEventBus";

export type SaveAsFavoriteCallback = () => Promise<PersonFavorite>
export type RemoveAsFavoriteCallback = (ref: string) => Promise<void>

@Component({})
export default class SaveAsFavoriteButton extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() subjectRef!: string
  @Prop() save!: SaveAsFavoriteCallback
  @Prop() remove!: RemoveAsFavoriteCallback

  private loader = Loader.create()

  private isHover = false

  get favorite(): PersonFavorite | undefined {
    return this.user!!.favorites
        .find((it: PersonFavorite) => it.subjectRef == this.subjectRef)
  }

  get saved(): boolean {
    return this.favorite != undefined || false
  }

  private async onButtonClick() {
    if (this.saved) this.removeFavorite()
    else this.saveAsFavorite()
  }

  private saveAsFavorite() {
    this.loader.load(async () => {
      await this.save()
          .then((pv: PersonFavorite) => {
            this.user!!.favorites.push(pv)
            fastNavigationEventBus.$emit("FAVORITE_UPDATE")
          })
    })
  }

  private removeFavorite() {
    this.loader.load(async () => {
      await this.remove(this.favorite!!.ref)
          .then(() => {
            this.user!!.favorites = this.user!!.favorites.filter(it => it.subjectRef !== this.subjectRef)
            fastNavigationEventBus.$emit("FAVORITE_UPDATE")
          })
    })
  }
}
