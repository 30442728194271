
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import {Option} from "@/utils/Utils";

@Component
export default class VueSelectField extends mixins(Vue, abstractField) {
    private defaultSelectOptions = {
        multiple: false,
        label: "text",
        reducer: (tupple: Option) => tupple.value,
        min: 1
    }

    get _selectOptions() {
        // @ts-ignore
        let options = this.schema.selectOptions;
        return {...this.defaultSelectOptions, ...options};
    }

    get description() {
        // @ts-ignore
        return this.schema.description
    }

    options() {
        // @ts-ignore
        let values = this.schema.values;
        if (!values) {
            return [];
        }
        if (typeof values == "function") {
            // @ts-ignore
            return values.apply(this, [this.model, this.schema]);
        } else {
            return values;
        }
    }

    defaultReducer(value: any) {
        return value
    }
}
