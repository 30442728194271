
import {Component, Vue, Watch} from "vue-property-decorator";
import {CalendarSidebarNavigation, sideBarService} from "@/modules/sidebar/services/SidebarService";
import EqifySmallTitle from "@/components/general/titles/EqifySmallTitle.vue";
import {Loader} from "@/utils/Loader";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import FastNavigationSidebarMixin from "@/modules/sidebar/mixin/FastNavigationSidebarMixin";
import CalendarItemView from "@/components/calendar/item/CalendarItemView.vue";
import {fastNavigationEventBus} from "@/modules/sidebar/services/FastNavigationEventBus";

@Component({
  components: {CalendarItemView, EqifySmallTitle}
})
export default class CalendarSideBarNavigation extends mixins(AuthenticatedUserMixin, FastNavigationSidebarMixin, Vue) {
  private loader = Loader.create()
  private calendar: CalendarSidebarNavigation = this.initCalendarSidebar

  mounted() {
    fastNavigationEventBus.$on("FAVORITE_UPDATE", () => this.load())
    if (this.authenticated && this.sidebarVisible) {
      this.load()
    }
  }

  beforeDestroy() {
    fastNavigationEventBus.$off("FAVORITE_UPDATE")
  }

  private load() {
    this.loader.load(async () => {
      this.calendar = await sideBarService.getCalendar()
    })
  }

  get hasCompetitionFavorites(): boolean {
    return this.calendar.competitions.length > 0
  }

  get hasCourseDayEventFavorites(): boolean {
    return this.calendar.courseDayEvents.length > 0
  }

  get hasChampionshipFavorites(): boolean {
    return this.calendar.championships.length > 0
  }

  get initCalendarSidebar(): CalendarSidebarNavigation {
    return {
      competitions: [],
      courseDayEvents: [],
      championships: []
    } as CalendarSidebarNavigation
  }
}
