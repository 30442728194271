
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import DayCountInput from "../general/DayCountInput.vue";

@Component({
  components: {DayCountInput}
})
export default class DayCountField extends mixins(Vue, abstractField) {

}
