import {Action, Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";
import {organisationFinanceService} from "@/modules/organisations/services/OrganisationFinanceService";
import {FinanceTransaction, InvoiceLimited, Money} from "@/shared/domain/Finance";
import {TransactionData, UpdateTransactionData} from "@/services/FinanceService";
import {memberFinanceService} from "@/modules/members/services/MemberFinanceService";
import {MemberRef} from "@/modules/members/services/MemberService";
import _ from "lodash";

@Module({namespaced: true, name: 'organisation-finance'})
export default class OrganisationFinanceModule extends VuexModule {
    balance: Money = {} as Money

    @MutationAction
    async loadBalance(req: LoadBalanceRequest) {
        const response = await organisationFinanceService.getBalance(req.ref)
        return {
            balance: response.amount,
        }
    }
}

interface LoadBalanceRequest {
    ref: string
}
