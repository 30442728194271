
import {mixins} from "vue-class-component";
import {Component, Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import CompetitionSearchInput from "@/components/general/CompetitionSearchInput.vue";
@Component({
  components: {CompetitionSearchInput}
})
export default class CompetitionSearchField extends mixins(Vue, abstractField) {
  get getDisabled() {
    // @ts-ignore
    return this.schema.disabled || false
  }
}
