import {RouteConfig} from "vue-router";
import AuthLogoutView from "@/modules/security/views/AuthLogoutView.vue";
import AuthAcceptInvitationView from "@/modules/security/views/AuthAcceptInvitationView.vue";
import AuthRegisterView from "@/modules/security/views/AuthRegisterView.vue";
import AuthVerifyRegistrationView from "@/modules/security/views/AuthVerifyRegistrationView.vue";

const routes: Array<RouteConfig> = [
    {
        path: '/auth/register',
        component: AuthRegisterView
    },
    {
        path: '/auth/registration/verify',
        component: AuthVerifyRegistrationView
    },
    {
        path: '/auth/accept-invitation',
        component: AuthAcceptInvitationView
    },
    {
        path: '/auth/logout',
        component: AuthLogoutView
    }
];

export default {
    routes: routes
};
