import {RouteConfig} from "vue-router";
import CalendarView from "@/modules/dashboard/views/CalendarView.vue";

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: CalendarView
    }
];

export default {
    routes: routes
};
