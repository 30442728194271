
import {Component, Prop, Vue} from "vue-property-decorator";
import SaveAsFavoriteButton from "@/components/general/buttons/SaveAsFavoriteButton.vue";
import {publicCourseEventService} from "@/modules/course/service/PublicCourseEventService";
import {CourseEventRef} from "@/modules/course/service/CourseEvent";
import {PersonFavorite} from "@/shared/domain/User";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {publicCompetitionService} from "@/modules/competition/service/PublicCompetitionService";

@Component({
  components: {SaveAsFavoriteButton}
})
export default class SaveCompetitionAsFavoriteButton extends Vue {
  @Prop() competitionRef!: CompetitionRef

  async save(): Promise<PersonFavorite> {
    return await publicCompetitionService.saveAsFavorite(this.competitionRef)
  }

  async remove(ref: string): Promise<void> {
    return await publicCompetitionService.removeAsFavorite(ref)
  }
}
