
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import SplitRevenueComponent from "@/components/priceprofile/SplitRevenueComponent.vue";
@Component({
  components: {SplitRevenueComponent}
})
export default class SplitRevenueField extends mixins(Vue, abstractField) {
  get type() {
    //@ts-ignore
    return this.schema.splitType
  }

  get federationRef() {
    //@ts-ignore
    return this.schema.federationRef
  }

  get maxPercentage() {
    //@ts-ignore
    return this.schema.maxPercentage
  }

  get maxAbsolute() {
    //@ts-ignore
    return this.schema.maxAbsolute
  }

  get articles() {
    //@ts-ignore
    return this.schema.articles
  }
}
