
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import {RefWithName} from "@/utils/Utils";
import AchievementSearchComponent from "@/components/rules/components/AchievementSearchComponent.vue"
import {AchievementRef} from "@/modules/federation/services/AchievementService";

@Component({
  components: {EqifyRuleComponent, AchievementSearchComponent}
})
export default class EqifyRulePersonHasAchievement extends Vue {
  @Prop() value?: PersonHasAchievement

  get achievement(): RefWithName {
    return this.value?.achievement || RefWithName.empty()
  }

  set achievement(val: RefWithName) {
    this.$emit("input", {
      achievement: val,
      required: this.required,
      inverted: this.inverted
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      achievement: this.achievement,
      required: val,
      inverted: this.inverted
    })
  }

  get inverted(): boolean {
    if (this.value == undefined) {
      return false
    } else {
      return this.value.inverted
    }
  }

  set inverted(val: boolean) {
    this.$emit("input", {
      achievement: this.achievement,
      required: this.required,
      inverted: val
    })
  }
}

export interface PersonHasAchievement {
  achievement: RefWithName<AchievementRef>,
  required: boolean,
  inverted: boolean
}
