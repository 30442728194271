import {Action, Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    ChampionshipPhaseItemData, manageChampionshipLayoutService
} from "@/modules/championship/services/ManageChampionshipLayoutService";
import {ChampionshipRef, publicChampionshipService} from "@/modules/championship/services/PublicChampionshipService";
import {RefWithName} from "@/utils/Utils";
import {manageChampionshipService} from "@/modules/championship/services/ManageChampionshipService";
import {
    initRankingCalculationFilter,
    RankingCalculationFilter
} from "@/modules/championship/services/ChampionshipRanking";
import {
    ChampionshipSportCategoryWithCareer
} from "@/modules/championship/services/ManageChampionshipCompetitionService";

@Module({namespaced: true, name: 'championship-manage-layout'})
export default class ManageChampionshipLayoutModule extends VuexModule {
    phases: Array<ChampionshipPhaseItemData> = []
    templates: Array<RefWithName> = []
    sportCategories: Array<ChampionshipSportCategoryWithCareer> = []
    selectionCalculationFilter: RankingCalculationFilter = initRankingCalculationFilter()

    @Mutation
    clearStore() {
        this.phases = []
    }

    @Action
    updateCalculationFilter(calculationFilter: RankingCalculationFilter) {
        this.selectionCalculationFilter.bestOfCount = calculationFilter.bestOfCount
    }

    @MutationAction
    async loadPhases(ref: ChampionshipRef) {
        const response = await manageChampionshipLayoutService.getChampionshipPhases(ref)

        //if no phases, load templates
        let templates: Array<RefWithName> = []
        if (response.length == 0) {
            templates = await manageChampionshipService.getChampionshipTemplates(ref)
        }

        return {
            phases: response,
            templates: templates
        }
    }

    @MutationAction
    async loadSportCategories(ref: ChampionshipRef) {
        const response = await publicChampionshipService.getChampionshipSportCategories(ref)
        return { sportCategories: response }
    }
}
