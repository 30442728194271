
import {Component, Prop, Vue} from 'vue-property-decorator';
import ButtonWithFederationSelection from "@/components/federation/ButtonWithFederationSelection.vue";
import {FederationUserInfo} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import PageActionMixin, {PageAction} from "@/components/PageActionMixin";

@Component({
        components: {ButtonWithFederationSelection}
    })
    export default class PageTitle extends mixins(PageActionMixin,Vue) {
      @Prop() federation?: FederationUserInfo;
      @Prop() title?: string;
      @Prop() subtitle?: string;
      @Prop({type: Boolean}) public noTitleText!: boolean;
      @Prop({type: Boolean, default: true}) public showActions: boolean = true
      @Prop({
        default: () => {
          []
        }, type: Array
      }) public actions?: Array<PageAction | string>;
    }

