export class GeoLocation {
    label?: string;
    street?: string;
    number?: string;
    bus?: string;
    cityName?: string
    postalCode?: string;
    state?: string;
    county?: string;
    country?: string
}

export interface LatLng {
    lat?: number;
    lng?: number;
}

export function emptyGeoLocation() {
    return {
        street: undefined,
        number: undefined,
        bus: undefined,
        postalCode: undefined,
        cityName: undefined,
        state: undefined,
        county: undefined,
        country: undefined
    }
}
