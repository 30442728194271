
import {Component, Prop, Vue} from 'vue-property-decorator';
import {CompetitionDetail} from "@/modules/competition/service/CompetitionService";
import {CompetitionStatus} from "@/modules/competition/service/ManageCompetitionService";

@Component
export default class CompetitionStatusBadge extends Vue {
  @Prop({type: Object, required: true}) public value!: CompetitionDetail;

  get variant() {
    if (this.value.status == CompetitionStatus.CANCELLED) return "danger"
    return "green"
  }
}
