
import {Component, Vue} from "vue-property-decorator";
import EqifyPage from "@/components/EqifyPage.vue";
import CalendarComponent, {SearchRequest} from "@/components/calendar/CalendarComponent.vue";
import {competitionCalendarService} from "@/modules/competition/service/CompetitionCalendarService";
import {mixins} from "vue-class-component";
import {CompetitionPermissionHelper} from "@/modules/competition/CompetitionPermissionHelper";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";
import {CompetitionCalendarItem} from "@/components/calendar/Calendar";
import {getFederationRefFromRoute} from "@/modules/federation/services/MyFederationService";
import FederationUtils from "@/utils/FederationUtils";
import {FederationUserInfo, UserProfile} from "@/shared/domain/User";

@Component({
  components: {CalendarComponent, EqifyPage}
})
export default class CompetitionCalendarView extends mixins(CompetitionPermissionHelper, Vue) {
  async searchCallback(form: SearchRequest): Promise<PagedResponse<CompetitionCalendarItem>> {
    return await competitionCalendarService.searchCompetitionCalendarItem(form.fromDate, form.toDate, form.sportTypeVariant, form.searchString, form.page)
  }

  canCreateCompetition(user: UserProfile, federationUserInfo: FederationUserInfo) {
    return user.canCreateCompetition(federationUserInfo.ref!)
  }
}
