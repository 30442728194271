
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class AccessDenied extends Vue {
  back() {
    if (window.history.length > 0) {
      this.$router.go(-1)
    } else {
      this.$router.push("/")
    }
  }
}
