
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";

@Component({})
export default class BicInput extends Vue {
  @Prop() value?: string
  @Prop() placeholder?: string

  get bic(): string {
    if (this.value) return this.value
    else return ""
  }

  set bic(bic: string) {
    this.$emit("input", bic)
  }
}
