
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import ArticlesSearchInput from "@/components/general/ArticlesSearchInput.vue";

@Component({
  components: {ArticlesSearchInput}
})
export default class ArticlesSearchField extends mixins(Vue, abstractField) {

}
