
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component
export default class TimePickerField extends mixins(Vue, abstractField) {
  input: string = "";
  placeHolder: string = "";

  get getDisabled() {
    // @ts-ignore
    const disabled = this.schema.disabled
    if (typeof disabled == "function") {
      // @ts-ignore
      return disabled.apply(this, [this.model, this.schema]) || false
    } else {
      return disabled || false
    }
  }

  get options() {
    // @ts-ignore
    return {time: true, timePattern: this.schema.timePattern || ['h', 'm']}
  }
}
