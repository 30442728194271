
import {Component, Vue} from "vue-property-decorator";
import EqifyMenuItem from "@/components/general/selectable/EqifyMenuItem.vue";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import FastNavigationSidebarMixin from "@/modules/sidebar/mixin/FastNavigationSidebarMixin";
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import EqifyMenuDropdown from "@/components/general/selectable/EqifyMenuDropdown.vue";
import EqifyMenuDropdownItem from "@/components/general/selectable/EqifyMenuDropdownItem.vue";
import EqifySmallTitle from "@/components/general/titles/EqifySmallTitle.vue";
@Component({
  components: {EqifySmallTitle, EqifyMenuDropdownItem, EqifyMenuDropdown, EqifyAvatarAndText, EqifyMenuItem}
})
export default class OrganisationNavigation extends mixins(AuthenticatedUserMixin, FastNavigationSidebarMixin, Vue) {
  get title()  {
    if (this.user && this.user.organisations && this.user.organisations.length > 1)
      return this.$t('menu.myorganisation.multiple')
    else return this.$t('menu.myorganisation.main')
  }
}
