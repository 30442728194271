
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component
export default class DatePickerField extends mixins(Vue, abstractField) {
  input: Date | undefined;
  placeHolder: string = "";
}
