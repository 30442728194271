import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {RefWithName} from "@/utils/Utils";
import {DateUtils} from "@/utils/DateUtils";
import {
    Championship,
    ChampionshipRef,
    ChampionshipStatus,
    CompetitionDetail
} from "@/modules/championship/services/PublicChampionshipService";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {SportCategory} from "@/modules/federation/services/SportCategoryService";
import {
    ChampionshipSportCategoryWithCareer
} from "@/modules/championship/services/ManageChampionshipCompetitionService";

class ManageChampionshipService {
    public createChampionship(federation: FederationRef, request: CreateChampionshipRequest): Promise<ChampionshipRef> {
        return httpService.post(`/championship`, {
            ...request,
            federationRef: federation,
            startDate: DateUtils.toDateString(request.startDate),
            endDate: DateUtils.toDateString(request.endDate),
        })
    }

    public getChampionship(ref: ChampionshipRef): Promise<Championship> {
        return httpService.get(`/championship/${ref}`)
    }

    public getInitialSaveChampionshipFormDataForCreate(ref: FederationRef): Promise<SaveChampionshipFormMetaData> {
        return httpService.get(`/championship/create/meta-data?federationRef=${ref}`)
    }

    public getChampionshipTemplates(ref: ChampionshipRef): Promise<Array<RefWithName>> {
        return httpService.get(`/championship/${ref}/manage/templates`)
    }

    public createFromChampionshipTemplate(ref: ChampionshipRef, templateRef: string): Promise<void> {
        return httpService.post(`/championship/${ref}/manage/templates/${templateRef}`, {})
    }

    public getChampionshipCompetitionsCandidates(ref: ChampionshipRef): Promise<Array<CompetitionDetail>> {
        return httpService.get(`/championship/${ref}/manage/competition-candidates`)
    }

    public updateChampionshipName(ref: ChampionshipRef, name: string): Promise<void> {
        return httpService.post(`/championship/${ref}/manage/update-name`, {name: name})
    }

    public updateChampionshipDate(ref: CompetitionRef, start: string, end?: string): Promise<void> {
        return httpService.post(`/championship/${ref}/manage/update-dates`, {start: start, end: end})
    }

    public updateChampionshipInfo(ref: ChampionshipRef, form: UpdateChampionshipGeneralData): Promise<void> {
        const data = {
            ...form,
            preRegistrationStart: DateUtils.toDateString(form.preRegistrationStart),
            preRegistrationEnd: DateUtils.toDateString(form.preRegistrationEnd),
            juryPreRegistrationStart: DateUtils.toDateString(form.juryPreRegistrationStart),
            juryPreRegistrationEnd: DateUtils.toDateString(form.juryPreRegistrationEnd),
        }
        return httpService.put(`/championship/${ref}/manage/general`, data)
    }

    public updateChampionshipBanner(ref: ChampionshipRef, banner: File): Promise<void> {
        return httpService.upload(`/championship/${ref}/manage/banner`, banner)
    }

    public getChampionshipGeneralInfoMetaData(ref: ChampionshipRef): Promise<ChampionshipGeneralInfoMetaData> {
        return httpService.get(`/championship/${ref}/manage/general/meta-data`)
    }

    publish(ref: ChampionshipRef): Promise<ChampionshipStatus> {
        return httpService.put(`/championship/${ref}/manage/status/published`, {})
    }

    cancel(ref: ChampionshipRef): Promise<ChampionshipStatus> {
        return httpService.put(`/championship/${ref}/manage/status/cancelled`, {})
    }

    archive(ref: ChampionshipRef): Promise<ChampionshipStatus> {
        return httpService.put(`/championship/${ref}/manage/status/archived`, {})
    }

    delete(ref: ChampionshipRef): Promise<ChampionshipStatus> {
        return httpService.put(`/championship/${ref}/manage/status/deleted`, {})
    }
}

export interface ChampionshipGeneralInfoMetaData {
    sportCategories: Array<SportCategory>,
    sportTypeVariants: Array<SportTypeVariant>,
    categories: Array<RefWithName>,
    hasRankingEntries: boolean
    registrationArticles: Array<RefWithName>,
    lateRegistrationArticles: Array<RefWithName>,
    noShowArticles: Array<RefWithName>,
    practitionerPriceProfiles: Array<RefWithName>
    practitionerGroups: Array<RefWithName>
}

export interface UpdateChampionshipGeneralData {
    categoryRef: string,
    registrationFee: number,
    lateRegistrationFee: number,
    noShowFee: number,
    hasPreRegistration: boolean,
    preRegistrationStart?: Date,
    preRegistrationEnd?: Date,
    description: string,
    hasJuryPreRegistration: boolean,
    juryPreRegistrationStart?: Date,
    juryPreRegistrationEnd?: Date,
    timeAssignmentStartInDaysBefore: number,
    sportCategories: Array<ChampionshipSportCategoryWithCareer>,
    color?: string,
    bannerUrl?: string,
    championshipPractitionerDependenceType: ChampionshipPractitionerDependenceType
    registrationArticleRef?: string,
    lateRegistrationArticleRef?: string,
    noShowArticleRef?: string,
    practitionerPriceProfileRef?: string,
    practitionerGroupRef?: string,
    maxParticipationsPerRider: number,
    maxParticipationsPerHorse: number,
    maxParticipationsPerCombination: number,
}

export const initEmptyUpdateChampionshipGeneralData = () => {
    const form: UpdateChampionshipGeneralData = {
        categoryRef: "",
        registrationFee: 0.0,
        noShowFee: 0.0,
        lateRegistrationFee: 0.0,
        hasPreRegistration: false,
        description: "",
        hasJuryPreRegistration: false,
        timeAssignmentStartInDaysBefore: 30,
        sportCategories: [],
        championshipPractitionerDependenceType: ChampionshipPractitionerDependenceType.RIDER_HORSE,
        maxParticipationsPerRider: 0,
        maxParticipationsPerHorse: 0,
        maxParticipationsPerCombination: 0,
    }
    return form
}

export enum ChampionshipPractitionerDependenceType {
    RIDER_HORSE = "RIDER_HORSE",
    HORSE = "HORSE"
}

//championship
export interface SaveChampionshipFormMetaData {
    availableSportCategories: SportCategory[],
    availableSportTypeVariants: SportTypeVariant[],
    registrationArticles: Array<RefWithName>,
    lateRegistrationArticles: Array<RefWithName>,
    noShowArticles: Array<RefWithName>,
    templates: Array<RefWithName>
    categories: Array<RefWithName>
    practitionerPriceProfiles: Array<RefWithName>
}

export interface CreateChampionshipRequest {
    federationRef: FederationRef;
    name: string;
    startDate: Date;
    endDate: Date;
    layoutTemplateRef: string;
    sportCategories: Array<ChampionshipSportCategoryWithCareer>;
    categoryRef?: string;
    registrationFee?: number;
    lateRegistrationFee?: number;
    noShowFee?: number;
    registrationArticleRef?: string;
    lateRegistrationArticleRef?: string;
    noShowArticleRef?: string;
    practitionerPriceProfileRef?: string;
}

export interface LayoutDetails {
    phases: Array<PhaseDetail>
}

export interface PhaseDetail {
    ref: string,
    name: string,
    groups: Array<GroupDetail>
}

export interface GroupDetail {
    ref: string,
    name: string
}

export const manageChampionshipService = new ManageChampionshipService();
