import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {httpService} from "@/services/HttpService";
import {EntityStateResource, EntityWithStateData} from "@/shared/domain/EntityState";
import {RefWithName} from "@/utils/Utils";
import {EqifyRenewal} from "@/shared/domain/EqifyRenewal";
import {MoneyWithTax} from "@/shared/domain/Finance";

class LicenseDefinitionService {
    getLicenses(federationRef: FederationRef): Promise<Array<LicenseDefinition>> {
        return httpService.get(`/myfederation/${federationRef}/license`)
    }

    addLicense(federationRef: FederationRef, license: LicenseDefinition): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/license`, {
            name: license.name,
            type: license.type,
            articleRef: license.article?.ref,
            renewal: license.renewal,
            money: license.money,
            rules: license.rules,
            description: license.description
        })
    }

    updateLicense(federationRef: FederationRef, license: LicenseDefinition): Promise<EntityWithStateData> {
        return httpService.put(`/myfederation/${federationRef}/license/${license.ref}`, {
            name: license.name,
            type: license.type,
            articleRef: license.article?.ref,
            renewal: license.renewal,
            money: license.money,
            rules: license.rules,
            description: license.description
        })
    }

    deleteLicense(federationRef: FederationRef, ref: LicenseRef): Promise<void> {
        return httpService.delete(`/myfederation/${federationRef}/license/${ref}`)
    }

    publishLicense(federationRef: FederationRef, ref: LicenseRef, note: String = "A Note"): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/license/${ref}/publish`, note)
    }

    unpublishLicense(federationRef: FederationRef, ref: LicenseRef): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/license/${ref}/unpublish`, {})
    }

    archiveLicense(federationRef: FederationRef, ref: LicenseRef): Promise<EntityWithStateData> {
        return httpService.post(`/myfederation/${federationRef}/license/${ref}/archive`, {})
    }
}

export type LicenseRef = string

export class LicenseDefinition {
    ref?: LicenseRef;
    name?: string;
    type?: LicenseType;
    article?: RefWithName;
    renewal?: EqifyRenewal;
    money?: MoneyWithTax;
    rules?: string;
    state?: EntityStateResource;
    description?: string;
}

export enum LicenseValidityType {
    UNLIMITED = "UNLIMITED",
    RECURRING = "RECURRING",
    CHAMPIONSHIP = "CHAMPIONSHIP"
}

export enum LicenseFeeType {
    ONE_TIME = "ONE_TIME",
    ANNUAL = "ANNUAL",
    MONTHLY = "MONTHLY",
    PER_USE = "PER_USE"
}

export enum LicenseQuantityType {
    UNLIMITED = "UNLIMITED",
    TIMES = "TIMES"
}

export enum LicenseState {
    DRAFT = "DRAFT",
    PUBLISHED = "PUBLISHED",
    ARCHIVED = "ARCHIVED"
}

export enum LicenseType {
    COMPETITION = "COMPETITION",
    RECREATIONAL = "RECREATIONAL",
    OFFICIAL = "OFFICIAL",
    OTHER = "OTHER",
    BOARD_MEMBER = "BOARD_MEMBER"
}

export const licenseDefinitionService = new LicenseDefinitionService();
