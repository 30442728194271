import {RouteConfig} from "vue-router";
import CourseEventSave from "@/modules/course/views/CourseEventSave.vue";
import {createPublicRoute, createSecureCrudRouteMetadata, createSecureReadOnlyRouteMetadata} from "@/router";
import CourseCalendarView from "@/modules/course/views/CourseCalendarView.vue";

const routes: Array<RouteConfig> = [
    {
        path: '/courses',
        component: CourseCalendarView,
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: '/courses/create',
        component: CourseEventSave,
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: '/courses/:courseEvent',
        component: () => import("@/modules/course/views/public/CourseEventProfileLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import("@/modules/course/views/public/CourseEventProfileOverview.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'registrations',
                component: () => import("@/modules/course/views/manage/CourseEventProfileRegistrationsView.vue"),
                meta: createPublicRoute()
            },
            {
                path: 'attendances',
                component: () => import("@/modules/course/views/manage/CourseEventProfileAttendancesView.vue"),
                meta: createSecureCrudRouteMetadata()
            },
            {
                path: 'results',
                component: () => import("@/modules/course/views/manage/CourseEventProfileResultsView.vue"),
                meta: createSecureCrudRouteMetadata()
            }
        ]
    },
    {
        path: '/courses/:courseEvent/edit',
        component: CourseEventSave,
        meta: createSecureCrudRouteMetadata()
    }
]

export default {
    routes: routes
};
