import {UserProfile} from "@/shared/domain/User";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class AuthenticatedUserMixin extends Vue {
    get user(): UserProfile | undefined {
        return this.$keycloak.userProfile
    }

    get pendingRegistration() {
        return this.$keycloak.authenticated && this.$keycloak.userProfile !== undefined && !this.$keycloak.userProfile.isRegistered && this.$keycloak.userProfile.hasPendingRegistration
    }

    get authenticated() {
        return this.$keycloak.authenticated && this.$keycloak.userProfile !== undefined && this.$keycloak.userProfile.isRegistered && !this.$keycloak.userProfile.hasPendingRegistration
    }

    get isKeycloakAuthenticated() {
        return this.$keycloak.authenticated
    }

    get isSuperAdmin(): boolean {
        return this.user !== undefined && this.user.isSuperAdmin
    }
}
