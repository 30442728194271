
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {emptyGeoLocation, GeoLocation} from "@/shared/domain/Address";
import CountrySelectInput from "@/components/general/CountrySelectInput.vue";
@Component({
  components: {CountrySelectInput}
})
export default class LocationEditComponent extends Vue {
  @Prop() value!: GeoLocation

  get street() { return this.value?.street || "" }
  set street(street: string) {
    this.value.street = street
    this.emitChanges()
  }

  get number() { return this.value?.number || "" }
  set number(number: string) {
    this.value.number = number
    this.emitChanges()
  }

  get bus() { return this.value?.bus || "" }
  set bus(bus: string) {
    this.value.bus = bus
    this.emitChanges()
  }

  get postalCode() { return this.value?.postalCode || "" }
  set postalCode(postalCode: string) {
    this.value.postalCode = postalCode
    this.emitChanges()
  }

  get cityName() { return this.value?.cityName || "" }
  set cityName(cityName: string) {
    this.value.cityName = cityName
    this.emitChanges()
  }

  get country() { return this.value?.country || "" }
  set country(country: string) {
    this.value.country = country
    this.emitChanges()
  }

  emitChanges() {
    this.$emit("input", this.value)
  }
}
