
import {Component, Vue} from "vue-property-decorator";
import LicenseSelectComponent from "@/components/rules/components/LicenseSelectComponent.vue";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
@Component({
  components: {LicenseSelectComponent}
})
export default class LicenseSelectField extends mixins(Vue, abstractField) {

  get licenseType() {
    //@ts-ignore
    return this.schema.licenseType || undefined
  }
}
