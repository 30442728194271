import {httpService} from "@/services/HttpService";

class WitherHeightService {
    async getWitherHeights(): Promise<Array<WitherHeight>> {
        return httpService.get(`/horse/witherheights`)
    }
}

export interface WitherHeight {
    ref: string,
    code: string,
    name: string,
    min: number
    max: number
}

export const initWitherHeight = () => {
    const data: WitherHeight = {
        ref: "",
        code: "",
        name: "",
        min: 0.0,
        max: 0.0
    }
    return data
}

export const witherHeightService = new WitherHeightService();