
import {Component, Prop, Vue} from "vue-property-decorator";
import SaveAsFavoriteButton from "@/components/general/buttons/SaveAsFavoriteButton.vue";
import {publicCourseEventService} from "@/modules/course/service/PublicCourseEventService";
import {CourseEventRef} from "@/modules/course/service/CourseEvent";
import {PersonFavorite} from "@/shared/domain/User";

@Component({
  components: {SaveAsFavoriteButton}
})
export default class SaveCourseAsFavoriteButton extends Vue {
  @Prop() courseDayEventRef!: CourseEventRef

  async save(): Promise<PersonFavorite> {
    return await publicCourseEventService.saveAsFavorite(this.courseDayEventRef)
  }

  async remove(ref: string): Promise<void> {
    return await publicCourseEventService.removeAsFavorite(ref)
  }
}
