
import Component, {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import {Vue} from "vue-property-decorator";
import RenewalInput from "@/components/general/RenewalInput.vue";
@Component({
  components: {RenewalInput}
})
export default class RenewalInputField extends mixins(abstractField, Vue) {

}
