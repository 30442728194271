import Vue from 'vue'
import VueRouter, {Route} from 'vue-router'
import PageNotFound from '@/views/PageNotFound.vue';
import PageLayout from "@/components/PageLayout.vue";
import federationModule from "@/modules/federation/FederationModule";
import championshipModule from "@/modules/championship/ChampionshipModule";
import competitionsModule from "@/modules/competition/CompetitionModule";
import MembersModule from "@/modules/members/MembersModule";
import securityModule from "@/modules/security/SecurityModule";
import AccessDenied from "@/views/AccessDenied.vue";
import HorsesModule from "@/modules/horses/HorsesModule";
import OrganisationsModule from "@/modules/organisations/OrganisationsModule";
import CalendarModule from "@/modules/calendar/EventModule";
import DashboardModule from "@/modules/dashboard/CalendarModule";
import SuperAdminModule from "@/modules/superadmin/SuperAdminModule";
import FinanceModule from "@/modules/finance/FinanceModule";
import {UserProfile} from "@/shared/domain/User";
import _ from "lodash";
import CourseEventModule from "@/modules/course/CourseEventModule";
import ErrorPage from "@/views/ErrorPage.vue";
import FederationUtils from "@/utils/FederationUtils";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: PageLayout,
        children: [
            ...SuperAdminModule.routes,
            ...DashboardModule.routes,
            ...CalendarModule.routes,
            ...federationModule.routes,
            ...championshipModule.routes,
            ...competitionsModule.routes,
            ...MembersModule.routes,
            ...HorsesModule.routes,
            ...OrganisationsModule.routes,
            ...CourseEventModule.routes,
            ...FinanceModule.routes
        ]
    },
    ...securityModule.routes,
    {path: '*', component: PageNotFound},
    {path: '/error/404', name: "not-found", component: PageNotFound},
    {path: '/error/403', name: "access-denied", component: AccessDenied},
    {path: '/error/500', name: "error", component: ErrorPage}
];

Vue.router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

Vue.router.beforeEach((to, from, next) => {
    const vue = Vue.router.app;
    if (to.meta!!.secure) {
        whenKeycloakReady(to, next, vue)
    } else {
        next();
    }
});

function whenKeycloakReady(to: Route, next: any, vue: Vue) {
    if (vue.$keycloak.ready) {
        handleSecureRoute(to, next, vue)
    } else {
        setTimeout(() => {
            whenKeycloakReady(to, next, vue)
        }, 150)
    }
}

const handleSecureRoute = (to: Route, next: any, vue: Vue) => {
    if (!vue.$keycloak.authenticated) {
        vue.$keycloak.login!()
    } else {
        checkRoles(next, to, vue);
    }
}

const checkRoles = (next: Function, to: Route, vue: Vue) => {
    if (!to.meta!!.secure) {
        vue.$log.warn("Invalid SecureRouteMetadata config for " + to.fullPath)
        next({name: 'access-denied'})
    } else {
        if (!vue.$keycloak.authenticated) {
            next({name: 'access-denied'})
        } else {
            const user = vue.$keycloak.userProfile
            if (to.meta!!.canAccess(to, vue, user)) {
                next()
            } else {
                next({name: 'access-denied'})
            }
        }
    }
};

export interface SecureRouteMetadata {
    secure: boolean

    canAccess(to: Route, vue: Vue, user?: UserProfile): boolean
}

export function createSecureReadOnlyRouteMetadata(): SecureRouteMetadata {
    return {
        secure: true,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            return isLoggedInUserAndRegistered(user);
        }
    }
}

export function createAnyLoggedInUserRoute(): SecureRouteMetadata {
    return {
        secure: true,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            return isLoggedInUserAndRegistered(user);
        }
    }
}

export function createSecureAdminOnlyReadOnlyRouteMetadata(): SecureRouteMetadata {
    return {
        secure: true,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            return user!.hasAtLeastOneHigherOrderRole();
        }
    }
}

export function createSecureCrudRouteMetadata(): SecureRouteMetadata {
    return {
        secure: true,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            return user!.hasAtLeastOneHigherOrderRole();
        }
    }
}

export function createSecureCompetitionMetadata(): SecureRouteMetadata {
    return {
        secure: true,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            const federation = FederationUtils.getFederationFromRoute(to, user!!)
            return federation ? user!.canCreateCompetition(federation!.ref!) : false;
        }
    }
}

export function createPublicRoute(): SecureRouteMetadata {
    return {
        secure: false,
        canAccess(to: Route, vue: Vue, user?: UserProfile): boolean {
            return true;
        }
    }
}

function isLoggedInUserAndRegistered(user?: UserProfile) {
    return !_.isEmpty(user) && !_.isEmpty(user!.username) && user!.isRegistered;
}

function getFromRoute(route: Route, field: string): string | undefined {
    let value = route.params[field];
    if (!value) {
        value = route.query[field] as string
    }
    return value
}

export default Vue.router
