
import {Prop, Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyTable from "@/components/table/EqifyTable.vue";
import PageTitle from "@/components/PageTitle.vue";
import {
  CreateCourseEventDayData,
  initEmptyCreateCourseEventDayData
} from "@/modules/course/service/PublicCourseEventService";
import {EqifyCrudUtils} from "@/components/crud/EqifyCrudComponent.vue";
import LoadingButton from "@/components/general/buttons/LoadingButton.vue";
import {hereLocationValidator, timeValidator} from "@/components/form/Validators";
import AddressComponent from "@/components/location/AddressComponent.vue";
import {CourseDayEvent} from "@/modules/course/service/CourseEvent";
import EditButton from "@/components/general/buttons/EditButton.vue";

@Component({
  components: {EditButton, AddressComponent, LoadingButton, PageTitle, EqifyTable}
})
export default class ManageCourseEventDays extends Vue {
  @Prop({}) value!: Array<CourseDayEvent>

  private tableFields: Array<any> = [];
  private formValid = false

  private form: CreateCourseEventDayData = initEmptyCreateCourseEventDayData()
  private formSchema = {}

  @Ref("vfg") vfg: any;
  $refs!: {
    vfg: any,
  };

  formOptions = {
    validateAfterLoad: false,
    validateAfterChanged: true
  };

  formValidated(isValid: boolean) {
    this.formValid = isValid
  }

  created() {
    this.daysValidator()
    this.tableFields = [
      {
        key: 'courseDay',
        label: this.$t("courses.create.table.label.courseDay"),
        sortable: true,
      },
      {
        key: 'day',
        label: this.$t("courses.create.table.label.day"),
        sortable: true,
      },
      {
        key: 'start',
        label: this.$t("courses.create.table.label.start"),
        sortable: true,
      },
      {
        key: 'end',
        label: this.$t("courses.create.table.label.end"),
        sortable: true,
      },
      {
        key: 'location',
        label: this.$t("courses.create.table.label.location"),
      },
      EqifyCrudUtils.actions(this)
    ]

    this.formSchema = {
      groups: [
        {
          fields: [
            {
              type: 'pickaday',
              label: this.$t("courses.day.label"),
              model: 'day',
              required: true,
              placeholder: this.$t("courses.day.placeholder"),
              validator: "date"
            },
            {
              type: 'timePicker',
              label: this.$t("courses.start.label"),
              model: "start",
              placeholder: this.$t("courses.start.placeholder"),
              required: true,
              validator: timeValidator,
              styleClasses: 'pl-0 col-6'
            },
            {
              type: 'timePicker',
              label: this.$t("courses.end.label"),
              model: "end",
              placeholder: this.$t("courses.end.placeholder"),
              required: true,
              validator: timeValidator,
              styleClasses: 'pl-0 col-6'
            },
            {
              type: 'hereAddressLookup',
              label: this.$t("courses.location.label"),
              model: 'location',
              placeholder: this.$t("courses.location.placeholder"),
              withMap: false,
              required: true,
              withForm: true,
              validator: hereLocationValidator
            },
          ]
        }
      ]
    }
  }

  openEditForm(row: any) {
    this.form = {
      ref: row.item.ref,
      day: row.item.day,
      courseDay: row.item.courseDay,
      start: row.item.start,
      end: row.item.end,
      location: row.item.location
    }
    this.$bvModal.show("editCourseDayEventModal")
  }

  edit() {
    const editedForm: CourseDayEvent = {
      ...this.form,
      day: this.form.day,
      start: this.form.start!!,
      end: this.form.end!!,
    }

    const index = this.value.findIndex(s => s.courseDay == editedForm.courseDay)
    this.$set(this.value, index, editedForm)
    this.daysValidator()
    this.$bvModal.hide("editCourseDayEventModal")
  }

  daysValidator() {
    let valid = false
    this.value.forEach(it => {
      valid = (it.start != null && it.end != null && it.day != undefined && it.location != undefined)
    })
    this.$emit("validated", {valid: valid})
  }
}
