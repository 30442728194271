
import Component, {mixins} from "vue-class-component";
import {Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import BicInput from "@/components/general/BicInput.vue";

@Component({
  components: {BicInput}
})
export default class BicInputField extends mixins(Vue, abstractField) {
  get placeholder() {
    // @ts-ignore
    return this.schema.placeholder
  }
}
