
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import '@netcz/vue-pikaday/dist/vue-pikaday.min.css';
import {UI_DATE_FORMAT} from "@/utils/DateUtils";

@Component
export default class PikadayField extends mixins(Vue, abstractField) {
  get placeholder() {
    //@ts-ignore
    return this.schema.placeholder || ""
  }

  get pikadayOptions() {
    //@ts-ignore
    let options = this.schema.pickadayOptions || {}
    return Object.assign({
      format: UI_DATE_FORMAT,
      firstDay: 1
    }, options)
  }
}
