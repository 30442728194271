
import {Component, Prop, Vue} from 'vue-property-decorator';
//@ts-ignore
import country from 'country-list-js';

@Component
export default class CountrySelectInput extends Vue {
  @Prop() value?: string;

  get selected() {
    return this.countries.find(it => it.iso3 == this.value)
  }

  onChanged(v: any) {
    this.$emit("input", v)
  }

  get countries() {
    //@ts-ignore
    return Object.keys(country.all).map((k:string) => country.all[k]);
  }
}
