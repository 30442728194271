
export default class AvatarUtils {
    public static avatarName(name?: string) {
        if (!name || name.trim().length == 0) {
            return "";
        }
        return name.trim()
            .split(" ")
            .map(p => p.substr(0, 1))
            .slice(0,3)
            .join("").toUpperCase()
    }
}
