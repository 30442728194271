
import {Component, Vue} from 'vue-property-decorator';
import PageNavbar from "./PageNavbar.vue";
import PageFooter from "./PageFooter.vue";
import FastNavigationSidebar from "@/modules/sidebar/FastNavigationSidebar.vue";

@Component({
  components: {FastNavigationSidebar, PageFooter, PageNavbar}
})
export default class PageLayout extends Vue {
  collapsed: boolean = false;
  isOnMobile: boolean = false;
}
