
export interface SharedObject {
    type: SharedObjectType,
    title: string,
    ref: string
}

export enum SharedObjectType {
    COMPETITION = "COMPETITION",
    COURSE = "COURSE"
}