

import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class InfiniteScrollTrigger extends Vue {
  @Prop({default: () => {return {root: null, threshold: 0}}}) options?: Object

  observer: IntersectionObserver = {} as IntersectionObserver

  mounted() {
    this.observer = new IntersectionObserver( entries => {
      this.handleIntersect(entries[0]);
    }, this.options)
    let element = this.$refs["trigger"]
    this.observer.observe(element as Element)
  }

  destroyed() {
    this.observer.disconnect()
  }

  handleIntersect(entry: any) {
    if (entry.isIntersecting) {
      this.$emit("triggerIntersected")
    }
  }
}
