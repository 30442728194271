import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    manageResultsService,
    Results,
    ResultsMenu,
    StartlistEntryRef
} from "@/modules/competition/service/ManageResultsService";
import {CompetitionTestRef, CompetitionTestSetRef} from "@/modules/competition/service/CompetitionTestsService";

@Module({namespaced: true, name: 'competition-manage-results'})
export default class CompetitionManageResultsModule extends VuexModule {
    menu: ResultsMenu = {} as ResultsMenu
    results: Results = {} as Results

    @Mutation
    clearStore() {
        this.menu = {} as ResultsMenu
        this.results = {} as Results
    }

    @MutationAction
    async loadData(ref: CompetitionRef) {
        const data = await manageResultsService.getData(ref)
        return {menu: data.menu, results: {} as Results}
    }

    @MutationAction
    async select(request: SelectRequest) {
        const r = await manageResultsService.getResults(request.competition, request.testRef, request.testSetRef)
        return {results: r}
    }

    @Mutation
    async refresh(competitionRef: CompetitionRef) {
        this.results = await manageResultsService.getResults(competitionRef, this.results.testRef, this.results.testSet.ref!)
    }
}

export interface SelectRequest {
    competition: CompetitionRef
    testRef: CompetitionTestRef
    testSetRef: CompetitionTestSetRef
}

export interface SaveRawRequest {
    competitionRef: CompetitionRef
    testRef: CompetitionTestRef
    testSetRef: CompetitionTestSetRef
}

export interface DeleteResultRequest {
    competitionRef: CompetitionRef
    testRef: CompetitionTestRef
    testSetRef: CompetitionTestSetRef
    startlistEntryRef: StartlistEntryRef
}
