import {MemberRef} from "@/modules/members/services/MemberService";
import {FinanceTransaction, Money} from "@/shared/domain/Finance";
import {FileContent, httpService} from "@/services/HttpService";
import {Bill, BillItem} from "@/services/FinanceService";
import {BillItemData} from "@/components/finance/FinanceTransactionCallback";
import {RefWithName} from "@/utils/Utils";
import {OrderDetail, OrderRef} from "@/modules/finance/services/OrderService";
import {
    OrganisationOrders
} from "@/modules/organisations/services/OrganisationFinanceService";

class MemberFinanceService {

    deleteBill(ref: MemberRef, billRef: string, moveToRef?: string): Promise<void> {
        return httpService.delete(`/member/${ref}/finance/bill/${billRef}?moveToRef=${moveToRef}`)
    }

    getBills(ref: MemberRef): Promise<Array<Bill>> {
        return httpService.get(`/member/${ref}/finance/bill`)
    }

    getBillItems(ref: MemberRef, billRef: string): Promise<MemberBillItems> {
        return httpService.get(`/member/${ref}/finance/bill/${billRef}/item`)
    }

    updateBillItem(ref: MemberRef, billRef: string, billItemRef: string, body: BillItemData): Promise<void> {
        return httpService.put(`/member/${ref}/finance/bill/${billRef}/item/${billItemRef}`, body)
    }

    addBillItem(ref: MemberRef, billRef: string): Promise<void> {
        return httpService.post(`/member/${ref}/finance/bill/${billRef}/item`, {})
    }

    deleteBillItem(ref: MemberRef, billRef: string, billItemRef: string): Promise<void> {
        return httpService.delete(`/member/${ref}/finance/bill/${billRef}/item/${billItemRef}`)
    }

    moveBillItem(ref: MemberRef, billRef: string, targetBillRef: string, billItemRef: string): Promise<void> {
        return httpService.put(`/member/${ref}/finance/bill/${billRef}/item/${billItemRef}/target/${targetBillRef}`, {})
    }

    getBalance(ref: MemberRef): Promise<MemberBalance> {
        return httpService.get(`/member/${ref}/finance/bill/balance`)
    }

    getOrders(ref: MemberRef): Promise<OrganisationOrders> {
        return httpService.get(`/member/${ref}/finance/orders`)
    }

    getOrderDetail(ref: MemberRef, orderRef: OrderRef): Promise<OrderDetail> {
        return httpService.get(`/member/${ref}/finance/orders/${orderRef}`)
    }

    exportOrder(ref: MemberRef, orderRef: OrderRef): Promise<FileContent> {
        return httpService.download(`/member/${ref}/finance/orders/${orderRef}/export`)
    }

    getCreateBillMetadata(ref: MemberRef): Promise<CreateBillMetadata> {
        return httpService.get(`/member/${ref}/finance/create-bill-metadata`)
    }

    createBill(ref: MemberRef, data: CreateMemberBillData): Promise<void> {
        return httpService.post(`/member/${ref}/finance/bill`, data)
    }
}

export interface CreateMemberBillData {
    billType: CreateMemberBillType,
    personAccountRef?: string,
    organisationRef?: string
}

export interface CreateBillMetadata {
    accounts: Array<RefWithName>,
    organisations: Array<RefWithName>
}

export enum CreateMemberBillType {
    PERSONAL = "PERSONAL",
    ORGANISATION = "ORGANISATION"
}

export interface MemberBillItems {
    bill: Bill,
    items: Array<BillItem>,
    articles: Array<RefWithName>
}

export interface MemberBalance {
    amount: Money
}

export const memberFinanceService = new MemberFinanceService()