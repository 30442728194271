import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {HorseRef, HorseResource, horseService, initEmptyHorseResource} from "@/modules/horses/services/HorseService";

@Module({namespaced: true, name: 'horse-profile'})
export default class HorseProfileModule extends VuexModule {
    horse: HorseResource = initEmptyHorseResource()

    get selectedHorse() {
        return this.horse
    }

    @MutationAction
    async loadHorse(ref: HorseRef) {
        const horse = await horseService.getHorse(ref);
        return {horse: horse}
    }
}
