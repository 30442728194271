import {httpService} from "@/services/HttpService";
import {LicenseType} from "@/modules/federation/services/LicenseDefinitionService";

class MemberLicenseService {
    getLicenses(licenseType: LicenseType | undefined): Promise<Array<LicenseData>> {
        let url
        if (licenseType == undefined) {
            url = `/license`
        } else {
            url = `/license?licenseType=${licenseType}`
        }
        //TODO optional params
        return httpService.get(url)
    }
}

export interface LicenseData {
    ref: string
    name: string
    amount?: string,
    description?: string,
}


export const initLicenseData = () => {
    const data: LicenseData = {
        ref: '',
        name: ''
    }
    return data
}
export const memberLicenseService = new MemberLicenseService()