import {RouteConfig} from "vue-router";

const routes: Array<RouteConfig> = [
    {
        path: '/events/create',
        component: () => import(/* webpackChunkName: "module-calendar" */ "@/modules/calendar/views/CreateEvent.vue")
    },
    {
        path: '/events/:ref',
        component: () => import(/* webpackChunkName: "module-calendar" */ "@/modules/calendar/views/EventDetail.vue")
    },
    {
        path: '/events/:ref/edit/FREE_EVENT',
        component: () => import(/* webpackChunkName: "module-calendar" */ "@/modules/calendar/views/UpdateFreeEvent.vue")
    }
];

export default {
    routes: routes
};
