import {ChampionshipPhaseRef, ChampionshipRef} from "@/modules/championship/services/PublicChampionshipService";
import {httpService} from "@/services/HttpService";
import {RefWithName} from "@/utils/Utils";
import {ChampionshipCompetitionData} from "@/modules/championship/services/ManageChampionshipGroupService";

class ManageChampionshipLayoutService {
    getChampionshipPhases(ref: ChampionshipRef): Promise<Array<ChampionshipPhaseItemData>> {
        return httpService.get(`/championship/${ref}/manage/phases`)
    }

    getChampionshipPhase(ref: ChampionshipRef, phaseRef: ChampionshipPhaseRef): Promise<ChampionshipPhaseResource> {
        return httpService.get(`/championship/${ref}/manage/phase/${phaseRef}`)
    }
}

export interface ChampionshipLayoutStructure {
    phases: Array<ChampionshipPhaseItemData>
}

export interface ChampionshipPhaseResource {
    ref: string,
    name: string,
    level: number,
    groups: Array<ChampionshipGroupResource>
}

export const emptyChampionshipPhaseResource = () => {
    const resource: ChampionshipPhaseResource = {
        ref: '',
        name: '',
        level: 0,
        groups: []
    }
    return resource
}

export interface ChampionshipGroupResource {
    ref: string,
    name: string,
    competitions: Array<ChampionshipCompetitionData>
}

export interface ChampionshipPhaseItemData {
    ref: string,
    name: string,
    level: number
    groups: Array<ChampionshipGroupItemData>
}

export const emptyChampionshipPhaseItemData = () => {
    const data: ChampionshipPhaseItemData = {
        ref: "",
        name: "",
        level: 0,
        groups: []
    }
    return data
}

export interface ChampionshipGroupItemData {
    ref: string,
    name: string
}

export const manageChampionshipLayoutService = new ManageChampionshipLayoutService()