import {Route} from "vue-router";
import {VueRouter} from "vue-router/types/router";
import {FederationUserInfo, UserProfile} from "@/shared/domain/User";

export default class FederationUtils {
    static getFederationFromRoute(route: Route, user: UserProfile | undefined): FederationUserInfo | undefined {
        if (user) {
            if (user.federations.length == 1) {
                return user.federations[0]
            }
            const federationRef = route.query["federation"]
            if (!federationRef) {
                return user.defaultFederation
            }
            const federation = user.federations.find(f => f.ref === federationRef)
            if (!federation) {
                return undefined
            }

            return federation
        } else {
            return undefined
        }
    }

    static resolveFederationFromRoute(router: VueRouter, route: Route, user: UserProfile | undefined): FederationUserInfo | undefined {
        const federation = this.getFederationFromRoute(route, user);
        if (!federation) {
            router.replace("/error/404")
            return undefined
        } else {
            return federation;
        }
    }
}
