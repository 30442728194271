import {Practitioner} from "@/shared/domain/Practitioner";

export interface RankingData {
    rankingRef?: string,
    selectionListRef?: string,
    pointDistributionRef?: string,
    hasSelections: boolean,
    rankingEntries: Array<RankingEntry>
}

export const initRankingData = () => {
    const data: RankingData = {
        pointDistributionRef: undefined,
        hasSelections: false,
        rankingEntries: []
    }
    return data
}

export interface RankingEntry {
    rankingEntryRef: String,
    practitioner: Practitioner,
    selected: boolean,
    isReserve: boolean,
    position: number,
    points: number
}

export interface PointDistributionCandidates {
    championship?: ChampionshipPointDistributionCandidate,
    phase?: PhasePointDistributionCandidate,
    group?: GroupPointDistributionCandidate
}

export interface ChampionshipPointDistributionCandidate {
    ref: string,
    name: string,
    pointDistributionRef: string
}

export interface PhasePointDistributionCandidate {
    ref: string,
    name: string,
    pointDistributionRef: string
}

export interface GroupPointDistributionCandidate {
    ref: string,
    name: string,
    pointDistributionRef: string
}


export interface RankingCalculationFilter {
    bestOfCount: number
}

export const initRankingCalculationFilter = () => {
    const data: RankingCalculationFilter = {
        bestOfCount: 100
    }
    return data
}