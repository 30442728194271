<template>
  <div :id="id">
    <div :class="sizeClasses">
      <b-input-group class="has-feedback">
        <b-input-group-prepend>
          <slot name="prepend">
            <b-input-group-text>
              <font-awesome-icon icon="search"/>
            </b-input-group-text>
          </slot>
        </b-input-group-prepend>
        <input
            ref="input"
            type="text"
            :class="`form-control ${inputClass} col-12`"
            :placeholder="placeholder"
            :aria-label="placeholder"
            :value="inputValue"
            :disabled="disabled"
            @focus="isFocused = true"
            @blur="handleBlur"
            @input="handleInput($event.target.value)"
            @keydown.esc="handleEsc($event.target.value)"
            @keyup.down="$emit('keyup.down', $event.target.value)"
            @keyup.up="$emit('keyup.up', $event.target.value)"
            @keyup.enter="handleEnter($event.target.value)"
            autocomplete="off"
        />
        <b-input-group-append>
          <slot name="append">
          </slot>
          <b-input-group-text v-if="loading">
            <b-spinner variant="primary" small/>
          </b-input-group-text>
          <b-button :disabled="disabled || !inputValue" @click="clearInput" variant="primary">
            <font-awesome-icon icon="times"/>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <eqify-bootstrap-typeahead-list
        ref="list"
        :visible="isFocused && data.length > 0"
        :advanced-visible="showAdvanced && advancedSearch"
        :query="inputValue"
        :data="formattedData"
        :background-variant="backgroundVariant"
        :text-variant="textVariant"
        :maxMatches="maxMatches"
        :minMatchingChars="minMatchingChars"
        :disableSort="disableSort"
        :showOnFocus="showOnFocus"
        :showAllResults="showAllResults"
        @hit="handleHit"
        @enter="handleEnter"
        @listItemBlur="handleChildBlur"
        :highlightClass='highlightClass'
        class="vbt-autcomplete-list"
    >
      <!-- pass down all scoped slots -->
      <template v-for="(slot, slotName) in $scopedSlots" :slot="slotName" slot-scope="{ data, htmlText }">
        <slot :name="slotName" v-bind="{ data, htmlText }"></slot>
      </template>
      <!-- below is the right solution, however if the user does not provide a scoped slot, vue will still set $scopedSlots.suggestion to a blank scope
      <template v-if="$scopedSlots.suggestion" slot="suggestion" slot-scope="{ data, htmlText }">
        <slot name="suggestion" v-bind="{ data, htmlText }" />
      </template>-->
    </eqify-bootstrap-typeahead-list>
  </div>
</template>

<script>
import EqifyBootstrapTypeaheadList from './EqifyBootstrapTypeaheadList'
import ResizeObserver from 'resize-observer-polyfill'

export default {
  name: 'EqifyBootstrapTypehead',

  components: {
    EqifyBootstrapTypeaheadList
  },

  props: {
    id: String,
    size: {
      type: String,
      default: null,
      validator: size => ['lg', 'sm'].indexOf(size) > -1
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true,
      validator: d => d instanceof Array
    },
    serializer: {
      type: Function,
      default: (d) => d,
      validator: d => d instanceof Function
    },
    backgroundVariant: String,
    textVariant: String,
    inputClass: {
      type: String,
      default: ''
    },
    maxMatches: {
      type: Number,
      default: 10
    },
    minMatchingChars: {
      type: Number,
      default: 2
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    showOnFocus: {
      type: Boolean,
      default: false
    },
    showAllResults: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    advancedSearch: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    prepend: String,
    append: String,
    highlightClass: String
  },
  computed: {
    sizeClasses() {
      return this.size ? `input-group input-group-${this.size}` : 'input-group'
    },
    formattedData() {
      if (!(this.data instanceof Array)) {
        return []
      }
      return this.data.map((d, i) => {
        return {
          id: i,
          data: d,
          text: this.serializer(d)
        }
      })
    }
  },
  methods: {
    resizeList(el) {
      const rect = el.getBoundingClientRect()
      const listStyle = this.$refs.list.$el.style
      // Set the width of the list on resize
      listStyle.width = rect.width + 'px'
      // Set the margin when the prepend prop or slot is populated
      // (setting the "left" CSS property doesn't work)
      if (this.$refs.prependDiv) {
        const prependRect = this.$refs.prependDiv.getBoundingClientRect()
        listStyle.marginLeft = prependRect.width + 'px'
      }
    },
    handleHit(evt) {
      if (typeof this.value !== 'undefined') {
        this.$emit('input', evt.text)
      }
      this.inputValue = evt.text
      this.$emit('hit', evt.data)
      if (this.autoClose) {
        this.$refs.input.blur()
        this.isFocused = false
      }
    },
    handleChildBlur() {
      this.$refs.input.focus()
      this.isFocused = false
    },
    handleBlur(evt) {
      const tgt = evt.relatedTarget
      if (tgt && tgt.classList.contains('vbst-item')) {
        return
      }
      this.isFocused = false
    },
    handleInput(newValue) {
      this.isFocused = true
      this.showAdvanced = true
      this.inputValue = newValue
      // If v-model is being used, emit an input event
      if (typeof this.value !== 'undefined') {
        this.$emit('input', newValue)
      }
    },
    handleEsc(inputValue) {
      if (inputValue === '') {
        this.$refs.input.blur()
        this.isFocused = false
        this.showAdvanced = false
      } else {
        this.inputValue = ''
      }
    },
    handleEnter(inputValue) {
      if (this.inputValue.length > 2) {
        this.$emit('enter', inputValue)
        this.isFocused = false
        this.showAdvanced = false
      }
    },
    clearInput() {
      this.inputValue = "";
      this.$emit("clear")
      this.$emit('input', undefined)
    }
  },
  data() {
    return {
      isFocused: false,
      inputValue: this.value || '',
      showAdvanced: false,
    }
  },
  mounted() {
    this.$_ro = new ResizeObserver(e => {
      this.resizeList(this.$refs.input)
    })
    this.$_ro.observe(this.$refs.input)
    this.$_ro.observe(this.$refs.list.$el)
  },
  beforeDestroy() {
    this.$_ro.disconnect()
  },
  watch: {
    value: function (val) {
      this.inputValue = val
    }
  }
}
</script>

<style scoped>
.vbt-autcomplete-list {
  padding-top: 5px;
  position: absolute;
  max-height: 350px;
  max-width: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: auto;
  z-index: 999;
}

.vbt-autcomplete-list >>> .vbt-matched-text {
  font-weight: bold;
}
</style>
