
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {AccountRefWithName} from "@/shared/domain/Account";
import {BModal} from "bootstrap-vue";
import LoadingButton from "@/components/general/buttons/LoadingButton.vue";
import {ContactType} from "@/shared/domain/General";
import {toI18nOptions} from "@/utils/Utils";
import {organisationValidator, personValidator} from "@/components/form/Validators";
import {accountService} from "@/services/AccountService";
import {Loader} from "@/utils/Loader";

@Component({
  components: {LoadingButton}
})
export default class SearchAccountComponent extends Vue {
  @Prop() value?: AccountRefWithName

  private loader = Loader.create()

  private formSchema = {}
  private form: Form = this.defaultForm
  private formValid = false
  private formOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true
  };

  private accounts: Array<AccountRefWithName> = []

  @Ref("modal")
  modal!: BModal

  get types(): Array<ContactType> {
    return [ContactType.ORGANISATION, ContactType.PERSON]
  }

  mounted() {
    this.formSchema = {
      fields: [
        {
          type: 'vueSelect',
          label: this.$t('finance.bill.contact.type.name'),
          model: 'type',
          values: toI18nOptions(this.types, 'finance.bill.contact.type', this),
          onChanged: (model: Form, val: any) => {
            model.contact = undefined
            model.account = undefined
          },
          styleClasses: 'col-12 pr-0 pl-0',
          required: true,
          validator: "string"
        },
        {
          type: 'organisationSearch',
          label: this.$t('finance.bill.contact.title'),
          model: 'contact',
          visible: (model: Form) => {
            return model && model.type === ContactType.ORGANISATION
          },
          onChanged: (model: Form, val: any) => {
            this.loader.load(async () => {
              if (val) {
                await this.fetchAccountsForContact(val.ref)
                model.contact = {
                  ref: val.ref,
                  name: val.name,
                  type: ContactType.ORGANISATION
                }
              } else {
                model.account = undefined
                model.contact = undefined
                this.accounts.length = 0
              }
            })
          },
          styleClasses: 'col-12 pr-0 pl-0',
          validator: organisationValidator
        },
        {
          type: 'personSearch',
          label: this.$t('finance.bill.contact.title'),
          model: "contact",
          visible: (model: Form) => {
            return model && model.type === ContactType.PERSON
          },
          onChanged: (model: Form, val: any) => {
            this.loader.load(async () => {
              if (val) {
                await this.fetchAccountsForContact(val.ref)
                model.contact = {
                  ref: val.ref,
                  name: val.name,
                  type: ContactType.PERSON
                }
              } else {
                model.account = undefined
                model.contact = undefined
                this.accounts.length = 0
              }
            })
          },
          styleClasses: 'col-12 pr-0 pl-0',
          validator: personValidator
        },
        {
          type: 'select',
          label: "Account",
          model: 'account',
          required: false,
          values: () => this.accounts.map(it => {
            return {
              name: it.name,
              value: it
            }
          }),
          selectOptions: {
            name: "name",
            value: "value",
          },
          visible: (model: Form) => {
            return this.accounts.length > 0
          }
        }
      ]
    }
  }

  formValidated(isValid: boolean) {
    this.formValid = isValid
  }

  openModal() {
    this.form = this.defaultForm
    this.modal.show()
  }

  private save() {
    this.$emit("input", this.form.account)
    this.close()
  }

  get defaultForm() {
    return {
      type: ContactType.ORGANISATION
    } as Form
  }

  private close() {
    this.modal.hide()
  }

  private async fetchAccountsForContact(contactRef: string) {
    this.accounts = await accountService.getAccounts(contactRef)
    if (this.accounts.length > 0) {
      this.form.account = this.accounts[0]
    }
  }
}

export class Form {
  type: ContactType = ContactType.ORGANISATION
  contact?: any = undefined
  account?: AccountRefWithName
}
