
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import {UserProfile} from "@/shared/domain/User";
import PageNavbarFederationDropdownItemList from "@/components/PageNavbarFederationDropdownItemList.vue";

@Component({
        components: {PageNavbarFederationDropdownItemList, EqifyAvatarAndText}
    })
    export default class PageNavbarFederationDropdown extends Vue {
        @Prop() user: UserProfile | undefined;
        private showSubMenu: boolean | undefined = false;
        private showMenu: boolean | undefined = true;

        hide() {
            this.showMenu = false;
        }
    }
