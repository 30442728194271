import {Component, Vue} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import FastNavigationSidebarModule from "@/modules/sidebar/store/fast-navigation-sidebar-module";

@Component
export default class FastNavigationSidebarMixin extends Vue {
    protected fastNavigationSidebarStore = getModule(FastNavigationSidebarModule, this.$store)

    get sidebarVisible(): boolean {
        return this.fastNavigationSidebarStore.open
    }

    openSidebar() {
        this.fastNavigationSidebarStore.openSidebar()
    }

    closeSidebar() {
        this.fastNavigationSidebarStore.closeSidebar()
    }

}