
import {Component, Vue} from 'vue-property-decorator';
//@ts-ignore
import Password from 'vue-password-strength-meter'
//@ts-ignore
import VFacebookLogin from 'vue-facebook-login-component';
import {userService} from "@/modules/federation/services/UserService";
import LoadingButton from "@/components/general/buttons/LoadingButton.vue";

@Component({
  components: {
    LoadingButton,
    Password, VFacebookLogin
  }
})
export default class AuthAcceptInvitationView extends Vue {
  form: Form = new Form();
  message: string = "";
  passwordLength = 6;
  invitationCode: InvitationCode = new InvitationCode();

  mounted() {
    const code = this.parseCode(this.$route.query.c as string);
    if (!code) {
      this.$router.push('/');
    } else {
      this.invitationCode = code;
    }
  }

  confirm(evt?: Event) {
    if (evt) {
      evt.preventDefault();
    }
    this.$loader.load(async () => {
      let invitationCode = this.invitationCode.code;
      let result = await userService.confirm(invitationCode, this.form.password)
      if (result.success) {
        await this.$keycloak.login!({loginHint: this.invitationCode.email, redirectUri: window.location.origin})
      } else {
        this.message = this.$t(result.message).toString()
      }
    })
  }

  get showMessage() {
    return this.message && this.message.length > 0;
  }

  parseCode(code: string): InvitationCode | undefined {
    try {
      return JSON.parse(atob(code)) as InvitationCode;
    } catch (e) {
      return undefined;
    }
  }
}

class Form {
  password!: string;
}

class InvitationCode {
  code!: string;
  name!: string;
  email!: string;
}
