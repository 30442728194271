
import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {emptyMoney, MoneyWithTax, VAT} from "@/shared/domain/Finance";
import {CurrencyInput} from "vue-currency-input";
//@ts-ignore
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {enumValues, Option, toI18nOptions} from "@/utils/Utils";
import {Currency} from "@/shared/domain/General";

@Component({
  components: {CurrencyInput}
})
export default class MoneyInput extends Vue {
  @Prop() value?: MoneyWithTax
  @Prop({default: false}) moneyOnly?: Boolean
  @Prop() disabled?: boolean

  private money: MoneyWithTax = emptyMoney()

  private currencies: Option[] = []
  private taxLevelCodes: Option[] = []

  created() {
    this.currencies = toI18nOptions(enumValues(Currency), "general.finance.currency", this)
    this.taxLevelCodes = toI18nOptions(enumValues(VAT), "general.finance.vat", this)

    if (this.value && Object.keys(this.value).length > 0) {
      this.money = this.value
    } else {
      this.money = {
        amountWithTax: 0.0,
        amountWithoutTax: 0.0,
        taxLevelCode: "VAT_0",
        currency: "EUR"
      } as MoneyWithTax
    }
    this.emit()
  }

  get amountWithTax() {
    return this.value?.amountWithTax?.toString()
  }

  set amountWithTax(amount: string | undefined) {
    if (amount) {
      this.money.amountWithTax = parseFloat(amount)
      this.emit()
    }
  }

  get currency() {
    return this.value?.currency || "EUR"
  }

  set currency(currency: string) {
    this.money.currency = currency
    this.emit()
  }

  get taxLevelCode() {
    return this.value?.taxLevelCode || "VAT_0"
  }

  set taxLevelCode(taxLevelCode: string) {
    this.money.taxLevelCode = taxLevelCode
    this.emit()
  }

  private emit() {
    this.$emit("input", this.money)
  }
}
