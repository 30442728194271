
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import PersonSearchInput from "@/components/general/PersonSearchInput.vue";

@Component({
  components: {PersonSearchInput}
})
export default class PersonSearchField extends mixins(Vue, abstractField) {
  get allowUnexisting() {
    // @ts-ignore
    return this.schema.allowUnexisting || false
  }

  get allowSave() {
    // @ts-ignore
    return this.schema.allowSave || false
  }
}
