
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import LocationEditComponent from "@/components/location/LocationEditComponent.vue";

@Component({
  components: {LocationEditComponent}
})
export default class HereAddressLookupField extends mixins(Vue, abstractField) {
}
