<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
      :class="'depth-' + depth.toString()"
      class="rules-group-card vqb-group mt-2"
      v-if="query"
  >
    <div class="vqb-group-heading bg-light p-2 d-flex flex-row justify-content-between">
      <div class="d-flex flex-row">
        <div class="w-100 d-flex">
          <label
              class="text-nowrap mt-2 mr-2"
              for="vqb-match-type"
          >
            {{ $t('general.rules.match_type.label') }}
          </label>

          <select
              id="vqb-match-type"
              v-model="query.logicalOperator"
              class="form-control"
          >
            <option
                v-for="label in labels.matchTypes"
                :key="label.id"
                :value="label.id"
            >
              {{ $t(`general.rules.match_type.types.${label.label}`) }}
            </option>
          </select>

          <b-form-checkbox
              class="my-auto ml-3"
              v-model="query.inverted"
              value="true"
              unchecked-value="false"
          >
            <font-awesome-icon icon="not-equal" class="text-muted"/>
          </b-form-checkbox>

        </div>
      </div>

      <button
          v-if="depth > 1"
          type="button"
          class="close ml-auto"
          @click="remove"
          v-html="labels.removeGroup"
      >
      </button>
    </div>

    <div class="vqb-group-body ml-3 mt-2">
      <div class="d-flex flex-row justify-content-between mt-2 rule-actions form-inline">
        <div class="d-flex flex-row">
          <select
              v-model="selectedRule"
              class="form-control mr-2"
          >
            <option
                v-for="rule in rules"
                :key="rule.id"
                :value="rule"
            >
              {{ $t(`general.rules.rule.${rule.id}`) }}
            </option>
          </select>
          <button
              type="button"
              class="btn btn-secondary mr-2"
              @click="addRule"
          >
            <font-awesome-icon icon="plus"/>
          </button>
        </div>

        <div>

          <div class="btn-group btn-group-sm mr-2" v-if="depth === 1" role="group">
            <button type="button" class="btn btn-primary" @click="copy(query)" v-b-tooltip.hover :title="copyMessage">
              <font-awesome-icon icon="copy"/>
            </button>
            <button type="button" class="btn btn-primary" @click="paste" v-b-tooltip.hover :title="pasteMessage">
              <font-awesome-icon icon="paste"/>
            </button>
          </div>

          <div v-if="depth < maxDepth" class="btn-group btn-group-sm" role="group">
            <button type="button" class="btn btn-primary" @click="addGroup">
              <font-awesome-icon icon="plus"/>
              {{ $t('general.rules.group') }}
            </button>
            <button v-if="federationRef" type="button" class="btn btn-primary" @click="openTemplateModal">
              <font-awesome-icon icon="plus"/>
              {{ $t('general.rules.template') }}
            </button>
          </div>
        </div>
      </div>
      <eqify-query-builder-children v-bind="$props"/>
      <eqify-query-builder-federation-template-modal ref="template-modal" @selected="addTemplate"/>
    </div>
  </div>
</template>

<script>
//@ts-ignore
import QueryBuilderGroup from "vue-query-builder/src/layouts/Bootstrap/BootstrapGroup.vue";
//@ts-ignore
import EqifyQueryBuilderRule from "@/components/rules/custom/EqifyQueryBuilderRule";
import EqifyQueryBuilderFederationTemplateModal
  from "@/components/rules/custom/EqifyQueryBuilderFederationTemplateModal";
import EqifyQueryBuilderChildren from "@/components/rules/EqifyQueryBuilderChildren";

export default {
  name: "QueryBuilderGroup",
  components: {
    EqifyQueryBuilderChildren,
    EqifyQueryBuilderFederationTemplateModal,
    // eslint-disable-next-line vue/no-unused-components
    'QueryBuilderRule': EqifyQueryBuilderRule
  },
  props: {
    federationRef: String
  },
  data: function() {
    return {
      copyMessage: this.$t('general.copy'),
      pasteMessage: this.$t('general.paste')
    }
  },
  extends: QueryBuilderGroup,
  methods: {
    openTemplateModal: function () {
      this.$refs['template-modal'].show(this.federationRef)
    },
    addTemplate: function (template) {
      this.$emit('update:query', template);
    },
    copy: function(toCopy) {
      this.pasteMessage = this.$t('general.paste')
      localStorage.setItem("eqify_rule_group_copy", JSON.stringify(toCopy))
      this.copyMessage = this.$t('general.copied')
    },
    paste: function () {
      this.copyMessage = this.$t('general.copy')
      const rawItemToPaste = localStorage.getItem("eqify_rule_group_copy")
      const itemToPaste = JSON.parse(rawItemToPaste)
      if (itemToPaste !== undefined && itemToPaste !== "") {
        this.$emit('update:query', itemToPaste);
        this.pasteMessage = this.$t('general.pasted')
      }

    }
  }
}

</script>