
import {Prop, Ref, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import {BModal} from "bootstrap-vue";

@Component({
  name: 'eqify-rule-group-viewer',
  components: {}
})
export default class EqifyRuleGroupViewer extends Vue {
  @Prop() group?: Object

  @Ref("modal")
  private modal?: BModal = undefined

  openRulesModal() {
    this.modal!!.show()
  }
}
