
import {Component, Prop, Vue} from "vue-property-decorator";
import InfiniteScrollTrigger from "@/components/InfiniteScrollTrigger.vue";

@Component({
    components: {InfiniteScrollTrigger}
})
export default class InfiniteScrollList<T> extends Vue {
    @Prop({type: Function}) requestDataCallback!: RequestDataCallback<T>

    private response = this.emptyResponse()
    private items: Array<any> = []

    mounted() {
        this.response = this.emptyResponse()
    }

    emptyResponse() {
        return {
            items: [],
            page: INITIAL_PAGE,
            size: 0,
            numberOfElements: 0,
            totalElements: 0,
        } as PagedResponse<T>
    }

    initialLoad() {
        this.items = []
        this.$loader.load(async () => {
            const response = await this.requestDataCallback(0)
            this.handleResponse(response)
            this.loadMore()
        }, "calendar::group::load")
    }

    loadMore() {
        if (!this.response || this.response.page == INITIAL_PAGE) {
            return
        }
        this.$loader.load(async () => {
            if (this.items.length < this.response.totalElements) {
                const response = await this.requestDataCallback(this.response.page + 1)
                this.handleResponse(response)
            }
        }, "calendar::group::loadmore")
    }

    private handleResponse(response: PagedResponse<T>) {
        this.items = [...this.items, ...response.items]
        this.response = response
    }
}

const INITIAL_PAGE = -1

export type RequestDataCallback<T> = (page?: number) => Promise<PagedResponse<T>>

export interface PagedResponse<T> {
    page: number
    size: number
    numberOfElements: number
    totalElements: number
    items: Array<T>
}

export function emptyPagedResponse<T>() {
    return {
        page: 0,
        size: 0,
        numberOfElements: 0,
        totalElements: 0,
        items: []
    } as PagedResponse<T>
}

