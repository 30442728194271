
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import HorseSearchInput from "@/components/general/HorseSearchInput.vue";

@Component({
  components: {HorseSearchInput}
})
export default class HorseSearchField extends mixins(Vue, abstractField) {

  get baseUri() {
    //@ts-ignore
    return this.schema.baseUri || undefined
  }

  get allowUnexisting() {
    // @ts-ignore
    return this.schema.allowUnexisting || false
  }

  get allowSave() {
    // @ts-ignore
    return this.schema.allowSave || false
  }
}
