
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import ConfirmDeleteDialog from "@/components/general/buttons/ConfirmDeleteDialog.vue";

@Component({
  components: {ConfirmDeleteDialog}
})
export default class DeleteButton extends Vue {
  @Prop({default: "sm"}) public size?: string;
  @Prop({default: "link-danger"}) public variant?: string;
  @Prop({default: false, type: Boolean}) withText!: Boolean;
  @Prop({default: false, type: Boolean}) isLoading!: Boolean;
  @Prop({default: false, type: Boolean}) disabled!: Boolean;
  @Ref("dialog") dialog?: ConfirmDeleteDialog

  showDialog() {
    this.dialog!.showDialog()
  }

  get textClass() {
    if (this.variant == 'link-danger') {
      return "text-danger"
    } else {
      return ""
    }
  }
}
