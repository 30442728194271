import {RefWithName} from "@/utils/Utils";
import {GeoLocation} from "@/shared/domain/Address";
import {MoneyWithTax} from "@/shared/domain/Finance";
import {DateUtils} from "@/utils/DateUtils";

export type CourseEventRef = string;

export interface CourseEvent {
    ref?: string,
    title?: string,
    description?: string,
    money?: MoneyWithTax,
    maxRegistrations?: number
    courseRef?: RefWithName,
    days: Array<CourseDayEvent>,
    rules: any
}

export const initEmptyCourseEvent = () => {
    const form: CourseEvent = {
        ref: "",
        title: "",
        money: {} as MoneyWithTax,
        courseRef: undefined,
        days: [],
        rules: ""
    }
    return form
}

export interface CourseDayEvent {
    ref?: string,
    courseDay: number,
    day?: Date,
    start?: string,
    end?: string,
    location?: GeoLocation
}

export const initEmptyCourseDayEvent = () => {
    const form: CourseDayEvent = {
        ref: "",
        courseDay: 1,
        start: "",
        end: "",
        location: undefined
    }
    return form
}

export interface CourseDayEventListItem {
    ref: string,
    title: string,
    course: CourseDetail,
    courseDay: number,
    start: string,
    end: string,
    location?: GeoLocation,
    courseEventRef?: string,
    userIsRegistered: boolean
}

export interface CourseDetail {
    title: string,
    achievement: string,
    description?: string
}

export interface CourseEventProfile {
    ref?: string,
    federationRef: string,
    title?: string,
    course?: CourseDetail,
    description?: string,
    money?: MoneyWithTax,
    avatar?: string;
    days: Array<CourseDayEventListItem>,
    registrationCount: number,
    maxRegistrations: number,
    userIsRegistered: boolean
}

export interface CourseRegistration {
    ref: string,
    person: PersonLimitedResource,
    date?: string,
    financeTransactionHasBeenPaid: boolean,
    remarks?: string,
}

export interface CourseAttendance {
    registrationRef: string,
    person: PersonLimitedResource,
    attendances: Array<CourseDayAttendance>
}

export interface CourseResult {
    registrationRef: string,
    person: PersonLimitedResource,
    result: ResultResource,
    date: Date
}

export const initEmptyCourseResult = () => {
    const form: CourseResult = {
        registrationRef: "",
        person: initEmptyPersonLimitedResource(),
        result: initEmptyResultResource(),
        date: DateUtils.now()
    }
    return form
}

export interface ResultResource {
    achievementName: string,
    score?: number,
    achieved: boolean,
    notes?: string,
    date?: string
}

export const initEmptyResultResource = () => {
    const form: ResultResource = {
        achievementName: "",
        achieved: false
    }
    return form
}

export const initEmptyCourseAttendance = () => {
    const form: CourseAttendance = {
        registrationRef: "",
        person: initEmptyPersonLimitedResource(),
        attendances: []
    }
    return form
}

export interface CourseDayAttendance {
    ref: string,
    courseDay: string,
    date: string,
    present: string
}

export interface PersonLimitedResource {
    ref: string,
    name: string,
    firstname: string,
    lastname: string,
    avatar: string
}

export const initEmptyPersonLimitedResource = () => {
    const form: PersonLimitedResource = {
        ref: "",
        name: "",
        firstname: "",
        lastname: "",
        avatar: ""
    }
    return form
}