
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {RefWithName, toI18nOptions} from "@/utils/Utils";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import {
  accountValidator,
  articlesValidator,
  organisationValidator,
  personValidator, refByNameValidator, splitValidator
} from "@/components/form/Validators";
import {BModal} from "bootstrap-vue";
import {AccountRefWithName} from "@/shared/domain/Account";
import {
  ContactType,
  PriceProfileSplitRevenue,
  PriceProfileSplitRevenueType,
  SplitValue,
  SplitValueType
} from "@/shared/domain/General";
import SplitRevenueComponent from "@/components/priceprofile/SplitRevenueComponent.vue";
import {accountService} from "@/services/AccountService";
import {reactive} from "vue";

@Component({
  components: {SplitRevenueComponent}
})
export default class AddSplitRevenueModal extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() federationRef!: string
  @Prop() type!: PriceProfileSplitRevenueType

  @Prop({default: []}) articles?: Array<RefWithName>

  @Prop() maxPercentage!: number
  @Prop() maxAbsolute!: number

  @Ref("modal")
  modal!: BModal

  private isEdit = false

  private formSchema = {}
  private form: Form = this.emptyForm()
  private formValid = false
  private formOptions = {
    validateAfterLoad: true,
    validateAfterChanged: true
  };

  show() {
    this.isEdit = false
    this.form = this.emptyForm()
    this.modal.show()
  }

  showEdit(sr: PriceProfileSplitRevenue) {
    this.isEdit = true
    this.form = {
      ref: sr.ref,
      article: sr.article,
      account: sr.account,
      split: sr.split
    }
    this.modal.show()
  }
  private emptyForm(): Form {
    return {
      split: {
        value: 0.0,
        type: SplitValueType.ABSOLUTE
      },
      article: undefined,
      account: undefined
    } as Form
  }

  private save() {
    if  (this.isEdit) {
      this.$emit("update", {...this.form, type: this.type,})
    } else {
      this.$emit("selected", {
        ref: undefined,
        type: this.type,
        split: this.form.split,
        account: this.form.account,
        article: this.form.article
      } as PriceProfileSplitRevenue)
    }
    this.modal.hide()
  }

  formValidated(isValid: boolean) {
    this.formValid = isValid
  }

  created() {
    this.formSchema = {
      fields: [
        {
          type: 'searchAccount',
          label: "Account",
          model: 'account',
          styleClasses: 'col-12 pr-0 pl-0',
          required: true,
          validator: accountValidator
        },
        {
          styleClasses: 'col-12 pr-0 pl-0',
          type: 'splitValueInput',
          label: 'Waarde',
          maxPercentage: this.maxPercentage,
          maxAbsolute: this.maxAbsolute,
          model: 'split',
          validator: splitValidator
        },
        {
          type: "select",
          label: "Artikel",
          model: 'article',
          required: true,
          selectOptions: {
            name: "name",
            value: "value"
          },
          values: () => this.articles?.map(it => {
            return {
              name: it.name,
              value: it
            }
          }) || [],
          styleClasses: 'col-12 pr-0 pl-0',
          validator: articlesValidator
        }
      ]
    }
  }
}

export class Form {
  ref?: string
  account?: AccountRefWithName
  article?: RefWithName
  split?: SplitValue
}

