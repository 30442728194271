import Vue from "vue";
import VueFormGenerator from "vue-form-generator";
import 'vue-form-generator/dist/vfg.css'
import CodeMirrorField from "@/components/form/CodeMirrorField.vue";
import HereAddressLookupField from "@/components/form/AddressField.vue";
import HorseSearchField from "@/components/form/HorseSearchField.vue";
import StudBookSearchField from "@/components/form/StudBookSearchField.vue";
import PersonSearchField from "@/components/form/PersonSearchField.vue";
import MemberSearchField from "@/components/form/MemberSearchField.vue";
import OrganisationSearchField from "@/components/form/OrganisationSearchField.vue";
import FederationSelectField from "@/components/form/FederationSelectField.vue";
import DatePickerField from "@/components/form/DatePickerField.vue";
import VueSelectField from "@/components/form/VueSelectField.vue";
import TimePickerField from "@/components/form/TimePickerField.vue";
import QuillEditorField from "@/components/form/QuillEditorField.vue";
import CheckBoxGroupField from "@/components/form/CheckBoxGroupField.vue";
import PlaceholderField from "@/components/form/PlaceholderField.vue";
import CurrencyField from "@/components/form/CurrencyField.vue";
import CountrySelectField from "@/components/form/CountrySelectField.vue";
import VMaskField from "@/components/form/VMaskField.vue";
import PikadayField from "@/components/form/PikadayField.vue";
import LicenseSearchField from "@/components/form/LicenseSearchField.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import UserGroupMemberSearch from "@/modules/federation/components/UserGroupMemberSearch.vue";
import CheckBoxSwitch from "@/components/form/CheckBoxSwitch.vue";
import ArticlesSearchField from "@/components/form/ArticlesSearchField.vue";
import AchievementSearchField from "@/components/form/AchievementSearchField.vue";
import DayCountField from "@/components/form/DayCountField.vue";
import CourseSearchField from "@/components/form/CourseSearchField.vue";
import PersonRegistrationCandidateSearchField from "@/components/form/PersonRegistrationCandidateSearchField.vue";
import HtmlReadOnlyField from "@/components/form/HtmlReadOnlyField.vue";
import SelectWithInfoModalField from "@/components/form/SelectWithInfoModalField.vue";
import EqifyRuleBuilderField from "@/components/form/EqifyRuleBuilderField.vue";
import MoneyInputField from "@/components/form/MoneyInputField.vue";
import IbanInputField from "@/components/form/IbanInputField.vue";
import BicInputField from "@/components/form/BicInputField.vue";
import RenewalField from "@/components/form/RenewalInputField.vue";
import LicenseSelectField from "@/components/form/LicenseSelectField.vue";
import ArticleSelectField from "@/components/form/ArticleSelectField.vue";
import CareerCategoryCriteriaFormField from "@/modules/federation/views/career/CareerCategoryCriteriaFormField.vue";
import AccountSelectorField from "@/components/form/AccountSelectorField.vue";
import CompetitionSearchField from "@/components/form/CompetitionSearchField.vue";
import SplitRevenueField from "@/components/form/SplitRevenueField.vue";
import AccountSearchField from "@/components/form/AccountSearchField.vue";
import SplitValueInput from "@/components/general/split/SplitValueInput.vue";
import SplitValueInputField from "@/components/form/SplitValueInputField.vue";
import SearchAccountField from "@/components/form/SearchAccountField.vue";

Vue.component("fieldCodeMirror", CodeMirrorField);
Vue.component("fieldHereAddressLookup", HereAddressLookupField);
Vue.component("fieldHorseSearch", HorseSearchField);
Vue.component("fieldStudBookSearch", StudBookSearchField);
Vue.component("fieldPersonSearch", PersonSearchField);
Vue.component("fieldMemberSearch", MemberSearchField);
Vue.component("fieldOrganisationSearch", OrganisationSearchField);
Vue.component("fieldLicenseSearch", LicenseSearchField);
Vue.component("fieldFederationSelect", FederationSelectField);
Vue.component("fieldDatePicker", DatePickerField);
Vue.component('fieldVueSelect', VueSelectField);
Vue.component("fieldTimePicker", TimePickerField);
Vue.component("fieldQuillEditor", QuillEditorField);
Vue.component('fieldCheckBoxGroup', CheckBoxGroupField);
Vue.component('fieldPlaceholder', PlaceholderField);
Vue.component('fieldCurrency', CurrencyField);
Vue.component('fieldPassword', PasswordField);
Vue.component('fieldCountrySelect', CountrySelectField);
Vue.component('fieldMask', VMaskField);
Vue.component('fieldPickaday', PikadayField);
Vue.component('fieldUserGroupMemberSearch', UserGroupMemberSearch);
Vue.component('fieldCheckboxSwitch', CheckBoxSwitch);
Vue.component('fieldArticlesSearch', ArticlesSearchField);
Vue.component("fieldAchievementsSearch", AchievementSearchField);
Vue.component("fieldDayCount", DayCountField);
Vue.component("fieldCourseSearch", CourseSearchField);
Vue.component("fieldPersonRegistrationCandidateSearch", PersonRegistrationCandidateSearchField);
Vue.component("fieldHtmlReadOnly", HtmlReadOnlyField)
Vue.component("fieldSelectWithInfoModal", SelectWithInfoModalField)
Vue.component("fieldEqifyRuleBuilder", EqifyRuleBuilderField)
Vue.component("fieldMoneyInput", MoneyInputField)
Vue.component("fieldIbanInput", IbanInputField)
Vue.component("fieldBicInput", BicInputField)
Vue.component("fieldRenewalInput", RenewalField)
Vue.component("fieldLicenseSelect", LicenseSelectField)
Vue.component("fieldArticleSelect", ArticleSelectField)
Vue.component("fieldCareerCategoryCriteria", CareerCategoryCriteriaFormField)
Vue.component("fieldAccountSelector", AccountSelectorField)
Vue.component("fieldCompetitionSearch", CompetitionSearchField)
Vue.component("fieldSplitRevenue", SplitRevenueField)
Vue.component("fieldAccountSearch", AccountSearchField)
Vue.component("fieldSplitValueInput", SplitValueInputField)
Vue.component("fieldSearchAccount", SearchAccountField)
Vue.use(VueFormGenerator);
