
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyAvatar from "@/components/general/EqifyAvatar.vue";

@Component({
  components: {EqifyAvatar}
})
export default class EqifyAvatarAndText extends Vue {
  @Prop() public url?: string;
  @Prop() public text?: string;
  @Prop() public icon?: string;
  @Prop() public size?: string;
  @Prop({type: Boolean}) public onlyIcon?: boolean;
}
