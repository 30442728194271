import {RouteConfig} from "vue-router";
import {createAnyLoggedInUserRoute, createSecureReadOnlyRouteMetadata} from "@/router";

const ROOT = '/championships';
const routes: Array<RouteConfig> = [
    {
        path: ROOT,
        component: () => import(/* webpackChunkName: "module-championship" */ "@/modules/championship/views/ChampionshipCalendarView.vue"),
    },
    {
        path: ROOT + '/create',
        component: () => import(/* webpackChunkName: "module-championship" */ "@/modules/championship/views/ChampionshipSave.vue"),
    },
    {
        path: ROOT + '/:championship',
        component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ChampionshipProfileLayout.vue"),
        children: [
            {
                path: 'calendar',
                component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ChampionshipCompetitionCalendarView.vue"),
                meta: createAnyLoggedInUserRoute(),
            },
            {
                path: 'info',
                component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ChampionshipInfoView.vue"),
                meta: createAnyLoggedInUserRoute(),
            },
            {
                path: '/',
                redirect: 'ranking',
                component: () => import(/* webpackChunkName: "module-championship" */ "@/modules/championship/views/public/ranking/RankingView.vue"),
                meta: createAnyLoggedInUserRoute(),
                children: [
                    {
                        path: "ranking",
                        component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ranking/SportTypeVariantList.vue"),
                        meta: createAnyLoggedInUserRoute(),
                    },
                    {
                        path: "ranking/sport-category/:sportCategoryRef",
                        component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ranking/RankingSportCategoryView.vue"),
                        meta: createAnyLoggedInUserRoute(),
                    },
                    {
                        name: "public_championship_ranking",
                        path: "ranking/sport-category/:sportCategoryRef/type/:targetType/target/:targetRef",
                        component: () => import(/* webpackChunkName: "module-championship-profile" */ "@/modules/championship/views/public/ranking/ChampionshipRankingView.vue"),
                        meta: createAnyLoggedInUserRoute(),
                    }
                ]
            }
        ]
    },
    {
        path: ROOT + '/:championship/manage',
        component: () => import(/* webpackChunkName: "module-championship-profile-manage" */ "@/modules/championship/views/manage/ManageChampionshipProfileLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-championship-profile-manage" */ "@/modules/championship/views/manage/ManageChampionshipLayoutView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'info',
                component: () => import(/* webpackChunkName: "module-championship-profile-manage" */ "@/modules/championship/views/manage/ManageChampionshipInfoView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            }
        ]
    }
];

export default {
    routes: routes
};
