
import {Component, Prop, Vue} from 'vue-property-decorator';
import PageTitle from "@/components/PageTitle.vue";
import {FederationUserInfo} from "@/shared/domain/User";
import {PageAction} from "@/components/PageActionMixin";
import EqifyLoader from "@/components/EqifyLoader.vue";

@Component({
  components: {EqifyLoader, PageTitle}
})
export default class EqifyPage extends Vue {
  @Prop() containerClass?: string
  @Prop({default: false, type: Boolean}) fluid?: boolean
  @Prop() title?: string
  @Prop() subtitle?: string
  @Prop() federation?: FederationUserInfo
  @Prop({default: false, type: Boolean}) loading?: boolean
  @Prop({type: Boolean}) public noTitleText!: boolean;
  @Prop({
    default: () => {
      []
    }, type: Array
  }) public actions?: Array<PageAction>;

  get _containerClass() {
    return "position-relative " + (this.containerClass ? this.containerClass : "p-2")
  }

}
