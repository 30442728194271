
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {emptyEntityStateResource, EntityStateResource} from "@/shared/domain/EntityState";
import PublishDialog from "@/components/general/buttons/PublishDialog.vue";

@Component({
  components: {PublishDialog}
})
export default class PublishButton extends Vue {
  @Prop({default: "sm"}) public size?: string;
  @Prop({default: () => emptyEntityStateResource(), type: Object}) public value!: EntityStateResource;
  @Ref("dialog") dialog?: PublishDialog

  showDialog() {
    this.dialog!.showDialog()
  }
}
