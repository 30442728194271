
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class EqifyRuleComponent extends Vue {
  @Prop({ default: true }) required?: boolean
  @Prop({ default: true }) withInverted?: boolean
  @Prop({ default: false }) inverted?: boolean

  get requiredValue(): boolean {
    return this.required || false
  }

  set requiredValue(val: boolean) {
    this.$emit("update:required", val)
  }

  get invertedValue(): boolean {
    return this.inverted || false
  }

  set invertedValue(val: boolean) {
    this.$emit("update:inverted", val)
  }
}
