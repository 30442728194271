import {
    CompetitionDetailContacts,
    CompetitionOrganizer,
    CompetitionRef,
    CompetitionRegistrationSettings
} from "@/modules/competition/service/CompetitionService";
import {httpService} from "@/services/HttpService";
import {RefWithName} from "@/utils/Utils";
import Vue from "vue";
import {SportResultDataType, TestResult} from "@/components/competition/SportResult";
import {SportTypeVariant} from "@/modules/federation/services/SportTypeVariantService";
import {CompetitionStatus} from "@/modules/competition/service/ManageCompetitionService";
import {CompetitionPractitioner} from "@/shared/domain/CompetitionPractitioner";
import {SportCategory} from "@/modules/competition/service/CompetitionTestsService";
import {Practitioner} from "@/shared/domain/Practitioner";
import {RankingField} from "@/modules/competition/service/ManageRankingService";
import {ChampionshipPractitioner} from "@/shared/domain/ChampionshipPractitioner";
import {PersonFavorite} from "@/shared/domain/User";

class PublicCompetitionService {

    saveAsFavorite(ref: CompetitionRef): Promise<PersonFavorite> {
        return httpService.post(`/public/competition/${ref}/favorite`, {})
    }

    public removeAsFavorite(ref: string): Promise<void> {
        return httpService.delete(`/sidebar/${ref}`)
    }

    getCompetition(ref: CompetitionRef): Promise<PublicCompetition> {
        return httpService.get(`/public/competition/${ref}`,)
    }

    getSecurity(ref: CompetitionRef): Promise<PublicCompetitionSecurityData> {
        return httpService.get(`/public/competition/${ref}/security`)
    }

    getTimeSchedule(competition: CompetitionRef): Promise<PublicTimeSchedule | undefined> {
        return httpService.get(`/public/competition/${competition}/timeschedule`,)
    }

    getStartlist(competition: CompetitionRef, activity: string): Promise<PublicActivity> {
        return httpService.get(`/public/competition/${competition}/activities/${activity}/startlist`,)
    }

    getChampionshipRanking(competition: CompetitionRef, ranking: string): Promise<PublicChampionshipRanking> {
        return httpService.get(`/public/competition/${competition}/activities/${ranking}/championship-ranking`,)
    }

    getCompetitionRanking(competition: CompetitionRef, ranking: string): Promise<PublicCompetitionRanking> {
        return httpService.get(`/public/competition/${competition}/activities/${ranking}/competition-ranking`,)
    }

    getCompetitionMasterlistDownloadUrl(competition: CompetitionRef): Promise<string | undefined> {
        return httpService.get(`/public/competition/${competition}/masterlist-download-url`)
    }
}

export interface PublicCompetitionSecurityData {
    canManage: boolean
}

export interface PublicCompetition {
    ref: CompetitionRef;
    federation: RefWithName,
    championship?: RefWithName,
    name: string;
    organizer: CompetitionOrganizer;
    avatar?: string;
    poster?: string;
    start?: string;
    end?: string;
    description?: string;
    sportTypes: Array<SportTypeVariant>;
    status: CompetitionStatus;
    remarksVisitors?: string;
    remarksVip?: string;
    remarksTrucks?: string;
    remarksTrailers?: string;
    subscriptionSettings: CompetitionRegistrationSettings;
    location?: Geolocation;
    locationInstructions?: string;
    contacts?: CompetitionDetailContacts;
    riderCount: number;
    horseCount: number;
    organisationCount: number;
    sportTypeCount: number;
    dayCount: number;
    live: boolean;
    startlistsDownloadUrl?: string;
}

export interface PublicTimeSchedule {
    sections: Array<PublicScheduleSection>
}

export enum PublicScheduleSectionType {
    GENERAL = "GENERAL",
    GENERAL_RANKING = "GENERAL_RANKING",
    DAY = "DAY",
    ARENA = "ARENA",
    SPORT = "SPORT",
    BREAK = "BREAK",
    TEXT = "TEXT",
    PARTICIPATION = "PARTICIPATION",
    CHAMPIONSHIP_RANKING = "CHAMPIONSHIP_RANKING"
}

// applies to general and day
export interface PublicScheduleSection {
    type: PublicScheduleSectionType;
    name: string;
    order: number;
}

export interface PublicArenaSection extends PublicScheduleSection {
    code: string;
    ref: string;
    hasActivities: boolean;
}

// applies to break
export interface PublicActivitySection extends PublicScheduleSection {
    ref: string;
    arena: RefWithName;
    date: string;
    start: string;
    end: string;
}

export interface PublicTextSection extends PublicActivitySection {
    description?: string;
    link?: string;
    general: boolean;
}

export interface PublicSportSection extends PublicActivitySection {
    numberOfStartlistEntries: number;
}

export interface PublicChampionshipSection extends PublicActivitySection {
    general: boolean,
    championship: RefWithName,
    championshipPhase: RefWithName,
    championshipGroup: RefWithName,
    championshipSportCategory: SportCategory
}

export interface PublicCompetitionSection extends PublicActivitySection {
    general: boolean
}

export interface PublicActivity {
    ref: string,
    start?: string,
    end?: string,
    arena?: RefWithName,
    name: string,
    hideTime: boolean,
    entries: Array<PublicStartlistEntry>
}

export interface PublicActivityTest {
    test: RefWithName,
    testSet: RefWithName,
    sportTypeVariant: SportTypeVariant,
    protocolName?: string,
    rankings: Array<PublicActivityRanking>
}

export interface PublicActivityRanking {
    ref: string,
    name: string,
    headers: Array<PublicResultHeader>,
    entries: Array<PublicStartlistEntry>
}

export interface PublicResultHeader {
    testResultId: string,
    type: SportResultDataType,
    order: number,
    name: string
}

export interface PublicStartlistEntry {
    type: PublicStartlistEntryType,
    order: number,
    time: string,
    duration: number,
    draft: boolean
}

export interface PublicBreakStartlistEntry extends PublicStartlistEntry {
}

export interface PublicDraggingBreakStartlistEntry extends PublicStartlistEntry {
}

export interface PublicExploreStartlistEntry extends PublicStartlistEntry {
}

export interface PublicOpenPositionStartlistEntry extends PublicStartlistEntry {
    position: number
}

export interface PublicPractitionerStartlistEntry extends PublicStartlistEntry {
    position: number,
    outOfCompetition: boolean,
    practitioner: CompetitionPractitioner,
    rank?: number,
    results: Array<TestResult>
}

export enum PublicStartlistEntryType {
    OPEN_POSITION = "OPEN_POSITION",
    BREAK = "BREAK",
    DRAGGING_BREAK = "DRAGGING_BREAK",
    EXPLORE_BREAK = "EXPLORE_BREAK",
    PARTICIPANT = "PARTICIPANT"
}

export interface PublicChampionshipRanking {
    ref: string,
    name: string,
    championship: RefWithName,
    championshipPhase: RefWithName,
    championshipGroup: RefWithName,
    championshipSportCategory: SportCategory,
    entries: Array<PublicChampionshipRankingEntry>
}

export interface PublicChampionshipRankingEntry {
    rank?: number,
    practitioner: ChampionshipPractitioner,
    points: number
}

export interface PublicCompetitionRanking {
    ref: string,
    name: string,
    entries: Array<PublicCompetitionRankingEntry>
}

export interface PublicCompetitionRankingEntry {
    rank?: number,
    practitioner: Practitioner,
    values: Array<RankingField>
}

export const getActivityRefFromRoute = (vue: Vue): string => {
    return vue.$route.params.activity;
};

export const publicCompetitionService = new PublicCompetitionService();
