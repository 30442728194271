
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import {FederationUserInfo} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";

@Component({
  components: {EqifyAvatarAndText}
})
export default class PageNavbarFederationDropdown extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() federation: FederationUserInfo | undefined;

  canManageForFederation(federation: FederationUserInfo) {
    return this.user!.canManageFederationData(federation.ref!!)
  }

  canForFederation(federation: FederationUserInfo) {
    return this.user!.canManageAndReadFederationData(federation.ref!!)
  }

  hide() {
    this.$emit('hide')
  }
}
