
import {Component, Prop, Vue} from 'vue-property-decorator';
import {emptyEntityStateResource, EntityState, EntityStateResource} from "@/shared/domain/EntityState";

@Component
export default class StateBadge extends Vue {
  @Prop({default: () => emptyEntityStateResource(), type: Object}) public value!: EntityStateResource;

  get published() {
    return this.value.state == EntityState.PUBLISHED
  }
}
