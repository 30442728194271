import {RouteConfig} from "vue-router";
import {createSecureCrudRouteMetadata, createSecureReadOnlyRouteMetadata} from "@/router";

const ROOT = '/myfederation/:federation';
const routes: Array<RouteConfig> = [
    {
        path: ROOT + '/general',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationGeneralView.vue"),
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: ROOT + '/users',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationUserView.vue"),
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: ROOT + '/usergroups',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationUserGroupsView.vue"),
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: ROOT + '/sportcategories',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationSportCategoryView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/sporttypevariants',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationSportTypeVariantView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/careers',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationCareerView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/careers/:career',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationCareerDetailView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/protocols',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationProtocolsView.vue"),
    },
    {
        path: ROOT + '/protocols/dressage/:protocol',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/protocol/dressage/MyFederationDressageProtocolDetailView.vue"),
    },
    {
        path: ROOT + '/protocols/styleriding/:protocol',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/protocol/styleriding/MyFederationStyleRidingProtocolDetailView.vue"),
    },
    {
        path: ROOT + '/protocols/presentation/:protocol',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/protocol/presentation/MyFederationPresentationProtocolDetailView.vue"),
    },
    {
        path: ROOT + '/licenses',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationLicenseView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/member-benefits',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationMemberBenefitView.vue")
    },
    {
        path: ROOT + '/achievements',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationAchievementView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/courses',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationCourseView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/rulestemplate',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationRulesTemplateView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/reporting',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationReportingView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/reporting/detail',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationReportDetailView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/price-profile',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationPriceProfileView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/practitioner-price-profile',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationPractitionerPriceProfileView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/practitioner-group',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationPractitionerGroupView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: ROOT + '/finance',
        component: () => import(/* webpackChunkName: "module-myfederation" */ "@/modules/federation/views/MyFederationFinanceView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    }
];

export default {
    routes: routes
};
