import {ChampionshipRef, CompetitionDetail} from "@/modules/championship/services/PublicChampionshipService";
import {httpService} from "@/services/HttpService";
import {RefWithName} from "@/utils/Utils";
import {Practitioner} from "@/shared/domain/Practitioner";
import {PointManipulator} from "@/modules/championship/services/ChampionshipSelectionService";
import {TestResult} from "@/components/competition/SportResult";

class PublicChampionshipRankingService {
    getRankingMenuLayout(
        ref: ChampionshipRef
    ): Promise<RankingMenu> {
        return httpService.get(`/championship/${ref}/public/ranking/menu`)
    }

    getRanking(
        ref: ChampionshipRef,
        sportCategoryRef: string,
        phaseRef?: string,
        groupRef?: string
    ): Promise<Array<ChampionshipSportCategoryRankingEntry>> {
        return httpService.get(`/championship/${ref}/public/ranking` +
            `?sportCategoryRef=${sportCategoryRef}` +
            `&phaseRef=${phaseRef != null ? phaseRef : ""}` +
            `&groupRef=${groupRef != null ? groupRef : ""}`
        )
    }

    getTopRanking(
        ref: ChampionshipRef,
        sportCategoryRef: string
    ): Promise<ChampionshipRankingTopEntries> {
        return httpService.get(
            `/championship/${ref}/public/ranking/sport-category/${sportCategoryRef}/top`
        )
    }

    getChampionshipPractitionersForGroupAndSportCategory(
        ref: ChampionshipRef,
        sportCategoryRef: string,
        groupRef: string
    ): Promise<SportCategoryChampionshipPractitioners> {
        return httpService.get(
            `/championship/${ref}/public/ranking/sport-category/${sportCategoryRef}/group/${groupRef}`
        )
    }

    getChampionshipPractitionersForPhaseAndSportCategory(
        ref: ChampionshipRef,
        sportCategoryRef: string,
        phaseRef: string
    ): Promise<SportCategoryChampionshipPractitioners> {
        return httpService.get(
            `/championship/${ref}/public/ranking/sport-category/${sportCategoryRef}/phase/${phaseRef}`
        )
    }

    getChampionshipPractitionerInfo(
        ref: ChampionshipRef,
        rankingRef: string,
        practitionerRef: string
    ): Promise<ChampionshipPractitionerPointHistory> {
        return httpService.get(
            `/championship/${ref}/public/ranking/${rankingRef}/practitioner/${practitionerRef}`
        )
    }
}

export interface ChampionshipPractitionerPointHistory {
    championship: RefWithName,
    entries: Array<CompetitionTestPointHistory>
}

export interface CompetitionTestPointHistory {
    competition: CompetitionDetail,
    test: RefWithName,
    points: number,
    rank?: number,
    results: Array<TestResult>
}

export interface SportCategoryChampionshipPractitioners {
    rankingRef?: string,
    sportCategory: RefWithName,
    group?: RefWithName,
    phase?: RefWithName,
    entries: Array<ChampionshipPractitioner>
}

export interface ChampionshipPractitioner {
    practitioner: Practitioner,
    entries: Array<RankingEntryResource>
    isSelected: boolean,
    position?: number,
    _rowVariant?: string,
}

export interface RankingEntryResource {
    ref?: string,
    position?: number,
    order?: number,
    points?: number,
    selectionPoints?: number,
    isSelected: boolean,
    pointManipulator?: PointManipulator,
    phaseRef?: string
}

export interface RankingMenu {
    phases: Array<PhaseRankingMenuItem>
    hasRanking: boolean
}

export const initRankingMenu = () => {
    const data: RankingMenu = {
        phases: [],
        hasRanking: false
    }
    return data
}

export interface PhaseRankingMenuItem {
    ref: string,
    name: string,
    hasRanking: boolean,
    groups: Array<GroupRankingMenuItem>
}

export interface GroupRankingMenuItem {
    ref: string,
    name: string,
    hasRanking: boolean
}

export interface ChampionshipSportCategoryRankingEntry {
    ref: string,
    points: number,
    position: number,
    practitioner: Practitioner,
    selections: Array<ChampionshipSportCategorySelectionEntry>,
    historyEntries: Array<ChampionshipSportCategoryHistoryEntry>
}

export interface ChampionshipSportCategoryHistoryEntry {
    competition: RefWithName,
    test: RefWithName,
    points: number
}

export interface ChampionshipSportCategorySelectionEntry {
    ref: string
    phase?: RefWithName
    group?: RefWithName
    points: number
}

export interface ChampionshipRankingTopEntries {
    championship: RefWithName,
    sportCategory: RefWithName,
    phases: Array<PhaseRankingTopEntries>,
    entries: Array<RankingTopEntry>,
    hasRankingsReady: boolean
}

export const emptyChampionshipRankingTopEntries = () => {
    const data: ChampionshipRankingTopEntries = {
        championship: RefWithName.empty(),
        sportCategory: RefWithName.empty(),
        phases: [],
        entries: [],
        hasRankingsReady: false
    }
    return data
}

export interface PhaseRankingTopEntries {
    phase: RefWithName,
    groups: Array<GroupRankingTopEntries>,
    entries: Array<RankingTopEntry>,
    hasRankingsReady: false
}

export interface GroupRankingTopEntries {
    group: RefWithName,
    entries: Array<RankingTopEntry>
}

export interface RankingTopEntry {
    avatar?: string,
    practitioner: Practitioner,
    position: number,
    order: number,
    points: number,
}

export const publicChampionshipRankingService = new PublicChampionshipRankingService()