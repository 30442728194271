
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import FastNavigationSidebarMixin from "@/modules/sidebar/mixin/FastNavigationSidebarMixin";
import EqifySmallTitle from "@/components/general/titles/EqifySmallTitle.vue";
import EqifyMenuDropdown from "@/components/general/selectable/EqifyMenuDropdown.vue";
import EqifyAvatarAndText from "@/components/general/EqifyAvatarAndText.vue";
import EqifyMenuDropdownItem from "@/components/general/selectable/EqifyMenuDropdownItem.vue";
import {FederationUserInfo} from "@/shared/domain/User";

@Component({
  components: {EqifyMenuDropdownItem, EqifyAvatarAndText, EqifyMenuDropdown, EqifySmallTitle}
})
export default class FederationSidebarNavigation extends mixins(AuthenticatedUserMixin, FastNavigationSidebarMixin, Vue) {

  get title() {
    if (this.user && this.user.organisations && this.user.organisations.length > 1)
      return this.$t('menu.myfederation.multiple')
    else return this.$t('menu.myfederation.main')
  }

  canManageForFederation(federation: FederationUserInfo) {
    return this.user!.canManageFederationData(federation.ref!!)
  }

  canForFederation(federation: FederationUserInfo) {
    return this.user!.canManageAndReadFederationData(federation.ref!!)
  }
}
