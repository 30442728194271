
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import CountrySelectInput from "@/components/general/CountrySelectInput.vue";

@Component({
        components: {CountrySelectInput}
    })
    export default class CountrySelectField extends mixins(Vue, abstractField) {
    }
