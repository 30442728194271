import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    CompetitionDetail,
    CompetitionRef,
    CompetitionSecurityData
} from "@/modules/competition/service/CompetitionService";
import {manageCompetitionService} from "@/modules/competition/service/ManageCompetitionService";

@Module({namespaced: true, name: 'competition-manage'})
export default class CompetitionManageModule extends VuexModule {
    competition: CompetitionDetail = {} as CompetitionDetail
    security: CompetitionSecurityData = {} as CompetitionSecurityData

    @MutationAction
    async loadCompetition(ref: CompetitionRef) {
        const competition = await manageCompetitionService.getCompetition(ref)
        return {competition: competition}
    }

    @MutationAction
    async loadSecurityCodes(ref: CompetitionRef) {
        const data = await manageCompetitionService.getSecurity(ref)
        return {security: data}
    }
}
