
import {Component, Prop, Vue} from "vue-property-decorator";
import {CalendarGroup, CalendarGroupType} from "@/components/calendar/Calendar";
import CalendarGroupView from "@/components/calendar/CalendarGroupView.vue";

@Component({
  components: {CalendarGroupView}
})
export default class CalendarGroupComponent extends Vue {
  @Prop({type: Function}) groupCallback!: RequestGroupCalendarDataCallback
  @Prop() title?: string

  private groupTypesToShow: Array<CalendarGroupType> = this.defaultGroups

  get defaultGroups() {
    return Object.values(CalendarGroupType)
  }

  withInfiniteLoading(type: CalendarGroupType): boolean {
    return type == CalendarGroupType.LATER
  }

  countChange(event: any) {
    this.$emit("count", event)
  }
}

export type RequestGroupCalendarDataCallback = (type: CalendarGroupType, page?: number) => Promise<CalendarGroup>

