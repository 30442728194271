
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import SearchInput from "@/components/general/SearchInput.vue";
import {AccountRefWithName} from "@/shared/domain/Account";
import {ContactType} from "@/shared/domain/General";
import {toI18nOptions} from "@/utils/Utils";
import {organisationValidator, personValidator} from "@/components/form/Validators";
import {accountService} from "@/services/AccountService";

@Component({
  components: {SearchInput}
})
export default class AccountSearchInput extends Vue {
  @Prop() value?: AccountRefWithName;

  private formValid: boolean = false;
  private formSchema = {fields: [] as Array<any>};
  private form = {} as Form
  private formOptions = {
    validateAfterLoad: false,
    validateAfterChanged: true
  };

  private accounts: Array<AccountRefWithName> = []

  get contactTypes() {
    return [ContactType.ORGANISATION, ContactType.PERSON]
  }

  get types(): Array<ContactType> {
    const types = [
      ContactType.ORGANISATION,
      ContactType.PERSON
    ]
    return types
  }

  created() {
    this.formSchema = {
      fields: [
        {
          type: 'vueSelect',
          label: this.$t('finance.bill.contact.type.name'),
          model: 'type',
          values: toI18nOptions(this.types, 'finance.bill.contact.type', this),
          onChanged: (model: Form, val: any) => {
            model.contact = undefined
            model.account = undefined
          },
          styleClasses: 'col-12 pr-0 pl-0',
          required: true,
          validator: "string"
        },
        {
          type: 'organisationSearch',
          label: this.$t('finance.bill.contact.title'),
          model: 'contact',
          visible: (model: Form) => {
            return model && model.type === ContactType.ORGANISATION
          },
          onChanged: (model: Form, val: any) => {
            if (val) {
              this.fetchAccountsForContact(val.ref)
              model.contact = {
                ref: val.ref,
                name: val.name,
                type: ContactType.ORGANISATION
              }
            } else {
              model.account = undefined
              model.contact = undefined
              this.accounts.length = 0
            }
          },
          styleClasses: 'col-12 pr-0 pl-0',
          validator: organisationValidator
        },
        {
          type: 'personSearch',
          label: this.$t('finance.bill.contact.title'),
          model: "contact",
          visible: (model: Form) => {
            return model && model.type === ContactType.PERSON
          },
          onChanged: (model: Form, val: any) => {
            if (val) {
              this.fetchAccountsForContact(val.ref)
              model.contact = {
                ref: val.ref,
                name: val.name,
                type: ContactType.PERSON
              }
            } else {
              model.account = undefined
              model.contact = undefined
              this.accounts.length = 0
            }
          },
          styleClasses: 'col-12 pr-0 pl-0',
          validator: personValidator
        },
        {
          type: 'select',
          label: "Account",
          model: 'account',
          required: false,
          values: () => this.accounts.map(it => {
            return {
              name: it.name,
              value: it
            }
          }),
          selectOptions: {
            name: "name",
            value: "value",
          },
          visible: (model: Form) => {
            return this.accounts.length > 0
          }
        },
      ]
    }
  }

  @Watch("form.account")
  onFormAccountChange() {
    this.$emit("input", this.form.account)
  }

  private async fetchAccountsForContact(contactRef: string) {
    this.accounts = await accountService.getAccounts(contactRef)
    if (this.accounts.length > 0) {
      this.form.account = this.accounts[0]
    }
  }

  formValidated(isValid: boolean, errors: Array<string>) {
    this.formValid = isValid;
  }
}

export class Form {
  type?: ContactType = ContactType.ORGANISATION
  contact?: any = undefined
  account?: AccountRefWithName
}
