import {HorseName, OrganisationName, PersonName, RiderName} from "@/shared/domain/CompetitionPractitioner";
import {FederationName} from "@/shared/domain/General";

export enum PractitionerType {
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM',
    HORSE = 'HORSE'
}

export interface Practitioner {
    type: PractitionerType;
    ref: string;
    federation: FederationName;
    active: boolean;
    externalId?: string;
    avatar?: string
}

export interface IndividualCombination extends Practitioner {
    person: RiderName,
    horse: HorseName,
    organisation?: OrganisationName,
}

export interface HorseOnly extends Practitioner {
    presenter?: PersonName,
    horse: HorseName,
    organisation?: OrganisationName,
}

export interface Team extends Practitioner {
    name: string,
    organisation?: OrganisationName,
    nationality?: string
}