import {Vue} from "vue-property-decorator";
import _ from "lodash";
import {AvatarUrl} from "@/shared/domain/General";

export const groupBy = (key: any) => (array: any) =>
    //@ts-ignore
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, []);

export const enumValues = <T>(enumType: {
    [key: string]: T
}): T[] => Object.values(enumType).filter(x => typeof x === "string")

export const toI18nOptions = <T>(values: Array<T>, i18nPrefix: string, vue: Vue, i18Suffix: string = ""): Array<Option<T>> => {
    const suffix = i18Suffix !== "" ? `.${i18Suffix}` : "";
    return values.map(v => {
        return {
            value: v,
            text: vue.$t(`${i18nPrefix}.${v}${suffix}`).toString()
        }
    })
};

export class Option<V = string> {
    text?: string;
    value?: V;
    group?: string;

    static create<T extends string | undefined>(value: T, text: string, group: string | undefined = undefined): Option<T> {
        return {
            text: text,
            value: value,
            group: group
        } as Option<T>
    }
}

export class RefWithName<R = string> {
    ref?: R;
    name?: string;

    static empty<T = string>(): RefWithName<T> {
        return {} as RefWithName<T>
    }

    static create<T extends string>(ref: T, name: string): RefWithName<T> {
        return {
            ref: ref,
            name: name
        } as RefWithName<T>
    }

    static isEmpty(selected: RefWithName | undefined) {
        return !selected || _.isEmpty(selected.name)
    }

    static isUnexisting(selected: RefWithName | undefined) {
        return !(selected && selected.ref)
    }

    static unexisting(name: string): RefWithName {
        return {ref: undefined, name: name}
    }
}

export interface NameInput {
    ref?: string;
    name?: string;
    save?: boolean;
}


export class RefWithNameAndAvatar<R = string> extends RefWithName<R> {
    avatar?: AvatarUrl;

    static empty<T = string>(): RefWithNameAndAvatar<T> {
        return {} as RefWithNameAndAvatar<T>
    }
}

export function toOption<T>(refWithName: RefWithName<T>): Option<T> {
    return {
        text: refWithName.name,
        value: refWithName.ref
    }
}


export interface Multimap<K, V> {
    clear(): void

    containsKey(key: K): boolean

    containsValue(value: V): boolean

    containsEntry(key: K, value: V): boolean

    delete(key: K, value?: V): boolean

    entries: MultimapEntry<K, V>[]

    get(key: K): V[]

    keys(): K[]

    put(key: K, value: V): MultimapEntry<K, V>[]
}

export class ArrayListMultimap<K, V> implements Multimap<K, V> {

    private _entries: MultimapEntry<K, V>[] = []

    public clear(): void {
        this._entries = []
    }

    public containsKey(key: K): boolean {
        return this._entries
            .filter(entry => entry.key == key)
            .length > 0
    }

    public containsValue(value: V): boolean {
        return this._entries
            .filter(entry => entry.value == value)
            .length > 0
    }

    public containsEntry(key: K, value: V): boolean {
        return this._entries
            .filter(entry => entry.key == key && entry.value == value)
            .length > 0
    }

    public delete(key: K, value?: V): boolean {
        const temp = this._entries
        this._entries = this._entries
            .filter(entry => {
                if (value)
                    return entry.key != key || entry.value != value
                return entry.key != key
            })
        return temp.length != this._entries.length
    }

    public get entries(): MultimapEntry<K, V>[] {
        return this._entries
    }

    public get(key: K): V[] {
        return this._entries
            .filter(entry => entry.key == key)
            .map(entry => entry.value)
    }

    public keys(): K[] {
        return Array.from(new Set(this._entries.map(entry => entry.key)))
    }

    public put(key: K, value: V): MultimapEntry<K, V>[] {
        this._entries.push(new MultimapEntry(key, value))
        return this._entries
    }
}

class MultimapEntry<K, V> {
    constructor(readonly key: K, readonly value: V) {
    }
}


export const pad = (number: number | undefined, size: number) => {
    return _.padStart(number + "", size, "0")
}

export function evaluate<T>(it: T | (() => T)): T {
    if (isFunction(it)) {
        return (it as Function)() as T
    } else {
        return it as T
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isPromise(promise: any): boolean {
    return !isNil(promise) && typeof promise.then === 'function'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isFunction(fun: any): boolean {
    return !isNil(fun) && typeof fun === 'function'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isString(text: any): boolean {
    return !isNil(text) && (typeof text === 'string' || text instanceof String)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isNil(value: any): boolean {
    return value === undefined || value === null
}

export const getCurrencyIcon = (currency: string) => {
    switch (currency) {
        case "EUR": {
            return '€'
        }
        case "USD": {
            return '$'
        }
        default: {
            return '€'
        }
    }
}
