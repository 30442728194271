
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Avatar} from "vue-avatar";
import AvatarUtils from "@/utils/AvatarUtils";
import {PersonSearchResultItem} from "@/shared/domain/Person";

@Component({
  components: {Avatar}
})
export default class PersonListItem extends Vue {
  @Prop() person?: PersonSearchResultItem;
  @Prop() suggestion?: string;

  avatarName(name: string) {
    return AvatarUtils.avatarName(name)
  }

  get isYou() {
    return this.person && this.$keycloak.userName == this.person.ref
  }
}
