import {CompetitionDetail} from "@/modules/competition/service/CompetitionService";
import {httpService} from "@/services/HttpService";
import {GeoLocation} from "@/shared/domain/Address";
import {ChampionshipRef, ChampionshipStatus} from "@/modules/championship/services/PublicChampionshipService";
import {CourseDayEvent} from "@/modules/course/service/CourseEvent";
import {CalendarGroup, ChampionshipCalendarItem} from "@/components/calendar/Calendar";

class CalendarService {
    getThisWeekCalendar(): Promise<CalendarGroup> {
        return httpService.get("/calendar/this-week")
    }

    getLastWeekCalendar(): Promise<CalendarGroup> {
        return httpService.get("/calendar/last-week")
    }

    getNextWeekCalendar(): Promise<CalendarGroup> {
        return httpService.get("/calendar/next-week")
    }

    getLaterCalendar(page: number): Promise<CalendarGroup> {
        return httpService.get(`/calendar/later?page=${page}`)
    }

    getChampionshipWithCompetitions(): Promise<Array<ChampionshipCalendarItem>> {
        return httpService.get(`/calendar/championships`)
    }
}

export interface CalenderEventResponse {
    other: GenericEvents
    competition: CompetitionEvents
    championship: ChampionshipEvents,
    courses: CourseEvents
}

export interface GenericEvents {
    events: Array<GenericEvent>
}

export interface GenericEvent {
    location: GeoLocation,
    title: string,
    start: Date,
    end: Date,
    ref: string // TODO should be genericEventRef
}

export interface CourseEvents {
    events: Array<CourseDayEvent>
}

export interface CompetitionEvents {
    events: Array<CompetitionDetail>
}

export interface ChampionshipEvents {
    events: Array<ChampionshipEvent>
}

export interface ChampionshipEvent {
    name: string,
    ref: ChampionshipRef,
    status: ChampionshipStatus,
    competitions: Array<CompetitionDetail>
}

export function emptyCalenderEventResponse(): CalenderEventResponse {
    return {
        other: {
            events: []
        },
        competition: {
            events: []
        },
        championship: {
            events: []
        },
        courses: {
            events: []
        }
    }
}


export const calendarService = new CalendarService();
