
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import {DateUtils} from "@/utils/DateUtils";
import _ from "lodash";
import InfiniteScrollList, {PagedResponse} from "@/components/InfiniteScrollList.vue";
import CalendarItemView from "@/components/calendar/item/CalendarItemView.vue";

@Component({
  components: {CalendarItemView, InfiniteScrollList}
})
export default class CalendarComponent<T> extends Vue {
  @Prop({default: false}) saveSearchData!: boolean
  @Prop({default: 'calendar'}) saveStoragePrefix!: string
  @Prop() title?: string
  @Prop() fromDate?: string
  @Prop() toDate?: string
  @Prop({default: false}) withYear?: boolean
  @Prop({type: Function}) searchCallback?: SearchCallback<T>
  @Ref("list") list!: InfiniteScrollList<T>

  searchForm = {} as SearchForm

  doSearchDebounce?: () => void;
  state?: CalendarSaveState

  created() {
    this.doSearchDebounce = _.debounce(() => {
      this.doSearch()
    }, 500)
  }

  mounted() {
    if (this.saveSearchData) {
      this.state = new CalendarSaveState(this.saveStoragePrefix)
      const saved = this.state.load()
      if (saved) {
        this.searchForm = saved
      } else {
        this.searchForm = this.initialForm()
      }
    } else {
      this.searchForm = this.initialForm()
    }
    this.list.initialLoad()
  }

  private initialForm() {
    return {
      searchString: undefined,
      fromDate: this.fromDate ? this.fromDate : DateUtils.toDateString(this.$moment().subtract(1, "w").toDate()),
      toDate: this.toDate ? this.toDate : DateUtils.toDateString(this.$moment().add(1, "M").toDate()),
      sportTypeVariant: undefined
    } as SearchForm
  }

  private resetSearchString() {
    this.searchForm.searchString = ''
    this.searchFormUpdated()
  }

  searchFormUpdated() {
    if (this.searchForm.searchString != undefined && this.searchForm.searchString.length < 3) this.searchForm.searchString = undefined

    if (this.doSearchDebounce) {
      this.doSearchDebounce()
    }

    if (this.saveSearchData) {
      this.state!.save(this.searchForm)
    }
  }

  doSearch() {
    this.list.initialLoad()
  }

  searchByPage(page: number): Promise<PagedResponse<T>> {
    return this.searchCallback!!({...this.searchForm, page: page})
  }
}

interface SearchForm {
  fromDate: string;
  toDate: string;
  searchString?: string;
  sportTypeVariant?: string;
}

export interface SearchRequest {
  fromDate: string;
  toDate: string;
  searchString?: string;
  sportTypeVariant?: string;
  page: number;
}

export type SearchCallback<T> = (request: SearchRequest) => Promise<PagedResponse<T>>

class CalendarSaveState {
  constructor(private readonly storageKey: string) {
  }

  load(): SearchForm | undefined {
    const s = localStorage.getItem(this.key)
    if (s) {
      try {
        return JSON.parse(s) as SearchForm
      } catch (e) {
        localStorage.removeItem(this.key)
      }
    }
    return undefined
  }

  save(request: SearchForm) {
    localStorage.setItem(this.key, JSON.stringify(request))
  }

  private get key() {
    return "eqify_" + this.storageKey
  }
}
