import {httpService} from "@/services/HttpService";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";
import {CompetitionCalendarItem} from "@/components/calendar/Calendar";

class CompetitionCalendarService {
    searchCompetitionCalendarItem(fromDate: string, toDate: string, sportTypeVariant?: string, searchString?: string, page?: number): Promise<PagedResponse<CompetitionCalendarItem>> {
        return httpService.get(`/competition/calendar/search?searchString=${searchString == undefined ? "" : searchString}&from=${fromDate}&to=${toDate}&sportTypeVariant=${sportTypeVariant}&page=${page}`)
    }
}

export const competitionCalendarService = new CompetitionCalendarService()