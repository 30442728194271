
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
    export default class EditButton extends Vue {
        @Prop({default: "sm"}) public size?: string;

        clicked() {
            this.$emit("click", {})
        }
    }
