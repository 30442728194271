
import {Component, Prop, Provide, Vue} from 'vue-property-decorator';
import SearchInput from "@/components/general/SearchInput.vue";
import AvatarUtils from "@/utils/AvatarUtils";
import {OrganisationSearchResultItem} from "@/shared/domain/Organisation";

@Component({
  components: {SearchInput}
})
export default class PublicOrganisationSearchInput extends Vue {
  @Prop() value?: OrganisationSearchResultItem;
  @Provide() query: string = "";
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop() allowUnexisting?: boolean;
  @Prop() allowSave?: boolean;
  @Prop() disabled?: boolean;

  serializer(item?: OrganisationSearchResultItem): string | undefined {
    if (item && item.name) {
      return item.site ? item.site + " (" + item.name + ")" : item.name
    } else {
      return undefined
    }
  }

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }

  avatarName(name: string) {
    return AvatarUtils.avatarName(name)
  }
}
