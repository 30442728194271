
import Vue from "vue";
import {Component, Prop, Ref, Watch} from "vue-property-decorator";
import {
  PriceProfile,
  PriceProfileSplitRevenue,
  PriceProfileSplitRevenueType
} from "@/shared/domain/General";
import EqifyTable from "@/components/table/EqifyTable.vue";
import {EqifyCrudUtils} from "@/components/crud/EqifyCrudComponent.vue";
import AddSplitRevenueModal from "@/components/general/AddSplitRevenueModal.vue";
import PercentageInput from "@/components/general/PercentageInput.vue";
import DeleteButton from "@/components/general/buttons/DeleteButton.vue";
import AccountRefWithNameComponent from "@/components/account/AccountRefWithNameComponent.vue";
import {RefWithName} from "@/utils/Utils";
import EditButton from "@/components/general/buttons/EditButton.vue";

@Component({
  components: {EditButton, AccountRefWithNameComponent, AddSplitRevenueModal, DeleteButton, PercentageInput, EqifyTable}
})
export default class SplitRevenueComponent extends Vue {
  @Prop() value!: Array<PriceProfileSplitRevenue>
  @Prop() type!: PriceProfileSplitRevenueType
  @Prop() federationRef!: string
  @Prop({default: []}) articles?: Array<RefWithName>

  @Prop() maxPercentage!: number
  @Prop() maxAbsolute!: number

  @Ref("addSplitRevenueModal")
  addSplitRevenueModal!: AddSplitRevenueModal

  private tableFields: Array<any> = [];

  private add(p: PriceProfileSplitRevenue) {
    this.value.push(p)
    this.$emit("input", this.value)
  }

  private onDelete(cs: PriceProfileSplitRevenue) {
    const idx = this.value.findIndex(it => it.ref == cs.ref)
    if (idx > -1) this.$delete(this.value, idx)
  }

  private openEditModal(cs: PriceProfileSplitRevenue) {
    this.addSplitRevenueModal.showEdit(cs)
  }

  private onEdit(cs: PriceProfileSplitRevenue) {
    const idx = this.value.findIndex(it => it.ref == cs.ref)
    if (idx > -1) this.$set(this.value, idx, cs)
  }

  private openModal() {
    this.addSplitRevenueModal.show()
  }

  created() {
    this.tableFields = [
      {
        key: 'account',
        label: 'Account'
      },
      {
        key: 'split',
        label: this.$t('finance.split_revenue.title')
      },
      {
        key: 'article',
        label: "Artikel"
      },
      EqifyCrudUtils.actions(this)
    ]
  }
}
