
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import CalendarItemComponent from "@/components/calendar/item/CalendarItemComponent.vue";
import {CourseCalendarItem} from "@/components/calendar/Calendar";
import {GenericEvent} from "@/modules/dashboard/services/CalendarService";
import {CourseDayEvent, CourseDayEventListItem} from "@/modules/course/service/CourseEvent";
import EqifyListAvatar from "@/components/general/EqifyListAvatar.vue";
import SaveCourseAsFavoriteButton from "@/modules/course/components/SaveCourseAsFavoriteButton.vue";

@Component({
  components: {SaveCourseAsFavoriteButton, EqifyListAvatar, CalendarItemComponent}
})
export default class CalendarCourseItemComponent extends Vue {
  @Prop({type: Object, required: true}) item!: CourseCalendarItem
  @Prop({default: true}) withAchievement?: boolean

  get course(): CourseDayEventListItem {
    return this.item.courseEvent
  }

  get avatar(): string | undefined {
    return this.item.avatar
  }

  private handleClick() {
    this.$router.push(`/courses/${(this.item as CourseCalendarItem).courseEvent.courseEventRef}`)
  }
}
