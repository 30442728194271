
import {Component, Prop, Vue} from 'vue-property-decorator';
import AvatarUtils from "@/utils/AvatarUtils";
import {FederationUserInfo, UserProfile} from "@/shared/domain/User";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import {CheckPermission} from "@/components/PageActionMixin";

@Component
export default class ButtonWithFederationSelection extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() variant?: string;
  @Prop() to?: string;
  @Prop() right?: boolean;
  @Prop({type: Function}) checkPermission?: CheckPermission;

  get singleFederation() {
    let federations = this.allowedFederations;
    return this.user && federations && federations.length == 1
  }

  get multipleFederations() {
    let federations = this.allowedFederations;
    return this.user && federations && federations.length > 1
  }

  get federations(): Array<FederationUserInfo> {
    return this.allowedFederations;
  }

  get federation() {
    return this.federations[0]
  }

  get allowedFederations() {
    return this.user ? this.user.federations.filter(f => this.isAllowed(this.user, f)) : [];
  }

  isAllowed(user: UserProfile|undefined, federation: FederationUserInfo) {
    if (this.checkPermission) {
      return this.checkPermission(user, federation)
    } else {
      return false
    }
  }

  createTo(to: string | { path: string, query?: any }, federation: FederationUserInfo | undefined) {
    if (typeof to == "string") {
      to = {path: to, query: {}}
    }
    if (!to.query) {
      to.query = {}
    }
    if (federation) {
      to.query.federation = federation.ref
    }
    return to
  }

  avatarName(federation: FederationUserInfo) {
    return AvatarUtils.avatarName(federation.name!!)
  }
}
