import { render, staticRenderFns } from "./PublicOrganisationSearchInput.vue?vue&type=template&id=92f05f80&scoped=true"
import script from "./PublicOrganisationSearchInput.vue?vue&type=script&lang=ts"
export * from "./PublicOrganisationSearchInput.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f05f80",
  null
  
)

export default component.exports