
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component
    export default class VMaskField extends mixins(Vue, abstractField) {
        get mask() {
            // @ts-ignore
            return this.schema.mask || "";
        }
    }
