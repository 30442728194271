
import {Prop, Provide, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import SearchInput from "@/components/general/SearchInput.vue";
import {RefWithName} from "@/utils/Utils";
import {AchievementRef} from "@/modules/federation/services/AchievementService";

//this component works uses the tenantContext federation ref
@Component({
  components: {SearchInput}
})
export default class AchievementSearchComponent extends Vue {
  @Prop() value?: RefWithName<AchievementRef>
  @Provide() query: string = "";
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }

  get baseUri() {
    return `/achievement/search`
  }
}
