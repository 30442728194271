import {RouteConfig} from "vue-router";
import {createSecureCrudRouteMetadata, createSecureReadOnlyRouteMetadata} from "@/router";

const routes: Array<RouteConfig> = [
    {
        path: '/members',
        component: () => import(/* webpackChunkName: "module-member" */ "@/modules/members/views/MemberSearchView.vue"),
        meta: createSecureReadOnlyRouteMetadata()
    },
    {
        path: '/members/create',
        component: () => import(/* webpackChunkName: "module-member-save" */ "@/modules/members/views/MemberSave.vue"),
        meta: createSecureCrudRouteMetadata()
    },
    {
        path: '/members/:member',
        component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileLayout.vue"),
        children: [
            {
                path: '/',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileOverview.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'combinations',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileCombinationsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'subscriptions',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileSubscriptionsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'results',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileResultsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'achievements',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileAchievementView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'finance',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileFinanceView.vue"),
                meta: createSecureReadOnlyRouteMetadata(),
                children: [
                    {
                        path: '',
                        redirect: 'balance',
                        meta: createSecureReadOnlyRouteMetadata()
                    },
                    {
                        path: 'balance',
                        component: () => import("@/modules/members/components/finance/MemberBillsView.vue"),
                        meta: createSecureReadOnlyRouteMetadata()
                    },
                    {
                        path: 'invoices',
                        component: () => import("@/modules/members/components/finance/MemberFinanceInvoicesView.vue"),
                        meta: createSecureReadOnlyRouteMetadata()
                    }
                ]
            },
            {
                path: 'account',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/MemberProfileAccountView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            },
            {
                path: 'similar',
                component: () => import(/* webpackChunkName: "module-member-profile" */ "@/modules/members/views/profile/SimilarPersonsView.vue"),
                meta: createSecureReadOnlyRouteMetadata()
            }
        ]
    },
    {
        path: '/members/:member/edit',
        component: () => import(/* webpackChunkName: "module-member-save" */ "@/modules/members/views/MemberSave.vue"),
        meta: createSecureCrudRouteMetadata()
    }
];

export default {
    routes: routes
};
