import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";

class UserService {
    invite(federationRef: FederationRef, personRef: string, resend: boolean = false): Promise<void> {
        return httpService.post("/user/invite", {federationRef: federationRef, personRef: personRef, resend: resend})
    }

    confirm(code: string, password: string): Promise<ConfirmResult> {
        return httpService.post("/user/confirm", {code: code, password: password})
    }
}

export interface ConfirmResult {
    success: boolean,
    message: string,
    username: string
}


export const userService = new UserService();
