
import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import {RefWithName} from "@/utils/Utils";
import {memberLicenseService} from "@/modules/members/services/MemberLicenseService";
import {MembershipAssociationType} from "@/modules/members/services/MemberService";
import {LicenseType} from "@/modules/federation/services/LicenseDefinitionService";

@Component({})
export default class LicenseSelectComponent extends Vue {
  @Prop() value?: RefWithName
  @Prop() licenseType?: LicenseType

  private availableLicense: Array<RefWithName> = []

  mounted() {
    this.getLicenses()
  }

  get inputValue(): RefWithName | undefined {
    return this.value;
  }

  set inputValue(value: RefWithName | undefined) {
    this.$emit('input', value)
  }

  @Watch("licenseType")
  onLicenseTypeChange() {
    this.availableLicense = []
    this.getLicenses()
  }

  async getLicenses() {
    const response =  await memberLicenseService.getLicenses(this.licenseType)
    this.availableLicense = response.map(it => {
      return {
        ref: it.ref,
        name: it.name
      }
    })
  }
}
