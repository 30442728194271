import {Championship, ChampionshipStatus} from "@/modules/championship/services/PublicChampionshipService";
import {UserProfile} from "@/shared/domain/User";

export default class ChampionshipUtils {

    static championshipUrl(user: UserProfile | undefined, championship: Championship) {
        const url = `/championships/${championship.ref}`

        if (ChampionshipUtils.redirectToManage(user, championship)) {
            return url + "/manage"
        } else if (ChampionshipUtils.isCancelled(championship)) {
            return undefined
        } else {
            return url
        }
    }

    static isPublished(championship: Championship) {
        return championship.status == ChampionshipStatus.PUBLISHED
    }

    static redirectToManage(user: UserProfile | undefined, championship: Championship) {
        return user && user.isFederationAdministratorOrCollaborator(championship.federationRef) && [ChampionshipStatus.DRAFT, ChampionshipStatus.HIDDEN, ChampionshipStatus.CANCELLED].includes(championship.status)
    }

    static isCancelled(championship: Championship) {
        return championship.status == ChampionshipStatus.CANCELLED
    }
}
