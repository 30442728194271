
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import {FederationUserInfo} from "@/shared/domain/User";
import {Option} from "@/utils/Utils";

@Component({
  components: {}
})
export default class FederationSelectField extends mixins(abstractField, Vue) {

  get user() {
    return this.$keycloak.userProfile
  }

  get hasMultipleFederations() {
    return this.user && this.user.federations.length > 0;
  }

  get defaultFederation(): FederationUserInfo {
    //@ts-ignore
    let federation = this.schema.options ? this.schema.options.default : undefined;
    if (typeof federation == "function") {
      // @ts-ignore
      federation = federation.apply(this, [this.model, this.schema]);
    }
    if (!federation && this.user && this.user.defaultFederation) {
      federation = this.user.defaultFederation
    }
    return federation
  }

  get federations(): Array<any> {
    return this.user ? this.user.federations.map(FederationSelectField.toTupple) : [];
  }

  get selectSingleFederation() {
    const federation = this.defaultFederation
    //@ts-ignore
    this.value = federation.ref!
    return federation.name
  }

  private static toTupple(federation: FederationUserInfo) {
    return Option.create(federation!.ref!, federation.name!)
  }

  mounted() {
    //@ts-ignore
    if (!this.value) {
      //@ts-ignore
      this.value = this.defaultFederation ? this.defaultFederation.ref : undefined
    }
  }
}
