
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import SearchAccountComponent from "@/components/account/SearchAccountComponent.vue";

@Component({
  components: {SearchAccountComponent}
})
export default class SearchAccountField extends mixins(Vue, abstractField) {

}
