<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="vqb-rule card">
    <div class="w-100 d-flex flex-row justify-content-between">
      <div class="d-flex flex-column flex-grow-1">
        <label class="font-weight-bold">{{ rule.label }}</label>
        <div class="w-100 d-flex flex-row  align-items-center justify-content-between">
          <!-- List of operands (optional) -->
          <b-form-select
              v-if="typeof rule.operands !== 'undefined'"
              v-model="query.operand"
              class="mr-2"
          >
            <b-form-select-option
                v-for="operand in rule.operands"
                :key="operand"
                :value="operand"
            >
              {{ operand }}
            </b-form-select-option>
          </b-form-select>

          <!-- List of operators (e.g. =, !=, >, <) -->
          <b-form-select
              v-if="typeof rule.operators !== 'undefined' && rule.operators.length > 1"
              v-model="query.operator"
              class="mr-2"
          >
            <option
                v-for="operator in rule.operators"
                :key="operator"
                :value="operator"
            >
              {{ operator }}
            </option>
          </b-form-select>

          <!-- Custom component input -->
          <div
              class="w-100"
              v-if="isCustomComponent"
          >
            <component
                :is="rule.component"
                :value="query.value"
                @input="updateQuery"
            />
          </div>

          <!-- Checkbox input -->
          <template
              v-if="rule.inputType === 'checkbox'"
          >
            <div
                v-for="choice in rule.choices"
                :key="choice.value"
                class="form-check form-check-inline"
            >
              <input
                  :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                  v-model="query.value"
                  type="checkbox"
                  :value="choice.value"
                  class="form-check-input"
              >
              <label
                  class="form-check-label"
                  :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
              >
                {{ choice.label }}
              </label>
            </div>
          </template>

          <!-- Radio input -->
          <template
              v-if="rule.inputType === 'radio'"
          >
            <div
                v-for="choice in rule.choices"
                :key="choice.value"
                class="form-check form-check-inline"
            >
              <input
                  :id="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
                  v-model="query.value"
                  :name="'depth' + depth + '-' + rule.id + '-' + index"
                  type="radio"
                  :value="choice.value"
                  class="form-check-input"
              >
              <label
                  class="form-check-label"
                  :for="'depth' + depth + '-' + rule.id + '-' + index + '-' + choice.value"
              >
                {{ choice.label }}
              </label>
            </div>
          </template>
        </div>
      </div>

      <!-- Select without groups -->
      <select
          v-if="rule.inputType === 'select' && !hasOptionGroups"
          v-model="query.value"
          class="form-control"
          :multiple="rule.type === 'multi-select'"
      >
        <option
            v-for="option in selectOptions"
            :key="option.value"
            :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>

      <!-- Select with groups -->
      <select
          v-if="rule.inputType === 'select' && hasOptionGroups"
          v-model="query.value"
          class="form-control"
          :multiple="rule.type === 'multi-select'"
      >
        <optgroup
            v-for="(option, option_key) in selectOptions"
            :key="option_key"
            :label="option_key"
        >
          <option
              v-for="sub_option in option"
              :key="sub_option.value"
              :value="sub_option.value"
          >
            {{ sub_option.label }}
          </option>
        </optgroup>
      </select>

      <!-- Remove rule button -->
      <b-button
          type="button"
          class="close ml-auto"
          @click="remove"
          v-html="labels.removeRule"
      >
      </b-button>
    </div>
  </div>
</template>

<script>
import QueryBuilderRule from "vue-query-builder/src/layouts/Bootstrap/BootstrapRule.vue";
export default {
  extends: QueryBuilderRule
}
</script>