
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import _ from "lodash";
import EqifyLoader from "@/components/EqifyLoader.vue";
import {BTable} from "bootstrap-vue";

@Component({
  components: {EqifyLoader}
})
export default class EqifyTable<E> extends Vue {
  @Prop() data?: Array<E>;
  @Prop() fields?: Array<any>;
  @Prop({type: Boolean}) withDetails?: boolean;
  @Prop({type: Boolean}) withSelect?: boolean;
  @Prop({type: Boolean}) fullPage?: boolean;
  @Prop({default: false, type: Boolean}) loading?: boolean;
  @Prop({default: false, type: Boolean}) hover?: boolean;
  @Prop({default: false, type: Boolean}) small?: boolean;
  @Prop({default: false, type: Boolean}) stickyHeader?: boolean;
  @Prop({default: true, type: Boolean}) responsive?: boolean;
  @Prop({default: "", type: String}) caption?: string;
  @Prop({default: 0, type: Number}) perPage?: number;
  @Prop() tbodyTrClass?: any;
  @Prop({
    default: 'single', validator(value: any): boolean {
      return ['single', 'multi', 'range'].includes(value)
    }
  }) selectMode?: string;

  private currentPage = 1
  private totalRows = 0

  @Ref("table")
  table!: BTable

  selectedItems: Array<E> = []

  private tableFilter: { text: string, select: any, switch: boolean } = {
    text: "",
    select: {},
    switch: false
  }

  get _fields() {
    if (this.withSelect) {
      return [{key: 'select', label: "", sortable: false, filterable: false}, ...this.fields || []]
    } else {
      return [...this.fields || []]
    }
  }

  onRowSelected(items: Array<E>) {
    if (this.withSelect) {
      this.selectedItems = items
      this.$emit("selected", items);
    }
  }

  onFiltered(filteredItems: any) {
    if (filteredItems != undefined) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }

  onRowClicked(row: any) {
    this.$emit("rowClicked", {item: row})
    if (this.withDetails) {
      const state = row['_showDetails'] || false
      this.$set(row, '_showDetails', !state)
      this.$emit("detail", {item: row});
    }
  }

  selectAllRows() {
    this.table.selectAllRows()
    this.$emit("selected", this.data || []);
  }

  clearSelected() {
    this.table.clearSelected()
    this.$emit("selected", []);
  }

  get bodyClass() {
    return this.fullPage ? 'bg-white' : 'm-0 p-0';
  }

  mounted() {
    if (this.data != undefined) {
      this.totalRows = this.data!!.length
    } else {
      this.totalRows = 0
    }
  }

  hasFilters() {
    if (!this.fields)
      return false

    return this.fields.filter(it => {
      return it.filterable;
    }).length > 0;
  }

  textFilters() {
    if (!this.fields)
      return []

    return this.fields.filter(it => {
      const field = Object.assign({filterOptions: {type: 'text'}}, it);
      return field.filterable && field.filterOptions && field.filterOptions.type === 'text';
    })
  }

  selectFilters() {
    if (!this.fields)
      return []
    return this.fields.filter(it => it.filterable && it.filterOptions && it.filterOptions.type === 'select')
  }

  switchFilters() {
    if (!this.fields)
      return []
    return this.fields.filter(it => it.filterable && it.filterOptions && it.filterOptions.type === 'switch')
  }

  selectOptions(options: any) {
    if (!options) {
      return []
    }
    if (typeof options === 'function') {
      return options()
    }
    if (typeof options === 'object') {
      return Object.values(options)
    }
    return options;
  }

  doFilter(item: any, filter: any) {
    let textInclude;
    if (!filter.text || filter.text === '') {
      textInclude = true;
    } else {
      textInclude = this.textFilters()
          .map(field => this.textMatches(this.resolveValue(item, field), filter.text))
          .reduce((shouldInclude, include) => include || shouldInclude, false)
    }
    const selectInclude = this.selectFilters()
        .map(field => this.selectMatches(this.resolveValue(item, field), filter.select[field.key], field))
        .reduce((shouldInclude, include) => include && shouldInclude, true)

    const switchInclude = this.switchFilters()
        .map(field => this.switchMatches(this.resolveValue(item, field), filter.switch, field))
        .reduce((shouldInclude, include) => include && shouldInclude, true)

    return textInclude && selectInclude && switchInclude;
  }

  textMatches(text: string, filter: string) {
    if (!text) {
      return false;
    }
    const f = _.deburr(_.lowerCase(_.trim(filter)))
    const t = _.deburr(_.lowerCase(_.trim(text)))
    return t.includes(f)
  }

  selectMatches(value: any, selectFilter: any, field: any) {
    if (!selectFilter) {
      return true;
    }
    if (_.isArray(selectFilter)) {
      const array = selectFilter as Array<any>;
      if (_.isEmpty(array)) {
        return true;
      }
      return array.filter(f => {
        return this.getRealValue(f, field) === value
      }).length > 0
    } else {
      return this.getRealValue(selectFilter, field) === value;
    }
  }

  switchMatches(value: any, switchFilter: any, field: any) {
    if (_.isArray(switchFilter)) {
      const array = switchFilter as Array<any>
      if (_.isEmpty(array)) {
        return value === true
      } else {
        return true
      }
    } else {
      return true;
    }
  }

  getRealValue(value: any, field: any) {
    if (field.filterOptions && field.filterOptions.valueField) {
      return value[field.filterOptions.valueField]
    } else {
      return value
    }
  }

  resolveValue(value: any, field: any) {
    if (!value) {
      return undefined
    }
    if (!field.filterOptions || !field.filterOptions.function) {
      return value[field.key];
    }
    if (typeof field.filterOptions.function === 'function') {
      return field.filterOptions.function(value)
    } else {
      return value;
    }
  }
}
