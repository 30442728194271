
import {Prop, Vue} from "vue-property-decorator";
import Component from "vue-class-component";
import EqifyRuleComponent from "@/components/rules/components/EqifyRuleComponent.vue";
import OrganisationSearchField from "@/components/form/OrganisationSearchField.vue";
import OrganisationSearchInput from "@/components/general/OrganisationSearchInput.vue";

@Component({
  components: {OrganisationSearchInput, OrganisationSearchField, EqifyRuleComponent}
})
export default class EqifyRulePersonHasAge extends Vue {
  @Prop() value?: PersonHasAge

  get age(): number {
    return this.value?.age || 0
  }

  set age(val: number) {
    this.$emit("input", {
      age: val,
      required: this.required,
    })
  }

  get required(): boolean {
    if (this.value == undefined) {
      return true
    } else {
      return this.value.required
    }
  }

  set required(val: boolean) {
    this.$emit("input", {
      age: this.age,
      required: val,
    })
  }
}

export interface PersonHasAge {
  age: number,
  required: boolean
}

