
import {Component, Vue} from "vue-property-decorator";

@Component
export default class ConfirmDeleteDialog extends Vue {
  showDialog() {
    const h = this.$createElement;

    const titleVNode = [h('div', {class: ['text-center']}, [
      h('font-awesome-icon', {props: {icon: "times"}, class: "icon-box"}),
      h('h4', {class: ['mt-5', 'mb-5']}, 'Are you sure?'),
      h('p', {class: ['mb-2']}, 'Do you really want to delete these records? This process cannot be undone.')
    ])];

    this.$bvModal.msgBoxConfirm(titleVNode, {
      size: 'sm',
      okVariant: 'danger',
      okTitle: 'Delete',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false
    })
        .then(value => {
          if (value) {
            this.$emit("click", {})
          }
        })
        .catch(err => {
          this.$log.error(err)
        })
  }
}
