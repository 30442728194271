import {RefWithName} from "@/utils/Utils";

export interface FinanceTransaction {
    ref: string,
    date: string,
    description: string,
    articleName: string,
    articleType: string,
    money: MoneyWithTax,
    to: RefWithName,
    billTo: RefWithName,
    from: RefWithName,
    type: FinanceTransactionType,
    status: FinanceTransactionStatus,
}

export enum FinanceTransactionType {
    DEBIT = "DEBIT",
    CREDIT = "CREDIT"
}

export enum FinanceTransactionStatus {
    OPEN = "OPEN",
    INVOICED = "INVOICED",
    PAID = "PAID",
    CANCELLED = "CANCELLED",
}

export enum PaymentStatus {
    PAID = "PAID",
    OPEN = "OPEN",
    CANCELLED = "CANCELLED",
    INVOICED = "INVOICED",
    UNKNOWN = "UNKNOWN"
}

export enum VAT {
    VAT_0 = "VAT_0",
    VAT_6 = "VAT_6",
    VAT_21 = "VAT_21"
}

export interface MoneyWithTax {
    amountWithTax: number,
    amountWithoutTax: number,
    taxLevel: number,
    taxLevelCode: string,
    currency: string
}

export interface Money {
    amount: number,
    currency: string
}

export interface TotalMoney {
    totalWithTax: number,
    totalWithoutTax: number,
    currency: string,
    taxes: Array<TotalTaxLevel>
}

export interface TotalTaxLevel {
    taxLevel: number;
    tax: number;
}

export const emptyMoney = () => {
    return {
        amountWithTax: 0.0,
        amountWithoutTax: 0.0,
        taxLevel: 0.0,
        taxLevelCode: VAT.VAT_0,
        currency: "EUR"
    }
}

export interface InvoiceLimited {
    ref: string,
    invoiceRef?: string,
    status: string,
    amount: MoneyWithTax,
    description: string
}
