
import {Component, Vue} from 'vue-property-decorator';
import EqifyLoader from "@/components/EqifyLoader.vue";
import EqifyPage from "@/components/EqifyPage.vue";

@Component({
  components: {
    EqifyPage,
    EqifyLoader,
  }
})
export default class AuthLogoutView extends Vue {
  private scope: any = {}

  mounted() {
    this.logout()
  }

  private logout() {

  }

}
