
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import _ from "lodash";
import {UserGroupMember, userGroupService} from "@/modules/federation/services/UserGroupService";

@Component
export default class UserGroupMemberSearch extends mixins(Vue, abstractField) {
  private options: Array<UserGroupMember> = []

  doSearchDebounce?: (loading: (loading: boolean) => void, q: string) => void

  created() {
    this.doSearchDebounce = _.debounce((loading: (loading: boolean) => void, q: string) => {
      loading(true);
      userGroupService.searchPersonAndGroupsByName(this.federationRef, q)
          .then(result => this.options = result)
          .finally(() => loading(false))
    }, 350)
  }

  onSearch(search: string, loading: (loading: boolean) => void) {
    if (search && search.length > 2) {
      this.doSearchDebounce!!(loading, search)
    }
  }

  get federationRef() {
    // @ts-ignore
    let values = this.schema.federationRef;
    if (!values) {
      return [];
    }
    if (typeof values == "function") {
      // @ts-ignore
      return values.apply(this);
    } else {
      return values;
    }
  }
}
