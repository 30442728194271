
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {httpService} from "@/services/HttpService";
import EqifyBootstrapTypeahead from "@/components/typeahead/EqifyBootstrapTypeahead.vue";
import _ from "lodash";
import {RefWithName} from "@/utils/Utils";

@Component({
  components: {EqifyBootstrapTypeahead}
})
export default class SearchInput extends Vue {
  private loading = false;
  private data: RefWithName[] = [];
  private saveRequested: Boolean = false
  private inputQuery: string = ""
  @Prop({
    type: Function,
    default: (d?: Partial<RefWithName>) => d ? d.name : undefined
  }) serializer!: (d?: Partial<RefWithName>) => string | undefined
  @Prop() value?: RefWithName;
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop({required: true}) baseUri?: string;
  @Prop({default: false, type: Boolean}) allowUnexisting?: boolean;
  @Prop({default: false, type: Boolean}) allowSave?: boolean;
  @Prop({default: false, type: Boolean}) disabled?: boolean;
  @Prop({default: false, type: Boolean}) advancedSearch?: boolean;
  @Prop({default: 3, type: Number}) minMatchingChars!: number;
  @Prop({default: "search"}) id?: string;

  doSearchDebounce?: (q: string) => void;

  created() {
    this.doSearchDebounce = _.debounce((query: string) => {
      this.doSearch(query)
    }, 500)
    this.valueUpdated()
  }

  @Watch("value")
  valueUpdated() {
    if (this.value) {
      this.inputQuery = this.serializer(this.value) || ""
    } else {
      this.inputQuery = ""
    }
  }

  @Watch("inputQuery")
  inputQueryChanged() {
    this.doSearchDebounce!(this.inputQuery)
  }

  doSearch(query: string) {
    if (!query || query.trim().length < this.minMatchingChars) {
      this.data = [];
      this.$emit('input', undefined);
      return;
    }
    if (query == this.serializer(this.value)) {
      return;
    }
    this.loading = true
    this.emitInputAsNewInput(this.inputQuery);
    httpService.get(`${this.baseUri}?q=${btoa(query.trim())}`)
        .then((data: any) => {
          this.data = data;
          if (this.data.length == 0) {
            this.emitInputAsNewInput(this.inputQuery);
          }
        })
        .finally(() => this.loading = false)
  }

  handleEnter(inputValue: any) {
    this.$emit('enter', inputValue)
  }

  emitInputAsNewInput(query: string) {
    if (this.allowUnexisting) {
      this.selected({ref: undefined, name: query})
    }
  }

  @Watch("saveRequested")
  saveSelected() {
    this.$emit('input', {ref: this.value!.ref, name: this.value!.name, save: this.saveRequested});
  }

  selected(event: any) {
    this.$emit('hit', event)
    this.$emit('input', {...event, save: this.saveRequested});
  }

  get isUnexisting() {
    return RefWithName.isUnexisting(this.value)
  }
}
