import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {publicCourseEventService} from "@/modules/course/service/PublicCourseEventService";
import {
    CourseAttendance,
    CourseEventProfile,
    CourseEventRef,
    CourseRegistration,
    CourseResult
} from "@/modules/course/service/CourseEvent";
import {manageCourseEventService} from "@/modules/course/service/ManageCourseEventService";

@Module({namespaced: true, name: 'courseEvent-profile'})
export default class CourseEventProfileModule extends VuexModule {
    courseEventProfile: CourseEventProfile = {} as CourseEventProfile
    courseAttendances: Array<CourseAttendance> = []
    courseRegistrations: Array<CourseRegistration>= []
    courseResults: Array<CourseResult> = []

    @MutationAction
    async loadCourseEvent(ref: CourseEventRef) {
        const result = await publicCourseEventService.getCourseEventProfile(ref)
        return {courseEventProfile: result}
    }

    @MutationAction
    async loadCourseAttendances(ref: CourseEventRef) {
        const result = await manageCourseEventService.getCourseAttendances(ref)
        return { courseAttendances: result }
    }

    @MutationAction
    async loadCourseRegistrations(ref: CourseEventRef) {
        const result = await manageCourseEventService.getCourseRegistrations(ref)
        return { courseRegistrations: result }
    }

    @MutationAction
    async loadCourseResults(ref: CourseEventRef) {
        const result = await manageCourseEventService.getCourseResults(ref)
        return { courseResults: result }
    }

}