
import {Component, Vue} from "vue-property-decorator";
import EqifyPage from "@/components/EqifyPage.vue";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import CalendarComponent, {SearchRequest} from "@/components/calendar/CalendarComponent.vue";
import {CourseCalendarItem} from "@/components/calendar/Calendar";
import {courseCalendarService} from "@/modules/course/service/CourseCalendarService";
import {PagedResponse} from "@/components/InfiniteScrollList.vue";

@Component({
  components: {CalendarComponent, EqifyPage}
})
export default class CourseCalendarView extends mixins(AuthenticatedUserMixin, Vue) {
  async searchCallback(form: SearchRequest): Promise<PagedResponse<CourseCalendarItem>> {
    return await courseCalendarService.searchCourseCalendarItem(form.fromDate, form.toDate, form.searchString, form.page)
  }

  canCreate(): boolean {
    return this.user!.canCreateCourseWithinFederation(this.user!.defaultFederation?.ref)
  }
}
