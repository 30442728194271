
import Component, {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "../AuthenticatedUserMixin";
import {Vue} from "vue-property-decorator";
import {DateUtils} from "@/utils/DateUtils";
import _ from "lodash";

@Component({})
export default class WelcomeMessage extends mixins(AuthenticatedUserMixin, Vue) {
  private welcomeMessage = ""

  mounted() {
    if (this.authenticated) {
      this.setWelcomeMessage()
    }
  }

  setWelcomeMessage() {
    const now = DateUtils.now()

    if (now.getHours() < 11) {
      this.welcomeMessage = `${this.$t('welcome.message.morning')} ${this.user!.name}`
    } else if (now.getHours() < 17) {
      this.welcomeMessage = `${this.$t('welcome.message.afternoon')} ${this.user!.name}!`
    } else if (now.getHours() < 24) {
      this.welcomeMessage = `${this.$t('welcome.message.evening')} ${this.user!.name}`
    } else {
      this.welcomeMessage = `${this.$t('welcome.message.hello')} ${this.user!.name}`;
    }
  }
}
