import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {httpService} from "@/services/HttpService";
import {RefWithName} from "@/utils/Utils";

class ArticleService {
    getArticles(federationRef: FederationRef): Promise<Array<Article>> {
        return httpService.get(`/myfederation/${federationRef}/article`)
    }

    getArticleNames(federationRef: FederationRef): Promise<Array<RefWithName>> {
        return httpService.get(`/myfederation/${federationRef}/article/names`)
    }

    getArticleByTypeAndFederation(federationRef: FederationRef, articleType: ArticleType): Promise<Article> {
        return httpService.get(`/myfederation/${federationRef}/article/type/${articleType}`)
    }
}

export type ArticleRef = string;

export interface Article {
    ref: ArticleRef;
    name?: string;
    externalRef?: string;
    entityRef?: string;
    default?: boolean;
    type?: ArticleType;
    taxLevel:RefWithName,
    currency?: string,
    defaultAmount:number
}

export enum ArticleType {
    OTHER = "OTHER",
    LICENSE = "LICENSE",
    COMBINATION = "COMBINATION",
    SUBSCRIPTION = "SUBSCRIPTION",
    ADDITIONAL_SUBSCRIPTION = "ADDITIONAL_SUBSCRIPTION",
    NO_SHOW = "NO_SHOW"
}

export const articleService = new ArticleService();
