import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    Championship,
    ChampionshipLayout,
    ChampionshipRef,
    initEmptyChampionshipLayout,
    publicChampionshipService
} from "@/modules/championship/services/PublicChampionshipService";
import {RefWithName} from "@/utils/Utils";
import {publicChampionshipRankingService} from "@/modules/championship/services/PublicChampionshipRankingService";
import {
    ChampionshipSportCategoryWithCareer
} from "@/modules/championship/services/ManageChampionshipCompetitionService";

@Module({namespaced: true, name: 'championship-profile'})
export default class ChampionshipProfileModule extends VuexModule {
    championship: Championship = {} as Championship
    competitions: ChampionshipLayout = initEmptyChampionshipLayout()
    layoutMenuItems: Array<EqifyProfileSideBarMenuItem> = []
    sportCategories: Array<ChampionshipSportCategoryWithCareer> = []

    @MutationAction
    async loadChampionship(ref: ChampionshipRef) {
        const championship = await publicChampionshipService.getChampionship(ref)
        return {
            championship: championship
        }
    }

    @MutationAction
    async loadChampionshipCompetitions(ref: ChampionshipRef) {
        const layout = await publicChampionshipService.getChampionshipCompetitions(ref)
        return {competitions: layout}
    }

    @MutationAction
    async loadMenuItems(ref: ChampionshipRef) {
        const championshipMenu = await publicChampionshipRankingService.getRankingMenuLayout(ref)
        let sideBarMenuItems: Array<EqifyProfileSideBarMenuItem> = []
        let counter = 0

        if (championshipMenu.hasRanking) {
            sideBarMenuItems.push(
                {
                    key: counter++,
                    name: this.championship.name,
                    active: true,
                    url: `/championships/${ref}/ranking/main`,
                    subItems: undefined
                }
            )
        }

        championshipMenu.phases.forEach(phase => {
            let subItems = phase.groups.filter((group) => group.hasRanking).map(group => {
                return {
                    key: counter++,
                    name: group.name,
                    active: false,
                    url: `/championships/${ref}/ranking/phases/${phase.ref}/groups/${group.ref}`,
                    subItems: undefined
                }
            })

            sideBarMenuItems.push({
                key: counter++,
                name: phase.name,
                active: true,
                url: `/championships/${ref}/ranking/phases/${phase.ref}`,
                subItems: subItems
            })
        })
        return {
            layoutMenuItems: sideBarMenuItems
        }
    }

    @MutationAction
    async loadSportCategories(ref: ChampionshipRef) {
        const response = await publicChampionshipService.getChampionshipSportCategories(ref)
        return {sportCategories: response}
    }
}

export class EqifyProfileSideBarMenuItem {
    key: number = 0
    name: string = ""
    active: boolean = false
    url: string = ""
    subItems: Array<EqifyProfileSideBarMenuItem> | undefined
}
