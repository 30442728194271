import Vue from "vue";
import {VueToastr} from "@/vue-toastr";
import {Loader} from "@/utils/Loader";

export function EqifyHttpPostPlugin(vue: typeof Vue, options?: any): void {
    vue.prototype.$httpPost = new HttpPost(vue.prototype.$toastr, vue.prototype.$log, vue.prototype.$t, vue.prototype.$loader);
}

export interface HttpPostConfig<T> {
    successMessage?: string;
    errorMessage?: string;
    onSuccess?: (response: T) => void;
    onFailure?: (error: any) => void;
    onAccessDenied?: (error: any) => void;
    onNotFound?: (error: any) => void;
    loaderName?: string
}

export class HttpPost {
    constructor(private $toastr: VueToastr,
                private $log: {
                    debug: (message: string) => void,
                    info: (message: string) => void,
                    warn: (message: string) => void,
                    error: (message: string) => void
                },
                private $t: (message: string) => string,
                private $loader: Loader) {
    }

    executeSave<T>(callBack: () => Promise<T>, config: HttpPostConfig<T>) {
        const loaderName = config.loaderName || "loader"
        this.$loader.start(loaderName)
        callBack().then((response: T) => {
            if (config.successMessage) {
                this.$toastr.s(config.successMessage);
            }
            if (config.onSuccess) {
                config.onSuccess(response)
            }
        }).catch(e => {
            this.$log.error(e);
            if (config.onFailure) {
                config.onFailure(e);
            } else {
                if (e.statusCode === 401) {
                    if (config.onAccessDenied) {
                        config.onAccessDenied(e);
                    } else {
                        this.$toastr.e(this.$t("auth.general.noPermission"));
                    }

                } else if (e.statusCode === 404) {
                    if (config.onNotFound) {
                        config.onNotFound(e);
                    } else {
                        this.$toastr.e(this.$t("general.api.notFound"));
                    }
                } else {
                    if (config.errorMessage) {
                        const errorCause = (e.functionalCauses.map((cause: string) => `- ${this.$t(cause)}`) || []).join("<br/>")
                        this.$toastr.e(config.errorMessage + `<br/>${errorCause}`);
                    }
                }
            }
        }).finally(() => this.$loader.end(loaderName));
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $httpPost: HttpPost,
    }
}
