
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
import AchievementsSearchInput from "@/components/general/AchievementsSearchInput.vue";
import CourseSearchInput from "@/components/general/CourseSearchInput.vue";

@Component({
  components: {CourseSearchInput, AchievementsSearchInput}
})
export default class CourseSearchField extends mixins(Vue, abstractField) {
  get getFederationRef() {
    // @ts-ignore
    return this.schema.federationRef || ""
  }

  get getDisabled() {
    // @ts-ignore
    return this.schema.disabled || false
  }
}
