
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Account, ContactType} from "@/modules/federation/services/InvoiceService";
import {Loader} from "@/utils/Loader";

@Component({
  components: {}
})
export default class AccountSelector extends Vue {
  @Prop({required: true, type: Function}) resolver!: () => Promise<Array<ContactWithAccounts>>
  @Prop({
    required: false,
    type: Array,
    default: () => [ContactType.PERSON, ContactType.ORGANISATION]
  }) contactTypes!: ContactType[]

  @Prop() value!: SelectedAccount

  loader = Loader.create()

  allContacts: Array<ContactWithAccounts> = []
  contacts: Array<ContactWithAccounts> = []
  accounts: Array<Account> = []

  contactType: ContactType = ContactType.PERSON
  selectedContactRef: string = ""

  get selectedAccountRef(): string {
    return this.value.ref || ""
  }

  set selectedAccountRef(value: string) {
    this.value.ref = value
    this.value.name = this.accounts.find(it => it.ref == this.value.ref)?.name
    this.value.contactName = this.contacts.find(it => it.contactRef == this.selectedContactRef)?.contactName
    this.value.contactType = this.contactType
    this.value.contactRef = this.selectedContactRef
  }

  mounted() {
    this.allContacts = []
    this.loader.load(async () => {
      await this.resolver()
          .then((r) => {
            this.allContacts = r

            if (this.selectedAccountRef) {
              const c = this.allContacts.find(c => c.accounts.find(a => a.ref == this.selectedAccountRef))
              if (c) {
                this.contactType = c.contactType
                this.selectedContactRef = c.contactRef
                this.updateContacts()
                this.updateAccounts()
              } else {
                this.onContactTypeChange(this.firstContactType)
              }
            } else {
              this.onContactTypeChange(this.firstContactType)
            }
          })
    })
  }

  onContactTypeChange(type: ContactType | undefined) {
    if (type) {
      this.contactType = type
      this.selectedContactRef = ""
      this.selectedAccountRef = ""
      this.updateContacts()
    }
  }

  updateContacts() {
    this.contacts = this.contactsByType()
    if (this.contacts.length > 0 && !this.selectedContactRef) {
      this.selectedContactRef = this.contacts[0].contactRef
      this.updateAccounts()
    }
  }

  onContactChanged() {
    this.selectedAccountRef = ""
    this.updateAccounts()
  }

  updateAccounts() {
    this.accounts = this.accountsForContactType()
    if (this.accounts.length > 0 && !this.selectedAccountRef) {
      this.selectedAccountRef = this.accounts[0].ref || ""
    }
  }

  get firstContactType() {
    const contacts = this.contactTypesThatHaveContacts
    if (this.allContacts.length > 0) {
      return contacts[0].value
    } else return undefined
  }

  get contactTypesThatHaveContacts() {
    return this.contactTypes.filter(ct => this.allContacts.filter(it => it.contactType == ct).length > 0).map(it => {
      return {
        value: it,
        text: this.$t(`members.profile.table.label.billto_${it}`)
      }
    })
  }

  contactsByType() {
    if (this.value && this.contactType) {
      return this.allContacts.filter(it => it.contactType == this.contactType)
    } else {
      return []
    }
  }

  accountsForContactType() {
    const c = this.contacts.find(it => it.contactRef == this.selectedContactRef)
    if (c) {
      return c.accounts
    } else {
      return []
    }
  }
}

export interface ContactWithAccounts {
  contactRef: string,
  contactName: string,
  contactType: ContactType,
  accounts: Array<Account>
}

export interface SelectedAccount {
  ref?: string,
  name?: string
  contactRef?: string,
  contactName?: string,
  contactType?: ContactType
}


