
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class EqifySmallTitle extends Vue {
  @Prop() title!: string

  get titleCapitalized(): string {
    return this.title.toUpperCase()
  }
}
