import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    manageTimescheduleService,
    ScheduleSection,
    TimescheduleMenu
} from "@/modules/competition/service/ManageTimescheduleService";
import {Utils} from "@/modules/competition/store/competition-manage-tests-module";
import {Arena} from "@/shared/domain/Combination";

@Module({namespaced: true, name: 'competition-manage-timeschedule'})
export default class CompetitionManageTimescheduleModule extends VuexModule {
    sections: Array<ScheduleSection> = []
    arenas: Array<Arena> = []
    menu: TimescheduleMenu = {} as TimescheduleMenu

    @MutationAction
    async loadData(ref: CompetitionRef) {
        const data = await manageTimescheduleService.getData(ref)
        return {
            sections: data.sections || [],
            arenas: Utils.sortByName(data.arenas),
            menu: data.menu
        }
    }
}
