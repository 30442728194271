
import {Component, Prop, Vue} from "vue-property-decorator";
import {CalendarGroup, CalendarItem} from "@/components/calendar/Calendar";
import CalendarItemComponent from "@/components/calendar/item/CalendarItemComponent.vue";
import CalendarItemView from "@/components/calendar/item/CalendarItemView.vue";
import InfiniteScrollTrigger from "@/components/InfiniteScrollTrigger.vue";

@Component({
  components: {CalendarItemTrigger: InfiniteScrollTrigger, CalendarItemView, CalendarItemComponent}
})
export default class CalendarGroupView extends Vue {
  @Prop({default: false}) withInfiniteLoading!: boolean
  @Prop() title!: string
  @Prop({type: Function}) requestCalendarDataCallback!: RequestCalendarDataCallback

  private page: number = 0
  private items: Array<CalendarItem> = []
  private lastItemRequestCount = 0

  created() {
    this.items = []
    this.loadGroup()
  }

  get currentPage(): number | undefined {
    if (this.withInfiniteLoading) {
      return this.page
    } else return undefined
  }

  get showTitle(): boolean {
    return this.items.length != 0;
  }

  loadGroup() {
    if (!this.withInfiniteLoading) {
      this.loadItems()
    }
  }

  loadItems() {
    this.$loader.load(async () => {
      const response = await this.requestCalendarDataCallback(this.currentPage)
      this.items = [...response.items]
      this.$emit("count", this.items.length)
      this.lastItemRequestCount = response.items?.length
    }, "calendar::group::load")
  }

  loadMore() {
    if (this.withInfiniteLoading) {
      this.$loader.load(async () => {
        const response = await this.requestCalendarDataCallback(this.currentPage)
        this.$emit("count", this.items.length)
        this.lastItemRequestCount = response?.items?.length
        if (this.lastItemRequestCount > 0) {
          this.items = [...this.items, ...response.items]
          this.page += 1
        }
      }, "calendar::group::loadmore")
    }
  }
}

export type RequestCalendarDataCallback = (page?: number) => Promise<CalendarGroup>

