import {GeoLocation} from "@/shared/domain/Address";
import {httpService} from "@/services/HttpService";
import {DateUtils} from "@/utils/DateUtils";
import Vue from "vue";
import {CourseDayEventListItem, CourseEventProfile, CourseEventRef} from "@/modules/course/service/CourseEvent";
import {PersonRef} from "@/shared/domain/Person";
import {EqifyEvaluationResponse} from "@/services/RegistrationRulesService";
import {PersonFavorite} from "@/shared/domain/User";

class PublicCourseEventService {
    saveAsFavorite(courseEventRef: CourseEventRef): Promise<PersonFavorite> {
        return httpService.post(`/course/day/${courseEventRef}/favorite`, {})
    }

    public removeAsFavorite(ref: string): Promise<void> {
        return httpService.delete(`/sidebar/${ref}`)
    }

    getCourseEventProfile(courseEventRef: CourseEventRef): Promise<CourseEventProfile> {
        return httpService.get(`/course/event/${courseEventRef}/profile`)
    }

    registerToCourseEvent(courseEventRef: CourseEventRef, personRef: PersonRef, confirmSaveWithWarnings: boolean, remarks?: string): Promise<CourseRegistrationRequestResponse> {
        return httpService.post(`/course/event/${courseEventRef}/register`, {
            personRef: personRef,
            confirmSaveWithWarnings: confirmSaveWithWarnings,
            remarks: remarks
        })
    }
}

export interface CourseRegistrationRequestResponse {
    evaluationResponse: EqifyEvaluationResponse,
    saved: boolean
}

export interface CreateCourseEventDayData {
    ref?: string,
    courseDay: number,
    day?: Date,
    start?: string,
    end?: string,
    location?: GeoLocation
}

export const initEmptyCreateCourseEventDayData = () => {
    const form: CreateCourseEventDayData = {
        ref: "",
        day: undefined,
        courseDay: 1,
        start: undefined,
        end: undefined,
        location: undefined
    }
    return form
}

export const getCourseEventRefFromRoute = (vue: Vue): CourseEventRef => {
    return vue.$route.params.courseEvent;
};

export const publicCourseEventService = new PublicCourseEventService()