const CONFIG: any = {
    base_url: "$EQIFY_BASE_URL",
    api_url: "$EQIFY_API_URL",
    i18n_locale: "$EQIFY_I18N_LOCALE",
    i18n_fallback_locale: "$EQIFY_I18N_FALLBACK_LOCALE",
    google_analytics_id: "$EQIFY_GOOGLE_ANALYTICS_ID",
    sso_url: "$EQIFY_SSO_URL",
    sso_realm: "$EQIFY_SSO_REALM",
    sso_clientid: "$EQIFY_SSO_CLIENTID"
};

export class ConfigProvider {

    public static get CONFIG(): any {
        return CONFIG;
    }

    public static value(name: string): string | undefined {
        if (!(name in this.CONFIG)) {
            return;
        }

        const value = this.CONFIG[name];

        if (!value) {
            return;
        }

        if (value.startsWith('$')) {
            const variable = (value as string).substring("$EQIFY_".length).toUpperCase()
            const envName = 'VUE_APP_' + variable;
            const envValue = process.env[envName];
            if (envValue) {
                return envValue;
            } else {
                return
            }
        } else {
            return value;
        }
    }

    public static get baseUrl(): string | undefined {
        return ConfigProvider.value('base_url')
    }

    public static get serviceBaseUrl(): string | undefined {
        return ConfigProvider.value('api_url')
    }

    public static get i18nLocale(): string | undefined {
        return ConfigProvider.value('i18n_locale')
    }

    public static get i18nFallbackLocale(): string | undefined {
        return ConfigProvider.value('i18n_fallback_locale')
    }

    public static get ssoUrl(): string {
        return ConfigProvider.value('sso_url')!!;
    }

    public static get ssoRealm(): string {
        return ConfigProvider.value('sso_realm')!!;
    }

    public static get ssoClientId(): string {
        return ConfigProvider.value('sso_clientid')!!;
    }

    public static get googleAnalyticsId(): string {
        return ConfigProvider.value('google_analytics_id')!!;
    }
}
