
import {Component, Prop, Vue} from 'vue-property-decorator';
import SearchInput from "@/components/general/SearchInput.vue";
import {CompetitionDetail} from "@/modules/competition/service/CompetitionService";
import CompetitionStatusBadge from "@/components/general/CompetitionStatusBadge.vue";
import SportTypeBadge from "@/components/general/SportTypeBadge.vue";

@Component({
  components: {SportTypeBadge, CompetitionStatusBadge, SearchInput}
})
export default class CompetitionSearchInput extends Vue {
  @Prop() value?: CompetitionDetail | undefined;
  @Prop() baseUri?: string;
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop() disabled?: boolean;

  get inputValue(): any {
    return this.value;
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }
}
