
import {Component, Prop, Vue} from "vue-property-decorator";
import {ChampionshipCalendarItem} from "@/components/calendar/Calendar";
import CompetitionStatusBadge from "@/components/general/CompetitionStatusBadge.vue";
import SportTypeVariantBadge from "@/components/general/SportTypeVariantBadge.vue";
import {Championship, ChampionshipStatus} from "@/modules/championship/services/PublicChampionshipService";
import CalendarCompetitionItemComponent from "@/components/calendar/item/CalendarCompetitionItemComponent.vue";
import ChampionshipUtils from "@/utils/ChampionshipUtils";
import {mixins} from "vue-class-component";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import CompetitionCalendarView from "@/modules/competition/views/CompetitionCalendarView.vue";

@Component({
  components: {
    CompetitionCalendarView,
    CalendarCompetitionItemComponent, CompetitionStatusBadge, SportTypeVariantBadge
  }
})
export default class ChampionshipCalendarItemComponent extends mixins(AuthenticatedUserMixin, Vue) {
  @Prop() item!: ChampionshipCalendarItem
  @Prop({default: false}) displayColumn?: boolean

  get championship() {
    return this.item.championship
  }

  get competitions() {
    return this.item.competitions
  }

  get showState() {
    return this.championship && this.championship.status && (
        this.championship.status === ChampionshipStatus.DRAFT ||
        this.championship.status === ChampionshipStatus.ARCHIVED ||
        this.championship.status === ChampionshipStatus.CANCELLED ||
        this.championship.status === ChampionshipStatus.HIDDEN)
  }

  get elementClass(): string {
    if (this.displayColumn == true) {
      return 'col-6 p-1'
    } else {
      return 'col-12 p-1'
    }
  }

  get parentClass(): string {
    if (this.displayColumn == true) {
      return 'w-100 rounded m-0 d-flex flex-row justify-content-space-between'
    } else {
      return 'w-100 p-3 d-flex flex-column'
    }
  }

  get textClass() {
    if (this.championship.status === ChampionshipStatus.CANCELLED) return "text-danger"
    return ""
  }

  championshipUrl(championship: Championship) {
    const route = ChampionshipUtils.championshipUrl(this.user, championship)
    if (route) this.$router.push(route)
  }

  getBackgroundColor(color: string) {
    return `background-color: ${color}`
  }
}

