import {Module, MutationAction, VuexModule} from "vuex-module-decorators";
import {
    initEmptyOrganisationProfile,
    OrganisationRef,
    organisationService
} from "@/modules/organisations/services/OrganisationService";
import {
    initOrganisationProfileSecurity,
    organisationSecurityService
} from "@/modules/organisations/services/OrganisationSecurityService";

@Module({namespaced: true, name: 'organisation-profile'})
export default class OrganisationProfileModule extends VuexModule {
    organisation = initEmptyOrganisationProfile()
    security = initOrganisationProfileSecurity()

    get selectedOrganisation() {
        return this.organisation
    }

    get profileSecurity() {
        return this.security
    }

    @MutationAction
    async loadOrganisation(ref: OrganisationRef) {
        const organisation = await organisationService.getOrganisationProfile(ref)
        return {
            organisation: organisation
        }
    }

    @MutationAction
    async loadSecurity(ref: OrganisationRef) {
        const security = await organisationSecurityService.getProfileSecurity(ref)
        return {
            security: security
        }
    }
}
