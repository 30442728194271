
import {Component, Vue} from "vue-property-decorator";
import {
    ManageCareerPointDistributionCategoriesMetadata,
    ManageCareerPointDistributionCategoryCriteriaType
} from "@/modules/federation/services/CareerService";
import {enumValues, Option, toI18nOptions} from "@/utils/Utils";
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";

@Component
export default class CareerCategoryCriteriaFormField extends mixins(Vue, abstractField) {
    private types: Array<Option> = []
    value: any;
    schema: any;

    get form(): any {
        if (!this.value) {
            this.value = {type: ManageCareerPointDistributionCategoryCriteriaType.NONE}
        }
        return this.value
    }

    mounted() {
        this.types = toI18nOptions(enumValues(ManageCareerPointDistributionCategoryCriteriaType), "myfederation.career.category.criteria.type", this)
    }

    get metadata(): ManageCareerPointDistributionCategoriesMetadata {
        let v = this.schema.metadata;
        if (!v) {
            return {championshipCategories: []} as ManageCareerPointDistributionCategoriesMetadata
        }
        if (typeof v == "function") {
            // @ts-ignore
            return v.apply(this, [this.model, this.schema]);
        } else {
            return v;
        }
    }

    get isChampionship(): boolean {
        return this.form && this.form.type == ManageCareerPointDistributionCategoryCriteriaType.BY_CHAMPIONSHIP_CATEGORY
    }
}

