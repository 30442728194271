
import Component from "vue-class-component";
import {RefWithName} from "@/utils/Utils";
import {Prop, Provide, Vue} from "vue-property-decorator";
import SearchInput from "./SearchInput.vue";
import {AchievementRef} from "@/modules/federation/services/AchievementService";

@Component({
  components: {SearchInput}
})
export default class AchievementsSearchInput extends Vue {
  @Prop() value?: RefWithName<AchievementRef>
  @Provide() query: string = "";
  @Prop() inputClass?: string;
  @Prop() size?: string;
  @Prop() placeholder?: string;
  @Prop() federationRef?: string

  get inputValue(): any {
    return this.value;
  }

  get baseUri() {
    return `/myfederation/${this.getFederationRef}/achievement/search`
  }

  get getFederationRef() {
    return this.federationRef
  }

  set inputValue(value: any) {
    this.$emit('input', value);
  }
}
