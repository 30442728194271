
import {Component, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import EqifyPage from "../../../components/EqifyPage.vue";
import LoadingButton from "../../../components/general/buttons/LoadingButton.vue";
import {Loader} from "../../../utils/Loader";
import {courseValidator, currencyValidator, moneyValidator} from "@/components/form/Validators";
import FederationUtils from "@/utils/FederationUtils";
import AuthenticatedUserMixin from "@/components/AuthenticatedUserMixin";
import {FederationUserInfo} from "@/shared/domain/User";
import ManageCourseEventDays from "@/components/general/ManageCourseEventDays.vue";
import {CourseDayEvent, CourseEvent, CourseEventRef, initEmptyCourseEvent} from "@/modules/course/service/CourseEvent";
import {getCourseEventRefFromRoute, manageCourseEventService} from "@/modules/course/service/ManageCourseEventService";
import {Course} from "@/modules/federation/services/CourseService";
import {DateUtils} from "@/utils/DateUtils";
import EqifyDataLoader from "@/components/EqifyDataLoader.vue";
import {RouteUtils} from "@/utils/RouteUtils";
import EqifyRuleBuilder from "@/components/rules/EqifyRuleBuilder.vue";

@Component({
  components: {EqifyRuleBuilder, EqifyDataLoader, ManageCourseEventDays, LoadingButton, EqifyPage}
})
export default class CourseEventSave extends mixins(AuthenticatedUserMixin, Vue) {
  federation?: FederationUserInfo = FederationUserInfo.empty();
  loader = Loader.create();

  courseEventDays: Array<CourseDayEvent> = []

  courseEventRef: CourseEventRef = ""

  currentCourse: Course = {ref: ""}

  scope = "";
  formSchema: { fields: any[], groups: any[] } = {
    fields: [],
    groups: []
  };

  formOptions = {
    validateAfterLoad: false,
    validateAfterChanged: true
  };
  formValid: boolean = false;
  daysValid: boolean = false
  form: CourseEvent = initEmptyCourseEvent()

  rules = {
    logicalOperator: "all",
    children: []
  }

  $refs!: {
    vfg: any,
  }

  created() {
    this.federation = FederationUtils.getFederationFromRoute(this.$route, this.user!!)
    if (this.user!.canManageAndReadFederationData(this.federation!!.ref!!)) {
      this.loadData()
      this.setCourseOnlyFormSchema()
    } else {
      RouteUtils.accessDenied(this.$router, true)
    }
  }

  @Watch("$route")
  routeChanged() {
    this.loadData()
  }

  loadData() {
    this.courseEventRef = getCourseEventRefFromRoute(this)
    this.scope = this.isEdit(this.courseEventRef) ? "edit" : "create";

    this.loader.load(async () => {
      if (this.scope == "edit") {
        await manageCourseEventService.getCourseEvent(this.federation!!.ref!!, this.courseEventRef)
            .then((metaData: CourseEvent) => {
              this.form = metaData
              this.courseEventDays = metaData.days
              this.rules = metaData.rules
              this.setFormSchema()
            })
            .catch((e: any) => {
              Vue.$log.error(e);
              const errorCause = (e.functionalCauses.map((cause: string) => `- ${this.$t(cause)}`) || []).join("<br/>")
              this.$toastr.e(this.$t(`general.action.create.failure`).toString() + `<br/>${errorCause}`);
            });
      }
    })
  }

  isEdit(courseEventRef: CourseEventRef): boolean {
    return courseEventRef != undefined;
  }

  formValidated(isValid: boolean, errors: Array<string>) {
    this.formValid = isValid && this.daysValid;
  }

  daysValidated(event: any) {
    this.daysValid = event.valid
    this.$refs.vfg.validate()
  }

  @Watch("form.courseRef")
  private onCourseChange() {
    if (this.form.courseRef !== undefined) {
      if (this.form.description == null) {
        //todo this if() can be removed when quillEditor bug is fixed
        this.setFullFormSchema()
      }
    } else {
      this.form.description = undefined
      this.setCourseOnlyFormSchema()
    }
  }

  setCourseOnlyFormSchema() {
    this.formSchema.groups = [
      {
        fields: [
          {
            styleClasses: 'col-12 pl-0 pr-0',
            type: 'courseSearch',
            label: this.$t("courses.course.label"),
            placeholder: this.$t("courses.course.placeholder"),
            model: 'courseRef',
            federationRef: this.federation!!.ref,
            required: true,
            disabled: this.scope == "edit",
            validator: courseValidator
          },
        ]
      }
    ]
  }

  setFullFormSchema() {
    this.$loader.load(async () => {
      this.courseEventDays = []
      this.currentCourse = await manageCourseEventService.getCourse(this.form.courseRef!!.ref!!)
      const dayCount = this.currentCourse.days
      this.form.title = this.currentCourse.title
      this.form.money = this.currentCourse.money
      this.form.description = this.currentCourse.description
      this.form.rules = {
        logicalOperator: "or",
        children: []
      }
      for (let index = 1; index < dayCount!! + 1; index++) {
        this.courseEventDays.push({
          start: undefined,
          end: undefined,
          courseDay: index,
          location: this.currentCourse.location,
        })
      }
      this.setFormSchema()
    }, "loader")
  }

  setFormSchema() {
    this.formSchema.groups = [
      {
        fields: [
          {
            styleClasses: 'col-12 pl-0 pr-0',
            type: 'courseSearch',
            label: this.$t("courses.course.label"),
            placeholder: this.$t("courses.course.placeholder"),
            model: 'courseRef',
            federationRef: this.federation!!.ref,
            disabled: this.scope == "edit",
            required: true,
            validator: courseValidator
          },
          {
            styleClasses: 'col-12 pr-0',
            type: 'placeholder'
          }
        ]
      },
      {
        legend: this.$t("courses.legend.general"),
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: this.$t("courses.title.label"),
            model: 'title',
            placeholder: this.$t("courses.title.placeholder"),
            required: true,
            validator: "string"
          },
          {
            styleClasses: 'col-12 pl-0',
            type: 'moneyInput',
            label: 'Amount',
            model: 'money',
            moneyOnly: true,
            required: true,
            validator: moneyValidator
          },
          {
            type: 'QuillEditor', //todo why does this trigger a reload or something??
            label: this.$t("courses.description.label"),
            height: 150,
            model: 'description',
            required: false,
            placeholder: this.$t("courses.description.placeholder"),
            styleClasses: 'pb-5',
            validator: "string"
          },
          {
            styleClasses: 'col-12 pr-0 pt-3',
            type: 'placeholder'
          },
          {
            type: 'input',
            inputType: 'number',
            label: this.$t("courses.maxRegistrations.label"),
            model: 'maxRegistrations',
            required: true,
            min: 0,
            validator: "number"
          }
        ]
      }
    ]
  }

  onSubmit() {
    this.$loader.load(async () => {
      this.$refs.vfg.validate()

      if (this.formValid) {
        const formData = {
          ...this.form,
          days: this.courseEventDays.map(it => {
            return {
              ...it,
              ref: it.ref,
              start: DateUtils.toDateString(it.day!!) + " " + it.start + ":00",
              end: DateUtils.toDateString(it.day!!) + " " + it.end + ":00"
            }
          }),
          rules: this.rules
        }
        await manageCourseEventService.createCourseEvent(this.federation!!.ref!!, formData)
            .then(() => {
              this.$toastr.s(this.$t(`courses.${this.scope}.success`) as string);
              this.$router.push("/courses")
            })
            .catch(e => {
              Vue.$log.error(e);
              const errorCause = (e.functionalCauses.map((cause: string) => `- ${this.$t(cause)}`) || []).join("<br/>")
              this.$toastr.e(this.$t(`general.action.create.failure`).toString() + `<br/>${errorCause}`);
            });
      }
    }, "loader")
  }

}
