
import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {BForm, BModal} from "bootstrap-vue";
import {EntityStateResource} from "@/shared/domain/EntityState";

@Component
export default class PublishDialog extends Vue {
  @Ref("modal") private modal!: BModal;
  @Ref("form") private form!: BForm;
  @Prop() public entityStateResource!: EntityStateResource;
  note: String = "";
  noteState: boolean | undefined = false;

  showDialog() {
    this.modal.show();
  }

  resetModal() {
    this.note = this.entityStateResource.note ? this.entityStateResource.note : "Initial Publication";
    this.noteState = undefined;
  }

  handleOk(event: Event) {
    event.preventDefault();
    this.handleSubmit()
  }

  handleSubmit() {
    if (!this.checkFormValidity()) {
      return
    }
    this.$emit("click", {note: this.note});

    this.$nextTick(() => {
      this.modal.hide();
    })
  }

  checkFormValidity() {
    const valid = this.form.checkValidity();
    this.noteState = valid;
    return valid;
  }
}
