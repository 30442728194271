import {Module, Mutation, MutationAction, VuexModule} from "vuex-module-decorators";
import {CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {
    manageSubscriptionService,
    RegistrationResponse,
    RegistrationsMenu,
    TestMenuEntry
} from "@/modules/competition/service/ManageSubscriptionService";

@Module({namespaced: true, name: 'competition-manage-subscriptions'})
export default class CompetitionManageSubscriptionsModule extends VuexModule {
    menu: RegistrationsMenu = {} as RegistrationsMenu
    selectedMenuItem: TestMenuEntry = {} as TestMenuEntry;
    subscriptionResponse: RegistrationResponse = {} as RegistrationResponse

    @Mutation
    clearStore() {
        this.menu = {} as RegistrationsMenu
        this.selectedMenuItem = {} as TestMenuEntry
        this.subscriptionResponse = {} as RegistrationResponse
    }

    @MutationAction
    async loadData(ref: CompetitionRef) {
        const data = await manageSubscriptionService.getData(ref)
        return {
            menu: data.menu
        }
    }

    @MutationAction
    async select(request: SelectRequest) {
        const data = await manageSubscriptionService.getRegistrations(request.competitionRef, request.menuItemRef)
        const menuItem = this.menu?.entries?.flatMap(it => it.entries)?.find(it => it.ref == request.menuItemRef)
        return {subscriptionResponse: data, selectedMenuItem: menuItem}
    }

    @Mutation
    async refresh(competitionRef: CompetitionRef) {
        this.subscriptionResponse = await manageSubscriptionService.getRegistrations(competitionRef, this.selectedMenuItem.ref)
    }
}

export interface SelectRequest {
    competitionRef: CompetitionRef
    menuItemRef: string
}

