import {httpService} from "@/services/HttpService";
import {FederationRef} from "@/modules/federation/services/MyFederationService";
import {DateUtils} from "@/utils/DateUtils";
import {
    FederationDetails,
    Gender,
    LicenseDetails,
    MembershipDetails,
    PersonRef,
    Profession,
    UserDetails
} from "@/shared/domain/Person";
import {RefWithName} from "@/utils/Utils";
import {LicenseRef} from "@/modules/federation/services/LicenseDefinitionService";
import Vue from "vue";
import {GeoLocation} from "@/shared/domain/Address";
import {OrganisationUserRole, OrganisationUserStatute} from "@/shared/domain/User";
import {AvatarUrl, Language} from "@/shared/domain/General";
import {RiderHorsesResponse} from "@/shared/domain/Combination";
import {CustomAttribute} from "@/shared/domain/CustomAttributes";
import {CompetitionDetail, CompetitionRef} from "@/modules/competition/service/CompetitionService";
import {MasterlistItem} from "@/modules/competition/service/PublicCompetitionMasterlistService";
import {MemberBenefitAssociation} from "@/shared/MemberBenefit";
import {ContactWithAccounts} from "@/components/account/AccountSelector.vue";
import {AccountRefWithName, AccountResource} from "@/shared/domain/Account";
import {HorseName} from "@/shared/domain/CompetitionPractitioner";

class MemberService {
    createUploadAvatarUrl(ref: PersonRef): string {
        return `/person/${ref}/avatar`
    }

    createMember(formData: SaveMemberForm): Promise<MemberRef> {
        return MemberService.saveMember(`/member`, formData);
    }

    updateMember(memberRef: MemberRef, formData: SaveMemberForm) {
        return MemberService.saveMember(`/member/${memberRef}`, formData);
    }

    changeMemberEqifyLanguage(memberRef: MemberRef, formData: ChangeLanguageForm): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            resolve(true);
        });
    }

    getMemberProfile(ref: MemberRef): Promise<MemberProfile> {
        return httpService.get(`/member/${ref}/profile`)
    }

    getMemberCombinationsOverview(ref: MemberRef, inactive: boolean): Promise<RiderHorsesResponse> {
        return httpService.get(`/member/${ref}/combinations?inactive=${inactive}`)
    }

    private static saveMember(url: string, formData: SaveMemberForm): Promise<MemberRef> {
        const submitForm = {
            ...formData,
            defaultFederation: "null" === formData.defaultFederation ? undefined : formData.defaultFederation,
            birthDate: formData.birthDate ? DateUtils.toDateString(formData.birthDate) : undefined,
            deceasedDate: formData.deceasedDate ? DateUtils.toDateString(formData.deceasedDate) : undefined,
            memberships: formData.memberships.map(r => {
                return {
                    ...r,
                    start: r.start ? DateUtils.toDateString(r.start) : undefined,
                    end: r.end ? DateUtils.toDateString(r.end) : undefined,
                    statutes: r.statutes.map(r => {
                        return {
                            ...r,
                            since: r.since ? DateUtils.toDateString(r.since) : undefined,
                            until: r.until ? DateUtils.toDateString(r.until) : undefined,
                        }
                    }),
                    organisation: r.organisation
                }
            }),
            licenses: formData.licenses.map(l => {
                return {
                    markedForDeletion: l.markedForDeletion,
                    ref: l.ref,
                    licenseRef: l.license?.ref,
                    billToAccountRef: l.billToAccount?.ref,
                    start: l.start != undefined ? DateUtils.toDateString(l.start) : undefined,
                    end: l.end != undefined ? DateUtils.toDateString(l.end) : undefined
                }
            }),
            memberBenefits: formData.memberBenefits?.map(mb => {
                return {
                    ref: mb.ref,
                    memberBenefitRef: mb.memberBenefit.ref,
                    billToAccountRef: mb.billToAccount?.ref,
                    checked: mb.checked
                }
            })
        };
        return httpService.post(url, submitForm);
    }

    getMember(ref: MemberRef): Promise<SaveMemberForm> {
        return httpService.get(`/member/${ref}`).then((data: any) => {
            const form: SaveMemberForm = {
                firstName: data.firstName,
                lastName: data.lastName,
                birthDate: data.birthDate ? DateUtils.fromDate(data.birthDate) : undefined,
                deceasedDate: data.deceasedDate ? DateUtils.fromDate(data.deceasedDate) : undefined,
                gender: data.gender,
                email: data.email,
                phone: data.phone,
                mobilePhone: data.mobilePhone,
                relations: data.relations,
                memberships: data.memberships || [],
                licenses: data.licenses || [],
                defaultFederation: data.defaultFederation,
                defaultLanguage: data.defaultLanguage,
                nationality: data.nationality,
                feiNumber: data.feiNumber,
                customAttributes: data.customAttributes,
                externalId: data.externalId,
                memberBenefits: data.memberBenefits,
                profession: data.profession,
                accounts: data.accounts
            };
            return Promise.resolve(form);
        });
    }

    getInitialSaveMemberFormData(memberRef: MemberRef | undefined = undefined): Promise<SaveMemberForm> {
        if (memberRef === undefined || memberRef === 'undefined') {
            return Promise.resolve(initEmptySaveMemberForm())
        } else {
            return this.getMember(memberRef)
        }
    }

    getMemberSubscriptions(memberRef: MemberRef, from: Date, to: Date): Promise<SubscriptionList> {
        return httpService.get(`/member/${memberRef}/subscriptions`).then((data: any) => {
            const subscriptions = data.map((s: any) => {
                const subscription: Subscription = {
                    date: s.date,
                    event: RefWithName.create(s.event.ref, s.event.name),
                    organizer: RefWithName.create(s.organizer.ref, s.organizer.name),
                    horse: RefWithName.create(s.horse.ref, s.horse.name),
                    category: s.category.name
                }
                return subscription;
            })
            return Promise.resolve({ref: 'unknown', entries: subscriptions});
        });
    }

    getMemberResults(memberRef: MemberRef, from: Date, to: Date): Promise<ResultList> {
        return httpService.get(`/member/${memberRef}/results`).then((data: any) => {
            const results = data.map((r: any) => {
                const result: Result = {
                    date: r.date,
                    event: RefWithName.create(r.event.ref, r.event.name),
                    organizer: RefWithName.create(r.organizer.ref, r.organizer.name),
                    horse: RefWithName.create(r.horse.ref, r.horse.name),
                    category: r.category.name,
                    result: r.result,
                    rank: r.rank
                }
                return result;
            })
            return Promise.resolve({ref: 'unknown', entries: results});
        });
    }

    deleteMemberRelation(memberRef: MemberRef, personRef: string): Promise<void> {
        return httpService.delete(`/member/${memberRef}/parent-relation?personRef=${personRef}`)
    }

    deactivateMemberRelation(memberRef: MemberRef, personRef: string): Promise<void> {
        return httpService.delete(`/member/${memberRef}/child-relation?personRef=${personRef}`)
    }

    getMemberCompetitions(memberRef: MemberRef): Promise<Array<CompetitionDetail>> {
        return httpService.get(`/member/${memberRef}/masterlist-competitions`)
    }

    getMemberCompetitionMasterlist(memberRef: MemberRef, competitionRef: CompetitionRef): Promise<Array<MasterlistItem>> {
        return httpService.get(`/member/${memberRef}/masterlist/${competitionRef}`)
    }

    getContactAccounts(memberRef: MemberRef): Promise<Array<ContactWithAccounts>> {
        return httpService.get(`/member/${memberRef}/contact-accounts`)
    }

    getSimilarPersons(personRef: PersonRef): Promise<Array<SimilarPerson>> {
        return httpService.get(`/person/${personRef}/similar-persons`)
    }

    mergeSimilarPersons(personRef: PersonRef, selected: Array<PersonRef>): Promise<Array<any>> {
        return httpService.post(`/person/${personRef}/similar-persons/merge`, {selected: selected})
    }

    setMemberDeceased(personRef: PersonRef, date: string): Promise<void> {
        return httpService.post(`/person/${personRef}/deceased`, {
            date: date
        })
    }
}

export type MemberRef = string;

export interface ResultList {
    ref: string,
    entries: Array<Result>
}

export interface Result {
    date: string,
    rank: string,
    event: RefWithName,
    organizer: RefWithName,
    horse: RefWithName,
    category: string,
    result: string
}

export function emptyResultList(): ResultList {
    return {
        ref: '',
        entries: []
    }
}

export interface SubscriptionList {
    ref: string,
    entries: Array<Subscription>
}

export interface Subscription {
    date: string,
    event: RefWithName,
    organizer: RefWithName,
    horse: RefWithName,
    category: string
}

export function emptyMemberSubscriptionList(): SubscriptionList {
    return {
        ref: '',
        entries: []
    }
}

export interface Membership {
    ref: string,
    organisation: OrganisationSummary;
    role: OrganisationUserRole;
    statutes: Array<MembershipStatute>;
    canOrganizeCompetitions: boolean;
    associationType: MembershipAssociationType;
    valid: boolean;
    markForDeletion: boolean;
    start?: Date,
    end?: Date,
    active?: boolean,
    expired?: boolean,
    isNew?: boolean,
    customAttributes?: Array<CustomAttribute>,
    memberId?: string
}

export interface OrganisationSummary {
    ref: string;
    name: string;
    avatar?: string;
    isHorse: boolean;
    isPony: boolean;
    externalId?: string;
}

export const initEmptyMembership = () => {
    const form: Membership = {
        ref: "",
        valid: false,
        start: DateUtils.now(),
        role: OrganisationUserRole.ORGANISATION_MEMBER,
        statutes: [],
        canOrganizeCompetitions: false,
        organisation: {} as OrganisationSummary,
        associationType: MembershipAssociationType.RECREATIONAL,
        markForDeletion: false,
        customAttributes: []
    };
    return form
};

export enum MembershipAssociationType {
    OTHER = "OTHER",
    COMPETITION = "COMPETITION",
    RECREATIONAL = "RECREATIONAL"
}

export interface MembershipStatute {
    ref?: string;
    active?: boolean;
    expired?: boolean;
    isNew?: boolean;
    markForDeletion?: boolean;
    statute: OrganisationUserStatute;
    since: Date;
    until?: Date;
    valid?: boolean;
}

export const initEmptyMembershipStatute = () => {
    const form: MembershipStatute = {
        active: false,
        valid: false,
        expired: false,
        markForDeletion: false,
        statute: OrganisationUserStatute.ORGANISATION_MEMBER,
        since: DateUtils.now(),
    };
    return form
};

export class MemberLicense {
    ref?: string;
    license?: RefWithName<LicenseRef>;
    start?: Date;
    end?: Date;
    active?: boolean;
    markedForDeletion?: boolean;
    canDelete?: boolean;
    description?: string;
    billToAccount?: AccountRefWithName
}

export interface SaveMemberForm {
    firstName: string,
    lastName: string,
    email: string,
    phone?: string,
    mobilePhone?: string,
    birthDate?: Date,
    deceasedDate?: Date,
    gender?: Gender,
    relations: Array<PersonRelation>,
    memberships: Array<Membership>,
    licenses: MemberLicense[],
    defaultFederation?: FederationRef
    defaultLanguage?: Language,
    nationality?: string,
    feiNumber?: string,
    customAttributes?: Array<CustomAttribute>,
    memberBenefits?: Array<MemberBenefitAssociation>
    externalId?: string,
    profession?: Profession,
    accounts: Array<AccountResource>
}

export interface PersonRelation {
    person: RefWithName<PersonRef>,
    active: boolean,
    permission: PersonRelationPermission,
    type: PersonRelationType
}

export enum PersonRelationPermission {
    REGISTRATION = "REGISTRATION",
    FULL = "FULL"
}

export enum PersonRelationType {
    FATHER = "FATHER",
    MOTHER = "MOTHER",
    OTHER_FAMILY = "OTHER_FAMILY",
    ASSOCIATE = "ASSOCIATE"
}

export const initEmptySaveMemberForm = () => {
    const form: SaveMemberForm = {
        firstName: "",
        lastName: "",
        email: "",
        phone: undefined,
        mobilePhone: undefined,
        birthDate: undefined,
        deceasedDate: undefined,
        gender: undefined,
        relations: [],
        memberships: [],
        licenses: [],
        defaultFederation: undefined,
        defaultLanguage: undefined,
        nationality: "",
        customAttributes: [],
        memberBenefits: [],
        accounts: []
    };
    return form
};

export interface ChangeLanguageForm {
    language: string,
}

export const initEmptyChangeLanguageForm = () => {
    const form: ChangeLanguageForm = {
        language: "",
    };
    return form
};

export interface ChangePreferencesForm {
    defaultFederation?: FederationRef
    defaultLanguage?: Language
}

export interface ChangeFamilyForm {
    mother: RefWithName<PersonRef>,
    father: RefWithName<PersonRef>
}

export interface ChangeBasicInfo {
    firstname?: string,
    lastname?: string,
    address?: GeoLocation,
    email?: string;
}

export interface MemberProfile {
    ref?: MemberRef,
    federationRef?: FederationRef,
    name?: string,
    firstname?: string,
    lastname?: string,
    address?: GeoLocation,
    email?: string;
    avatar?: AvatarUrl;
    userDetails?: UserDetails
    federationDetails?: FederationDetails
    membershipDetails?: MembershipDetails
    licenseDetails?: LicenseDetails,
    nationality?: string,
    defaultFederation?: string,
    defaultLanguage?: Language,
    feiNumber?: string,
    childRelations: Array<PersonRelation>,
    relations: Array<PersonRelation>,
    isDeceased: boolean,
    deceasedDate?: string,
    accounts: Array<AccountResource>
}

export const initEmptyMemberProfile = () => {
    const form: MemberProfile = {
        ref: undefined,
        federationRef: undefined,
        name: "",
        email: "",
        address: undefined,
        avatar: undefined,
        membershipDetails: {},
        userDetails: {},
        federationDetails: {},
        licenseDetails: {},
        nationality: "",
        defaultFederation: undefined,
        defaultLanguage: undefined,
        childRelations: [],
        relations: [],
        isDeceased: false,
        deceasedDate: undefined,
        accounts: []
    };
    return form
};


export interface SimilarPerson {
    ref: PersonRef,
    name: string,
    email?: string,
    feiNumber?: string,
    birthDate?: string,
    address?: string,
    combinations: Array<HorseName>,
    recentCompetitions: Array<string>,
    memberships: Array<string>,
    matchBy: SimilarPersonMatchBy
}

export enum SimilarPersonMatchBy {
    NAME = "NAME",
    FEI = "FEI",
    EMAIL = "EMAIL",
}

export const getMemberRefFromRoute = (vue: Vue): MemberRef => {
    return vue.$route.params.member;
};

export const memberService = new MemberService();
