
import {mixins} from "vue-class-component";
import {Component, Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import ArticleSelectInput from "@/components/general/ArticleSelectInput.vue";

@Component({
  components: {ArticleSelectInput}
})
export default class ArticleSelectField extends mixins(Vue, abstractField) {

  get federationRef() {
    //@ts-ignore
    return this.schema.federationRef || undefined
  }

  // get disabled() {
  //   //@ts-ignore
  //   return this.schema.disabled || false
  // }
}
