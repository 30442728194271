import {OrganisationRef} from "@/modules/organisations/services/OrganisationService";
import {httpService} from "@/services/HttpService";

class OrganisationSecurityService {
    getProfileSecurity(organisationRef: OrganisationRef): Promise<OrganisationProfileSecurity> {
        return httpService.get(`/organisation/${organisationRef}/profile/security`)
    }
}

export interface OrganisationProfileSecurity {
    generalSecurity: OrganisationGeneralSecurity,
    membersPageSecurity: OrganisationMembersPageSecurity,
    competitionsPageSecurity: OrganisationCompetitionsPageSecurity
}

export interface OrganisationGeneralSecurity {
    canEdit: boolean,
    canEditAvatar: boolean,
    canViewOverviewPage: boolean,
    canViewMembersPage: boolean,
    canViewCompetitionsPage: boolean,
    canViewFinancePage: boolean,
    canViewInfoPage: boolean
}

export interface OrganisationMembersPageSecurity {
    canCreateMember: boolean,
    canEditMember: boolean
}

export interface OrganisationCompetitionsPageSecurity {
    canViewOpenRegistrations: boolean,
    canViewTimeAssignment: boolean,
    canViewPreRegistrations: boolean,
    canViewTeams: boolean
}

export const initOrganisationProfileSecurity = () => {
    return {
        generalSecurity: {
            canEdit: false,
            canEditAvatar: false,
            canViewCompetitionsPage: false,
            canViewInfoPage: false,
            canViewMembersPage: false,
            canViewFinancePage: false,
            canViewOverviewPage: true
        } as OrganisationGeneralSecurity,
        membersPageSecurity: {
            canEditMember: false,
            canCreateMember: false
        } as OrganisationMembersPageSecurity
    } as OrganisationProfileSecurity
}

export const organisationSecurityService = new OrganisationSecurityService();