
import {Component, Prop, Vue} from 'vue-property-decorator';
import EqifyLoader from "@/components/EqifyLoader.vue";

@Component({
  components: {EqifyLoader}
})
export default class EqifyDataLoader extends Vue {
  @Prop({default: "load data"}) loaderName?: String
}
