import {Vue} from "vue-property-decorator";
import VueWait from "vue-wait";

export function LoaderPlugin(vue: typeof Vue, options?: any): void {
    const wait = options && options['wait']
    const strategy = wait ? new VueWaitLoadingStrategy(wait) : new InMemoryLoadingStrategy()
    vue.prototype.$loader = new Loader(strategy);
}

export class Loader {

    constructor(private strategy: LoadingStrategy) {
    }

    public async load(callback: () => any, name: string = "loader", error: () => void = () => {
    }) {
        try {
            this.strategy.start(name)
            await callback()
        } catch (e) {
            Vue.$log.error("Error catched by loader:" + e)
            Vue.prototype.$toastr.e(e, "Whoops, something went wrong")
            if (error) error()
        } finally {
            this.strategy.end(name)
        }
    }

    public get isLoading() {
        return this.strategy.isLoading("loader")
    }

    public start(name: string) {
        this.strategy.start(name)
    }

    public end(name: string) {
        this.strategy.end(name)
    }

    public static create(vue: Vue | undefined = undefined, name: string = "loader") {
        const strategy = vue ? new VueWaitLoadingStrategy(vue.$wait) : new InMemoryLoadingStrategy()
        return new Loader(strategy)
    }
}

interface LoadingStrategy {
    start(name: string): void

    end(name: string): void

    isLoading(name: string): boolean
}

class InMemoryLoadingStrategy implements LoadingStrategy {
    private loading = false;

    start(): void {
        this.loading = true
    }

    end(): void {
        this.loading = false
    }

    isLoading(): boolean {
        return this.loading;
    }
}

class VueWaitLoadingStrategy implements LoadingStrategy {
    constructor(private wait: VueWait) {
    }

    start(name: string): void {
        this.wait.start(name)
    }

    end(name: string): void {
        this.wait.end(name)
    }

    isLoading(name: string): boolean {
        return this.wait.is(name)
    }
}
