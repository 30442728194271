
import Component, {mixins} from "vue-class-component";
import {Ref, Vue} from "vue-property-decorator";
import {abstractField} from "vue-form-generator";
import {BModal} from "bootstrap-vue";

@Component({})
export default class HtmlReadOnlyField extends mixins(Vue, abstractField) {
  @Ref("modal")
  private modal?: BModal
}
