
import {Component, Prop, Vue} from "vue-property-decorator";
import {enumValues, Option, toI18nOptions} from "@/utils/Utils";
import {
  EqifyRenewal,
  EqifyRenewalClosePeriod,
  EqifyRenewalPeriod,
  EqifyRenewalStartType,
  EqifyRenewalType
} from "@/shared/domain/EqifyRenewal";
import '@netcz/vue-pikaday/dist/vue-pikaday.min.css';
import {UI_DATE_FORMAT} from "@/utils/DateUtils";

@Component({})
export default class RenewalInput extends Vue {
  @Prop() value?: EqifyRenewal

  private renewal: EqifyRenewal = this.defaultRenewal
  private dayLimit = 0

  private closePeriodChecked = false

  mounted() {
    this.closePeriodChecked = (this.renewal.closePeriod != undefined)
  }

  private get defaultRenewal() {
    return {
      type: EqifyRenewalType.RECURRING,
      period: EqifyRenewalPeriod.ANNUAL,
      startType: EqifyRenewalStartType.DAY_OF_MONTH,
      startDay: 1,
      startMonth: 1,
      closePeriod: undefined
    } as EqifyRenewal
  }

  created() {
    if (this.value) {
      this.renewal = this.value

      if (this.renewal.startMonth) {
        this.dayLimit = this.maxDayForSelectedStartMonth(this.renewal.startMonth)
      }
    } else this.renewal = this.defaultRenewal
    this.emit()
  }

  get isRecurring(): boolean {
    return this.value?.type == EqifyRenewalType.RECURRING
  }

  get now(): boolean {
    return this.value?.startType == EqifyRenewalStartType.NOW
  }

  get dayOfWeek(): boolean {
    return this.value?.startType == EqifyRenewalStartType.DAY_OF_WEEK
  }

  get dayOfMonth(): boolean {
    return this.value?.startType == EqifyRenewalStartType.DAY_OF_MONTH
  }

  get dayOfYear(): boolean {
    return this.value?.startType == EqifyRenewalStartType.DAY_OF_YEAR
  }

  maxDayForSelectedStartMonth(month: number): number {
    return this.$moment(month, 'M').daysInMonth()
  }

  get months() {
    const months = this.$moment.months() as unknown as Array<string>
    return months.map(
        it => {
          return {key: months.indexOf(it) + 1, value: it}
        }
    )
  }

  get days() {
    const weekDays = Array.from(Array(6), (_, x) => x).map((it: any) => {
      return {key: it + 1, value: this.$moment(it + 1, 'e').format('dddd')}
    })
    weekDays.push({key: 0, value: this.$moment(0, 'e').format('dddd')})
    return weekDays
  }

  get renewalTypes(): Array<Option> {
    return toI18nOptions(enumValues(EqifyRenewalType), 'myfederation.memberbenefit.renewal.types', this)
  }

  get renewalPeriods(): Array<Option> {
    if (this.renewal.type == EqifyRenewalType.RECURRING) {
      return this.renewalRecurringPeriods
    } else return this.renewalOneTimePeriods
  }

  get renewalRecurringPeriods(): Array<Option> {
    return toI18nOptions(enumValues(EqifyRenewalPeriod), 'myfederation.memberbenefit.renewal.periods.RECURRING', this)
  }

  get renewalOneTimePeriods(): Array<Option> {
    return toI18nOptions(enumValues(EqifyRenewalPeriod), 'myfederation.memberbenefit.renewal.periods.ONE_TIME', this)
  }

  get renewalStartTypes(): Array<Option> {
    return toI18nOptions(enumValues(EqifyRenewalStartType), 'myfederation.memberbenefit.renewal.starttypes', this)
  }

  get type() {
    return this.renewal.type
  }

  set type(type: string) {
    this.renewal.type = type
  }

  get period() {
    return this.renewal.period
  }

  set period(period: string | undefined) {
    this.renewal.startDay = 1
    this.renewal.period = period
    this.emit()
  }

  get startType() {
    return this.renewal.startType
  }

  set startType(type: string | undefined) {
    this.renewal.startMonth = undefined
    this.renewal.startDay = undefined
    this.renewal.startType = type
    this.emit()
  }

  updateStartMonth(month: number) {
    this.renewal.startMonth = month
    this.dayLimit = this.maxDayForSelectedStartMonth(month)
    this.emit()
  }

  updateStartDay(day: number | undefined) {
    this.renewal.startDay = day
    this.emit()
  }

  get startDay() {
    return this.renewal.startDay
  }

  set startDay(day: number | undefined) {
    this.renewal.startDay = day
    this.emit()
  }

  get closePeriodStartMonth() {
    return this.renewal.closePeriod?.startMonth
  }

  set closePeriodStartMonth(month: number | undefined) {
    this.renewal.closePeriod = {
      ...this.renewal.closePeriod,
      startMonth: month
    } as EqifyRenewalClosePeriod
    this.emit()
  }

  get closePeriodEndMonth() {
    return this.renewal.closePeriod?.endMonth
  }

  set closePeriodEndMonth(month: number | undefined) {
    this.renewal.closePeriod = {
      ...this.renewal.closePeriod,
      endMonth: month
    } as EqifyRenewalClosePeriod
    this.emit()
  }

  get closePeriodStartDay() {
    return this.renewal.closePeriod?.startDay
  }

  set closePeriodStartDay(day: number | undefined) {
    this.renewal.closePeriod = {
      ...this.renewal.closePeriod,
      startDay: day
    } as EqifyRenewalClosePeriod
    this.emit()
  }

  get closePeriodEndDay() {
    return this.renewal.closePeriod?.endDay
  }

  set closePeriodEndDay(day: number | undefined) {
    this.renewal.closePeriod = {
      ...this.renewal.closePeriod,
      endDay: day
    } as EqifyRenewalClosePeriod
    this.emit()
  }

  private onClosePeriodChange(event: any) {
    this.closePeriodChecked = event
    if (!this.closePeriodChecked) this.renewal.closePeriod = undefined
  }

  emit() {
    this.$emit("input", this.renewal)
  }

  get pikadayOptions() {
    return Object.assign({
      format: UI_DATE_FORMAT,
      firstDay: 1
    })
  }

  get maxClosePeriodDay(): number {
    if (this.period == EqifyRenewalPeriod.WEEKLY) return 7
    else if (this.period == EqifyRenewalPeriod.MONTHLY || this.period == EqifyRenewalPeriod.ANNUAL) return 30 //TODO
    else return 0
  }

  get canHaveClosePeriod(): boolean {
    return this.period !== EqifyRenewalPeriod.DAILY
  }
}
