import Vue from "vue";
import {AvatarUrl, EqifyFeature, Language} from "@/shared/domain/General";
import axios from 'axios';
import {ConfigProvider} from "@/ConfigProvider";
import i18n from "@/i18n";

function EqifyTenantPlugin(vue: typeof Vue, options?: any): void {
    axios.get(ConfigProvider.serviceBaseUrl + "/../_/system/tenant", {headers: {"eqify-domain": window.location.hostname}}).then((result: any) => applyTenantConfig(vue, result.data))
}

function applyTenantConfig(vue: typeof Vue, tenant: TenantData) {
    if (!tenant) {
        return
    }
    vue.prototype.$tenant = new Tenant(tenant)
    if (!tenant.theme.match(/default/i)) {
        loadTheme(tenant.theme)
        i18n.locale = tenant.language.toLowerCase()
    }
}

function loadTheme(theme: string) {
    const link = document.querySelector('link[data-theme="theme"]') as HTMLLinkElement
    if (link) {
        link.href = `/css/${theme}`;
    } /*else {
        console.warn("Could not find the Theme CSS Link")
    }*/
}

export interface TenantData {
    federationRef: string,
    name: string,
    theme: string,
    language: Language,
    avatar: AvatarUrl,
    features: Array<EqifyFeature>
}

export class Tenant implements TenantData {
    avatar: AvatarUrl;
    features: Array<EqifyFeature>;
    federationRef: string;
    language: Language;
    name: string;
    theme: string;

    constructor(data: TenantData) {
        this.avatar = data.avatar
        this.features = data.features
        this.federationRef = data.federationRef
        this.language = data.language
        this.name = data.name
        this.theme = data.theme
    }

    hasFeature(feature: EqifyFeature) {
        return this.features.includes(feature)
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $tenant: Tenant,
    }
}

Vue.use(EqifyTenantPlugin);
