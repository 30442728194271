
import {Component, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {abstractField} from "vue-form-generator";
//@ts-ignore
import Password from 'vue-password-strength-meter'

@Component({
        components: {Password}
    })
    export default class PasswordField extends mixins(Vue, abstractField) {
    }
